import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";

import { MandatoryfieldService } from "../common/Validators/mandatoryfield.service";
import { AlertService } from "../services/alert.service";
import { AuthenticationService } from "../services/authentication.service";
import { ApiService } from '../services/apiservices.service';
import { CommonfunctionsService } from '../services/commonfunctions.service';
declare var $: any;
@Component({
  selector: "app-log-in",
  templateUrl: "./log-in.component.html",
  styleUrls: ["./log-in.component.css"]
})
export class LogInComponent implements OnInit {
  model: any = {};
  scope: any = {};
  errorText: any;

  loginForm: FormGroup;

  showModal: boolean = false;
  submitted: boolean = false;
  loading: boolean = false;
  forget_password: boolean = false;
  new_password: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    public authenticationService: AuthenticationService,
    private alertService: AlertService,
    private validate: MandatoryfieldService,
    private apiData: ApiService,
    private commonfunctions: CommonfunctionsService
  ) { }

  ngOnInit() {
    this.alertService.clearLocalstorage();
    this.loginForm = this.formBuilder.group({
      username: ["", Validators.required],
      password: ["", Validators.required]
    });

    // reset login status
    //this.authenticationService.logout();
  }
  get f() {
    return this.loginForm.controls;
  }
  changePassword() {
    this.submitted = false;
    this.alertService.enteredMailtoForgetScreen.set(this.f.username.value);
    this.authenticationService.loginFormsave(this.loginForm);
    this.authenticationService.forgetOrChangePassword('loggedoff');
  }

  Login() {
    let requestData: any;
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.alertService.clearLocalstorage();
    this.alertService.tokenId.remove();
    requestData = {
      username: this.f.username.value,
      password: this.authenticationService.enCryptText(this.f.password.value), //hashCode.toString()
      scope: "",
      grant_type: "password"
    };

    this.loading = true;
    this.authenticationService
      .login(requestData)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          this.submitted = false;
          if (data.status == 1) {
            this.alertService.userId.set(requestData.username);
            if (this.alertService.firstTimeLogin(data, 'login')) {
              this.authenticationService.loginFormsave(this.loginForm);
              this.alertService.changePasswordId.set(data.changepassword_id);
              this.authenticationService.showModal = true;
              this.authenticationService.firstTimeLogin();
              this.loginForm.reset();
              this.validate.popUpAlert("Please reset the password", "", "info");
            } else {
              this.alertService.first = false;
              if (data.userType == "Tie-Up Sub User" || data.userType == "TieUp Sub User") {
                this.alertService.navigateTo("/choose-transaction");
              } else if (data.userType == "MR-Super Admin") {
                this.alertService.navigateTo("/tie-up");
              } else if (data.userType == "Tie-Up Admin") {
                this.alertService.navigateTo("/user");
              } else {
                this.validate.popUpAlert("Invalid User Type", "", "error");
              }
            }
          } else {
            this.validate.popUpAlert(data.message, "", "error");
          }
        },
        error => {
          this.loading = false;
          this.commonfunctions.serviceErrorHandling(error);
        }
      );
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Component, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { HttpModule } from '@angular/http';

import { NgxSelectModule, INgxSelectOptions } from 'ngx-select-ex';
import { MyDatePickerModule } from 'mydatepicker';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { NgDragDropModule } from 'ng-drag-drop';
import { FileDropModule } from 'ngx-file-drop';
import { TooltipModule } from "ngx-tooltip";
import { NvD3Module } from 'ngx-nvd3';

import { AppComponent } from './app.component';
import { LogInComponent } from './log-in/log-in.component';
import { UserCreationComponent } from './user-creation/user-creation.component';
import { CommonModules } from './common/common.module';
import { RategenerationModule } from './rategeneration/rategeneration.module';

import { ApiService } from './services/apiservices.service';
import { AuthenticationService } from './services/authentication.service';
import { AlertService } from './services/alert.service';
import { CommonfunctionsService } from './services/commonfunctions.service';
import { CustomErrorHandler } from './error-interceptor';
import { HttpErrorInterceptor } from './error-interceptor';

import { HeaderComponent } from './header/header.component';
import { TieupmainComponent } from './tieupmain/tieupmain.component';
import { AuthGuard } from './auth/auth.guard';
import { RoleGuard } from './auth/role.guard';
import { ParametersettingComponent } from './parametersetting/parametersetting.component';
import { EnablereportComponent } from './enablereport/enablereport.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { UserlistDownloadComponent } from './userlist-download/userlist-download.component';
import { LoadingDiscountComponent } from './loading-discount/loading-discount.component';
import { RolesComponent } from './roles/roles.component';
import { TrackingactivityComponent } from './trackingactivity/trackingactivity.component';
import { ViewreportComponent } from './viewreport/viewreport.component';
import { PreviousyearComponent } from './previousyear/previousyear.component';
import { FinalloadingconfigComponent } from './finalloadingconfig/finalloadingconfig.component';
import { DemoTransactionidComponent } from './demo-transactionid/demo-transactionid.component';
import { CreateTieupComponent } from './create-tieup/create-tieup.component';
import { DistrictWiseFormComponent } from './district-wise-form/district-wise-form.component';
import { GlobalDefaultsComponent } from './global-defaults/global-defaults.component';
import { TieUpMappingComponent } from './tie-up-mapping/tie-up-mapping.component';
import { AdminUploadComponent } from './admin-upload/admin-upload.component';
import { FileUploadWarningsComponent } from './file-upload-warnings/file-upload-warnings.component';
import { FileDataWarningComponent } from './file-data-warning/file-data-warning.component';
import { ManageStateDistrictComponent } from './manage-state-district/manage-state-district.component';

const routes: Routes = [
  { path: 'login', component: LogInComponent },
  {
    path: 'user', component: UserCreationComponent, canActivate: [AuthGuard, RoleGuard], data: {
      expectedRole: 'Tie-Up Admin', expectedRole2: 'MR-Super Admin'
    }
  }, {
    path: 'user/:term', component: UserCreationComponent, canActivate: [AuthGuard, RoleGuard], data: {
      expectedRole: 'Tie-Up Admin', expectedRole2: 'MR-Super Admin'
    }
  },
  {
    path: 'report', component: EnablereportComponent, canActivate: [AuthGuard, RoleGuard], data: {
      expectedRole: 'MR-Super Admin', expectedRole2: ''
    }
  },
  {
    path: 'parameter-setting', component: ParametersettingComponent, canActivate: [AuthGuard, RoleGuard], data: {
      expectedRole: 'MR-Super Admin', expectedRole2: ''
    }
  },
  {
    path: 'tie-up', component: TieupmainComponent, canActivate: [AuthGuard, RoleGuard], data: {
      expectedRole: 'MR-Super Admin', expectedRole2: ''
    }
  },
  {
    path: 'create-tie-up', component: CreateTieupComponent, canActivate: [AuthGuard, RoleGuard], data: {
      expectedRole: 'MR-Super Admin', expectedRole2: '', mode: 'create'
    }
  },
  {
    path: 'update-tie-up/:id', component: CreateTieupComponent, canActivate: [AuthGuard, RoleGuard], data: {
      expectedRole: 'MR-Super Admin', expectedRole2: '', mode: 'edit'
    }
  },
  {
    path: 'district-wise', component: DistrictWiseFormComponent, canActivate: [AuthGuard, RoleGuard], data: {
      expectedRole: 'MR-Super Admin', expectedRole2: ''
    }
  },
  {
    path: 'global-defaults', component: GlobalDefaultsComponent, canActivate: [AuthGuard, RoleGuard], data: {
      expectedRole: 'MR-Super Admin', expectedRole2: ''
    }
  },
  {
    path: 'tieup-mapping', component: TieUpMappingComponent, canActivate: [AuthGuard, RoleGuard], data: {
      expectedRole: 'MR-Super Admin', expectedRole2: ''
    }
  },
  {
    path: 'manage-state-district', component: ManageStateDistrictComponent, canActivate: [AuthGuard, RoleGuard], data: {
      expectedRole: 'MR-Super Admin', expectedRole2: ''
    }
  },
  {
    path: 'yield-data-upload', component: AdminUploadComponent, canActivate: [AuthGuard, RoleGuard], data: {
      expectedRole: 'MR-Super Admin', expectedRole2: ''
    }
  },
  {
    path: 'user-list-download', component: UserlistDownloadComponent, canActivate: [AuthGuard, RoleGuard], data: {
      expectedRole: 'MR-Super Admin', expectedRole2: 'Tie-Up Admin'
    }
  },
  {
    path: 'configurator', component: LoadingDiscountComponent, canActivate: [AuthGuard, RoleGuard], data: {
      expectedRole: 'MR-Super Admin', expectedRole2: ''
    }
  }, {
    path: 'roles', component: RolesComponent, canActivate: [AuthGuard, RoleGuard], data: {
      expectedRole: 'MR-Super Admin', expectedRole2: ''
    }
  }, {
    path: 'activity-tracking', component: TrackingactivityComponent, canActivate: [AuthGuard, RoleGuard], data: {
      expectedRole: 'MR-Super Admin', expectedRole2: ''
    }
  }, {
    path: 'report-list', component: ViewreportComponent, canActivate: [AuthGuard, RoleGuard], data: {
      expectedRole: 'MR-Super Admin', expectedRole2: 'Tie-Up Sub User'
    }
  }, {
    path: 'previous-year', component: PreviousyearComponent, canActivate: [AuthGuard, RoleGuard], data: {
      expectedRole: 'Tie-Up Sub User', expectedRole2: ''
    }
  }, {
    path: 'finalloading-config', component: FinalloadingconfigComponent, canActivate: [AuthGuard, RoleGuard], data: {
      expectedRole: 'MR-Super Admin', expectedRole2: ''
    }
  },{
    path: 'demo-transId', component: DemoTransactionidComponent, canActivate: [AuthGuard, RoleGuard], data: {
      expectedRole: 'Tie-Up Sub User', expectedRole2: ''
    }
  },
  { path: 'change-password', component: ChangepasswordComponent },
  { path: '', component: LogInComponent },
  { path: '**', component: LogInComponent }
];
@NgModule({
  declarations: [
    AppComponent,
    LogInComponent,
    UserCreationComponent,
    HeaderComponent,
    TieupmainComponent,
    ParametersettingComponent,
    EnablereportComponent,
    ChangepasswordComponent,
    UserlistDownloadComponent,
    LoadingDiscountComponent,
    RolesComponent,
    TrackingactivityComponent,
    ViewreportComponent,
    PreviousyearComponent,
    FinalloadingconfigComponent,
    DemoTransactionidComponent,
    CreateTieupComponent,
    DistrictWiseFormComponent,
    GlobalDefaultsComponent,
    TieUpMappingComponent,
    AdminUploadComponent,
    FileUploadWarningsComponent,
    FileDataWarningComponent,
    ManageStateDistrictComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModules,
    RategenerationModule,
    NgxSelectModule,
    AngularFontAwesomeModule,
    MyDatePickerModule,
    FileDropModule,
    TooltipModule,
    NvD3Module,
    NgDragDropModule.forRoot(),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.threeBounce,
      backdropBackgroundColour: 'rgba(0, 0, 0, 0.1)',
      backdropBorderRadius: '10px',
      primaryColour: '#175788',
      secondaryColour: '#1b4a6e',
      tertiaryColour: '#054f88',
      fullScreenBackdrop: true
    }),
    RouterModule.forRoot(routes)
  ],
  providers: [ApiService, AuthenticationService, AlertService, CommonfunctionsService, AuthGuard, RoleGuard, UserCreationComponent, {
    provide: ErrorHandler,
    useClass: CustomErrorHandler
  }, { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }],
  bootstrap: [AppComponent]
})

export class AppModuleDev { }


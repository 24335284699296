import { Injectable } from "@angular/core";
import {
  Request,
  XHRBackend,
  RequestOptions,
  Response,
  Http,
  RequestOptionsArgs,
  Headers
} from "@angular/http";
import { Observable, Subject, BehaviorSubject } from "rxjs/Rx";
import { AlertService } from "./alert.service";
import { MandatoryfieldService } from '../common/Validators/mandatoryfield.service';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { promise } from 'protractor';
import { request } from 'http';
import { resolve } from 'dns';
import { environment } from '../../environments/environment';

@Injectable()
export class ApiService {
  // host: string = "https://mr-reva-fe-app-dev.azurewebsites.net/";
  host: string = environment.host; // "https://mr-reva-fe-app-development.azurewebsites.net/";
  // host: string = "https://mr-reva-fe-app-tst.azurewebsites.net/";
  path: string = "api/";
  url: string = this.host + this.path;
  api_target: string;
  header: any;
  reponseData: any;
  private _lastRequest: Observable<Response>;

  public dropdownList: any = {
    status: [
      {
        text: "Active",
        value: "Active",
      },
      {
        text: "Inactive",
        value: "DeActive",
      },
    ],
    statusTieup: [
      {
        text: "Active",
        value: "Active",
      },
      {
        text: "Inactive",
        value: "Inactive",
      },
    ],
    userstatus: [
      {
        text: "Active",
        value: "Active",
      },
      {
        text: "Inactive",
        value: "Inactive",
      },
    ],
    mandatory: [
      {
        text: "Yes",
        value: "Yes",
      },
      {
        text: "No",
        value: "No",
      },
    ],
    previousYear: [
      {
        text: new Date().getFullYear() - 1,
        value: new Date().getFullYear() - 1,
      },
    ],
  };
  authenticationUser() {
    let reqHeader = new HttpHeaders({
      ContentType: "application/x-www-urlencoded",
      Authorization: "Bearer " + this.alert.tokenId.get(),
    });
    return reqHeader;
  }
  public dateForm: any = {
    date: {},
    savedate: (data) => {
      this.dateForm.date = data;
    },
    getdate: () => {
      return this.dateForm.date;
    },
  };
  public saveData: any = {
    data: {},
    pushdata: (data) => {
      this.saveData.data = data;
    },
  };

  public tieUpEdit: any = {
    data: {},
    type: "",
    pushdata: (data) => {
      this.formRequest.EditOrCreate = "edit";
      this.tieUpEdit.data = data;
    },
    popData: () => {
      return this.tieUpEdit.data;
    },
    removData: () => {
      this.formRequest.EditOrCreate = "create";
      this.tieUpEdit.data = {};
    },
  };

  public outputCombo: any = {
    data: {},
    id: "",
    pushdata: (data) => {
      this.outputCombo.data = data;
    },
    popData: () => {
      return this.outputCombo.data;
    },
    removData: () => {
      this.outputCombo.data = {};
    },
  };
  public inputCombo: any = {
    data: {},
    pushdata: (data) => {
      this.inputCombo.data = data;
    },
    popData: () => {
      return this.inputCombo.data;
    },
    removData: () => {
      this.inputCombo.data = {};
    },
  };
  public userEdit: any = {
    data: {},
    pushdata: (data) => {
      this.formRequest.EditOrCreate = "edit";
      this.userEdit.data = data;
    },
    popData: () => {
      return this.userEdit.data;
    },
    removData: () => {
      this.formRequest.EditOrCreate = "create";
      this.userEdit.data = {};
    },
  };
  public editDetails: any = {
    data: {},
    pushdata: (data) => {
      this.formRequest.EditOrCreate = "edit";
      this.editDetails.data = data;
    },
    popData: () => {
      return this.editDetails.data;
    },
    removeAndAddData: (data) => {
      this.formRequest.EditOrCreate = "create";
      this.editDetails.data = {};
      this.editDetails.data = data;
    },
  };
  constructor(
    private alert: AlertService,
    private validate: MandatoryfieldService,
    private http: HttpClient,
    backend: XHRBackend,
    defaultOptions: RequestOptions
  ) {}

  /****************************************************
   * Form request Json before calling the api services
   * *************************************************/
  public formRequest: any = {
    EditOrCreate: "",
    getRequest: (additionalParam, formValue, type) => {
      var additionalVal = {};
      var data = {};
      data = formValue;
      if (type == "tieup") {
        var status_txt = data["status"].toLowerCase();
        data["status"] = status_txt != "active" ? "DeActive" : "Active";
        if (this.formRequest.EditOrCreate == "edit") {
          additionalVal = {
            tenantId: this.alert.getUserDetails("getTenant"),
            countryCode: "",
            id: additionalParam,
            updatedBy: this.alert.getUserDetails("getid"),
          };
        } else {
          additionalVal = {
            tenantId: this.alert.getUserDetails("getTenant"),
            countryCode: "",
            createdBy: this.alert.getUserDetails("getid"),
          };
        }
      } else if (type == "user") {
        var status_txt = data["status"].toLowerCase();
        data["status"] = status_txt != "active" ? "InActive" : "Active";
        if (this.formRequest.EditOrCreate == "edit") {
          additionalVal = {
            tenantId: this.alert.getUserDetails("getTenant"),
            id: additionalParam,
            updatedBy: this.alert.getUserDetails("getid"),
          };
        } else {
          additionalVal = {
            tenantId: this.alert.getUserDetails("getTenant"),
            createdBy: this.alert.getUserDetails("getid"),
          };
        }
      } else if (type == "report") {
        var array = [];
        for (let i = 0; i < formValue.length; i++) {
          array.push({
            tenantId: this.alert.getUserDetails("getTenant"),
            reportID: formValue[i]["reportID"],
            reportName: formValue[i]["reportName"],
            status: formValue[i]["status"],
            tieupID: formValue[i]["tieupID"],
            createdBy: this.alert.getUserDetails("getid"),
          });
        }
        additionalVal = array;
      } else if (type == "parameter") {
        additionalVal = {
          tieupID: this.editDetails.data["tieupID"],
          paramName: this.editDetails.data["paramName"],
          parameterID: this.editDetails.data["parameterID"],
          tenantId: this.alert.getUserDetails("getTenant"),
          country: additionalParam,
          createdBy: this.alert.getUserDetails("getid"),
        };
      } else if (type == "loadingdisc") {
        additionalVal = {
          tieupID: this.editDetails.data["tieupID"],
          country: this.editDetails.data["country"],
          loadingDiscountID: this.editDetails.data["loadingDiscountID"],
          //tenantId: this.alert.getUserDetails("getTenant"),
          createdBy: this.alert.getUserDetails("getid"),
        };
        data["isMandatory"] = data["isMandatory"] == "Yes" ? true : false;
        data["isActive"] = data["isActive"] == "Active" ? true : false;
      } else if (type == "inputcombination") {
        additionalVal = {
          tieupID: this.alert.getUserDetails("getTieup"),
          tenantId: this.alert.getUserDetails("getTenant"),
          createdBy: this.alert.getUserDetails("getid"),
          rateGenerationID: this.alert.rateGenerationID.get(),
        };
      } else if (type == "outputcombination") {
        additionalVal = {
          tieupID: this.alert.getUserDetails("getTieup"),
          tenantId: this.alert.getUserDetails("getTenant"),
          updatedBy: this.alert.getUserDetails("getid"),
          id: this.alert.combinationId.get(),
        };
      } else if (type == "rateloadingdisc") {
        additionalVal = {
          tieupID: this.alert.getUserDetails("getTieup"),
          tenantId: this.alert.getUserDetails("getTenant"),
          updatedBy: this.alert.getUserDetails("getid"),
          id: this.alert.combinationId.get(),
        };
      } else if (type == "getReprtList") {
        additionalVal = {
          tieupID: this.alert.getUserDetails("getTieup") ? 'root' : null,
          userType: this.alert.getUserDetails("getUsertype"),
        };
      } else if (type == "getState") {
        additionalVal = {
          tieupID: data["tieupName"],
          userType: this.alert.getUserDetails("getUsertype"),
          year: data["Year"],
          reportType: data["reportName"],
          loginUser: this.alert.getUserDetails("getid"),
        };
      } else if (type == "getReportYears") {
        additionalVal = {
          tieupID: data["tieupName"],
          reportType: data["reportName"],
          userType: this.alert.getUserDetails("getUsertype"),
          loginUser: this.alert.getUserDetails("getid"),
        };
      } else if (type == "getTransactionid") {
        additionalVal = {
          tieupID: data["tieupName"],
          userType: this.alert.getUserDetails("getUsertype"),
          year: data["Year"],
          loginUser: this.alert.getUserDetails("getid"),
          state: data["state"],
        };
      } else if (type == "getPricingResultsRpt") {
        additionalVal = {
          tieupID: data["tieupName"],
          transactionID: data["transactionID"],
        };
      } else if (type == "getBarChart") {
        additionalVal = {
          tieupID: data["tieupName"],
          transactionID: data["transactionID"],
          previousYearToCompare: data["compareyear"],
        };
      } else if (type == "UWCommentsRpt") {
        additionalVal = {
          tieupID: data["tieupName"],
          transactionID: data["transactionID"],
          state: data["state"],
          year: data["Year"],
        };
      } else if (type == "getCompareyear") {
        additionalVal = {
          tieupID: data["tieupName"],
          userType: this.alert.getUserDetails("getUsertype"),
        };
      }
      if (
        type == "report" ||
        type == "getState" ||
        type == "getReportYears" ||
        type == "getTransactionid" ||
        type == "getPricingResultsRpt" ||
        type == "UWCommentsRpt" ||
        type == "getBarChart"
      ) {
        this.saveData.pushdata(additionalVal);
      } else {
        if (additionalVal != undefined) {
          var keys = Object.keys(additionalVal);
          keys.map((obj) => {
            data[obj] = additionalVal[obj];
          });
        }
        this.saveData.pushdata(data);
      }
    },
  };
  /******************************************
   * Tie Up ::::: GET,CREATE,EDIT:::::::Start
   * ****************************************/
  public portalRevaApi: any = {
    url: this.url,
    getTieUpList: (success, err) => {
      this.http
        .get(this.portalRevaApi.url + "tieups", {
          headers: this.authenticationUser(),
        })
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    CreateEditTieUp: (success, err) => {
      let type = "tieups";
      let id = "";
      if (this.formRequest.EditOrCreate == "edit") {
        id = "/" + this.saveData.data.id;
        this.http
          .put(this.portalRevaApi.url + type + id, this.saveData.data, {
            headers: this.authenticationUser(),
          })
          .subscribe(
            (response) => {
              if (typeof success === "function") {
                success(response);
              }
            },
            (error) => {
              if (typeof err === "function") {
                err(error);
              }
            }
          );
      } else {
        this.http
          .post(this.portalRevaApi.url + type, this.saveData.data, {
            headers: this.authenticationUser(),
          })
          .subscribe(
            (response) => {
              if (typeof success === "function") {
                success(response);
              }
            },
            (error) => {
              if (typeof err === "function") {
                err(error);
              }
            }
          );
      }
    },
  };

  /******************************************
   * USer ::::: GET,CREATE,EDIT:::::::Start
   * ****************************************/
  public userAPI: any = {
    url: this.url,
    getUserList: (success, err) => {
      this.http
        .get(
          this.userAPI.url +
            "users/usersByTieup/" +
            this.alert.getUserDetails("getTenant"),
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    CreateOrEditUser: (success, err) => {
      let type = "users";
      let id = "";
      if (this.formRequest.EditOrCreate == "edit") {
        id = "/" + this.saveData.data.id;
        this.http
          .put(this.userAPI.url + type + id, this.saveData.data, {
            headers: this.authenticationUser(),
          })
          .subscribe(
            (response) => {
              if (typeof success === "function") {
                success(response);
              }
            },
            (error) => {
              if (typeof err === "function") {
                err(error);
              }
            }
          );
      } else {
        this.http
          .post(this.userAPI.url + type, this.saveData.data, {
            headers: this.authenticationUser(),
          })
          .subscribe(
            (response) => {
              if (typeof success === "function") {
                success(response);
              }
            },
            (error) => {
              if (typeof err === "function") {
                err(error);
              }
            }
          );
      }
    },
  };
  /******************************************
   * Report ::::: GET,EDIT:::::::Start
   * ****************************************/
  public reportApi: any = {
    url: this.url,
    id: "",
    saveId: (data) => {
      this.reportApi.id = data;
    },
    getlist: (success, err) => {
      this.http
        .get(
          this.reportApi.url + "reports/reportsByTieup/" + this.reportApi.id,
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    saveReport: (success, err) => {
      this.http
        .post(this.portalRevaApi.url + "reports", this.saveData.data, {
          headers: this.authenticationUser(),
        })
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
  };

  public finalloadingApi: any = {
    url: this.url,
    finalloadinglist: "",
    id: "",
    saveId: (data) => {
      this.finalloadingApi.id = data;
    },
    getlist: (success, err) => {
      this.http
        .get(
          this.finalloadingApi.url +
            "finalloading/finalLoadingByTieupID/" +
            this.finalloadingApi.id,
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    uploadfinalloading: (success, err) => {
      this.http
        .post(this.parameterApi.url + "finalloading/view", this.saveData.data, {
          headers: this.authenticationUser(),
        })
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    savefinalloading: (success, err) => {
      this.http
        .post(this.parameterApi.url + "finalloading", this.saveData.data, {
          headers: this.authenticationUser(),
        })
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
  };

  /******************************************
   * Parameter ::::: GET,EDIT:::::::Start
   * ****************************************/
  public parameterApi: any = {
    url: this.url,
    id: "",
    saveId: (data) => {
      this.parameterApi.id = data;
    },
    getlist: (success, err) => {
      this.http
        .get(
          this.parameterApi.url +
            "parameterSettings/paramSettingsByTieup/" +
            this.parameterApi.id,
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    createParameter: (success, err) => {
      this.http
        .post(this.parameterApi.url + "parameterSettings", this.saveData.data, {
          headers: this.authenticationUser(),
        })
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    updateCount: (success, err) => {
      this.http
        .post(
          this.parameterApi.url + "parameterSettings/saveParameterInput",
          this.saveData.data,
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    deleteParameter: (success, err) => {
      this.http
        .delete(
          this.parameterApi.url +
            "parameterSettings/" +
            this.parameterApi.id +
            "/" +
            this.alert.getUserDetails("getid"),
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
  };
  /******************************************
   * loading and discounting config ::::: GET,EDIT:::::::Start
   * ****************************************/
  public loadingApi: any = {
    url: this.url,
    id: "",
    country: "",
    saveId: (data) => {
      this.loadingApi.id = data;
    },
    selectedcountry: (data) => {
      this.loadingApi.country = data;
    },
    getlist: (success, err) => {
      this.http
        .get(
          this.loadingApi.url +
            "loadingDiscount/loadingDiscountConfig/" +
            this.loadingApi.id,
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    getloadingDiscount: (success, err, ...param) => {
      if (!param) param = [];
      this.http
        .get(
          this.loadingApi.url +
            "loadingDiscount/loadingDiscount/" +
            (param[0] || this.alert.getUserDetails("getTenant")) + `/${param[1] || 'all'}/${param[2] || 'all'}/all`,
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    createLoadingDisc: (success, err) => {
      this.http
        .post(this.loadingApi.url + "loadingDiscount", this.saveData.data, {
          headers: this.authenticationUser(),
        })
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
  };
  /******************************************
   * roles and right ,User tracking ::::: GET::::Start
   * ****************************************/
  public roleApi: any = {
    url: this.url,
    reqData: "",
    method: "",
    selectedData: (data, type) => {
      this.roleApi.reqData = data;
      if (type == "rights") {
        this.roleApi.method = "moduleRightsByRole?userType=";
      } else if (type == "activity") {
        this.roleApi.method = "users/activitiesSearch";
      }
    },
    getlist: (success, err) => {
      this.http
        .get(this.roleApi.url + this.roleApi.method + this.roleApi.reqData, {
          headers: this.authenticationUser(),
        })
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    getTrackinglist: (success, err) => {
      this.http
        .post(this.roleApi.url + this.roleApi.method, this.roleApi.reqData, {
          headers: this.authenticationUser(),
        })
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
  };
  /******************************************
   * User Download ::::: GET,EDIT:::::::Start
   * ****************************************/
  public userlistDownloadApi: any = {
    url: this.url,
    id: "",
    country: "",
    saveId: (data) => {
      this.userlistDownloadApi.id = data;
    },
    selectedcountry: (data) => {
      this.userlistDownloadApi.country = data;
    },
    getlist: (success, err) => {
      this.http
        .get(
          this.userlistDownloadApi.url +
            "users/usersListDownload/" +
            this.userlistDownloadApi.id,
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
  };

  /******************************************
   * USER-END    Parameter ::::: GET,EDIT:::::::Start
   * ****************************************/
  public getParameterAPI: any = {
    url: this.url,
    id: "",
    getlist: (success, err) => {
      this.http
        .get(
          this.getParameterAPI.url +
            "rateGeneration/inOutCombination/" +
            this.alert.getUserDetails("getTieup"),
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    getlistPY: (success, err) => {
      this.http
        .get(
          this.getParameterAPI.url +
            "rateGeneration/inOutCombinationPY/" +
            this.alert.getUserDetails("getTieup"),
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    getLoadongDiscount: (success, err, param?) => {
      let path = 'rateGeneration/loadingDiscount/' + this.alert.getUserDetails("getTieup");
      if (param) {
        const paramData =  JSON.parse(atob(param));
        const state = paramData[0];
        let adminlevel = 'all';
        let admin = 'all';
        if (paramData[1]) {
          adminlevel = paramData[1]
        }
        if (paramData[2]) {
          admin = paramData[2]
        }

        path += `/${state}/${admin}/${adminlevel}`;
      }
      this.http
        .get(
          this.getParameterAPI.url + path ,
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
  };
  /***************************************************
   * USER-END/Rate Generation     ::::Post data:::::::Start
   * ***********************************************/
  public rateGenerationApi: any = {
    url: this.url,
    id: "",
    saveInputs: (success, err) => {
      this.http
        .post(
          this.rateGenerationApi.url + "rateGeneration/generateRate",
          this.saveData.data,
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    saveOutput: (success, err) => {
      this.http
        .post(
          this.rateGenerationApi.url +
            "rateGeneration/outputCombinationSave/" +
            this.alert.combinationId.get(),
          this.saveData.data,
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    uploadFile: (success, err) => {
      this.http
        .post(
          this.rateGenerationApi.url + "rateGeneration",
          this.saveData.data,
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    skipCsvUpload: (success, err) => {
      this.http
        .post(
          this.rateGenerationApi.url + "rateGeneration/skipCsvUpload",
          this.saveData.data,
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    MRexecuteApi: (success, err) => {
      this.http
        .post(
          this.rateGenerationApi.url +
            "rateGeneration/MRexecuteApiResponse/" +
            this.alert.getUserDetails("getTenant") +
            "/" +
            this.alert.rateGenerationID.get(),
          "",
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    MRexecuteApiNew: (success, err) => {
      this.http
        .post(
          this.rateGenerationApi.url +
            "rateGeneration/MRexecuteApiResponseNew/" +
            this.alert.getUserDetails("getTenant") +
            "/" +
            this.alert.rateGenerationID.get(),
          "",
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    getTransactonByMRId: (success, err) => {
      this.http
        .get(
          this.rateGenerationApi.url +
            "rateGeneration/GetTransactonByMRId/" +
            this.alert.mrCode.get(),
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    getTransactonByUserId: (success, err) => {
      this.http
        .get(
          this.rateGenerationApi.url +
            "rateGeneration/GetTransactonByUserId/",
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    markTransactionRateGenerated: (success, err) => {
      this.http
        .post(
          this.rateGenerationApi.url +
            "rateGeneration/MarkTransactionRateGenerated/" + this.alert.mrCode.get(),{},
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    getTransactonByTenantId: (success, err) => {
      this.http
        .get(
          this.rateGenerationApi.url +
            "rateGeneration/GetTransactonByTenantId/" +
            this.alert.getUserDetails("getTenant"),
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    getRateGenerationData: (success, err) => {
      this.http
        .get(
          this.rateGenerationApi.url +
            "rateGeneration/getRateGenerationData/" +
            this.alert.rateGenerationID.get(),
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    MRgetstatusApi: (success, err) => {
      let statusPromise = this.http
        .post(
          this.rateGenerationApi.url +
            "rateGeneration/MRgetStatusApiResponse/" +
            this.alert.getUserDetails("getTenant") +
            "/" +
            this.alert.rateGenerationID.get(),
          "",
          { headers: this.authenticationUser() }
        )
        .toPromise();

      let statusMarketRatePromise = this.http
        .post(
          this.rateGenerationApi.url +
            "rateGeneration/MRgetStatusApiResponse/" +
            this.alert.getUserDetails("getTenant") +
            "/" +
            this.alert.rateGenerationID.get() +
            "_mr",
          "",
          { headers: this.authenticationUser() }
        )
        .toPromise();

      Promise.all([statusPromise, statusMarketRatePromise])
        .then((results) => {
          var res0 = results[0] as any;
          var res1 = results[1] as any;

          if (
            res0.data.toLowerCase() === "completed" &&
            res1.data.toLowerCase() === "completed"
          ) {
            success(res0);
          }

          if (res0.data.toLowerCase() === "inprogress") {
            success(res0);
          }
          if (res1.data.toLowerCase() === "inprogress") {
            success(res1);
          }

          if (res0.data.toLowerCase() === "terminated") {
            success(res0);
          }

          if (res1.data.toLowerCase() === "terminated") {
            success(res1);
          }
        })
        .catch((error) => {
          if (typeof err === "function") {
            err(error);
          }
        });
    },
    saveLoadingDisc: (success, err) => {
      this.http
        .post(
          this.rateGenerationApi.url +
            "rateGeneration/CalculateLoadingDiscount/" +
            this.alert.combinationId.get(),
          this.saveData.data,
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    deleteTransactionByUserId: (success, err) => {
      this.http
        .delete(
          this.rateGenerationApi.url +
            "rateGeneration/DeleteTransactonByUserId",
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    viewRateData: (success, err) => {
      this.http
        .get(
          this.rateGenerationApi.url +
            "rateGeneration/viewRateData/" +
            this.alert.getUserDetails("getTenant") +
            "/" +
            this.alert.rateGenerationID.get(),
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    downloadRateData: (success, err) => {
      this.http
        .get(
          this.rateGenerationApi.url +
            "rateGeneration/viewRateDataPdf/" +
            this.alert.getUserDetails("getTenant") +
            "/" +
            this.alert.rateGenerationID.get(),
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    downloadRateFile: (success, err) => {
      this.http
        .get(
          this.rateGenerationApi.url +
            "rateGeneration/downloadRateFile/" +
            this.alert.downloadInUsertracking.get("id") +
            "/" +
            this.alert.downloadInUsertracking.get("type"),
          {
            headers: this.authenticationUser(),
            responseType: "blob",
            observe: "response",
          }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    downloadSampleCsv: (success, err) => {
      this.http
        .get(this.rateGenerationApi.url + "rateGeneration/downloadSample", {
          headers: this.authenticationUser(),
          responseType: "blob",
          observe: "response",
        })
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    downloadPrevSampleCsv: (success, err) => {
      this.http
        .get(
          this.rateGenerationApi.url + "previousYearRate/prevYearRateTemplate",
          {
            headers: this.authenticationUser(),
            responseType: "blob",
            observe: "response",
          }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    getAdminLevel: (success, err) => {
      this.http
        .post(
          this.rateGenerationApi.url + "rateGeneration/adminLevelByState",
          this.saveData.data,
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    uploadPreviousYearRate: (success, err) => {
      this.http
        .post(
          this.rateGenerationApi.url + "previousYearRate",
          this.saveData.data,
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    transactionMapList: (success, err) => {
      this.http
        .get(
          this.rateGenerationApi.url +
            "previousYearRate/transactionMapList/" +
            this.rateGenerationApi.id,
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
  };
  /******************************************
   * Final Report ::::::Start
   * ****************************************/
  finalReportsApi: any = {
    url: this.url,
    getReport: (success, err) => {
      this.http
        .post(this.finalReportsApi.url + "viewReports", this.saveData.data, {
          headers: this.authenticationUser(),
        })
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    getState: (success, err) => {
      this.http
        .post(
          this.finalReportsApi.url + "viewReports/States",
          this.saveData.data,
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    getTransactionid: (success, err) => {
      this.http
        .post(
          this.finalReportsApi.url + "viewReports/transactionIDSearch",
          this.saveData.data,
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    getPreviousYearToCompare: (success, err) => {
      this.http
        .post(
          this.finalReportsApi.url + "viewReports/previousYearToCompare",
          this.saveData.data,
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    getPricingResultsRpt: (success, err) => {
      this.http
        .post(
          this.finalReportsApi.url + "viewReports/pricingResultsRpt",
          this.saveData.data,
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    getPricingResultsLoadingsRpt: (success, err) => {
      this.http
        .post(
          this.finalReportsApi.url + "viewReports/pricingResultsLoadingsRpt",
          this.saveData.data,
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    getBoxplotRpt: (success, err) => {
      this.http
        .post(
          this.finalReportsApi.url + "viewReports/boxPlotRpt",
          this.saveData.data,
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    getPreviousYearRatesComparisonRpt: (success, err) => {
      this.http
        .post(
          this.finalReportsApi.url +
            "viewReports/previousYearRatesComparisonRpt",
          this.saveData.data,
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    giveWarningMessageRpt: (success, err) => {
      this.http
        .post(
          this.finalReportsApi.url + "viewReports/giveWarningMessageRpt",
          this.saveData.data,
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    UWCommentsRpt: (success, err) => {
      this.http
        .post(
          this.finalReportsApi.url + "viewReports/UWCommentsRpt",
          this.saveData.data,
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    previousYearPricingResultsRpt: (tieup, success, err) => {
      this.http
        .post(
          this.finalReportsApi.url + "viewReports/previousYearPricingResultsRpt",
          {
            "tieupID": tieup
          },
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    }
  };
  /******************************************
   * Load DROP DOWN ITEMS ::::::Start
   * ****************************************/
  tieupDropdownFormation(response) {
    let resultdata = response;
    let additionalVal = [];
    if (response.status == 1) {
      for (let i = 0; i < resultdata["data"].length; i++) {
        additionalVal.push({
          value: response["data"][i].value,
          text: response["data"][i].text,
          list: response["data"][i].value + "_" + response["data"][i].text,
        });
      }

      if (additionalVal != undefined) {
        var keys = Object.keys(response["data"]);
        keys.map((obj) => {
          resultdata["data"][obj] = additionalVal[obj];
        });
      }
    }
    return resultdata;
  }
  getdropdownList: any = {
    url: this.url,
    country: "",
    loadingDiscountKey: "",
    selectedcountry: (data) => {
      this.getdropdownList.country = data;
    },
    selectedLDkey: (data) => {
      this.getdropdownList.loadingDiscountKey = data;
    },
    getCountry: (success, err) => {
      this.http
        .get(
          this.getdropdownList.url +
            "countries" +
            "/" +
            this.alert.getUserDetails("getTenant"),
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    getloadingItems: (success, err) => {
      this.http
        .get(this.getdropdownList.url + "finalloading/loadingDiscountItems", {
          headers: this.authenticationUser(),
        })
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    getsubsectionsbyLD: (success, err) => {
      //
      this.http
        .get(
          this.getdropdownList.url +
            "finalloading/subSectionsByLD" +
            "/" +
            this.getdropdownList.loadingDiscountKey,
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    getAllUserType: (success, err) => {
      this.http
        .get(this.getdropdownList.url + "userTypes", {
          headers: this.authenticationUser(),
        })
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    getUserType: (success, err) => {
      this.http
        .get(
          this.getdropdownList.url +
            "userTypes/" +
            this.alert.getUserDetails("getTenant"),
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    getTieUplist: (success, err) => {
      this.http
        .get(
          this.getdropdownList.url +
            "tieupsById/" +
            this.alert.getUserDetails("getTenant"),
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              let result = this.tieupDropdownFormation(response);
              success(result);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    getTieUplistByCountry: (success, err) => {
      this.http
        .get(
          this.getdropdownList.url +
            "tieupsByCountry/" +
            this.getdropdownList.country +
            "/" +
            this.alert.getUserDetails("getTenant"),
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              let result = this.tieupDropdownFormation(response);
              success(result);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    getPreviousYear: (success, err) => {
      this.http
        .get(this.getdropdownList.url + "previousYears", {
          headers: this.authenticationUser(),
        })
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    getTransactionIDByTieUp: (success, err) => {
      this.http
        .get(
          this.getdropdownList.url +
            "rateGeneration/transactionIDByTieUp/" +
            this.alert.getUserDetails("getTieup") +
            "/" +
            this.alert.getUserDetails("getid") +
            "/" +
            this.alert.rateGenerationID.get(),
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
    getReportYears: (success, err) => {
      this.http
        .post(this.getdropdownList.url + "reportYears", this.saveData.data, {
          headers: this.authenticationUser(),
        })
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
  };

  /*******************************
   * Global Error handling
   *****************************/

  public errorhanlingApi: any = {
    url: this.url,
    data: {},
    generateRequestData: (error, page) => {
      this.errorhanlingApi.data = {
        logApplication: "Client",
        logModule: page, //controller name or screen name
        logDesc: error, // exception error message
        tenantId: this.validate.isUndefined(
          this.alert.getUserDetails("getTenant")
        )
          ? "root"
          : this.alert.getUserDetails("getTenant"), //by default root or tenantId
        createdBy: this.alert.getUserDetails("getid"),
      };
    },
    sendError: (success, err) => {
      this.http
        .post(
          this.errorhanlingApi.url + "ErrorLog",
          this.errorhanlingApi.data,
          { headers: this.authenticationUser() }
        )
        .subscribe(
          (response) => {
            if (typeof success === "function") {
              success(response);
            }
          },
          (error) => {
            if (typeof err === "function") {
              err(error);
            }
          }
        );
    },
  };

  /******************************************
   * Load DROP DOWN ITEMS ::::::Start
   * ****************************************/

  getTransactionIds() {
    return this.http
      .get(
        this.userAPI.url +
          "rateGeneration/transactionIDs/" +
          this.alert.getUserDetails("getTenant"),
        { headers: this.authenticationUser() }
      )
      .toPromise();
  }
  getTransactionInputInfo(csvId) {
    return this.http
      .get(this.userAPI.url + "rateGeneration/transactionInput/" + csvId, {
        headers: this.authenticationUser(),
      })
      .toPromise();
  }
  getTransactionOutputInfo(csvId) {
    return this.http
      .get(this.userAPI.url + "rateGeneration/rateGeneratedResult/" + csvId, {
        headers: this.authenticationUser(),
      })
      .toPromise();
  }

  getStateDistrictWiseData() {
    return this.http
      .get(
        this.userAPI.url +
          "parameterSettings?parameterName=district&tieupID=" +
          this.alert.getUserDetails("getTenant"),
        { headers: this.authenticationUser() }
      )
      .toPromise();
  }

  rateGenerationProcessYieldData(request) {
    return this.http
      .post(
        this.userAPI.url +
          "rateGeneration/processYieldData",
          request,
        { headers: this.authenticationUser() }
      )
      .toPromise();
  }

  /******************************************
   * Load DROP DOWN ITEMS ::::::End
   * ****************************************/

   masterUploadYieldDataUpload(request) {
    return this.http
      .post(
        this.userAPI.url +
          "MasterUpload/yieldDataUpload",
          request,
        { headers: this.authenticationUser() }
      )
      .toPromise();
    }
   masterUploadYielVarifyYiedData(request) {
    return this.http
      .post(
        this.userAPI.url +
          "MasterUpload/verifyYieldData",
          request,
        { headers: this.authenticationUser() }
      )
      .toPromise();
    }
   masterUploadValidatedYieldDataUpload(request) {
    return this.http
      .post(
        this.userAPI.url +
          "MasterUpload/validatedYieldDataUpload",
          request,
        { headers: this.authenticationUser() }
      )
      .toPromise();
    }
   masterUploadVerifyDistrictsLevelLoadingData(request) {
    return this.http
      .post(
        this.userAPI.url +
          "MasterUpload/verifyDistrictsLevelLoadingData",
          request,
        { headers: this.authenticationUser() }
      )
      .toPromise();
    }
   masterUploadValidatedDistrictLoadingDataUpload(request) {
    return this.http
      .post(
        this.userAPI.url +
          "MasterUpload/validatedDistrictLoadingDataUpload",
          request,
        { headers: this.authenticationUser() }
      )
      .toPromise();
    }
   masterUploadDownloadSampleDistrictLoadingUpload() {
    return this.http
      .get(
        this.userAPI.url +
          "MasterUpload/downloadSampleDistrictLoadingUpload",
          {
            headers: this.authenticationUser(),
            responseType: "blob",
            observe: "response",
          }
      )
      .toPromise();
    }
   masterUploadGetLastYieldDataUploadStatus(state: any) {
    return this.http
      .get(
        this.userAPI.url +
          "MasterUpload/getLastYieldDataUploadStatus/"+state,
          {
            headers: this.authenticationUser(),
          }
      )
      .toPromise();
    }
    MasterUploadClusterMapUpload(request: any) {
      return this.http
      .post(
        this.userAPI.url +
          "MasterUpload/clusterMapUpload",
          request,
        { headers: this.authenticationUser() }
      )
      .toPromise();
    }
    MasterUploadUpdateClusterMapping(request: any) {
      return this.http
      .post(
        this.userAPI.url +
          "MasterUpload/updateClusterMapping",
          request,
        { headers: this.authenticationUser() }
      )
      .toPromise();
    }
    MasterUploaddownloadSampleClusterMapping() {
      return this.http
      .get(
        this.userAPI.url +
        "MasterUpload/downloadSampleClusterMapping",
          {
            headers: this.authenticationUser(),
            responseType: "blob",
            observe: "response",
          }
      )
      .toPromise();
      }
    getLastYieldData(state: any) {
    return this.http
      .get(
        this.userAPI.url +
          "rateGeneration/getLastYieldData/"+state,
          {
            headers: this.authenticationUser(),
          }
      )
      .toPromise();
    }
   masterUploadGetDistrictLevelLoadingUploadStatus(state: any) {
    // Transaction Id will wanished after refresh
    return this.http
      .get(
        this.userAPI.url +
          "/MasterUpload/getDistrictsLevelLoadingUploadStatus/"+state,
          {
            headers: this.authenticationUser(),
          }
      )
      .toPromise();
    }
   masterUploadaddZeroWarningYield(request: any) {
    return this.http.post(
        this.userAPI.url +
          "/MasterUpload/addZeroWarningYield", request,
          {
            headers: this.authenticationUser(),
          }
      )
      .toPromise();
    }
   masterUploadaddBlankWarningYield(request: any) {
    return this.http.post(
        this.userAPI.url +
          "/MasterUpload/addBlankWarningYield", request,
          {
            headers: this.authenticationUser(),
          }
      )
      .toPromise();
    }
   masterUploadaddOutliersWarningYield(request: any) {
    return this.http.post(
        this.userAPI.url +
          "/MasterUpload/addOutliersWarningYield", request,
          {
            headers: this.authenticationUser(),
          }
      )
      .toPromise();
    }
   masterUploadaddZeroWarningYieldFile(request: any) {
    return this.http.post(
        this.userAPI.url +
          "/MasterUpload/addZeroWarningYieldFile", request,
          {
            headers: this.authenticationUser(),
          }
      )
      .toPromise();
    }
   masterUploadaddBlankWarningYieldFile(request: any) {
    return this.http.post(
        this.userAPI.url +
          "/MasterUpload/addBlankWarningYieldFile", request,
          {
            headers: this.authenticationUser(),
          }
      )
      .toPromise();
    }
   masterUploadaddOutliersWarningYieldFile(request: any) {
    return this.http.post(
        this.userAPI.url +
          "/MasterUpload/addOutliersWarningYieldFile", request,
          {
            headers: this.authenticationUser(),
          }
      )
      .toPromise();
    }
   masterUploadgetAgreeDatasetWarnings(year: any,state: any) {
    return this.http.get(
        this.userAPI.url +
          `/MasterUpload/getAgreeDatasetWarnings?year=${year}&state=${state}`,
          {
            headers: this.authenticationUser(),
          }
      )
      .toPromise();
      }

      masterUploadGetDistrictUploadTransaction() {
        return this.http.get(
            this.userAPI.url +
              `/MasterUpload/getDistrictUploadTransaction`,
              {
                headers: this.authenticationUser(),
              }
          )
          .toPromise();
    }

    /******************************************
   * MASTER UPLOAD ::::::Start
   * ****************************************/


  /******************************************
   * MASTER UPLOAD ::::::Start
   * ****************************************/


   /******************************************
   * RATE GENERATION ::::::Start
   * ****************************************/
    async  rateGenerationGetYieldData(state: any, year: any) {
      return this.http
        .get(
          this.userAPI.url +
            `rateGeneration/getYieldData/${state}/${year}`,
          { headers: this.authenticationUser() }
        )
        .toPromise();
    }

    /******************************************
   * RATE GENERATION ::::::End
   * ****************************************/


  /******************************************
   * Load DISCOUNT ::::::Start
   * ****************************************/

  getParam(params, type) {
    if (type == "global") {
      params.tenantId = this.alert.getUserDetails("getTenant");
      params.tieupID = this.alert.getUserDetails("getTenant");
      params.updatedBy = this.alert.getUserDetails("getid");
    } else if (type == "create-tieup") {
      params.tenantId = this.alert.getUserDetails("getTenant");
      params.updatedBy = this.alert.getUserDetails("getid");
    } else if (type == "district-wise") {
      params.updatedBy = this.alert.getUserDetails("getid");
    } else if (type == "create-tieup") {
      params.tenantId = this.alert.getUserDetails("getTenant");
      params.countryCode = "";
      params.createdBy = this.alert.getUserDetails("getid");
    } else if (type == "edit-tieup") {
      params.tenantId = this.alert.getUserDetails("getTenant");
      params.countryCode = "";
      params.updatedBy = this.alert.getUserDetails("getid");
    }

    return params;
  }
  configureDefaultLoadingDiscount(params) {
    return this.http
      .post(
        this.userAPI.url + "finalloading/configureDefaultLoadingDiscount",
        params,
        { headers: this.authenticationUser() }
      )
      .toPromise();
  }
  configureDefaultLoadingDiscountByDistrict(params) {
    return this.http
      .post(
        this.userAPI.url +
          "finalloading/configureDefaultLoadingDiscountByDistrict",
        params,
        { headers: this.authenticationUser() }
      )
      .toPromise();
  }
  tieups(params) {
    return this.http
      .post(
        this.userAPI.url +
          "tieups",
        params,
        { headers: this.authenticationUser() }
      )
      .toPromise();

    }
  updatetieups(id, params) {
    return this.http
      .put(
        this.userAPI.url +
          "tieups/" + id,
        params,
        { headers: this.authenticationUser() }
      )
      .toPromise();

    }
    fetchTieUpsId(id) {
      return this.http
        .get(
          this.userAPI.url +
            "tieups/" + id,
          { headers: this.authenticationUser() }
        )
        .toPromise();
    }
    deleteTieup(id) {
      return this.http
        .delete(
          this.userAPI.url +
            "tieups/Delete/" + id,
          { headers: this.authenticationUser() }
        )
        .toPromise();
    }

  /******************************************
   * Load DISCOUNT ::::::End
   * ****************************************/

  parameterSettingAllStatesDistricts() {
    return this.http
        .get(
          this.userAPI.url +
            "parameterSettings/allStatesDistricts",
          { headers: this.authenticationUser() }
        )
        .toPromise();
  }
  parameterSettingAllowedStatesDistricts(tieupId) {
    return this.http
        .get(
          this.userAPI.url +
            "/parameterSettings/allowedStatesDistricts/" + tieupId,
          { headers: this.authenticationUser() }
        )
        .toPromise();
  }
  parameterSettingassignStatesDistricts(request) {
    return this.http
        .post(
          this.userAPI.url +
            "/parameterSettings/assignStatesDistricts",
            request,
          { headers: this.authenticationUser() }
        )
        .toPromise();

      }
    async rateGenerationDefaultLoadingDiscount() {
      return this.http
          .get(
            this.userAPI.url +
              "rateGeneration/defaultLoadingDiscount",
            { headers: this.authenticationUser() }
          )
          .toPromise();
    }

    async finalLoadingDefaultLoadingByTieupId() {
      return this.http
          .get(
            this.userAPI.url +
              "finalloading/defaultLoadingDiscountByTieupID/" + this.alert.getUserDetails("getTenant"),
            { headers: this.authenticationUser() }
          )
          .toPromise();
    }


  rateGenerationLocakPrice(rateId) {
    return this.http
        .get(
          this.userAPI.url +
            "rateGeneration/lockAsFinalPrice/" + rateId +'/'+ this.alert.getUserDetails("getid"),
            { headers: this.authenticationUser() }
        )
        .toPromise();

      }

  downloadLastYieldFile(url) {
   return this.http
        .get(url, {
          headers: this.authenticationUser(),
          responseType: "blob",
          observe: "response",
        }).toPromise()
  }
   /******************************************
   * LOADING STATE AND DISTRICT ::::::Start
   * ****************************************/

    /******************************************
   * LOADING STATE AND DISTRICT ::::::End
   * ****************************************/
  // sampleapi() {
  //   return new Promise((resolve, reject) => {
  //     this.http.get(this.getdropdownList.url + "reportYear", { headers: this.authenticationUser() }).subscribe((res) => {
  //       resolve(res);
  //     }, (err) => {
  //       reject(err);
  //     })
  //   });
  // }

  // postApi(path) {
  //   return new Promise((resolve, reject) => {
  //     this.http.post(this.url + path, this.saveData.data, { headers: this.authenticationUser() }).subscribe((res) => {
  //       resolve(res);
  //     }, (err) => {
  //       reject(err);
  //     })
  //   });
  // }
}

import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import Swal from 'sweetalert2';
import { Router } from "@angular/router";
import { ApiService } from "../services/apiservices.service";
import { MandatoryfieldService } from "../common/Validators/mandatoryfield.service";
import {
  FormBuilder,
  FormArray,
  Validators,
  FormControl,
  FormGroup
} from "@angular/forms";
import { AlertService } from "../services/alert.service";
import { UserCreationComponent } from '../user-creation/user-creation.component';
import { NgZone } from '@angular/core';
import { CommonfunctionsService } from '../services/commonfunctions.service';
declare var $: any;

@Component({
  selector: "app-tieupmain",
  templateUrl: "./tieupmain.component.html",
  styleUrls: ["./tieupmain.component.css"]
})
export class TieupmainComponent implements OnInit {

  @ViewChild('name') name: ElementRef;
  @ViewChild('tieupID') tieupID: ElementRef;
  @ViewChild('phoneNumber') phoneNumber: ElementRef;
  @ViewChild('emailID') emailID: ElementRef;
  @ViewChild('country') country: ElementRef;

  loading: boolean = false;
  resArray: any = {};
  tableData: any = {};
  showModal: boolean;
  isValid: boolean;
  CreateOrEdit: any;
  idVal: any;
  errorText: any;

  TieUpDetails: any;
  countryList: any;
  statusVal: any;
  showtable: boolean = false;
  constructor(
    private alertService: AlertService,
    private router: Router,
    private formBuilder: FormBuilder,
    private apiData: ApiService,
    private validate: MandatoryfieldService,
    public userCreate: UserCreationComponent,
    public commonfunctions: CommonfunctionsService,
    private _ngZone: NgZone,
  ) { }

  ngOnInit() {
    this.getTieUpList();
    this.TieUpDetails = this.loadForm();
    /*****************************************
    * Edit the table details::::Edit icon pressed
    * By Aravind
    * ***************************************/
    var obj = this;
    var rowindex = {};
    var createuser = {};
    $("#tieUpTable").on("click", ".adduser", function (e) {
      e.stopImmediatePropagation();
      e.preventDefault();
      obj.showtable = false;
      obj.showModal = false;
      obj.apiData.formRequest.EditOrCreate = "create";
      createuser["tieupName"] = rowindex["name"];
      createuser["tieupID"] = rowindex["tieupID"];
      obj.apiData.userEdit.pushdata(createuser);
      obj.alertService.tieupuserCreate("/user");
    });
    $("#tieUpTable").on("click", ".editTieup", function (e) {
      e.stopImmediatePropagation();
      e.preventDefault();
      obj.router.navigate(['/update-tie-up', obj.apiData.tieUpEdit.data.id])
      // obj.CreateOrEdit = "Edit";
      // obj.loadDropDown();
      // obj.TieUpDetails.patchValue(obj.apiData.tieUpEdit.popData());
      // obj.setEnableDisable("edit");
      // obj.showModal = true;
    });
    $("#tieUpTable").on("click-row.bs.table", function (e, row, $element) {
      rowindex = {};
      rowindex = row;
      createuser = {};
      obj.idVal = row.id;
      obj.apiData.tieUpEdit.pushdata(rowindex);
    });
    $("#tieUpTable").on("refresh.bs.table", function (e, params) {
      obj.getTieUpList();
    });
  }
  setEnableDisable(type) {
    if (type == "edit") {
      this.TieUpDetails.controls.country.disable("true");
      this.TieUpDetails.controls.name.disable("true");
      this.TieUpDetails.controls.tieupID.disable("true");
      this.TieUpDetails.controls.emailID.disable("true");
    } else {
      this.TieUpDetails.controls.country.enable("true");
      this.TieUpDetails.controls.name.enable("true");
      this.TieUpDetails.controls.tieupID.enable("true");
      this.TieUpDetails.controls.emailID.enable("true");
    }
  }
  /***********************************************************
   * 1.get the Tieup details and generate table using api call
   * By Aravind
   * *********************************************************/
  getTieUpList() {
    this.loading = true;
    this.apiData.portalRevaApi.getTieUpList(res => {
      if (!this.validate.isUndefined(res)) {
        let result = res;
        if (result.status == 1) {
          this.loading = false;
          this.prepareJson(result);
          $("#tieUpTable").bootstrapTable("destroy");
          this.tableData = $("#tieUpTable").bootstrapTable({
            data: this.resArray,
            columnDefs: [
              {
                className: "table-data"
              }
            ],
            select: true
          });
          this.showtable = true;
        }
      }
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }
  /**************************************
  * 2. Build the Tieup form
  * By Aravind
  * *************************************/
  loadForm() {
    return this.formBuilder.group({
      country: ["", Validators.required],
      name: ["", Validators.required],
      tieupID: ["", Validators.required],
      emailID: ["", Validators.email],
      status: ["", Validators.required]
    });
  }
  /*****************************************
  * 3. Load the dropdown values using api call
  * By Aravind
  * ***************************************/
  loadDropDown() {
    this.statusVal = this.apiData.dropdownList.statusTieup;
    this.loading = true;
    this.apiData.getdropdownList.getCountry(res => {
      if (!this.validate.isUndefined(res)) {
        let result = res;
        this.loading = false;
        if (result.status == 1) {
          this.countryList = result.data;
        } else {
          let errorText = this.alertService.ErrorText.checkError(result.data);
          this.validate.popUpAlert(errorText, "", "error");
        }
      }
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }
  /*****************************************
  * 4. Generate table Data - Tieup details
  * By Aravind
  * ***************************************/
  prepareJson(resData) {
    this.resArray = [];
    for (var i = 0; i < resData.data.length; i++) {
      let resArray1 = {};
      resArray1["action"] = "";
      resArray1["id"] = resData.data[i]["id"];
      resArray1["sno"] = i + 1;
      resArray1["tieupID"] = resData.data[i]["tieupID"];
      resArray1["name"] = resData.data[i]["name"];
      resArray1["country"] = resData.data[i]["country"];
      resArray1["emailID"] = resData.data[i]["emailID"];
      var status_txt=resData.data[i]["status"].toLowerCase();
      resArray1["status"] = status_txt!='active' ?  'Inactive':'Active';
      resArray1["action"] =
        `<div class = "row" style="cursor:pointer;width: 100%;">
            <div class="px-2 text-right editTieup" title="Edit">
              <img src="/assets/images/edit.png">
            </div>
            <div class="adduser" title="Add User">
            <img src="/assets/images/add_mem.png">
            </div>
          </div>`;
      this.resArray.push(resArray1);
    }
  }

  /***********tieup creation/edit ***************/
  createTieUp() {
    // this.apiData.tieUpEdit.removData();
    // this.showModal = true;
    // this.CreateOrEdit = "Create";
    // this.TieUpDetails.reset();
    // this.TieUpDetails.controls["status"].setValue("Active");
    // this.setEnableDisable("create");
    // this.loadDropDown();
    this.router.navigate(['/create-tie-up']);
  }
  resetForm() {
    if (this.apiData.formRequest.EditOrCreate == "edit") {
      this.TieUpDetails.patchValue(this.apiData.tieUpEdit.popData());
    } else {
      this.TieUpDetails.reset();
      this.TieUpDetails.controls.status.setValue('Active');
    }
  }
  submitClick() {
    this.setEnableDisable("create");
    this.isValid = this.validate.submitDetails(this.TieUpDetails);
    this.loading = true;
    this.validate.trigger = false;
    if (this.isValid) {
      this.apiData.formRequest.getRequest(
        this.idVal,
        this.TieUpDetails.value,
        "tieup"
      );
      this.apiData.portalRevaApi.CreateEditTieUp(res => {
        this.loading = false;
        if (!this.validate.isUndefined(res)) {
          let result = res;
          if (result.status == 1) {
            if (this.apiData.formRequest.EditOrCreate == "edit") {
              this.validate.popUpAlert("Tie-Up Updated Successfully", "", "success");
            } else {
              this.validate.popUpAlert("Tie-Up Created Successfully", "", "success");
            }
            this.showModal = false;
            //$("#tieUpTable").bootstrapTable("destroy");
            this.getTieUpList();
          } else if (result.status == 0) {
            this.errorText = this.alertService.ErrorText.checkError(result.data);
            this.validate.popUpAlert(this.errorText, "", "error");
          }
        }
      }, err => {
        this.loading = false;
        this.commonfunctions.serviceErrorHandling(err);
      });
    } else {
      this.loading = false;
      let valid = true;
      Swal({
        title: "Required",
        text: "Please Fill Required Fields!",
        type: "warning",
        confirmButtonColor: "#175788",
      }).then((result) => {
        Object.keys(this.TieUpDetails.controls).forEach(field => {
          const control = this.TieUpDetails.get(field);
          if (control.status == "INVALID" && valid) {
            if (field == "country" || field == "status") {
              setTimeout(() => this[field].optionsOpen(), 0);
            } else {
              setTimeout(() => this[field].nativeElement.focus(), 500);
            }
            valid = false;
          }
        });
      });
    }
  }
}

import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CommonfunctionsService } from '../services/commonfunctions.service';

declare var $: any;

@Component({
  selector: 'app-file-data-warning',
  templateUrl: './file-data-warning.component.html',
  styleUrls: ['./file-data-warning.component.css']
})
export class FileDataWarningComponent implements OnInit {

  @Output() action: EventEmitter<any> = new EventEmitter();
  @Input() mismatchedYieldDatas: any[] = [];
  @Input() mismatchedCurrentYearYieldDatas: any[] = [];
  @Input() mismatchedYieldFields: any[] = [];
  @Input() warnings: any[] = [];

  constructor(private commonfunctions: CommonfunctionsService) { }

  ngOnInit() {
    this.mismatchedYieldFields = [
      {
      "field": "rowNumber",
      "header": "Line No"
    },
      {
      "field": "district",
      "header": "District"
    },
      {
      "field": "year",
      "header": "Year"
    },
    {
      "field": "session",
      "header": "Season"
    },
    // {
    //   "field": "column",
    //   "header": "Column"
    // },
    {
      "field": "oldValue",
      "header": "Old Value"
    },
    {
      "field": "newValue",
      "header": "New Value"
    },
    {
      "field": "sum_insured",
      "header": "Sum Insured"
    }
  ]
    this.refreshMismatchedTable();
    this.refreshMismatchedYieldTable();
  }

  goto(process) {
    this.action.emit(process);
  }

  refreshMismatchedTable() {
  $("#mistmatchedReport").bootstrapTable("destroy");
  setTimeout(() => {
    $("#mistmatchedReport").bootstrapTable({
      data: this.mismatchedYieldDatas,
      columnDefs: [
        {
          className: "table-data"
        }
      ],
      select: true
    });
  })
  }
  refreshMismatchedYieldTable() {
  $("#mismatchedCurrentYearYieldDatas").bootstrapTable("destroy");
  setTimeout(() => {
    $("#mismatchedCurrentYearYieldDatas").bootstrapTable({
      data: this.mismatchedCurrentYearYieldDatas,
      columnDefs: [
        {
          className: "table-data"
        }
      ],
      select: true
    });
  })
  }

  exportAsXLSX(data, fields, name): void {
    this.commonfunctions.downloadTableToExcel(data, fields, name);
  }

}

import { Component, OnInit } from '@angular/core';
import { MandatoryfieldService } from '../common/Validators/mandatoryfield.service';
import { ApiService } from '../services/apiservices.service';
import { AlertService } from '../services/alert.service';
import { FormBuilder, Validators } from '@angular/forms';
import { CommonfunctionsService } from '../services/commonfunctions.service';
import Swal from 'sweetalert2';
declare var $: any;
@Component({
  selector: 'app-finalloadingconfig',
  templateUrl: './finalloadingconfig.component.html',
  styleUrls: ['./finalloadingconfig.component.css']
})
export class FinalloadingconfigComponent implements OnInit {
  TieUpListForm: any;
  showModal: boolean = false;
  showPopup: boolean = false;
  showPopupGrid: boolean = false;
  showTable: boolean = false;
  tieuplist: any;
  countryList: any;
  btn_name: string = "VIEW";
  loading: boolean = false;
  resArray: any;
  tableData: any;
  tableDatapopup: any;
  ldItems=new Array();
  subsectionlist=new Array();  
  fileList: FileList;
  curLDname: string;
  cursubsectionname: string;
  wfEntry: boolean=false;
  finalLoadingData: any;
  
  finalLoadingForm : any;
  fileNameVal: any;
  constructor(private formBuilder: FormBuilder, private validate: MandatoryfieldService, private apiData: ApiService, private alertService: AlertService, private commonfunctions: CommonfunctionsService) { }

  ngOnInit() {
    this.TieUpListForm = this.formBuilder.group({
      tieupID: ["", Validators.required],
      country: ["", Validators.required]
    });
    
    this.finalLoadingForm = this.formBuilder.group({      
      tieupID: ["", Validators.required],      
      loadingDiscountID: ["", Validators.required],      
      subSectionKey: ["", Validators.required],      
      fileName: [""]
    });

    this.loadDropDown();

    var obj = this;
    var rowindex = {};
    var obj=this;
    $("#tblfinalloading").on("click", ".edit", function (e) {
      var rowData=rowindex;
      obj.showPopupGrid = true;      
      obj.curLDname=rowData["loadingDiscountName"];
      obj.cursubsectionname=rowData["subSectionName"];
      if(rowData["loadingDiscountID"]=='weather_forecast')
      {
        obj.wfEntry=true;
        obj.finalLoadingData=rowData["finalLoadingWF"];
      }
      else{obj.wfEntry=false;
        obj.finalLoadingData=rowData["finalLoadingOtherLoadings"];
      }        
    });
    $("#tblfinalloading").on("click-row.bs.table", function (e, row, $element) {
      rowindex = {};
      rowindex = row;
    });
    $("#tblfinalloading").on("refresh.bs.table", function (e, params) {
      obj.getReportList();
    });
  }

  loadDropDown() {
    this.loading = true;
    this.apiData.getdropdownList.getCountry(res => {
      if (!this.validate.isUndefined(res)) {
        let result = res;
        this.loading = false;
        if (result.status == 1) {
          this.countryList = result.data;
        } else {
          let errorText = this.alertService.ErrorText.checkError(result.data);
          this.validate.popUpAlert(errorText, "", "error");
        }
      }
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }

  showTieupList() {
    this.showTable = false;
    this.showModal = false;
    this.tieuplist = [];
    this.TieUpListForm.controls.tieupID.setValue('');
    this.apiData.getdropdownList.selectedcountry(this.TieUpListForm.value.country);
    this.TieUpListForm.controls.tieupID.enable(true);
    this.apiData.getdropdownList.getTieUplistByCountry(res => {
      if (!this.validate.isUndefined(res)) {
        let result = res;
        this.loading = false;
        if (result.status == 1) {
          this.tieuplist = result.data;
          this.TieUpListForm.controls.tieupID.enable(true);
        } else {
          let errorText = this.alertService.ErrorText.checkError(result.data);
          this.validate.popUpAlert(errorText, "", "error");
        }
      }
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }

  showtable(action) {
    if (action == "VIEW") {
      if (this.validate.submitDetails(this.TieUpListForm)) {        
        this.apiData.finalloadingApi.saveId(this.TieUpListForm.value.tieupID)
        this.getReportList();
        this.showModal = true;

        //load ld items here 
        this.loadldItems();       
      }      
    }    
    this.fileNameVal = "";
  }
 
  loadldItems() {
    this.loading = true;
    this.apiData.getdropdownList.getloadingItems(res => {
      if (!this.validate.isUndefined(res)) {
        let result = res;
        this.loading = false;
        if (result.status == 1) {
          this.ldItems = result.data;
        } else {
          let errorText = this.alertService.ErrorText.checkError(result.data);
          this.validate.popUpAlert(errorText, "", "error");
        }
      }
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }

  showsubSectionKey() {
    this.setloadingdiscountName();
    this.subsectionlist = new Array();
    this.finalLoadingForm.controls.subSectionKey.setValue('');
    this.apiData.getdropdownList.selectedLDkey(this.finalLoadingForm.value.loadingDiscountID);
    this.finalLoadingForm.controls.subSectionKey.enable(true);
    this.apiData.getdropdownList.getsubsectionsbyLD(res => {
      if (!this.validate.isUndefined(res)) {
        let result = res;
        this.loading = false;
        if (result.status == 1) {
          this.subsectionlist = result.data;
          this.finalLoadingForm.controls.subSectionKey.enable(true);
        } else {
          let errorText = this.alertService.ErrorText.checkError(result.data);
          this.validate.popUpAlert(errorText, "", "error");
        }
      }
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }

  setloadingdiscountName() {
    for(var i=0;i<this.ldItems.length;i++){
      if(this.ldItems[i].value==this.finalLoadingForm.value.loadingDiscountID)
      {
        this.curLDname=this.ldItems[i].text;
        break;
      }
    }

    if(this.finalLoadingForm.value.loadingDiscountID=='weather_forecast')
    {this.wfEntry=true;}
  }

  setsubsectionName()
  {
    for(var i=0;i<this.subsectionlist.length;i++){
      if(this.subsectionlist[i].value==this.finalLoadingForm.value.subSectionKey)
      {
        this.cursubsectionname=this.subsectionlist[i].text;
        break;
      }
    }
  }

  getReportList() {
    this.btn_name = "VIEW";
    this.loading = true;
    this.apiData.finalloadingApi.getlist(res => {
      if (!this.validate.isUndefined(res)) {
        let result = res;
        if (result.status == 1) {
          this.loading = false;          
          this.prepareJson(result);
          $("#tblfinalloading").bootstrapTable("destroy");
          this.tableData = $("#tblfinalloading").bootstrapTable({
            data: this.resArray,
            columnDefs: [
              {
                className: "table-data"
              }
            ],
            select: true
          });
          this.showTable = true;
        }
      }
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }

  prepareJson(resData) {
    this.resArray = [];
    for (var i = 0; i < resData.data.length; i++) {
      let resArray1 = {};
      if (!this.validate.isUndefined(resData.data[i]["effectiveDate"])) {
        let date = resData.data[i]["effectiveDate"].split('T');
        resArray1["effectiveDate"] = date[0];
      } else {
        resArray1["effectiveDate"] = resData.data[i]["effectiveDate"];
      }
      resArray1["effectiveDateWithTime"] = this.validate.convertDate(resData.data[i]["effectiveDate"]);
      resArray1["loadingDiscountName"] = resData.data[i]["loadingDiscountName"];
      resArray1["subSectionName"] = resData.data[i]["subSectionName"];      
      resArray1["loadingDiscountID"] = resData.data[i]["loadingDiscountID"];
      resArray1["subSectionKey"] = resData.data[i]["subSectionKey"];
      resArray1["finalLoadingData"] = resData.data[i]["finalLoadingData"];
      resArray1["finalLoadingWF"] = resData.data[i]["finalLoadingWF"];
      resArray1["finalLoadingOtherLoadings"] = resData.data[i]["finalLoadingOtherLoadings"];      
      resArray1["sno"] = i + 1;
      resArray1["action"] =
        '<div class = "row offset-1" style="cursor:pointer;width: 100%;"><div class="edit px-2 font-weight-bold" style="cursor:pointer">View</div>';
      this.resArray.push(resArray1);
    }
  }

  fileChange(event): void {    
    this.fileNameVal="";
    let getFile = event.target.files;    
    let array = getFile[0].name.split('.');
    if (array[array.length - 1] == "csv") {
      this.fileList = getFile;      
      this.fileNameVal=this.fileList[0].name;
      this.finalLoadingForm.controls.fileName.setValue(this.fileList[0].name);      
    } else {      
      this.validate.popUpAlert(this.alertService.ErrorText.incorrectfile, "", "error");
    }
  }
  formRequest() {
    const formData = new FormData();
    formData.append("tieupID", this.finalLoadingFormControls.tieupID.value);
    formData.append("country", this.TieUpListForm.controls.country.value);
    formData.append("loadingDiscountID", this.finalLoadingFormControls.loadingDiscountID.value);
    formData.append("subSectionKey", this.finalLoadingFormControls.subSectionKey.value);
    formData.append("createdBy", this.alertService.getUserDetails('getid'));
    formData.append("tenantId", this.alertService.getUserDetails('getTenant'));
    formData.append('file', this.fileList[0], this.fileList[0].name);
    this.apiData.saveData.pushdata(formData);
  }
  get finalLoadingFormControls() {
    return this.finalLoadingForm.controls;
  }

  closeModel() {    
    this.fileNameVal="";
    this.finalLoadingForm.reset();
  } 

  reloadTable() {
    this.closeModel();    
    this.showtable("VIEW");    
  }

  viewForm() {    
      this.finalLoadingForm.controls.tieupID.setValue(this.TieUpListForm.value.tieupID);      
      if (this.validate.submitDetails(this.finalLoadingForm) && !this.validate.isUndefined(this.fileNameVal)) {
        this.loading = true;
        this.formRequest();        
        this.apiData.finalloadingApi.uploadfinalloading(res => {
          this.loading = false;
          if (!this.validate.isUndefined(res)) {
            let result = res;
            if (result.status == 1) {              
              this.showPopup = true;
              this.setsubsectionName();
              this.setloadingdiscountName();
              this.finalLoadingData=result.data;
            } 
            else if (result.status == 0 && result.message=='invalidfile') {
              let errorText = this.alertService.ErrorText.checkError(result.data);
              this.validate.popUpAlert(errorText, "", "error");
            }
            else if (result.status == 0) {
              let errorText = this.alertService.ErrorText.checkError(result.data);
              this.validate.popUpAlert(errorText, "", "error");
            }
          }
        }, err => {
          this.loading = false;
          this.commonfunctions.serviceErrorHandling(err);
        });
      } else {
        if (this.finalLoadingForm.status == "VALID" && this.validate.isUndefined(this.fileNameVal)) {
          this.validate.popUpAlert("Please Upload CSV file", "", "error");
        }
      }    
  }

  saveForm() {    
      this.finalLoadingForm.controls.tieupID.setValue(this.TieUpListForm.value.tieupID);                  
      let message = "Final loading details saved successfully";    
      if (this.validate.submitDetails(this.finalLoadingForm) && !this.validate.isUndefined(this.fileNameVal)) {
        this.loading = true;
        this.formRequest();    
        this.apiData.finalloadingApi.savefinalloading(res => {
          this.loading = false;
          if (!this.validate.isUndefined(res)) {
            let result = res;
            if (result.status == 1) {              
              this.reloadTable();
              this.showPopup = false;              
              this.validate.popUpAlert(message, "", "success");
            } else if (result.status == 0) {
              let errorText = this.alertService.ErrorText.checkError(result.data);
              this.validate.popUpAlert(errorText, "", "error");
            }
          }
        }, err => {
          this.loading = false;
          this.commonfunctions.serviceErrorHandling(err);
        });
      } else {
        if (this.finalLoadingForm.status == "VALID" && this.validate.isUndefined(this.fileNameVal)) {
          this.validate.popUpAlert("Please Upload CSV file", "", "error");
        }
      }    
  }

  fnReSetFinalLoadingForm()
  {
    this.fileNameVal="";
    this.finalLoadingForm.reset();
  }  
}

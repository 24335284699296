import { Component, OnInit } from '@angular/core';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { MandatoryfieldService } from '../common/Validators/mandatoryfield.service';
import { ApiService } from '../services/apiservices.service';
import { AlertService } from '../services/alert.service';
import { FormBuilder, Validators } from '@angular/forms';
import { CommonfunctionsService } from '../services/commonfunctions.service';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-parametersetting',
  templateUrl: './parametersetting.component.html',
  styleUrls: ['./parametersetting.component.css']
})
export class ParametersettingComponent implements OnInit {
  TieUpListForm: any;
  parameterForm: any;
  resArray: any;
  tableData: any;
  showTable: boolean = false;
  loading: boolean = false;
  showModal: boolean = false;
  inputYN: boolean = false;

  btn_name: string = "VIEW";
  name: string = "";
  CreateOrEdit: any;
  tieuplist: any;
  countryList: any;
  fileUpload: any;
  fileList: FileList;
  fieldname: any;
  placeholder: any = "Select Date"
  paramID: any = "";
  fileNameVal: any;
  myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    sunHighlight: true,
    inline: false,
    selectionTxtFontSize: '16px',
    editableDateField: false,
    openSelectorOnInputClick: true,
    indicateInvalidDate: true,
    disableUntil: { year: Number(new Date().getFullYear()), month: Number(new Date().getMonth() + 1), day: Number(new Date().getDate() - 1) }
  };
  constructor(private formBuilder: FormBuilder, private validate: MandatoryfieldService, private apiData: ApiService, private alertService: AlertService, private commonfunctions: CommonfunctionsService) { }

  ngOnInit() {
    this.TieUpListForm = this.formBuilder.group({
      tieupID: ["", Validators.required],
      country: ["", Validators.required]
    });
    this.parameterForm = this.formBuilder.group({
      tieupID: ["", Validators.required],
      paramName: ["", Validators.required],
      paramEffDate: ["", Validators.required],
      data: ["", Validators.required],
      fileName: [""]
    });
    this.loadDropDown();
    $("#parametertable").on("click", ".edit", function (e) {
      e.stopImmediatePropagation();
      e.preventDefault();
      obj.loading = true;
      setTimeout(() => obj.loading = false, 500);
      obj.apiData.editDetails.pushdata(rowindex);
      obj.showModal = true;
      obj.inputYN = false;
      obj.CreateOrEdit = "Edit";
      obj.name = "";
      obj.closeModel();
      if (rowindex["parameterID"] == "max_adminallowed" || rowindex["parameterID"] == "max_subuserallowed") {
        obj.inputYN = true;
        if (rowindex["parameterID"] == "max_adminallowed") {
          obj.fieldname = "Max. No of Admin allowed";
        } else if (rowindex["parameterID"] == "max_subuserallowed") {
          obj.fieldname = "Max. No of sub-user allowed";
        }
      }
      obj.parameterForm.patchValue(obj.apiData.editDetails.popData());
      if (!obj.validate.isUndefined(obj.apiData.editDetails.data["paramEffDate"])) {
        obj.alertService.patchDate.changeToLocal(obj.apiData.editDetails.data["paramEffDate"]);
        obj.dateFormat();
      }
      obj.setFormEnableDisable();
    });
    $("#parametertable").on("click", ".delete", function (e) {
      obj.popUpYesOrNo(rowindex);
      console.log("to be deleted" + JSON.stringify(rowindex));
    });
    var obj = this;
    var rowindex = {};
    $("#parametertable").on("click-row.bs.table", function (e, row, $element) {
      rowindex = {};
      rowindex = row;
    });
    $("#parametertable").on("refresh.bs.table", function (e, params) {
      obj.getReportList();
    });
  }
  popUpYesOrNo(values) {
    Swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#286914',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.deleteParameter(values);
      }
    })
  }
  deleteParameter(toDelete) {
    // this.apiData.editDetails.pushdata(toDelete);
    //debugger;
    this.apiData.parameterApi.saveId(toDelete.id)
    this.apiData.parameterApi.deleteParameter(res => {
      this.loading = false;
      if (!this.validate.isUndefined(res)) {
        let result = res;
        if (result.status == 1) {
          this.reloadTable();
          this.showModal = false;
          this.validate.popUpAlert("Parameter deleted successfully", "", "success");
        } else if (result.status == 0) {
          let errorText = this.alertService.ErrorText.checkError(result.data);
          this.validate.popUpAlert(errorText, "", "error");
        }
      }
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }
  /*** DateFormat patching ***/
  dateFormat() {
    this.parameterForm.patchValue({
      paramEffDate: {
        date: this.alertService.patchDate.getPatchedDate(),
        formatted: this.alertService.patchDate.getPatchedDate()["year"] + "-" + this.alertService.patchDate.getPatchedDate()["month"] + "-" + this.alertService.patchDate.getPatchedDate()["day"]
      }
    });
  }
  /*** to set field enable and disabe based on EDIT and Create parameter***/
  setFormEnableDisable() {
    if (this.inputYN) {
      this.parameterForm.controls.tieupID.disable(true);
      this.parameterForm.controls.paramName.disable(true);
      this.parameterForm.controls.data.enable(true);
      this.parameterForm.controls.fileName.disable(true);
      this.parameterForm.controls.paramEffDate.disable(true);
    }
    else if (this.CreateOrEdit == "Edit") {
      this.parameterForm.controls.tieupID.disable(true);
      this.parameterForm.controls.paramName.disable(true);
      this.parameterForm.controls.data.disable(true);
      this.parameterForm.controls.paramEffDate.enable(true);
    } else {
      this.parameterForm.controls.tieupID.enable(true);
      this.parameterForm.controls.paramName.enable(true);
      this.parameterForm.controls.data.disable(true);
      this.parameterForm.controls.paramEffDate.enable(true);
    }
  }
  loadDropDown() {
    this.loading = true;
    this.apiData.getdropdownList.getCountry(res => {
      if (!this.validate.isUndefined(res)) {
        let result = res;
        this.loading = false;
        if (result.status == 1) {
          this.countryList = result.data;
        } else {
          let errorText = this.alertService.ErrorText.checkError(result.data);
          this.validate.popUpAlert(errorText, "", "error");
        }
      }
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }
  showTieupList() {
    this.showTable = false;
    this.showModal = false;
    this.tieuplist = [];
    this.TieUpListForm.controls.tieupID.setValue('');
    this.apiData.getdropdownList.selectedcountry(this.TieUpListForm.value.country);
    this.TieUpListForm.controls.tieupID.enable(true);
    this.apiData.getdropdownList.getTieUplistByCountry(res => {
      if (!this.validate.isUndefined(res)) {
        let result = res;
        this.loading = false;
        if (result.status == 1) {
          this.tieuplist = result.data;
          this.TieUpListForm.controls.tieupID.enable(true);
        } else {
          let errorText = this.alertService.ErrorText.checkError(result.data);
          this.validate.popUpAlert(errorText, "", "error");
        }
      }
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }
  showtable(action) {
    if (action == "VIEW") {
      if (this.validate.submitDetails(this.TieUpListForm)) {
        this.createParam();
        this.apiData.parameterApi.saveId(this.TieUpListForm.value.tieupID)
        this.getReportList();
      }
      this.showModal = false;
    } else if (action == "CREATE") {
      this.btn_name = "EDIT";
      this.CreateOrEdit = "Create";
      this.showTable = false;
      this.showModal = true;
      this.inputYN = false;
      this.name = "";
      this.parameterForm.reset();
    } else if (action == "EDIT") {
      this.btn_name = "VIEW";
      this.showtable("VIEW");
    }
    this.fileUpload = "";
    this.fileNameVal="";
    this.setFormEnableDisable();
  }
  getReportList() {
    this.btn_name = "CREATE";
    this.loading = true;
    this.apiData.parameterApi.getlist(res => {
      if (!this.validate.isUndefined(res)) {
        let result = res;
        if (result.status == 1) {
          this.loading = false;
          this.prepareJson(result);
          $("#parametertable").bootstrapTable("destroy");
          this.tableData = $("#parametertable").bootstrapTable({
            data: this.resArray,
            columnDefs: [
              {
                className: "table-data"
              }
            ],
            select: true
          });
          this.showTable = true;
        }
      }
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }
  prepareJson(resData) {
    this.resArray = [];
    for (var i = 0; i < resData.data.length; i++) {
      let resArray1 = {};
      if (!this.validate.isUndefined(resData.data[i]["paramEffDate"])) {
        let date = resData.data[i]["paramEffDate"].split('T');
        resArray1["paramEffDate"] = date[0];
      } else {
        resArray1["paramEffDate"] = resData.data[i]["paramEffDate"];
      }
      resArray1["paramEffDatewithTime"] = this.validate.convertDate(resData.data[i]["paramEffDate"]);
      resArray1["paramName"] = resData.data[i]["paramName"];
      resArray1["isCustomParameter"] = resData.data[i]["isCustomParameter"];
      let deleteoption = (resArray1["isCustomParameter"]) ? '<div class="delete" title="Delete"><i class="fa fa-trash-o" style="font-size:20px"></i></div></div>' : '</div>'
      resArray1["action"] =
        '<div class = "row" style="cursor:pointer;width: 100%;"><div class="edit px-2" style="cursor:pointer"><img title="Edit" src="/assets/images/edit.png"></div>' + deleteoption;
      resArray1["parameterID"] = resData.data[i]["parameterID"];
      resArray1["tenantId"] = resData.data[i]["tenantId"];
      resArray1["tieupID"] = resData.data[i]["tieupID"];
      resArray1["data"] = resData.data[i]["data"];
      resArray1["id"] = resData.data[i]["id"];
      resArray1["sno"] = i + 1;
      this.resArray.push(resArray1);
    }
  }
  createParam() {
    if (this.validate.submitDetails(this.TieUpListForm)) {
      this.showModal = true;
      this.CreateOrEdit = 'Create';
      this.inputYN = false;
      this.closeModel();
      this.apiData.editDetails.removeAndAddData(this.TieUpListForm.value);
      this.parameterForm.patchValue(this.apiData.editDetails.popData());
      this.setFormEnableDisable();
    } else {
      this.validate.popUpAlert("Please Fill Required Fields!", "Required", "warning");
    }
  }
  fileChange(event): void {
    this.fileNameVal="";
    let getFile = event.target.files;
    let array = getFile[0].name.split('.');
    if (array[array.length - 1] == "csv") {
      this.fileList = getFile;
      this.fileNameVal=this.fileList[0].name;
      this.parameterForm.controls.fileName.setValue(this.fileList[0].name);
    } else {
      this.validate.popUpAlert(this.alertService.ErrorText.incorrectfile, "", "error");
    }
  }
  // createnew() {
  //   if (!this.inputYN) {
  //     this.CreateOrEdit = 'Create';
  //     this.setFormEnableDisable();
  //   } else {
  //     this.showtable('CREATE');
  //   }
  //   this.fileUpload = "";
  //   this.parameterForm.reset();
  // }
  closeModel() {
    this.fileUpload = "";
    this.fileNameVal="";
    this.parameterForm.reset();
  }
  formRequest() {
    const formData = new FormData();
    formData.append("tieupID", this.parameterFormControls.tieupID.value);
    formData.append("country", this.TieUpListForm.controls.country.value);
    formData.append("paramName", this.parameterFormControls.paramName.value);
    formData.append("paramEffDate", this.parameterFormControls.paramEffDate.value);
    formData.append("parameterID", this.paramID);
    formData.append("isCustomParameter", this.apiData.editDetails.popData().isCustomParameter);
    formData.append("tenantId", this.alertService.getUserDetails('getTenant'));
    formData.append("createdBy", this.alertService.getUserDetails('getid'));
    formData.append('file', this.fileList[0], this.fileList[0].name);
    this.apiData.saveData.pushdata(formData);
  }
  get parameterFormControls() {
    return this.parameterForm.controls;
  }
  saveForm() {
    this.parameterForm.controls.tieupID.setValue(this.TieUpListForm.value.tieupID);
    if (!this.validate.isUndefined(this.parameterForm.controls.paramEffDate.value)) {
      this.parameterForm.controls.paramEffDate.setValue(this.parameterForm.controls.paramEffDate.value.formatted);
    }
    if (this.inputYN) {
      if (this.validate.submitDetails(this.parameterForm)) {
        this.loading = true;
        this.apiData.formRequest.getRequest(this.TieUpListForm.controls.country.value, this.parameterForm.value, "parameter");
        this.apiData.parameterApi.updateCount(res => {
          this.loading = false;
          if (!this.validate.isUndefined(res)) {
            let result = res;
            if (result.status == 1) {
              this.closeModel();
              this.getReportList();
              this.showModal = false;
              this.validate.popUpAlert("Updated Successfully", "", "success");
            } else if (result.status == 0) {
              let errorText = this.alertService.ErrorText.checkError(result.data);
              let errorType = "error";
              if (errorText.indexOf("deactive") != -1) {
                errorType = "info";
              }
              this.validate.popUpAlert(errorText, "", errorType);
            }
          }
        }, err => {
          this.loading = false;
          this.commonfunctions.serviceErrorHandling(err);
        });
      } else {
        if (!this.validate.isUndefined(this.parameterForm.controls.paramEffDate.value)) {
          this.alertService.patchDate.changeToLocal(this.parameterForm.controls.paramEffDate.value);
          this.dateFormat();
        }
      }

    } else {
      let message = "";
      if (this.CreateOrEdit == "Edit") {
        this.paramID = this.apiData.editDetails.data["parameterID"];
        message = "Updated Successfully";
      } else {
        this.paramID = "";
        this.apiData.editDetails.pushdata({ isCustomParameter: true });
        message = "Parameter Created Successfully";
      }
      if (this.validate.submitDetails(this.parameterForm) && !this.validate.isUndefined(this.fileNameVal)) {
        this.loading = true;
        this.formRequest();
        this.apiData.parameterApi.createParameter(res => {
          this.loading = false;
          if (!this.validate.isUndefined(res)) {
            let result = res;
            if (result.status == 1) {
              this.reloadTable();
              this.validate.popUpAlert(message, "", "success");
            } else if (result.status == 0) {
              let errorText = this.alertService.ErrorText.checkError(result.data);
              this.validate.popUpAlert(errorText, "", "error");
            }
          }
        }, err => {
          this.loading = false;
          this.commonfunctions.serviceErrorHandling(err);
        });
      } else {
        if (this.parameterForm.status == "VALID" && this.validate.isUndefined(this.fileNameVal)) {
          this.validate.popUpAlert("Please Upload CSV file", "", "error");
        }
      }
    }
    if (!this.validate.isUndefined(this.parameterForm.controls.paramEffDate.value)) {
      this.alertService.patchDate.changeToLocal(this.parameterForm.controls.paramEffDate.value);
      this.dateFormat();
    }
  }
  reloadTable() {
    this.closeModel();
    this.createParam();
    this.showtable("VIEW");
    this.showModal = false;
  } 
}

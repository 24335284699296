import { Component, OnInit, HostListener } from "@angular/core";
import { Subject, timer, Subscription, Observable } from 'rxjs';
import { takeUntil, take, map, first } from 'rxjs/operators';
import { AuthenticationService } from "../services/authentication.service";
import { AppComponent } from "../app.component";
import { AlertService } from "../services/alert.service";
import { ApiService } from '../services/apiservices.service';
import { MandatoryfieldService } from '../common/Validators/mandatoryfield.service';
declare var $: any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"]
})

export class HeaderComponent implements OnInit {
  userID: any = "";
  userName: any = "";
  tieup: boolean = false;
  user: boolean = false;
  management: boolean = false;
  userlistdownload: boolean = false;
  // rolerights: boolean = false;
  // activitytrack: boolean = false;
  config: boolean = false;
  // report: boolean = false;
  // parametersetting: boolean = false;
  // loadingconfig: boolean = false;
  rategen: boolean = false;
  reportlist: boolean = false;
  previousyear: boolean = false;
  showModal: boolean = false;
  loading: boolean = false;
  /***Session handling**/
  IsSessionExpired: boolean=false;
  eventobserved: boolean = true;
  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  resetTimerOnclick() {
    let page = window.location.pathname;
    if (page.toLocaleLowerCase() != '/login') {
      this.notifyUserAction();
    }
  }
  unsubscribe$: Subject<void> = new Subject();
  timerSubscription: Subscription;
  endTime = parseInt(this.alertService.getUserDetails("getSessiontime"));

  _userActionOccured: Subject<void> = new Subject();
  get userActionOccured(): Observable<void> { return this._userActionOccured.asObservable() };

  notifyUserAction() {
    if (this.eventobserved)
      this._userActionOccured.next();
  }
  constructor(public authenticationService: AuthenticationService, public app: AppComponent, public alertService: AlertService, private apiData: ApiService, private validate: MandatoryfieldService) { }

  ngOnInit() {
    this.userID = this.alertService.getUserDetails("getuserEmailID");
    this.userName = this.alertService.getUserDetails("getUserName")
    if (this.alertService.getUserDetails("getUsertype") == "MR-Super Admin") {
      this.tieup = true;
      this.user = true;
      this.management = true;
      this.config = true;
      // this.report = true;
      // this.parametersetting = true;
      // this.loadingconfig = true;
      //this.userlistdownload = true;
      // this.rolerights = true;
      // this.activitytrack = true;
      //this.rategen = true;
      this.reportlist = true;
    }
    else if (this.alertService.getUserDetails("getUsertype") == "Tie-Up Admin") {
      this.user = true;
      this.userlistdownload = true;
    } else {
      this.rategen = true;
      this.reportlist = true;
      this.previousyear = true;
    }
    this.resetTimer();
    this.userActionOccured.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      if (this.timerSubscription) {
        this.timerSubscription.unsubscribe();
      }
      this.resetTimer();
    });
    /******refreshtoken*******/
    this.authenticationService.authenticationUser();
    let req = {
      username: this.alertService.userId.get(),
      grant_type: "refresh_token"
    }
    this.authenticationService.login(req)
      .pipe(first())
      .subscribe(res => {
      }, err => {
        this.validate.popUpAlert(this.alertService.ErrorText.unauthorized, "", "error");
        this.Logout();
      });
  }
  /***Session handling**/
  resetTimer(endTime: number = this.endTime) {
    console.log('resetTimer start');
    const interval = 1000; //1 second = 1000 ms
    const duration = endTime * 2; // ex:: endtime = 900 ms(0.9s) interval =1000, 1000*(0.9)*4 => 3600 sec =>1 hour
    this.timerSubscription = timer(0, interval).pipe(
      take(duration)
    ).subscribe(value =>
      localStorage.setItem("Timer", (duration - +value).toString()),
      err => { },
      () => {
        console.log('resetTimer end');
        if(this.IsSessionExpired!=true)
        {
          this.IsSessionExpired=true;
          alert("session expired !")
        }
        this.authenticationService.logoutService();
      }
    )
  }
  clearAll() {
    this.alertService.rateGenerationID.remove();
    this.alertService.combinationId.remove();
    this.alertService.isFinalRate.remove();
    this.apiData.inputCombo.removData();
    this.apiData.outputCombo.removData();
    if (this.alertService.getUserDetails("getUsertype") == "MR-Super Admin"){
      this.goTo('/tie-up')
    }else{
      this.goTo('/choose-transaction')
    }

  }
  goTo(page) {
    this.alertService.rateGenerationID.remove();
    this.alertService.combinationId.remove();
    this.alertService.isFinalRate.remove();
    this.apiData.inputCombo.removData();
    this.apiData.outputCombo.removData();
    this.alertService.navigateTo(page);
  }
  changePassword() {
    this.authenticationService.forgetOrChangePassword('loggedin');
  }
  Logout() {
    this.eventobserved = false;
    this.timerSubscription.unsubscribe();
    this.authenticationService.logoutService();
  }
}

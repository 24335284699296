import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { AlertService } from '../../services/alert.service';
import { IMultiSelectOption } from 'angular-2-dropdown-multiselect';
import { UploadEvent, UploadFile, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { MandatoryfieldService } from '../../common/Validators/mandatoryfield.service';
import { ApiService } from '../../services/apiservices.service';
import { CommonfunctionsService } from '../../services/commonfunctions.service';
import {
	FormBuilder,
	FormArray,
	Validators,
	FormControl,
	FormGroup
} from '@angular/forms';

@Component({
	selector: 'app-uploadfiles',
	templateUrl: './uploadfiles.component.html',
	styleUrls: ['./uploadfiles.component.css']
})
export class UploadfilesComponent implements OnInit {
	fileList: any;
	fileName: any;
	loading: boolean = false;
	GetStatusHitCount: number=0;
	loadingtext: string;
	rateGenerationUploadForm: any;
	transactionIdList: any = [];
	default: any = [{ text: "Select", value: "Select" }];
  combinationInputData: any;
	constructor(private formBuilder: FormBuilder, public alertService: AlertService, private validate: MandatoryfieldService, private apiData: ApiService, private commonfunctions: CommonfunctionsService) { }

	ngOnInit() {
		if (this.validate.isUndefined(this.alertService.combinationId.get()) && !(this.apiData.saveData && this.apiData.saveData.data && this.apiData.saveData.data.input)) {
			this.alertService.navigateTo("/choose-transaction");
		}
    this.combinationInputData = this.apiData.saveData.data.input;
		this.alertService.deleteLocal("upload");
		this.alertService.deleteLocal("loading");
		this.alertService.changeStepper(3);
		this.rateGenerationUploadForm = this.formBuilder.group({
			transactionID: [""]
		});
		//this.loadDropDown();
	}
	loadDropDown() {
		if (this.transactionIdList.length == 0) {
			this.loading = true;
			this.apiData.getdropdownList.getTransactionIDByTieUp(res => {
				this.loading = false;
				if (!this.validate.isUndefined(res)) {
					let result = res;
					if (result.status == 1) {
						this.transactionIdList = result.data;
						var keys = Object.keys(this.default);
						keys.map(obj => {
							this.transactionIdList[obj] = this.default[obj];
						});
					} else {
						let errorText = this.alertService.ErrorText.checkError(result.data);
						this.validate.popUpAlert(errorText, "", "error");
					}
				}
			}, err => {
				this.loading = false;
				this.commonfunctions.serviceErrorHandling(err);
			});
		}
	}
	onSelectId(value) {
		if (value != "Select") {
			this.validate.popUpAlert("Click on upload to use transaction ID", "", "info");
			this.fileName = "";
		} else {
			this.rateGenerationUploadForm.reset();
		}
	}
	public dropped(event: UploadEvent) {
		let getFile = event.files;
		let array = getFile[0].relativePath.split('.');
		if (array[array.length - 1] == "csv") {
			for (const droppedFile of event.files) {
				if (droppedFile.fileEntry.isFile) {
					const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
					fileEntry.file((file: File) => {
						console.log(droppedFile.relativePath, file);
						this.fileList = file;
						this.fileName = droppedFile.relativePath;
					});
				}
			}
		} else {
			this.validate.popUpAlert(this.alertService.ErrorText.incorrectfile, "", "error");
		}
		if (!this.validate.isUndefined(this.rateGenerationUploadForm.value.transactionID)) {
			this.confirmationAlert();
		}
	}
	onFileChange(event): void {
		let getFile = event.target.files;
		let array = getFile[0].name.split('.');
		if (array[array.length - 1] == "csv") {
			this.fileList = getFile[0];
			this.fileName = this.fileList.name;
		} else {
			this.validate.popUpAlert(this.alertService.ErrorText.incorrectfile, "", "error");
		}
		if (!this.validate.isUndefined(this.rateGenerationUploadForm.value.transactionID)) {
			this.confirmationAlert();
		}
	}
	confirmationAlert() {
		Swal({
			title: "",
			text: "Click on upload to use uploaded file",
			type: "info",
			confirmButtonColor: "#175788"
		}).then((result) => {
			this.rateGenerationUploadForm.reset();
		})
	}
	formFileRequest() {
		const formData = new FormData();
		formData.append("tieupID", this.alertService.getUserDetails('getTieup'));
		formData.append("tenantId", this.alertService.getUserDetails('getTenant'));
		formData.append("updatedBy", this.alertService.getUserDetails('getid'));
		formData.append("userFileName",this.fileName);
		formData.append("id", this.alertService.combinationId.get());
		formData.append('file', this.fileList, this.fileName);
		this.apiData.saveData.pushdata(formData);
	}
	formJsonRequest() {
		let jsonData = {
			"tieupID": this.alertService.getUserDetails('getTieup'),
			"tenantId": this.alertService.getUserDetails('getTenant'),
			"id": this.alertService.combinationId.get(),
			"csvTransactionID": this.rateGenerationUploadForm.value.transactionID,
			"rateGenerationID": this.alertService.rateGenerationID.get(),
			"updatedBy": this.alertService.getUserDetails('getid')
		}
		this.apiData.saveData.pushdata(jsonData);
	}

	showWarnings(res) {
		let warningString = "";
		this.loadingtext = "";
		var span = document.createElement("span");
		for (var i = 0; i < res.warnings.length; i++) {
			span.innerHTML += "<div style='text-align:left'>" + (i + 1) + ". " + res.warnings[i].warning + ". " + "</div>";
		}
		Swal({
			title: "Warnings",
			html: span,
			type: "warning",
			confirmButtonColor: "#175788",
			timer: parseFloat('120000')
		}).then((result) => {
			this.loading = true;
			this.fileValidation(res);
		})
	}
	fileValidation(res) {
		if (this.validate.isUndefined(res.transactionId)) {
			this.loadingtext = "Calculating rate...";
			this.MRexecuteApi();
		} else {
			this.loadingtext = "Calculating and downloading rate...";
			this.GetStatusHitCount=0;
			this.MRgetstatusApi();
		}
	}
	goto(process) {
		if (process == "next") {
			let apiServiceName = "";
			if (!this.validate.isUndefined(this.rateGenerationUploadForm.value.transactionID) || !this.validate.isUndefined(this.fileName)) {
				if (!this.validate.isUndefined(this.rateGenerationUploadForm.value.transactionID) && this.rateGenerationUploadForm.value.transactionID != "Select") {
					apiServiceName = "skipCsvUpload";
					this.formJsonRequest()
				} else if (!this.validate.isUndefined(this.fileName)) {
					apiServiceName = "uploadFile";
					this.formFileRequest();
				}
				this.loadingtext = "Uploading and validating data...";
				this.loading = true;
				this.apiData.rateGenerationApi[apiServiceName](res => {
					if (!this.validate.isUndefined(res)) {
						if (res.status == 1) {
							if (!this.validate.isUndefined(res.warnings)) {
								this.loading = false;
								this.showWarnings(res);
							} else {
								this.fileValidation(res);
							}
						} else if (res.status == 0) {
							this.loading = false;
							this.loadingtext = "";
							let errorText = res.data;
							this.validate.popUpAlert(errorText, "", "error");
							this.fileName = "";
						}
					}
				}, err => {
					this.serviceError(err);
					this.fileName = "";
				})
			} else {
				this.validate.popUpAlert("Please Upload file", "Required", "warning");
			}
		} else {
			this.alertService.navigateTo("/choose-transaction");
		}
	}
	MRexecuteApi() {
		this.apiData.rateGenerationApi.MRexecuteApi(res => {
			if (!this.validate.isUndefined(res)) {
				if (res.status == 1) {
					if (this.validate.isUndefined(res.transactionId)) {
						this.loading = false;
						this.loadingtext = "";
						this.validate.popUpAlert("Upload the file again", "", "error");
					} else {
						this.loadingtext = "Calculating and downloading rate...";
						this.GetStatusHitCount=0;
						this.MRgetstatusApi();
					}
				}
				else if (res.status == 0 && res.message=='INVALID_REQUEST') {
					this.loading = false;
					this.loadingtext = "";
					this.validate.popUpAlert(res.data, "", "error");
				}
				else if (res.status == 0) {
					this.loading = false;
					this.loadingtext = "";
					let errorText = this.alertService.ErrorText.checkError(res.data);
					this.validate.popUpAlert(errorText, "", "error");
				}
			}
		}, err => {
			this.serviceError(err);
		})
	}

	MRgetstatusApi() {
		this.GetStatusHitCount=this.GetStatusHitCount+1;
    const combinationInputData = this.combinationInputData;
		this.apiData.rateGenerationApi.MRgetstatusApi(res => {
			if (!this.validate.isUndefined(res)) {
				if (res.status == 1) {
					//this.loadingtext = "";
					// if (res.data.toLowerCase() != "completed") {
					// 	this.loading = false;
					// 	this.validate.popUpAlert("Upload the file again", "", "error");
					// } else {
					// 	this.alertService.createLocal("upload", "true");
					// 	this.alertService.navigateTo("/rate-generate");
					// }
						if (res.data.toLowerCase() == "completed") {
							this.loadingtext = "";
							this.loading = false;
							this.alertService.createLocal("upload", "true");
              combinationInputData;
              const state = combinationInputData.find(d => d.key == 'state');
              const level = combinationInputData.find(d => d.key == 'level');
              const admin = combinationInputData.find(d => d.key == 'admin');
              const param = btoa(JSON.stringify([state ? state.value : null, level ? level.value : null, admin ? admin.value : null]))
							this.alertService.navigateTo("/rate-generate", param);
						}
						else if (res.data.toLowerCase() == "terminated") {
							this.loading = false;
							this.loadingtext = "";
							this.validate.popUpAlert("Upload the file again", "", "error");
						}
						else {
							//hit count increased from start
							if(this.GetStatusHitCount<5)
							{
								setTimeout(any=>{this.MRgetstatusApi();}, 20000);
								//this.delayTimer(20000).then(any=>{this.MRgetstatusApi();});
							}
							else{
								this.loading = false;
								this.loadingtext = "";
								this.validate.popUpAlert("Upload the file again", "", "error");
							}
						}
				}
				else if (res.status == 0 && res.message=='INVALID_REQUEST') {
					this.loading = false;
					this.loadingtext = "";
					this.validate.popUpAlert(res.data, "", "error");
				}
				else if (res.status == 0) {
					this.loading = false;
					this.loadingtext = "";
					let errorText = this.alertService.ErrorText.checkError(res.data);
					this.validate.popUpAlert(errorText, "", "error");
				}
			}
		}, err => {
			this.serviceError(err);
		})
	}
	downloadSample() {
		this.loading = true;
		this.apiData.rateGenerationApi.downloadSampleCsv(res => {
			this.loading = false;
			this.commonfunctions.csvDownloadFromServer(res);
		}, err => {
			this.loading = false;
			this.commonfunctions.serviceErrorHandling(err);
		})
	}
	serviceError(err) {
		this.loadingtext = "";
		this.loading = false;
		this.commonfunctions.serviceErrorHandling(err);
	}
}

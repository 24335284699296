import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApiService } from '../services/apiservices.service';

declare var $: any;

@Component({
  selector: 'app-demo-transactionid',
  templateUrl: './demo-transactionid.component.html',
  styleUrls: ['./demo-transactionid.component.css']
})
export class DemoTransactionidComponent implements OnInit {

  public loading: Boolean = false;
  public selectedTenantId = new FormControl();
  public transactionIdData: any[] = [];
  public inputData: any;
  public outputData: any;
  public inputFields: any[] = [];
  public outputFields: any[] = [];
  public showTabs: Boolean = false;
  public activeTab: any = 2;


  constructor(private apiData: ApiService) { }

  ngOnInit() {
    this.getTransactionId();
    this.onTransactionIdChange();
  }

  getTransactionId() {
    this.loading = true;
    this.apiData.getTransactionIds().then((response: any)=> {
      if (response.status == 1)  {
        this.transactionIdData = response.data || [];
      }
      this.loading = false
    }).catch(err => {
      this.loading = false
    })
    // .finally(() => this.loading = false)
  }

  onTransactionIdChange() {
    this.selectedTenantId.valueChanges
    .subscribe((csvId) => {
      this.showTabs = false;
      this.inputData = null;
      this.outputData = null;
      if (csvId) {
        this.loading = true
        Promise.all([
          // this.apiData.getTransactionInputInfo(csvId),
          this.apiData.getTransactionOutputInfo(csvId)
        ]).then((responses: any)=> {
          const [
            // inputData,
            outputData
          ] = responses
          // this.getInputData(inputData);
          this.getOutputData(outputData);
          this.showTabs = true
          this.loading = false
        }).catch(err => {
          this.loading = false
        })
        // .finally(() => this.loading = false)
      }
    });
  }


  getInputData(inputData) {
    this.inputData = inputData.status == 1 && Array.isArray(inputData.data) ? inputData.data : [];
    this.inputData = this.inputData.filter((data, index) => index < 50)
    this.inputFields = [
      {
          "field": "tieupID",
          "header": "tieupID"
      },
      {
          "field": "csvTransactionID",
          "header": "csvTransactionID"
      },
      {
          "field": "year",
          "header": "year"
      },
      {
          "field": "season",
          "header": "season"
      },
      {
          "field": "crop",
          "header": "crop"
      },
      {
          "field": "management",
          "header": "management"
      },
      {
          "field": "admin1",
          "header": "admin1"
      },
      {
          "field": "admin2",
          "header": "admin2"
      },
      {
          "field": "admin3",
          "header": "admin3"
      },
      {
          "field": "admin4",
          "header": "admin4"
      },
      {
          "field": "admin5",
          "header": "admin5"
      },
      {
          "field": "cluster_num",
          "header": "cluster_num"
      },
      {
          "field": "cov_level",
          "header": "cov_level"
      },
      {
          "field": "area_sown",
          "header": "area_sown"
      },
      {
          "field": "value_per_crop_ha",
          "header": "value_per_crop_ha"
      },
      {
          "field": "sum_insured",
          "header": "sum_insured"
      },
      {
          "field": "calamity1",
          "header": "calamity1"
      },
      {
          "field": "calamity2",
          "header": "calamity2"
      },
      {
          "field": "csvRowNumber",
          "header": "csvRowNumber"
      },
      {
          "field": "isGiveWarning",
          "header": "isGiveWarning"
      },
      {
          "field": "tenantId",
          "header": "tenantId"
      },
      {
          "field": "id",
          "header": "id"
      },
      {
          "field": "type",
          "header": "type"
      },
      {
          "field": "createdAt",
          "header": "createdAt"
      }
  ]
  }
  getOutputData(outputData) {
    this.outputData = outputData.status == 1 && Array.isArray(outputData.data) ? outputData.data : [];
    // this.outputData = this.outputData.filter((data, index) => index < 50)
    this.outputFields = [
      {
        "field": "sno",
        "header": "sno"
      },
      {
          "field": "tieupid",
          "header": "tieupid"
      },
      {
          "field": "rategenerationid",
          "header": "rategenerationid"
      },
      {
          "field": "year",
          "header": "year"
      },
      {
          "field": "season",
          "header": "season"
      },
      {
          "field": "crop",
          "header": "crop"
      },
      {
          "field": "admin1",
          "header": "admin1"
      },
      {
          "field": "cluster",
          "header": "cluster"
      },
      {
          "field": "area_sown",
          "header": "area_sown"
      },
      {
          "field": "sum_insured",
          "header": "sum_insured"
      },
      {
          "field": "rate",
          "header": "rate"
      },
      {
          "field": "market_rate",
          "header": "market_rate"
      },
      {
          "field": "reva_risk_rate",
          "header": "reva_risk_rate"
      },
      {
          "field": "heterogeneity",
          "header": "heterogeneity"
      },
      {
          "field": "datainsufficiency",
          "header": "datainsufficiency"
      },
      {
          "field": "plr",
          "header": "plr"
      },
      {
          "field": "weatherforecast",
          "header": "weatherforecast"
      },
      {
          "field": "farmmgmtpractices",
          "header": "farmmgmtpractices"
      },
      {
          "field": "groundlevelexpertise",
          "header": "groundlevelexpertise"
      },
      {
          "field": "specificrisks",
          "header": "specificrisks"
      },
      {
          "field": "commercialdiscount",
          "header": "commercialdiscount"
      },
      {
          "field": "adminld",
          "header": "adminld"
      },
      {
          "field": "costcapitalld",
          "header": "costcapitalld"
      },
      {
          "field": "rateafterbasicldfactors",
          "header": "rateafterbasicldfactors"
      },
      {
          "field": "rateaftercommercialld",
          "header": "rateaftercommercialld"
      },
      {
          "field": "rateafteradmincostcapitalld",
          "header": "rateafteradmincostcapitalld"
      },
      {
          "field": "id",
          "header": "id"
      },
      {
          "field": "type",
          "header": "type"
      }
  ]

  $("#outputReport").bootstrapTable("destroy");
  setTimeout(() => {
    $("#outputReport").bootstrapTable({
      data: this.outputData,
      columnDefs: [
        {
          className: "table-data"
        }
      ],
      select: true
    });
  })

  }



}

import { Component, OnInit } from '@angular/core';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../services/apiservices.service';
import { MandatoryfieldService } from '../common/Validators/mandatoryfield.service';
import { AlertService } from '../services/alert.service';
import { CommonfunctionsService } from '../services/commonfunctions.service';
declare var $: any;


@Component({
  selector: 'app-trackingactivity',
  templateUrl: './trackingactivity.component.html',
  styleUrls: ['./trackingactivity.component.css']
})
export class TrackingactivityComponent implements OnInit {
  reportForm: any;
  tieuplist: any;
  tableData: any;
  resArray: any;
  loading: boolean = false;
  showTable: boolean = false;
  alertmessage: string;
  alerttype: string = 'Required';
  placeholderfrom: string = "Select Date";
  placeholderto: string = "Select Date";
  myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    sunHighlight: true,
    inline: false,
    selectionTxtFontSize: '16px',
    editableDateField: false,
    openSelectorOnInputClick: true,
    indicateInvalidDate: true,
    disableSince: { year: Number(new Date().getFullYear()), month: Number(new Date().getMonth() + 1), day: Number(new Date().getDate() + 1) }
  };
  constructor(private formBuilder: FormBuilder, private apiData: ApiService, private validate: MandatoryfieldService, private alertService: AlertService, private commonfunctions: CommonfunctionsService) { }
  ngOnInit() {
    this.reportForm = this.formBuilder.group({
      tieupId: ["", Validators.required],
      fromDate: "",
      toDate: "",
    });
    this.loadDropDown();
    var obj = this;
    var rowindex = {};
    $("#activityByIdTable").on("click", ".enabledownload", function (e) {
      e.stopImmediatePropagation();
      e.preventDefault();
      console.log(rowindex);
      obj.alertService.downloadInUsertracking.set(rowindex["description"], rowindex["activityType"]);
      obj.downloadFile();
    });

    $("#activityByIdTable").on("click-row.bs.table", function (e, row, $element) {
      rowindex = {};
      rowindex = row;
    });
    $("#activityByIdTable").on("refresh.bs.table", function (e, params) {
      obj.getList();
    });
  }
  loadDropDown() {
    this.loading = true;
    this.apiData.getdropdownList.getTieUplist(res => {
      this.loading = false;
      if (!this.validate.isUndefined(res)) {
        let result = res;
        if (result.status == 1) {
          this.tieuplist = result.data;
        } else {
          let errorText = this.alertService.ErrorText.checkError(result.data);
          this.validate.popUpAlert(errorText, "", "error");
        }
      }
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }
  formRequest() {
    let array = {
      "tieupId": this.reportForm.value.tieupId,
      "fromDate": (this.validate.isUndefined(this.reportForm.value.fromDate)) ? "" : this.reportForm.value.fromDate.formatted,
      "toDate": (this.validate.isUndefined(this.reportForm.value.toDate)) ? "" : this.reportForm.value.toDate.formatted,
    }
    return array;
  }
  onDateChanged() {
    this.showTable = false;
  }
  validateEnteredDates() {
    this.alerttype = "Required";
    if (this.validate.isUndefined(this.reportForm.value.fromDate) && !this.validate.isUndefined(this.reportForm.value.toDate)) {
      this.alertmessage = "Select From Date";
      return false;
    } else if (!this.validate.isUndefined(this.reportForm.value.fromDate) && this.validate.isUndefined(this.reportForm.value.toDate)) {
      this.alertmessage = "Select To Date";
      return false;
    } 
    else if (this.reportForm.value.fromDate.formatted >= this.reportForm.value.toDate.formatted) {
      this.alertmessage = "To Date should not less than From Date";
      this.alerttype = "Alert";
      return false;
    }
     else {
      this.alertmessage = "Select Tie-Up Id";
      return true;
    }
  }
  showtable() {
    if (this.validateEnteredDates() && this.validate.submitDetails(this.reportForm)) {
      this.apiData.roleApi.selectedData(this.formRequest(), "activity")
      this.alertService.downloadInUsertracking.remove();
      this.getList();
    } else {
      this.validate.popUpAlert(this.alertmessage, this.alerttype, "warning");
    }
  }
  getList() {
    this.loading = true;
    this.apiData.roleApi.getTrackinglist(res => {
      if (!this.validate.isUndefined(res)) {
        let result = res;
        this.loading = false;
        if (result.status == 1) {
          this.prepareJson(result);
          $("#activityByIdTable").bootstrapTable("destroy");
          this.tableData = $("#activityByIdTable").bootstrapTable({
            data: this.resArray,
            columnDefs: [
              {
                className: "table-data"
              }
            ],
            select: true
          });
          this.showTable = true;
        } else {
          let errorText = this.alertService.ErrorText.checkError(result.data);
          this.validate.popUpAlert(errorText, "", "warning");
        }
      }
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }
  prepareJson(resData) {
    this.resArray = [];
    for (var i = 0; i < resData.data.length; i++) {
      let resArray1 = {};
      resArray1["userName"] = resData.data[i]["userName"];
      resArray1["userType"] = resData.data[i]["userType"];
      resArray1["userId"] = resData.data[i]["userId"];
      resArray1["id"] = resData.data[i]["id"];
      resArray1["activityType"] = resData.data[i]["activityType"];
      if (!this.validate.isUndefined(resData.data[i]["activityDate"])) {
        let date = resData.data[i]["activityDate"].split('T');
        resArray1["activityDate"] = date[0];
      } else {
        resArray1["activityDate"] = resData.data[i]["activityDate"];
      }
      resArray1["activityDateWithTime"] = this.validate.convertDate(resData.data[i]["activityDate"]);
      
      
      if (resData.data[i]["isDownloadFile"]) {
        resArray1["action"] = '<div class="enabledownload"  title="Download"><i class="fa fa-download" style="color:#175788;cursor:pointer;font-size: 20px;"></div>'
      }
      resArray1["tenantId"] = resData.data[i]["tenantId"];
      resArray1["description"] = resData.data[i]["description"];
      resArray1["sno"] = i + 1;
      this.resArray.push(resArray1);
    }
  }
  downloadFile() {
    this.loading = true;
    this.apiData.rateGenerationApi.downloadRateFile(res => {
      this.loading = false;
      this.commonfunctions.csvDownloadFromServer(res);
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    })
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSelectModule, INgxSelectOptions } from 'ngx-select-ex';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { NgDragDropModule } from 'ng-drag-drop';
import { FileDropModule } from 'ngx-file-drop';


import { ChoosedetailsComponent } from './choosedetails/choosedetails.component';
import { ChoosecombinationComponent } from './choosecombination/choosecombination.component';
import { UploadfilesComponent } from './uploadfiles/uploadfiles.component';
import { AuthGuard } from '../auth/auth.guard';
import { RoleGuard } from '../auth/role.guard';
import { AlertService } from '../services/alert.service';
import { CommonModules } from '../common/common.module';
import { CommonfunctionsService } from '../services/commonfunctions.service';
import { RategenerateComponent } from './rategenerate/rategenerate.component';
import { RateListComponent } from './rate-list/rate-list.component';
import { ChooseTransactionComponent } from './choose-transaction/choose-transaction.component';

// {
//   path: 'output-combination', component: ChoosecombinationComponent, canActivate: [AuthGuard, RoleGuard], data: {
//     expectedRole: 'Tie-Up Sub User', expectedRole2: ''
//   }
// }
const routes: Routes = [
  {
    path: 'input-combination', component: ChoosedetailsComponent, canActivate: [AuthGuard, RoleGuard], data: {
      expectedRole: 'Tie-Up Sub User', expectedRole2: ''
    }
  },{
    path: 'choose-transaction', component: ChooseTransactionComponent, canActivate: [AuthGuard, RoleGuard], data: {
      expectedRole: 'Tie-Up Sub User', expectedRole2: ''
    }
  }, {
    path: 'file-upload', component: UploadfilesComponent, canActivate: [AuthGuard, RoleGuard], data: {
      expectedRole: 'Tie-Up Sub User', expectedRole2: ''
    }
  }, {
    path: 'rate-generate', component: RategenerateComponent, canActivate: [AuthGuard, RoleGuard], data: {
      expectedRole: 'Tie-Up Sub User', expectedRole2: ''
    },
  },{
    path: 'rate-generate/:param', component: RategenerateComponent, canActivate: [AuthGuard, RoleGuard], data: {
      expectedRole: 'Tie-Up Sub User', expectedRole2: ''
    },
  }, {
    path: 'rate-download', component: RateListComponent, canActivate: [AuthGuard, RoleGuard], data: {
      expectedRole: 'Tie-Up Sub User', expectedRole2: ''
    }
  }
];

@NgModule({
  imports: [
    CommonModule,
    CommonModules,
    FormsModule,
    ReactiveFormsModule,
    NgxSelectModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.threeBounce,
      backdropBackgroundColour: 'rgba(0, 0, 0, 0.3)',
      backdropBorderRadius: '10px',
      primaryColour: '#ffffff',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff',
      fullScreenBackdrop: true
    }),
    MultiselectDropdownModule,
    FileDropModule,
    NgDragDropModule.forRoot(),
    RouterModule.forRoot(routes)
  ],
  providers: [AuthGuard, RoleGuard, CommonfunctionsService],
  declarations: [ChoosedetailsComponent, ChoosecombinationComponent, UploadfilesComponent, RategenerateComponent, RateListComponent, ChooseTransactionComponent]
})
export class RategenerationModule { }

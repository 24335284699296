import { Component, OnInit ,Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MandatoryfieldService } from '../mandatoryfield.service';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.css']
})
export class ErrorMessageComponent implements OnInit {

  @Input() control: FormControl;
  constructor(private validationService:MandatoryfieldService) { }
	get errorMessage() {
		for (let propertyName in this.control.errors) {
			if(propertyName == "pattern"){
				return MandatoryfieldService.patternMatch(this.control.errors);
			}
			else if (this.control.errors.hasOwnProperty(propertyName) && this.control.touched) {
				return MandatoryfieldService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
			}
		}
    
		return null;
	}

  ngOnInit() {
  }
}

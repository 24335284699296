import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../services/apiservices.service';
import { MandatoryfieldService } from '../common/Validators/mandatoryfield.service';
import { AlertService } from '../services/alert.service';
import { CommonfunctionsService } from '../services/commonfunctions.service';
declare var $: any;

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {

  userTypeForm: any;
  userTypeList: any;
  tableData: any;
  resArray: any;
  loading: boolean = false;
  showTable: boolean = false;

  constructor(private formBuilder: FormBuilder, private apiData: ApiService, private validate: MandatoryfieldService, private alertService: AlertService, private commonfunctions: CommonfunctionsService) { }

  ngOnInit() {
    this.userTypeForm = this.formBuilder.group({
      userType: ["", Validators.required]
    });
    this.loadDropDown();
  }
  loadDropDown() {
    this.loading = true;
    this.apiData.getdropdownList.getAllUserType(res => {
      if (!this.validate.isUndefined(res)) {
        let result = res;
        this.loading = false;
        if (result.status == 1) {
          this.userTypeList = result.data;
        } else {
          let errorText = this.alertService.ErrorText.checkError(result.data);
          this.validate.popUpAlert(errorText, "", "error");
        }
      }
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }
  showtable() {
    if (this.validate.submitDetails(this.userTypeForm)) {
      this.apiData.roleApi.selectedData(this.userTypeForm.value.userType, "rights")
      this.getList()
    }
  }
  getList() {
    this.loading = true;
    this.apiData.roleApi.getlist(res => {
      if (!this.validate.isUndefined(res)) {
        let result = res;
        this.loading = false;
        if (result.status == 1) {
          this.prepareJson(result);
          $("#roleRightTable").bootstrapTable("destroy");
          this.tableData = $("#roleRightTable").bootstrapTable({
            data: this.resArray,
            columnDefs: [
              {
                className: "table-data"
              }
            ],
            select: true
          });
          this.showTable = true;
        }
      }
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }
  prepareJson(resData) {
    this.resArray = [];
    for (var i = 0; i < resData.data.length; i++) {
      let resArray1 = {};
      resArray1["moduleName"] = resData.data[i]["moduleName"];
      resArray1["rights"] = resData.data[i]["rights"];
      resArray1["sno"] = i + 1;
      this.resArray.push(resArray1);
    }
  }
}

import { Component, HostListener } from '@angular/core';
import { RouterModule, Router, NavigationEnd } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { AuthenticationService } from './services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  header: boolean;
  appParticles: boolean;
  /*** for navigation active***/
  tieupAcive: boolean = false;
  userAcive: boolean = false;
  reportAcive: boolean = false;
  parametersettingAcive: boolean = false;
  userlistActive: boolean = false;
  loadingdiscActive: boolean = false;
  rolesActive: boolean = false;
  trackingActive: boolean = false;
  rateActive: boolean = false;
  reportlistActive: boolean = false;
  rateDownloadActive: boolean = false;
  previousyearActive: boolean = false;
  finalloadingconfigActive: boolean = false;
  demoTransIdActive: boolean = false;
  /*** nav -end****/

  constructor(private router: Router, private location: PlatformLocation, private authenticationService: AuthenticationService) {
    location.onPopState(() => {
      history.forward();
    });
    this.router.events.subscribe((e) => {
      this.appParticles = false;
      if (e instanceof NavigationEnd) {
        console.log("page:::" + e.url);
        if (((e.url) === '/change-password') || ((e.url) === '/login')) {
          this.header = false;
          this.appParticles = true;
        } /*** for navigation active***/
        else if ((e.url) === '/tie-up') {
          this.resetall();
          this.header = true;
          this.tieupAcive = true;
          this.userlistActive = false;
        } else if ((e.url) === '/create-tie-up') {
          this.resetall();
          this.header = true;
          this.tieupAcive = true;
          this.userlistActive = false;
        } else if ((e.url).startsWith('/update-tie-up')) {
          this.resetall();
          this.header = true;
          this.tieupAcive = true;
          this.userlistActive = false;
        } else if ((e.url) === '/district-wise') {
          this.resetall();
          this.header = true;
          this.tieupAcive = true;
          this.userlistActive = false;
        }
        else if ((e.url) === '/global-defaults') {
          this.resetall();
          this.header = true;
          this.tieupAcive = true;
          this.userlistActive = false;
        }
        else if ((e.url) === '/tieup-mapping') {
          this.resetall();
          this.header = true;
          this.tieupAcive = true;
          this.userlistActive = false;
        } else if ((e.url) === '/manage-state-district') {
          this.resetall();
          this.header = true;
          this.tieupAcive = true;
          this.userlistActive = false;
        } else if ((e.url) === '/yield-data-upload') {
          this.resetall();
          this.header = true;
          this.tieupAcive = true;
          this.userlistActive = false;
        } else if ((e.url) == "/user" || (e.url) == "/user/create") {
          this.resetall();
          this.header = true;
          this.userAcive = true;
        }
        else if ((e.url) == '/report') {
          this.resetall();
          this.header = true;
          this.reportAcive = true;
        }
        else if ((e.url) == '/parameter-setting') {
          this.resetall();
          this.header = true;
          this.parametersettingAcive = true;
        } else if ((e.url) == '/user-list-download') {
          this.resetall();
          this.header = true;
          this.userlistActive = true;
        } else if ((e.url) == '/configurator') {
          this.resetall();
          this.header = true;
          this.loadingdiscActive = true;
        } else if ((e.url) == '/roles') {
          this.resetall();
          this.header = true;
          this.rolesActive = true;
        } else if ((e.url) == '/activity-tracking') {
          this.resetall();
          this.header = true;
          this.trackingActive = true;
        } else if ((e.url) == '/input-combination'|| (e.url) == '/file-upload' || (e.url) == '/rate-generate' || (e.url) == '/choose-transaction'  || (e.url.startsWith('/rate-generate'))) {
          this.resetall();
          this.header = true;
          this.rateActive = true;
        } else if ((e.url) == '/report-list') {
          this.resetall();
          this.header = true;
          this.reportlistActive = true;
        } else if ((e.url) == '/rate-download') {
          this.resetall();
          this.header = true;
          this.rateDownloadActive = true;
        } else if ((e.url) == '/previous-year') {
          this.resetall();
          this.header = true;
          this.previousyearActive = true;
        } else if ((e.url) == '/finalloading-config') {
          this.resetall();
          this.header = true;
          this.finalloadingconfigActive = true;
        } else if ((e.url) == '/demo-transId') {
          this.resetall();
          this.header = true;
          this.demoTransIdActive = true;
        }
      }
    });

  }
  private resetall() {
    this.tieupAcive = false;
    this.userAcive = false;
    this.reportAcive = false;
    this.parametersettingAcive = false;
    this.userlistActive = false;
    this.loadingdiscActive = false;
    this.rolesActive = false;
    this.trackingActive = false;
    this.rateActive = false;
    this.reportlistActive = false;
    this.rateDownloadActive = false;
    this.previousyearActive = false;
    this.finalloadingconfigActive=false;
  }
  ngOnInit() {
  }

}

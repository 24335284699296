import { Component, OnInit } from '@angular/core';
//import {ExcelService} from '../services/excel.service';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { MandatoryfieldService } from '../common/Validators/mandatoryfield.service';
import { ApiService } from '../services/apiservices.service';
import { AlertService } from '../services/alert.service';
import { FormBuilder, Validators } from '@angular/forms';
import { CommonfunctionsService } from '../services/commonfunctions.service';

declare var $: any;
declare function escape(s: string): string;

@Component({
  selector: 'app-userlist-download',
  templateUrl: './userlist-download.component.html',
  styleUrls: ['./userlist-download.component.css']
})
export class UserlistDownloadComponent implements OnInit {
  TieUpListForm: any;
  loading: boolean = false;
  countryList: any;
  tieuplist: any;
  resArray: any;
  tableData: any;
  showTable: boolean = false;

  constructor(private formBuilder: FormBuilder, private validate: MandatoryfieldService, private apiData: ApiService, private alertService: AlertService, private commonfunctions: CommonfunctionsService) { }


  ngOnInit() {
    this.TieUpListForm = this.loadform();
    this.loadDropDown();
    if (!this.validate.isUndefined(this.alertService.getUserDetails("getCountry"))) {
      this.TieUpListForm.controls["country"].setValue(this.alertService.getUserDetails("getCountry"));
      this.showTieupList();
    }
    var obj = this;
    $("#userListDownloadTable").on("refresh.bs.table", function (e, params) {
      obj.getFieldList();
    });
  }
  loadform() {
    return this.formBuilder.group({
      tieupID: ["", Validators.required],
      country: ["", Validators.required]
    });
  }

  loadDropDown() {
    this.loading = true;
    this.apiData.getdropdownList.getCountry(res => {
      if (!this.validate.isUndefined(res)) {
        let result = res;
        this.loading = false;
        if (result.status == 1) {
          this.countryList = result.data;
        } else {
          let errorText = this.alertService.ErrorText.checkError(result.data);
          this.validate.popUpAlert(errorText, "", "error");
        }
      }
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }
  showTieupList() {
    this.tieuplist = [];
    this.TieUpListForm.controls["tieupID"].setValue('');
    this.apiData.getdropdownList.selectedcountry(this.TieUpListForm.value.country);
    this.TieUpListForm.controls.tieupID.enable(true);
    this.apiData.getdropdownList.getTieUplistByCountry(res => {
      let result = res;
      if (result.status == 1) {
        this.tieuplist = result.data;
        this.TieUpListForm.controls.tieupID.enable(true);
      } else {
        let errorText = this.alertService.ErrorText.checkError(result.data);
        this.validate.popUpAlert(errorText, "", "error");
      }
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }
  showtable() {
    if (this.validate.submitDetails(this.TieUpListForm)) {
      this.apiData.userlistDownloadApi.saveId(this.TieUpListForm.value.tieupID)
      this.getFieldList()
    } else {
      this.validate.popUpAlert("Please select a Tie-up to View the User List", "Required", "warning");
    }
  }
  getFieldList() {
    this.loading = true;
    this.apiData.userlistDownloadApi.getlist(res => {
      if (!this.validate.isUndefined(res)) {
        let result = res;
        if (result.status == 1) {
          this.prepareJson(result);
          $("#userListDownloadTable").bootstrapTable("destroy");
          this.tableData = $("#userListDownloadTable").bootstrapTable({
            data: this.resArray,
            columnDefs: [
              {
                className: "table-data"
              }
            ],
            select: true
          });
        }
        this.loading = false;
        this.showTable = true;
      }
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }

  prepareJson(resData) {
    this.resArray = [];
    for (var i = 0; i < resData.data.length; i++) {
      let resArray1 = {};
      resArray1["S.No"] = i + 1;
      resArray1["User Name"] = resData.data[i]["userName"];
      resArray1["User Type"] = resData.data[i]["userType"];
      resArray1["Email ID"] = resData.data[i]["userEmailID"];
      resArray1["Status"] = resData.data[i]["status"];
      this.resArray.push(resArray1);
    }
  }
  exportAsXLSX(): void {
    if (this.resArray && this.resArray.length) {
      this.commonfunctions.exportAsExcelFile(this.resArray, Object.keys(this.resArray[0]), "UserList")

    }
    // var CSV = this.commonfunctions.JSONToXLSConvertor(this.resArray, 'ReportTitle', 'ShowLabel')
    // this.commonfunctions.xlsFileDownload(CSV, "UserList");
    //this.commonfunctions.exportAsExcelFile(this.resArray,"UserList");
  }
}

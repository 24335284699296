import { Component, OnInit } from '@angular/core';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { MandatoryfieldService } from '../common/Validators/mandatoryfield.service';
import { ApiService } from '../services/apiservices.service';
import { AlertService } from '../services/alert.service';
import { FormBuilder, Validators,FormGroup } from '@angular/forms';
import { CommonfunctionsService } from '../services/commonfunctions.service';
declare var $: any;

@Component({
  selector: 'app-loading-discount',
  templateUrl: './loading-discount.component.html',
  styleUrls: ['./loading-discount.component.css']
})
export class LoadingDiscountComponent implements OnInit {
  TieUpListForm: any;
  editForm: FormGroup=new FormGroup({});
  editFormWithSubSections: FormGroup=new FormGroup({});
  ldWeightage: number;
  countryList: any;
  tieuplist: any;
  resArray: any;
  tableData: any;
  statusList: any;
  mandatoryList: any;

  showTable: boolean = false;
  showEdit: boolean = false;
  showFormWithSubsections: boolean = false;
  loading: boolean = false;
  placeholder: any = "Select Date"
  myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    sunHighlight: true,
    inline: false,
    selectionTxtFontSize: '16px',
    editableDateField: false,
    openSelectorOnInputClick: true,
    indicateInvalidDate: true,
    disableUntil: { year: Number(new Date().getFullYear()), month: Number(new Date().getMonth() + 1), day: Number(new Date().getDate()-1) }
  };
  constructor(private formBuilder: FormBuilder, private validate: MandatoryfieldService, private apiData: ApiService, private alertService: AlertService, private commonfunctions: CommonfunctionsService) { }

  ngOnInit() {
    this.TieUpListForm = this.loadform();
    this.editForm = this.loadEditForm();
    this.editFormWithSubSections=this.loadEditFormWithSubSections('','');
    this.loadDropDown();
    var obj = this;
    var rowindex = {};
    $("#fieldTable").on("click", ".edit", function (e) {
      obj.loading = true;
      setTimeout(() => obj.loading = false, 500);
      rowindex["country"] = obj.TieUpListForm.value.country;
      rowindex["tieupID"] = obj.TieUpListForm.value.tieupID;
      obj.apiData.editDetails.pushdata(rowindex);
	  obj.showEdit=false;
      obj.showFormWithSubsections=false;      
      if(obj.apiData.editDetails.data["subSections"].length>0)
      {
        obj.showFormWithSubsections=true;
      }
      else{
        obj.showEdit = true;
      }
      
      obj.editForm.reset();
      obj.editFormWithSubSections.reset();     
      if(obj.apiData.editDetails.data["subSections"].length>0)
      {   
        var effectiveDate={};     
        if (!obj.validate.isUndefined(obj.apiData.editDetails.data["effectiveDate"]))
        {
          obj.alertService.patchDate.changeToLocal(obj.apiData.editDetails.data["effectiveDate"]);      
          effectiveDate={
                  date: obj.alertService.patchDate.getPatchedDate(),
                  formatted: obj.alertService.patchDate.getPatchedDate()["year"] + "-" + obj.alertService.patchDate.getPatchedDate()["month"] + "-" + obj.alertService.patchDate.getPatchedDate()["day"]
          }
        }
        obj.editFormWithSubSections = obj.loadEditFormWithSubSections(rowindex,effectiveDate);
      }
      else{
        obj.editForm.patchValue(obj.apiData.editDetails.popData());
      if (!obj.validate.isUndefined(obj.apiData.editDetails.data["effectiveDate"])) {
        obj.alertService.patchDate.changeToLocal(obj.apiData.editDetails.data["effectiveDate"]);
        obj.editForm.patchValue({
          effectiveDate: {
            date: obj.alertService.patchDate.getPatchedDate(),
            formatted: obj.alertService.patchDate.getPatchedDate()["year"] + "-" + obj.alertService.patchDate.getPatchedDate()["month"] + "-" + obj.alertService.patchDate.getPatchedDate()["day"]
          }
        });
      }
      }
    });
    $("#fieldTable").on("click-row.bs.table", function (e, row, $element) {
      rowindex = {};
      rowindex = row;
    });
    $("#fieldTable").on("refresh.bs.table", function (e, params) {
      obj.getFieldList();
    });
  }
  loadform() {
    return this.formBuilder.group({
      tieupID: ["", Validators.required],
      country: ["", Validators.required]
    });
  }
  loadEditForm() {
    return this.formBuilder.group({
      loadingDiscountName: ["", Validators.required],
      isMandatory: ["", Validators.required],
      effectiveDate: ["", Validators.required],
      isActive: ["", Validators.required],
      tenantId: [""],
    });
  }

  loadEditFormWithSubSections(LDConfig,effectiveDate) {    

    if(LDConfig!='')
    {
      this.ldWeightage=LDConfig.ldWeightage;
    var grpSubSctionsArray=this.formBuilder.array([]);
    for(let i=0;i<LDConfig.subSections.length;i++)
    {
      var grpSubSctions=this.formBuilder.group({
        title:[LDConfig.subSections[i].title, Validators.required],
        key:[LDConfig.subSections[i].key, Validators.required],
        weightage:[LDConfig.subSections[i].weightage, Validators.required]
      });
      grpSubSctionsArray.push(grpSubSctions);
    }

    return this.formBuilder.group({
      loadingDiscountName: [LDConfig.loadingDiscountName, Validators.required],
      isMandatory: [LDConfig.isMandatory, Validators.required],
      effectiveDate: [effectiveDate, Validators.required],
      isActive: [LDConfig.isActive, Validators.required],
      tenantId: [LDConfig.tenantId],
      ldWeightage: [LDConfig.ldWeightage],
      subSections: grpSubSctionsArray
    });
  }
  else{
    var grpSubSctionsArray=this.formBuilder.array([]);
    var grpSubSctions=this.formBuilder.group({
      title:[''],
      key:[''],
      weightage:['']
    });
    grpSubSctionsArray.push(grpSubSctions);
    return this.formBuilder.group({
      loadingDiscountName: [''],
      isMandatory: [''],
      effectiveDate: [''],
      isActive: [''],
      tenantId: [''],
      ldWeightage: [''],
      subSections: grpSubSctionsArray
    });
  }
  }

  loadDropDown() {
    this.loading = true;
    this.statusList = this.apiData.dropdownList.status;
    this.mandatoryList = this.apiData.dropdownList.mandatory;
    this.apiData.getdropdownList.getCountry(res => {
      this.loading = false;
      if (!this.validate.isUndefined(res)) {
        let result = res;
        if (result.status == 1) {
          this.countryList = result.data;
        } else {
          let errorText = this.alertService.ErrorText.checkError(result.data);
          this.validate.popUpAlert(errorText, "", "error");
        }
      }
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }
  showTieupList() {
    this.showTable = false;
    this.showEdit = false;
    this.showFormWithSubsections=false;
    this.tieuplist = [];
    this.TieUpListForm.controls["tieupID"].setValue('');
    this.apiData.getdropdownList.selectedcountry(this.TieUpListForm.value.country);
    this.TieUpListForm.controls.tieupID.enable(true);
    this.apiData.getdropdownList.getTieUplistByCountry(res => {
      if (!this.validate.isUndefined(res)) {
        let result = res;
        if (result.status == 1) {
          this.tieuplist = result.data;
          this.TieUpListForm.controls.tieupID.enable(true);
        }
        else {
          let errorText = this.alertService.ErrorText.checkError(result.data);
          this.validate.popUpAlert(errorText, "", "error");
        }
      }
    }, err => {
      this.commonfunctions.serviceErrorHandling(err);
    });
  }
  showtable() {
    if (this.validate.submitDetails(this.TieUpListForm)) {
      this.apiData.loadingApi.saveId(this.TieUpListForm.value.tieupID)
      this.getFieldList()
    }
  }
  getFieldList() {
    this.loading = true;    
    this.apiData.loadingApi.getlist(res => {
      if (!this.validate.isUndefined(res)) {
        let result = res;
        if (result.status == 1) {
          this.prepareJson(result);
          $("#fieldTable").bootstrapTable("destroy");
          this.tableData = $("#fieldTable").bootstrapTable({
            data: this.resArray,
            columnDefs: [
              {
                className: "table-data"
              }
            ],
            select: true
          });
        }
        this.showTable = true;
        this.loading = false;
      }
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }

  prepareJson(resData) {
    this.resArray = [];
    for (var i = 0; i < resData.data.length; i++) {
      let resArray1 = {};
      if (!this.validate.isUndefined(resData.data[i]["effectiveDate"])) {
        let date = resData.data[i]["effectiveDate"].split('T');
        resArray1["effectiveDate"] = date[0];
      } else {
        resArray1["effectiveDate"] = resData.data[i]["effectiveDate"];
      }
      resArray1["loadingDiscountName"] = resData.data[i]["loadingDiscountName"];
      resArray1["loadingDiscountID"] = resData.data[i]["loadingDiscountID"];
      
      //cr1
      resArray1["id"] = resData.data[i]["id"];
      resArray1["effectiveDateWithTime"] = this.validate.convertDate(resData.data[i]["effectiveDate"]);
      resArray1["ldWeightage"] = resData.data[i]["ldWeightage"];
      resArray1["subSections"] = resData.data[i]["subSections"];
      resArray1["tenantId"] = resData.data[i]["tenantId"];
      //cr1 end

      resArray1["isMandatory"] = resData.data[i]["isMandatory"] ? "Yes" : "No";
      resArray1["isActive"] = resData.data[i]["isActive"] ? "Active" : "DeActive";
      resArray1["action"] =
        '<div class="edit" style="cursor:pointer" title="Edit"><img src="/assets/images/edit.png"></div>';
      resArray1["sno"] = i + 1;
      this.resArray.push(resArray1);
    }
  }

  addDetails() {  
    var effDate='';        
    if (!this.validate.isUndefined(this.editFormWithSubSections.controls.effectiveDate.value)) {
      effDate=this.editFormWithSubSections.controls.effectiveDate.value.formatted;     
    }

    if(this.showFormWithSubsections==true)
    {
      var sumOfWeightage=0;      
      for(var i=0;i<this.editFormWithSubSections.controls.subSections.value.length;i++)
      {        
        if(parseFloat(this.editFormWithSubSections.controls.subSections.value[i].weightage)>=0)
        {
          var weightage=parseFloat(this.editFormWithSubSections.controls.subSections.value[i].weightage);       
          sumOfWeightage=sumOfWeightage+weightage;
        }
        else{          
          return false;  
        }
      }
      
      if(sumOfWeightage!=100)
      {
        let errorText = this.alertService.ErrorText.checkError("weightagenot100");
        this.validate.popUpAlert(errorText, "", "error");
        return false;
      }

      if (!this.validate.isUndefined(this.editFormWithSubSections.controls.effectiveDate.value)) {
        this.editFormWithSubSections.controls.effectiveDate.setValue(this.editFormWithSubSections.controls.effectiveDate.value.formatted);
      }
    }
    else {
      if (!this.validate.isUndefined(this.editForm.controls.effectiveDate.value)) {      
        this.editForm.controls.effectiveDate.setValue(this.editForm.controls.effectiveDate.value.formatted);
      }
    }

    if ((this.validate.submitDetails(this.editForm) && this.showEdit==true) || (this.validate.submitDetails(this.editFormWithSubSections) && this.showFormWithSubsections==true)){
      this.loading = true;
      if(this.showFormWithSubsections==true)
      {this.apiData.formRequest.getRequest('', this.editFormWithSubSections.value, 'loadingdisc');}
      else{this.apiData.formRequest.getRequest('', this.editForm.value, 'loadingdisc');}      
      this.apiData.loadingApi.createLoadingDisc(res => {
        this.loading = false;
        if (!this.validate.isUndefined(res)) {
          let result = res;
          if (result.status == 1) {
            this.showEdit = false;
            this.showFormWithSubsections=false;
            this.getFieldList();
            this.validate.popUpAlert("Added Successfully", "", "success");
          } else if (result.status == 0) {
            let errorText = this.alertService.ErrorText.checkError(result.data);
            this.validate.popUpAlert(errorText, "", "error");
            this.setEffDateControl(effDate);            
          }
        }
      }, err => {
        this.loading = false;
        this.commonfunctions.serviceErrorHandling(err);
        this.setEffDateControl(effDate);
      });
    }
    else{
        this.setEffDateControl(effDate);
    }
  }

  private setEffDateControl(effDate: string) {
    var obj=this;
    if (!obj.validate.isUndefined(effDate)) {
      obj.alertService.patchDate.changeToLocal(effDate);
      if (obj.showFormWithSubsections == true) {
        obj.editFormWithSubSections.patchValue({
          effectiveDate: {
            date: obj.alertService.patchDate.getPatchedDate(),
            formatted: obj.alertService.patchDate.getPatchedDate()["year"] + "-" + obj.alertService.patchDate.getPatchedDate()["month"] + "-" + obj.alertService.patchDate.getPatchedDate()["day"]
          }
        });
      }
      else {
        obj.editForm.patchValue({
          effectiveDate: {
            date: obj.alertService.patchDate.getPatchedDate(),
            formatted: obj.alertService.patchDate.getPatchedDate()["year"] + "-" + obj.alertService.patchDate.getPatchedDate()["month"] + "-" + obj.alertService.patchDate.getPatchedDate()["day"]
          }
        });
      }
    }
  }

  checkDecimal(index,controlnameNew) {        
    controlnameNew.setValue(controlnameNew.value.replace(/[^\d.-]/g, '')) // numbers and decimals only
    controlnameNew.setValue(controlnameNew.value.replace("-.", '-')) //not decimal point after minus
    controlnameNew.setValue(controlnameNew.value.replace(/(^[-]+[\d]{2})[\d-]/g, '$1'))   // not more than 2 digitsafter the minus 
    controlnameNew.setValue(controlnameNew.value.replace(/(^[\d]{2})[\d-]/g, '$1'))   // not more than 2 digits at the beginning
    controlnameNew.setValue(controlnameNew.value.replace(/(\..*)\./g, '$1'))         // decimal can't exist more than once
    controlnameNew.setValue(controlnameNew.value.replace(/(\.[\d]{3})./g, '$1'));    // not more than 3 digits after decimal
  }
}

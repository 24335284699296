import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { first } from "rxjs/operators";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MandatoryfieldService } from '../common/Validators/mandatoryfield.service';
import { AuthenticationService } from '../services/authentication.service';
import { AlertService } from '../services/alert.service';
import { TooltipDirective } from '../common/Directives/tooltip.directive';
import { ApiService } from '../services/apiservices.service';
import { CommonfunctionsService } from '../services/commonfunctions.service';
import { TooltipModule } from "ngx-tooltip";
declare var $: any;

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css'],
})
export class ChangepasswordComponent implements OnInit {
  @ViewChild('codeone') codeone: ElementRef;
  @ViewChild('codetwo') codetwo: ElementRef;
  @ViewChild('codethree') codethree: ElementRef;
  @ViewChild('codefour') codefour: ElementRef;
  @ViewChild('codefive') codefive: ElementRef;
  @ViewChild('codesix') codesix: ElementRef;

  model: any = {};
  reqId: any;
  new_pass: string = "";
  sendMailForm: FormGroup;
  resetPasswordForm: FormGroup;

  verify_mail: boolean = false;
  verify_code: boolean = false;
  loading: boolean = false;
  verificationText: boolean = false;
  matched: boolean = true;
  correctpwd: boolean = true;
  flag_passwordlength: boolean = true;
  retypePassword: boolean = true;
  resetPasswordstrength: any = "";
  retypePasswordStrength: any = "";
  tooltiptext: any = "Password must be atleast 8 characters along with one number,one upper and lower case letter  and special characters *$-+_=!{}";

  constructor(private formBuilder: FormBuilder, private validate: MandatoryfieldService, private el: ElementRef,
    public authenticationService: AuthenticationService, private alertService: AlertService, private apiData: ApiService, private commonfunctions: CommonfunctionsService) {
  }

  ngOnInit() {
    this.sendMailForm = this.formBuilder.group({
      fEmailID: ["", Validators.email]
    });
    this.authenticationService.forget_password = true;
    this.verify_mail = true;
    this.resetPasswordForm = this.createResetForm();

  }
  get resetpasswordControl() {
    return this.resetPasswordForm.controls;
  }
  get sendMailControl() {
    return this.sendMailForm.controls;
  }
  goBack() {
    if (!this.authenticationService.changePwdReq) {
      this.sendMailForm.reset();
      this.authenticationService.loginForm.reset();
      this.authenticationService.forget_password = true;
      this.verify_mail = true;
      this.verify_code = false;
    }
    this.resetPasswordForm.reset();
    this.authenticationService.new_password = false;
    this.authenticationService.showModal = false;
    this.matched = true;
    this.correctpwd = true;
    this.flag_passwordlength = true;
    this.resetPasswordstrength = "initial";
    this.retypePasswordStrength = "initial";
    this.model = {};
  }
  setFocus(currField, nextField, event, previousField) {
    if (event.key === "Backspace") {
      this[previousField].nativeElement.focus();
    } else {
      if (!this.validate.isUndefined(this.model[currField]))
        this[nextField].nativeElement.focus();
    }
    this.verificationText = false;
  }
  /*********************************************************************************
   *Start:Added For Reset password by sending verification code to mail
   *By Aravind:21-11-2018
   *********************************************************************************/
  sendMail() {
    let requestData: any;
    if (this.validate.submitDetails(this.sendMailForm)) {
      this.loading = true;
      requestData = {
        "EmailId": this.sendMailControl.fEmailID.value,
        "TenantId": "root",
        "activityType": "Forgot Password",
        "createdBy": this.alertService.getUserDetails("getid")
      };
      this.authenticationService
        .changePasswordApi("sendverification", requestData)
        .pipe(first())
        .subscribe(
          data => {
            this.loading = false;
            if (data.status == 1) {
              this.validate.popUpAlert("Verification Code has been sent to the Email-ID", "", "success");
              this.reqId = data.data;
              this.verify_mail = false;
              this.verify_code = true;
            } else {
              this.validate.popUpAlert("Email-ID does not exists", "", "error");
            }
          },
          error => {
            this.loading = false;
            this.commonfunctions.serviceErrorHandling(error);
          }
        );
    }
  }
  verifyCode() {
    let requestData: any;
    this.model.fVerifyCode = this.validate.makeCode(this.model);
    if (!this.validate.isUndefined(this.model.fVerifyCode)) {
      this.verificationText = false;
      requestData = {
        "Id": this.reqId,
        "VerificationCodeByUser": this.model.fVerifyCode,
        "TenantId": "root",
        "createdBy": this.alertService.getUserDetails("getid")
      };
      this.loading = true;
      this.authenticationService
        .changePasswordApi("verifycode", requestData)
        .pipe(first())
        .subscribe(
          data => {
            this.loading = false;
            if (data.status == 1) {
              this.authenticationService.new_password = true;
              this.authenticationService.forget_password = false;
              this.verify_code = false;
            } else {
              this.validate.popUpAlert("Invalid Verification Code", "", "error");
            }
          },
          error => {
            this.loading = false;
            this.commonfunctions.serviceErrorHandling(error);
          }
        );
    } else {
      this.verificationText = true;
    }
  }

  createResetForm() {
    return this.formBuilder.group({
      newPass: ["", [Validators.compose([Validators.required, Validators.minLength(8), Validators.pattern(this.validate.passwordPattern)])]
      ],
      newPassRe: ["", Validators.required]
    });
  }
  changedPassword() {
    let requestData;
    let mail;
    let id;
    let length = this.validate.isUndefined(this.resetpasswordControl.newPass.value) ? 0 : this.resetpasswordControl.newPass.value.length;
    if (length >= 8) {
      if (this.resetpasswordControl.newPass.status == "VALID") {
        this.matched = this.validate.checkPasswords(this.resetPasswordForm);
        if (this.matched) {
          this.loading = true;
          if (this.alertService.first) { // for first time login 
            mail = this.alertService.getUserDetails("getuserEmailID");
            id = this.alertService.getUserDetails("getchangepasswordID");
          } else if (this.authenticationService.changePwdReq) {
            mail = this.alertService.getUserDetails("getuserEmailID");
            id = this.alertService.changePasswordId.get();
          } else {
            mail = this.sendMailControl.fEmailID.value;
            id = this.reqId;
          }
          requestData = {
            "changepassword_id": id,
            "emailId": mail,
            "password": this.authenticationService.enCryptText(this.resetpasswordControl.newPass.value)
          };
          this.authenticationService
            .changePasswordApi("setpassword", requestData)
            .pipe(first())
            .subscribe(
              data => {
                this.loading = false;
                this.resetPasswordForm.reset();
                if (data.status == 1) {
                  this.model = {};
                  this.authenticationService.forget_password = false;
                  this.authenticationService.new_password = false;
                  this.goBack();
                  this.validate.popUpAlert("Password reset successfully", "", "success");
                } else {
                  this.validate.popUpAlert(data.message, "", "error");
                }
              },
              error => {
                this.loading = false;
                this.commonfunctions.serviceErrorHandling(error);
              }
            );
        } else {
          this.retypePasswordStrength = "weak";
          if (this.validate.isUndefined(this.resetpasswordControl.newPassRe.value)) {
            this.retypePassword = false;
          }
        }
      } else {
        this.correctpwd = false;
        this.resetPasswordstrength = "weak";
      }
    } else {
      this.flag_passwordlength = false;
      this.resetPasswordstrength = "weak";
    }
  }
  /**********************************************************************
   * End Reset password
   **********************************************************************/

}

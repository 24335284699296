import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { AppModuleDev } from './app_dev/app.module';
import { AppModulePRD } from './app_prd/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
	if(window){
	   window.console.log=function(){return false;};
	  }
}

//platformBrowserDynamic().bootstrapModule(AppModulePRD)
platformBrowserDynamic().bootstrapModule(AppModuleDev)
  .catch(err => console.error(err));

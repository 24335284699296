import { Component, OnInit, NgZone, ElementRef, SimpleChanges, ViewChild } from '@angular/core';
import { Chart, ChartData } from 'chart.js';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { NvD3Module } from 'ngx-nvd3';
declare var $: any;
import {
  FormBuilder,
  FormArray,
  Validators,
  FormControl,
  FormGroup
} from "@angular/forms";
import { AlertService } from "../services/alert.service";
import { ApiService } from "../services/apiservices.service";
import { MandatoryfieldService } from "../common/Validators/mandatoryfield.service";
import { CommonfunctionsService } from '../services/commonfunctions.service';
@Component({
  selector: 'app-viewreport',
  templateUrl: './viewreport.component.html',
  styleUrls: ['./viewreport.component.css']
})
export class ViewreportComponent implements OnInit {
  reportForm: any;
  @ViewChild('nvd3') nvd3;
  public result;
  public barChartLabels = [];
  public barChartData = [];
  public barChartOptions = {};
  public filterName = {};
  /***********************
   * Bar chart options
   * ********************/
  public options = {
    scaleShowVerticalLines: false,
    responsive: true,
    animate: false,
    title: {},
    backgroundColor: "#ddd",
    animation: {
      onComplete: function () {
        var ctx = this.chart.ctx;
        ctx.font = "10px!important 'Helvetica Neue', Helvetica, Arial, sans-serif"
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.imageSmoothingQuality = 'high';
        this.data.datasets.forEach(function (dataset) {
          for (var i = 0; i < dataset.data.length; i++) {
            var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
              scale_max = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._yScale.maxHeight;
            ctx.fillStyle = '#738593';
            var y_pos = model.y - 5;
            // Make sure data value does not get overflown and hidden
            // when the bar's value is too close to max value of scale
            // Note: The y value is reverse, it counts from top down
            if ((scale_max - model.y) / scale_max >= 0.93)
              y_pos = model.y + 20;
            ctx.fillText(dataset.data[i], model.x, y_pos);
          }
        });
      },
    },
    scales: {
      xAxes: [{
        barPercentage: 0.8,
        xLabels: this.barChartLabels,
        gridLines: {
          display: false,
          color: "#ddd",
        },

      }],
      yAxes: [{
        ticks: {
          beginAtZero: true,
          callback: function (value) { return value + "%" }
        }, gridLines: {
          display: true,
          color: "#ddd"
        },
        scaleLabel: {
          display: true,
          labelString: "Percentage"
        }
      }]
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontColor: '#175788',
        boxWidth: 10,
      }
    },
  };
  /***********************
  * Boxplot chart options
  * ********************/
  public optionsplot = {
    chart: {
      type: 'boxPlotChart',
      height: 450,
      width: 1000,
      showXAxis: true,
      showYAxis: true,
      xAxis: {
        axisLabel: "Year",
        axisLabelDistance: 10,
        tickSize: 1,
        tickPadding: 12,
        line: {
          display: true,
          color: "#ddd"
        }
      },
      yAxis: {
        tickSize: 1,
        tickPadding: 3,
        showMaxMin: true,
        line: {
          display: true,
          color: "#ddd"
        }
      },
      margin: {
        top: 20,
        right: 20,
        bottom: 60,
        left: 60
      },
      color: ["#04315B", "#007F92", "#7FA1B1"],
      maxBoxWidth: 100,
      label: "title text",
      subtitle: {
        enable: true,
        text: "text"
      },
    }
  };
  myChart: Chart = [];
  boxPlotData = [];
  ReportList: any = [];
  tieuplist: any = [];
  transactionIdList: any = [];
  yearlist: any = [];
  stateList: any = [];
  levels: any;
  resArray: any = [];
  fields: any = [];
  title: any = [];
  crops: any = [];
  compareyearlist: any = [];
  tableData: any;
  firstCrop: any;
  showChart: boolean = false;
  showcomparison: boolean = false;
  loading: boolean = false;
  showBoxChart: boolean = false;
  pricingresults: boolean = false;
  comparison_previousyear_rates: boolean = false;
  pricingresults_loadings: boolean = false;
  uw_comments: boolean = false;
  downloadAllTable: boolean = false;
  notComprehensive: boolean = true;
  report: any = "";
  tieup: any = "";
  year: any = "";
  state: any = "";
  transacion: any = "";
  tableName: any = "";
  compareyear: any = ""
  startY: any = 170;
  /*********************Hardcoded for warning tables******************/
  showMultiTable: boolean = false;
  tableCount: any = [
    { fields: "csvGiveWarningsfieldname", data: "csvGiveWarnings", table: "csvWarningTable" },
    { fields: "deviationfieldname", data: "deviationWarningsOut", table: "deviationWarningTable" },
    { fields: "percentilefieldname", data: "previousYearsPercentileOut", table: "percentileWarningTable" }];
  multiTableData: any = {
    "pricingresults": "",
    "pricingresults_loadings": "",
    "uw_comments": "",
    "csvGiveWarnings": "",
    "deviationWarningsOut": "",
    "previousYearsPercentileOut": "",
    "comparison_previousyear_rates": ""
  }
  multitableHeaders: any = {
    "pricingresults": "",
    "pricingresults_loadings": "",
    "uw_comments": "",
    "csvGiveWarnings": "",
    "deviationWarningsOut": "",
    "previousYearsPercentileOut": "",
    "comparison_previousyear_rates": ""
  }
  multitableTitles: any = {
    "pricingresults": "",
    "pricingresults_loadings": "",
    "uw_comments": "",
    "csvGiveWarnings": "",
    "deviationWarningsOut": "",
    "previousYearsPercentileOut": "",
    "comparison_previousyear_rates": ""
  }
  multipleTableDataForExcel: any = {}

  public pageTitle = "Report List"
  public tieupId: any;

  years: any[] = [];
  yearErrorData: any[] = [];
  excelJsonData: any[] = [];
  warningYield = null;

  constructor(private readonly elementRef: ElementRef, private readonly ngZone: NgZone, private formBuilder: FormBuilder,
    private validate: MandatoryfieldService, private apiData: ApiService, private alertService: AlertService,
    private commonfunctions: CommonfunctionsService) {
  }

  ngOnInit() {
    this.tieupId = this.alertService.getUserDetails("getTieup");
    this.apiData.outputCombo.removData();
    this.reportForm = this.formBuilder.group({
      reportName: [this.tieupId ? "comprehensive_report" : "", Validators.required],
      tieupName: [this.tieupId || "", Validators.required],
      Year: ["", Validators.required],
      transactionID: ["", Validators.required],
      state: ["", Validators.required],
      compareyear: ["", Validators.required]
    });
    if (this.tieupId) {
      this.pageTitle = "Comprehensive Report";
      // this.reportselection("comprehensive_report")
    }
    this.loadDropDown();
    this.getReports();
  }
  /*****************************************************
  * get report list on page load
  * ****************************************************/
  getReports() {
    let empty = {};
    this.apiData.formRequest.getRequest("", empty, "getReprtList");
    this.loading = true;
    this.apiData.finalReportsApi.getReport(res => {
      if (!this.validate.isUndefined(res)) {
        let result = res;
        this.loading = false;
        if (result.status == 1) {
          this.ReportList = result.data;
          if (this.tieupId) {
            this.reportselection("comprehensive_report")
          }
        } else {
          let errorText = this.alertService.ErrorText.checkError(result.data);
          this.validate.popUpAlert(errorText, "", "error");
        }
      }
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }
  /*******************************
  * get tieup and years dropdown on page load
  * *****************************/
  loadDropDown() {
    this.apiData.getdropdownList.getTieUplist(res => {
      if (!this.validate.isUndefined(res)) {
        let result = res;
        this.loading = false;
        if (result.status == 1) {
          this.tieuplist = result.data;
          if (this.tieupId) {
            this.closeall();
            this.clearYear();
          }
        } else {
          let errorText = this.alertService.ErrorText.checkError(result.data);
          this.validate.popUpAlert(errorText, "", "error");
        }
      }
    }, err => {
      this.commonfunctions.serviceErrorHandling(err);
    });
  }
  getReportYears() {
    this.yearlist = [];
    this.apiData.formRequest.getRequest("", this.reportForm.value, "getReportYears");
    this.apiData.getdropdownList.getReportYears(res => {
      if (!this.validate.isUndefined(res)) {
        let result = res;
        if (result.status == 1) {
          this.yearlist = result.data.filter(d => d.value != 0);
        } else {
          let errorText = (result.data == "NO_DATA_FOUND") ? "Year data not found" : this.alertService.ErrorText.checkError(result.data);
          this.validate.popUpAlert(errorText, "", "error");
        }
      }
    }, err => {
      this.commonfunctions.serviceErrorHandling(err);
    });
  }
  /*****************************************************
  * close the reports while reselect any dropdown options
  * ****************************************************/
  closeall() {
    this.showChart = false;
    this.showBoxChart = false;
    this.showMultiTable = false;
    this.pricingresults = false;
    this.pricingresults_loadings = false;
    this.comparison_previousyear_rates = false;
    this.uw_comments = false;
    this.downloadAllTable = false;
  }
  /***************************************************************
  * clear year dropdown when tieup reselect && get the report years
  * *************************************************************/
  clearYear() {
    this.reportForm.controls["Year"].setValue("");
      this.getReportYears();
  }
  /************************************************************************
  * show comparison year dropdown while selection comparison report choosing
  * ***********************************************************************/
  reportselection(value) {
    this.closeall();
    this.showcomparison = false;
    this.notComprehensive = true;
    this.reportForm.controls.compareyear.disable(true);
    this.reportForm.controls.compareyear.setValue("");
    this.report = this.getTableName(value);
     if (value == "comprehensive_report") { // changes on 20-03-2019
      this.notComprehensive = false;
    } else if (value == "warningmessages") {
      this.reportForm.controls.tieupName.setValue('root')
      this.closeall();
      this.clearYear()
    }
  }
  /************************************************************************
   * get 'years to compare'  dropdown list
   * ***********************************************************************/
  getPreviousYearToCompare() {
    this.loading = true;
    this.compareyearlist = [];
    this.apiData.formRequest.getRequest("", this.reportForm.value, "getCompareyear");
    this.apiData.finalReportsApi.getPreviousYearToCompare(res => {
      if (!this.validate.isUndefined(res)) {
        let result = res;
        this.loading = false;
        if (result.status == 1) {
          this.compareyearlist = result.data;
        } else {
          let errorText = (result.data == "NO_DATA_FOUND") ? "Year to compare not found for " + this.reportForm.value.tieupName : this.alertService.ErrorText.checkError(result.data);
          this.validate.popUpAlert(errorText, "", "error");
        }
      }
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }
  /************************************************************************
    * get 'state' dropdown based on year
    * ***********************************************************************/
  getState() {
    this.loading = true;
    this.stateList = [];
    this.transactionIdList = [];
    this.apiData.formRequest.getRequest("", this.reportForm.value, "getState");
    this.apiData.finalReportsApi.getState(res => {
      if (!this.validate.isUndefined(res)) {
        let result = res;
        this.loading = false;
        if (result.status == 1) {
          this.stateList = result.data;
        } else {
          let errorText = (result.data == "NO_DATA_FOUND") ? "State data not found" : this.alertService.ErrorText.checkError(result.data);
          this.validate.popUpAlert(errorText, "", "error");
        }
      }
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }
  /************************************************************************
   * get transactionid dropdown list based on state
   * ***********************************************************************/
  getTransactionid() {
    this.transactionIdList = [];
    if (this.reportForm.get('reportName').value == "warningmessages") {
      return
    }
    this.loading = true;
    this.apiData.formRequest.getRequest("", this.reportForm.value, "getTransactionid");
    this.apiData.finalReportsApi.getTransactionid(res => {
      if (!this.validate.isUndefined(res)) {
        let result = res;
        this.loading = false;
        if (result.status == 1) {
          this.transactionIdList = result.data;
        } else {
          let errorText = (result.data == "NO_DATA_FOUND") ? "Transaction ID not found" : this.alertService.ErrorText.checkError(result.data);
          this.validate.popUpAlert(errorText, "", "error");
        }
      }
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }
  getTableName(value) {
    var result = this.ReportList.filter(obj => {
      return obj.value == value;
    });
    return result[0].text;
  }
  /************************************************************************
   * show the reports when view button is clicked
   * ***********************************************************************/
  showchart(selectreport) {
  this.years = [];
  this.yearErrorData = [];
  this.excelJsonData = []
  this.warningYield = null;
    if (this.reportForm.value.reportName == "warningmessages") {
      this.getWarningReport()
      return;
    }
    if (this.validate.submitDetails(this.reportForm) || (this.reportForm.value.reportName == "comparison_previousyear_rates" && this.reportForm.value.tieupName)) {
      this.loading = true;
      this.tieup = this.reportForm.value.tieupName;
      this.year = this.reportForm.value.Year;
      this.state = this.reportForm.value.state;
      this.transacion = this.reportForm.value.transactionID;
      if (selectreport == "pricingresults") {
        this.apiData.formRequest.getRequest("", this.reportForm.value, "getPricingResultsRpt");
        this.loadTable("getPricingResultsRpt", selectreport);
      } else if (selectreport == "pricingresults_loadings") {
        this.apiData.formRequest.getRequest("", this.reportForm.value, "getPricingResultsRpt");
        this.loadTable("getPricingResultsLoadingsRpt", selectreport);
      } else if (selectreport == "warningmessages") {
        this.apiData.formRequest.getRequest("", this.reportForm.value, "getPricingResultsRpt");
        this.loadTable("giveWarningMessageRpt", selectreport);
      } else if (selectreport == "uw_comments") {
        this.apiData.formRequest.getRequest("", this.reportForm.value, "UWCommentsRpt");
        this.loadTable("UWCommentsRpt", selectreport);
      } else if(selectreport == "comparison_previousyear_rates") {
        this.loadPreviousYearDataTable("UWCommentsRpt", selectreport);
      } else if (selectreport == "comprehensive_report") {
        for (var i = 0; i < this.ReportList.length; i++) {
          if (i===0 && !this.tieup) {
            this.getWarningReport()
          }
          let value = this.ReportList[i].value
          if (value == "pricingresults" || value == "pricingresults_loadings" || value == "uw_comments" || value == "warningmessages") {
            if (!this.tieupId || !['warningmessages', 'pricingresults'].includes(value))
            this.showchart(value);
          }
        }
        this.downloadAllTable = true;
      } else if (selectreport == "comparison_previousyear_rates") {
        this.compareyear = this.reportForm.value.compareyear;
        this.apiData.formRequest.getRequest("", this.reportForm.value, "getBarChart");
        this.apiData.finalReportsApi.getPreviousYearRatesComparisonRpt(res => {
          if (!this.validate.isUndefined(res)) {
            this.result = res;
            this.loading = false;
            if (this.result.status == 1) {
              $("#chartContainer").empty();
              this.levels = this.result.level;
              /*************************************************************************
               * barchart creation
               *************************************************************************/
              for (var i = 0; i < this.result.admin.length; i++) {
                $("#chartContainer #display-chart" + i).remove();
                var canvas = $('<canvas id="display-chart' + i + '" class="bg-color py-2"></canvas>');
                $("#chartContainer").append(canvas);
                this.onSelectionChange(this.result.admin[i], i);
              }
            } else {
              let errorText = (this.result.data == "NO_DATA_FOUND") ? "No report found for " + this.reportForm.value.transactionID : this.alertService.ErrorText.checkError(this.result.data);
              this.validate.popUpAlert(errorText, "", "error");
            }
          }
        }, err => {
          this.loading = false;
          this.commonfunctions.serviceErrorHandling(err);
        });
      } else if (selectreport == "boxplots") {
        this.firstCrop = "";
        this.apiData.formRequest.getRequest("", this.reportForm.value, "getPricingResultsRpt");
        this.apiData.finalReportsApi.getBoxplotRpt(res => {
          if (!this.validate.isUndefined(res)) {
            this.result = res;
            this.loading = false;
            if (this.result.status == 1) {
              this.crops = this.result.crops;
              this.firstCrop = this.crops[0];
              this.makeBoxPlotData(this.firstCrop);
              this.showBoxChart = true;
            } else {
              let errorText = (this.result.data == "NO_DATA_FOUND") ? "No report found for " + this.reportForm.value.transactionID : this.alertService.ErrorText.checkError(this.result.data);
              this.validate.popUpAlert(errorText, "", "error");
            }
          }
        }, err => {
          this.loading = false;
          this.commonfunctions.serviceErrorHandling(err);
        });
      } else {
        this.loading = false;
      }
    } else {
      this.validate.popUpAlert("Please Select Required Fields!", "Required", "warning");
    }
  }

  /************************************************************************
   * load the table format reports
   * ***********************************************************************/
  loadTable(serviceName, selectedName) {
    this.loading = true;
    this.apiData.finalReportsApi[serviceName](res =>
      {
      this.handleTableResponse(res, serviceName, selectedName);
    }, err => {
      this.multiTableData[selectedName] = [];
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }

  loadPreviousYearDataTable(serviceName, selectedName) {
    this.loading = true;
    this.apiData.finalReportsApi.previousYearPricingResultsRpt(this.reportForm.value.tieupName, res => {
      if (res && res.data && res.data.length) {
        res.data = Array.from(new Set(res.data.map(d => d.admin2 + d.crop))).map(d => res.data.find(e => (e.admin2 + e.crop) === d)).sort((a,b) => a.sno - b.sno)
      }
      this.handleTableResponse(res, serviceName, selectedName);
    }, err => {
      this.multiTableData[selectedName] = [];
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    })
  }

  handleTableResponse(res: any, serviceName: any, selectedName: any) {
    if (!this.validate.isUndefined(res)) {
      let result = res;
      if (result.status == 1) {
        if (result.fieldname) {
          for (let i = 0; i < res.fieldname.length; i++) {
            const data = res.fieldname[i];
            if (!data.hide) {
              if (['sum_insured'].includes(data.name)) {
                data.title += ' (Cr)'
              }
            }
          }
          result.fieldname = res.fieldname.filter(d => !d.hide)
          result.fieldname
        }
        if (result.data) {
          for(let i = 0; i< result.data.length; i++) {
            const data = result.data[i]
            if (data) {
              if (data.sum_insured || data.sum_insured == 0) {
                data.actual_sum_insured = data.sum_insured;
                data.sum_insured = +(+data.sum_insured / 1e7).toFixed(2)
              }
              if (data.area_sown || data.area_sown == 0) {
                data.actual_area_sown = data.area_sown;
                data.area_sown = +data.area_sown.toFixed(0)
              }

            }
          }
        }
        if (serviceName == "giveWarningMessageRpt") {
          for (var i = 0; i < this.tableCount.length; i++) {
            $("#" + this.tableCount[i].table).bootstrapTable("destroy");
            this.prepareMultitableHeader(result, this.tableCount[i].fields, this.tableCount[i].table, this.tableCount[i].data);
            this.prepareMultiTableJson(result, this.tableCount[i].data, this.tableCount[i].data);
            this.multipleTableDataForExcel[this.tableCount[i].data] = result
            $("#" + this.tableCount[i].table).bootstrapTable({
              data: this.resArray,
              columnDefs: [
                {
                  className: "table-data"
                }
              ],
              select: true
            });
          }
          setTimeout(() => this.loading = false, 500);
          this.showMultiTable = true;
        } else {
          let tableName = selectedName + "Table";
          $("#" + tableName).bootstrapTable("destroy");
          this.prepareMultitableHeader(result, "fieldname", tableName, selectedName);
          this.prepareMultiTableJson(result, "data", selectedName);
          this.multipleTableDataForExcel[selectedName] = result;
          this[selectedName] = true;
          this.tableData = $("#" + tableName).bootstrapTable({
            data: this.resArray,
            columnDefs: [
              {
                className: "table-data"
              }
            ],
            select: true
          });
          this.loading = false;
        }
      } else {
        this.multiTableData[selectedName] = [];
        this.loading = false;
        let errorText = (result.data == "NO_DATA_FOUND") ? "No report found for " + this.reportForm.value.transactionID : this.alertService.ErrorText.checkError(result.data);
        this.validate.popUpAlert(errorText, "", "error");
      }
    }
  }

  /**
   * load the table format reports for warnings
   */
   getWarningReport() {
     const reportValue = this.reportForm.value;
    if (!reportValue.Year || !reportValue.state) {
      this.validate.popUpAlert("Please Select Required Fields!", "Required", "warning");
      return;
    }
    this.loading = true;
    this.apiData.masterUploadgetAgreeDatasetWarnings(reportValue.Year,reportValue.state).then((response: any) => {
      if (response.status == 1) {
        if (!response.data.reva_BlankWarningYields && !response.data.reva_OutliersWarningYields && !response.data.reva_ZeroWarningYields){
        } else {
          this.warningYield = response.data;
        }
      }
      this.loading = false;
    }).catch(err => {
      this.loading = false;
      console.log(err)
    })
   }

  /************************************************************************
   * header creation for table data
   * ***********************************************************************/
  prepareMultitableHeader(resData, filed, tableid, data) {
    this.fields = [];
    this.title = [];
    document.getElementById(data).innerHTML = "";
    for (var i = 0; i < resData[filed].length; i++) {
      let resArray1 = {};
      this.fields.push(resData[filed][i]["title"]);
      this.title.push({ header: resData[filed][i]["title"], field: resData[filed][i]["name"] });
      var tr = $('<th class="table-head" data-sortable="true" data-field="' + resData[filed][i]["title"] + '" >' + resData[filed][i]["title"] + '</th>');
      $("#" + tableid + " thead tr").append(tr);
    }
    this.multitableHeaders[data] = this.fields;
    this.multitableTitles[data] = this.title;
  }
  /************************************************************************
   * table data creation
   * ***********************************************************************/
  prepareMultiTableJson(resData, data, id) {
    this.resArray = [];
    for (var i = 0; i < resData[data].length; i++) {
      let resArray1 = {};
      for (var j = 0; j < this.title.length; j++) {
        resArray1[this.title[j].header] = resData[data][i][this.title[j].field];
      }
      this.resArray.push(resArray1);
    }
    this.multiTableData[id] = this.resArray;
  }

  /************************************************************************
   * creation box plot chart data
   * ***********************************************************************/
  makeBoxPlotData(crop) {
    this.boxPlotData = [];
    for (var i = 0; i < this.result.data.length; i++) {
      if (crop == this.result.data[i]["crop"]) {
        let resArray1 = {};
        let outlier = [];
        let data = [];
        resArray1["whisker_low"] = this.result.data[i]["minimumYieldByYear"];
        resArray1["whisker_high"] = this.result.data[i]["maximumYieldByYear"];
        resArray1["Q1"] = this.result.data[i]["q1ByYear"];
        resArray1["Q2"] = this.result.data[i]["medianByYear"];
        resArray1["Q3"] = this.result.data[i]["q3ByYear"];
        outlier.push(this.result.data[i]["percentileByYear"]);
        resArray1["outliers"] = outlier;
        this.boxPlotData.push({ "label": this.result.data[i]["previousYear"], "values": resArray1 })
      }
    }
    this.optionsplot.chart.xAxis.axisLabel = crop;
    this.nvd3.options = this.optionsplot;
  }
  /************************************************************************
  * creation bar chart data
  * ***********************************************************************/

  clearCharts() {
    this.barChartOptions = {};
    this.barChartLabels = [];
    this.barChartData = [];
  }

  onSelectionChange(value, chartNumber) {
    this.clearCharts();
    var filter = this.result.level;
    let chartlabearray = [];
    let barchartdataset = [];
    let color, highlightFill, highlightStroke = "";
    for (var i = 0; i < this.result.data.length; i++) {
      if (this.result.data[i]["admin"] == value) {
        chartlabearray.push(this.result.data[i].crop);
      }
    }

    this.barChartLabels = chartlabearray;
    for (var k = 0; k < this.result.dataplot.length; k++) {
      let plottData = [];
      let labels = "";
      let data = [];
      for (var j = 0; j < this.result.data.length; j++) {
        for (var i = 0; i < chartlabearray.length; i++) {
          if (chartlabearray[i] == this.result.data[j].crop && this.result.data[j]["admin"] == value) {
            plottData.push(this.result.data[j][this.result.dataplot[k]].value)
            labels = this.result.data[j][this.result.dataplot[k]].text;
            color = this.getRandomColor(k);
          }
        }
      }
      barchartdataset.push({
        data: plottData, label: labels, borderWidth: 1, backgroundColor: color
      });
    }
    this.barChartData = barchartdataset;
    this.filterName = {
      display: true,
      position: "top",
      text: this.levels + " - " + value,
      fontSize: 15,
      fontColor: "#175788"
    };
    this.options.title = this.filterName;
    this.barChartOptions = this.options;
    // if (!this.validate.isUndefined(this.myChart[chartNumber])) {
    //   this.myChart[chartNumber].data.datasets = this.barChartData;
    //   this.myChart[chartNumber].data.labels = this.barChartLabels;
    //   this.myChart[chartNumber].options = this.barChartOptions;
    //   this.myChart[chartNumber].update();
    // } else {
    this.initChart(chartNumber);
    // }
    this.showChart = true;
  }

  getRandomColor(count) {
    let colorpatterns = ["#04315B", "#007F92", "#7FA1B1"]
    return colorpatterns[count];
  }
  initChart(chartNumber) {
    document.getElementById("display-chart" + chartNumber).innerHTML = "";
    let ctx = document.getElementById("display-chart" + chartNumber);
    this.myChart[chartNumber] = new Chart(ctx, {
      type: 'bar',
      destroy: true,
      data: {
        labels: this.barChartLabels,
        datasets: this.barChartData
      },
      options: this.options,
      defaultFontSize: 10
    });
  }
  /************************************************************************
   * create pdf for chart
   * ***********************************************************************/
  exportToPdf(chart) {
    let pageMode = 'p';
    if (this.multitableTitles[chart] && this.multitableTitles[chart].length > 10) {
      pageMode = 'l'
    }
    let canvas;
    var canvasImg = [];
    var reportPageHeight = ""
    var reportPageHeight = "";
    var pdf = new jsPDF(pageMode, 'pt', 'a4');
    if (chart == "bar") {
      var pdfctxX = 30;
      var pdfctxY = 220;
      var buffer = 30;
      this.addHeader(pdf);
      for (var i = 0; i < this.result.admin.length; i++) {
        canvas = <HTMLCanvasElement>document.getElementById('display-chart' + i);
        canvasImg = canvas.toDataURL("image/PNG", 1.0);
        pdf.setFontSize(30);
        pdf.addImage(canvasImg, 'PNG', pdfctxX, pdfctxY, 500, 320);
        pdfctxX = 30;
        pdfctxY += 320 + buffer;
        if (i % 2 != 1 && i != this.result.admin.length - 1) {
          pdfctxY = 40;
          pdf.addPage();
        }
      }
      pdf.save(this.reportForm.value.reportName + "_" + this.reportForm.value.transactionID + '_' + this.commonfunctions.getDateTimeExt() + ".pdf");
    } else if (chart == "box") {
      document.getElementById("canvas2").innerHTML = "";
      var appendCanvas = $('<canvas id="display-box-chart" width="1000" height="450" class="bg-color py-2"></canvas>');
      $("#canvas2").append(appendCanvas);
      var svgString = new XMLSerializer().serializeToString(document.querySelector('svg'));
      canvas = document.getElementById("display-box-chart");
      var ctx = canvas.getContext("2d");
      var DOMURL = self.URL;
      var img = new Image();
      var svg = new Blob([svgString], { type: "image/svg+xml;charset=utf-8" });
      var url = DOMURL.createObjectURL(svg);
      // var pdfctxX = 40;
      // var pdfctxY = 570;
      var pdfctxX = 160;
      var pdfctxY = 570;
      var reportName = this.reportForm.value.reportName;
      var transactionId = this.reportForm.value.transactionID;
      var obj = this;
      img.onload = function () {
        ctx.drawImage(img, 0, 0);
        var png = canvas.toDataURL("image/png");
        pdf.addImage(png, 'PNG', 15, pdfctxX, pdfctxY, 300, 300);
        obj.addHeader(pdf);
        pdf.save(reportName + "_" + transactionId + '_' + obj.commonfunctions.getDateTimeExt() + ".pdf");
      };
      img.src = url;
    }
    else {
      var doc = new jsPDF(pageMode, 'pt', 'a4');
      if (this.reportForm.value.reportName == "comprehensive_report" && chart == "all") {
        let objValues = Object.keys(this.multitableHeaders);
        var firstRpt=true;
        for (var i = 0; i < objValues.length; i++) {
          var rpt_name=objValues[i];
          if(this.multiTableData[rpt_name].length>0)
          {
            if(firstRpt!=true){
              doc.addPage();
            }
            firstRpt=false;
            this.addHeader(doc);
            this.convertToPdf(objValues[i], doc);
            this.startY = 170;
            // if (i < objValues.length - 1) {
            //   doc.addPage();
            // }
          }
        }
      } else {
        this.startY = 170;
        this.addHeader(doc);
        this.convertToPdf(chart, doc);
      }
      doc.save(this.reportForm.value.reportName + "_" + this.reportForm.value.transactionID + '_' + this.commonfunctions.getDateTimeExt() + ".pdf");
    }
  }
  addHeader(doc) {
    doc.autoTable({
      html: '#example',
      tableLineColor: "#175788",
      tableLineWidth: 0.2,
      margin: {
        right: 20,
        left: 20
      },
      headStyles: {
        fontSize: 8,
        fontStyle: 'normal',
        textColor: "#fff",
        fillColor: "#175788",
        halign: 'left'
      },
      styles: {
        // cellWidth: 275
      },
      theme: "striped",
      bodyStyles: {
        fontSize: 10
      },
    });
  }
  /************************************************************************
   * excel download for table data
   * ***********************************************************************/
  exportAsXLSX(value): void {
    if (value == "singletable") {
      this.commonfunctions.exportAsExcelFile(this.resArray, this.fields, this.reportForm.value.reportName + "_" + this.reportForm.value.transactionID);
    }
    else if (value == 'all' && this.reportForm.value.reportName == "comprehensive_report") {
      this.commonfunctions.exportComprehensiveExcel(this.multiTableData, this.multitableHeaders, this.reportForm.value.reportName + "_" + this.reportForm.value.transactionID);
    }
    else if (value == 'all' && this.reportForm.value.reportName != "comprehensive_report") {
      this.commonfunctions.exportAsMultiExcelFile(this.multiTableData, this.multitableHeaders, this.reportForm.value.reportName + "_" + this.reportForm.value.transactionID);
    } else {
      const resultData = this.generateExcelData(this.multipleTableDataForExcel[value]);
      // debugger
      // const data = JSON.parse(JSON.stringify(this.multiTableData[value]))
      // const header = JSON.parse(JSON.stringify(this.multitableHeaders[value]))
      // const sum_insured_index = this.multitableHeaders[value].indexOf('Sum Insured (Cr)');
      // if (sum_insured_index > -1) {
      //   header[sum_insured_index] = 'Sum Insured';
      // }
      // data.forEach((d: any) => {
      //   d.sum_insured = d.actual_sum_insured;
      //   d.area_sown = d.actual_area_sown;
      //   delete d.actual_sum_insured
      //   delete d.actual_area_sown
      // })
      // console.log(header)
      // console.log(data)
      debugger
      this.commonfunctions.exportAsExcelFile(resultData.data, resultData.header, this.reportForm.value.reportName + "_" + this.reportForm.value.transactionID);
    }
  }
  /************************************************************************
  * pdf download for table data
  * ***********************************************************************/
  convertToPdf(value, doc) {
    if (value != "singletable") {
      this.resArray = {};
      this.title = {}
      this.resArray = this.multiTableData[value];
      this.title = this.multitableTitles[value];
    }
    var col = [];
    var rowdata = [];
    var len = this.resArray.length;
    for (var i = 0; i < this.title.length; i++) {
      col.push({ header: this.title[i].header });
    }
    for (var k = 0; k < this.resArray.length; k++) {
      let fileddata = [];
      for (var i = 0; i < col.length; i++) {
        for (var j = 0; j < this.title.length; j++) {
          if (this.title[j].header == col[i].header)
            fileddata.push(this.resArray[k][this.title[j].header]);
        }
      }
      rowdata.push(fileddata);
    }
    this.downloadpdf(doc, col, rowdata)
  }
  downloadpdf(pdf, cloumn, row) {
    let styles: any = {};
    if (cloumn.length > 10) {
      styles.cellWidth = 800 / cloumn.length;
    }
    pdf.autoTable(cloumn, row, {
      startY: this.startY,
      margin: {
        right: 20,
        left: 20
      },
      headStyles: {
        fontSize: 8,
        fontStyle: 'normal',
        textColor: "#fff",
        fillColor: "#175788",
        halign: 'center'
      },
      styles,
      // drawCell: function (cell, data) {
      //   if (data.column.dataKey === 'Sum Insured' || data.column.dataKey === 'SNo' || data.column.dataKey === 'Cluster') {
      //     pdf.autoTableText(cell.text, cell.x + cell.width / 2, cell.y + cell.height / 2, {
      //       halign: 'right',
      //       valign: 'middle'
      //     });
      //   }
      // },
      tableLineWidth: 0,
      theme: "striped",
      bodyStyles: {
        fontSize: 7,
        halign: 'center',
        valign: 'middle'
      },
    });
  }

  generateExcelData(res: any) {
    const resultData = res.data;
    const fieldname =  res.fieldname;
    const keyHeaderMap = new Map();
    const keys = fieldname.map((d: any) => d.name)
    const header = fieldname.map((d: any) => {
      if (d.title === 'Sum Insured (Cr)') {
        keyHeaderMap.set(d.name, 'Sum Insured');
        return 'Sum Insured';
      }
      keyHeaderMap.set(d.name, d.title);
      return d.title;
    });
    const data: any[] = [];
    for (let i = 0; i < resultData.length; i++) {
      const ele = resultData[i];
      const result = {};
      for (let ki = 0; ki < keys.length; ki++) {
        const kele = keys[ki];
        if (['sum_insured', 'area_sown'].includes(kele)) {
          debugger
          result[keyHeaderMap.get(kele)] = ele['actual_' +kele]
        } else {
          result[keyHeaderMap.get(kele)] = ele[kele]
        }
      }
      data.push(result)
    }
    return {header, data}
  }

  abbreviateNumber(number: any, excp?: any): any | void {
    const numberFormatter = new Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 1,
    });
    if (!number) {
      return excp ? excp : "";
    } else {
      number = Math.ceil(number);
    }
    const numberCleaned = Math.round(Math.abs(number));
    if (numberCleaned < 1e3) return numberFormatter.format(Math.floor(number));
    else if (numberCleaned >= 1e3 && numberCleaned < 1e5)
      return numberFormatter.format(number / 1e3) + " K";
    else if (numberCleaned >= 1e5 && numberCleaned < 1e7)
      return numberFormatter.format(number / 1e5) + " L";
    else return numberFormatter.format(number / 1e7) + " Cr";
  }
}

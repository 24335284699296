import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormArray,
  Validators,
  FormControl,
  FormGroup
} from "@angular/forms";
import { AlertService } from "../services/alert.service";
import { ApiService } from "../services/apiservices.service";
import { MandatoryfieldService } from "../common/Validators/mandatoryfield.service";
import { CommonfunctionsService } from '../services/commonfunctions.service';
declare var $: any;
@Component({
  selector: 'app-enablereport',
  templateUrl: './enablereport.component.html',
  styleUrls: ['./enablereport.component.css']
})
export class EnablereportComponent implements OnInit {

  reportForm: any;
  tieupID: any;//ng model
  ReportDetails: any;
  tableData: any = {};
  loadedStatus: any = {};
  resArray: any;
  tieuplist: any;
  status: any;
  showTable: boolean = false;
  loading: boolean = false;
  showModal: boolean = false;
  disablefield: boolean = true;
  changedList: any = [];
  constructor(private formBuilder: FormBuilder, private validate: MandatoryfieldService, private apiData: ApiService, private alertService: AlertService, private commonfunctions: CommonfunctionsService) { }

  ngOnInit() {
    this.reportForm = this.formBuilder.group({
      tieupID: ["", Validators.required]
    });
    this.ReportDetails = this.loadForm();
    this.loadDropDown();

    var obj = this;
    var rowindex = {};

    $("#enableReport")
      .off("click", ".switchToggle")
      .on("click", ".switchToggle", function (e) {

        e.stopImmediatePropagation();
        e.preventDefault();
        $(this.firstChild).prop('checked', !($(this.firstChild).prop('checked')))
        if (rowindex["status"] == "Active") {
          rowindex["status"] = "DeActive";
        } else {
          rowindex["status"] = "Active";
        }
        rowindex["changed"] = !rowindex["changed"];
        if (obj.changedList.length != 0) {
          for (let i = 0; i < obj.changedList.length; i++) {
            if (obj.changedList[i].sno == rowindex["sno"]) {
              obj.changedList.splice(i, 1);
            }
          }
        }
        if (rowindex["changed"])
          obj.changedList.push(rowindex);
        // obj.apiData.formRequest.getRequest('', obj.resArray, 'report');
      });

    $("#enableReport").on("click-row.bs.table", function (e, row, $element) {
      e.stopImmediatePropagation();
      e.preventDefault();
      rowindex = {};
      rowindex = row;
    });
  }
  loadDropDown() {
    this.loading = true;
    this.apiData.getdropdownList.getTieUplist(res => {
      this.loading = false;
      if (!this.validate.isUndefined(res)) {
        let result = res;
        if (result.status == 1) {
          this.tieuplist = result.data;
        } else {
          let errorText = this.alertService.ErrorText.checkError(result.data);
          this.validate.popUpAlert(errorText, "", "error");
        }
      }
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }
  getReportList() {
    this.changedList = [];
    this.loading = true;
    this.apiData.reportApi.getlist(res => {
      if (!this.validate.isUndefined(res)) {
        let result = res;
        //console.log(res.trim());
        this.loading = false;
        if (result.status == 1) {
          this.loadedStatus = result;
          this.prepareJson(result);
          $("#enableReport").bootstrapTable("destroy");
          this.tableData = $("#enableReport").bootstrapTable({
            data: this.resArray,
            columnDefs: [
              {
                className: "table-data"
              }
            ],
            select: true
          });
          this.showTable = true;
        }
      }
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);;
    });
  }
  showtable() {
    if (this.validate.submitDetails(this.reportForm)) {
      this.apiData.reportApi.saveId(this.reportForm.value.tieupID);
      this.getReportList();
    }
  }
  prepareJson(resData) {
    this.resArray = [];
    for (var i = 0; i < resData.data.length; i++) {
      let resArray1 = {};
      resArray1["reportName"] = resData.data[i]["reportName"];
      resArray1["tieupID"] = resData.data[i]["tieupID"];
      resArray1["reportID"] = resData.data[i]["reportID"];
      resArray1["status"] = resData.data[i]["status"];
      resArray1["sno"] = i + 1;
      if (resData.data[i]["status"] == "Active") {
        resArray1["reportstatus"] =
          '<div class="switchToggle"><input type="checkbox" id="s' + i + '" checked ><label for="s' + i + '"></label></div>';
      } else {
        resArray1["reportstatus"] = '<div class="switchToggle"><input type="checkbox" id="s' + i + '"><label for="s' + i + '"></label></div>';
      }
      resArray1["changed"] = false;
      this.resArray.push(resArray1);
    }
  }

  loadForm() {
    return this.formBuilder.group({
      tieupID: ["", Validators.required],
      reportname: ["", Validators.required],
      status: ["", Validators.required]
    });
  }
  submitClick() {
    if (this.changedList.length != 0) {
      this.apiData.formRequest.getRequest('', this.changedList, 'report');
      this.loading = true;
      this.apiData.reportApi.saveReport(res => {
        if (!this.validate.isUndefined(res)) {
          let result = res;
          if (result.status == 1) {
            this.loading = false;
            this.validate.popUpAlert("Updated Successfully", "", "success");
          } else if (result.status == 0) {
            let errorText = this.alertService.ErrorText.checkError(result.data);
            this.validate.popUpAlert(errorText, "", "error");
          }
        }
      }, err => {
        this.loading = false;
        this.commonfunctions.serviceErrorHandling(err);
      });
      this.changedList = [];
    } else {
      this.getReportList();
    }

  }
}

import { Component, OnInit } from "@angular/core";
import { ApiService } from "../services/apiservices.service";
import Swal from "sweetalert2";
import { MandatoryfieldService } from "../common/Validators/mandatoryfield.service";

declare var $: any;

@Component({
  selector: "app-global-defaults",
  templateUrl: "./global-defaults.component.html",
  styleUrls: ["./global-defaults.component.css"],
})
export class GlobalDefaultsComponent implements OnInit {
  loading = false;
  configData: any;
  math = Math;
  booleanOptions: any[] = [];
  detrending: any;
  gpDownscaling: any;
  catastropheLoading: any;
  prevented_sowing_loading: any;
  current_year: any;
  years: any[] = [];
  weightageFields = ["weather_forecast", "farm_management_practices", "ground_level_expertise"]

  constructor(
    private apiData: ApiService,
    private validate: MandatoryfieldService
  ) {
    this.booleanOptions = [
      {text: 'False', value: 'False'},
      {text: 'True', value: 'True'}
    ]
    for (let i = 2017; i <= 2030; i++) {
      this.years.push({text: i, value: i})
    }
  }

  ngOnInit() {
    this.fetchConfigData();
  }

  fetchConfigData() {
    this.loading = true;
    this.apiData.loadingApi.getloadingDiscount(
      (res: any) => {
        this.loading = false;
        if (res && res.status == 1) {
          this.getDefautForFinalLoading();
          this.configData = res.data;
          this.validateAllData();
        }
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  getDefautForFinalLoading() {
    this.loading = true;
    this.apiData.finalLoadingDefaultLoadingByTieupId().then((res: any) => {
      if (res && res.status == 1) {
        const data = res.data || {};
        this.detrending = data.detrending ? 'True' : 'False';
        this.gpDownscaling = data.gpDownscaling ? 'True' : 'False';
        this.catastropheLoading = data.catastropheLoading ? 'True' : 'False';
        this.prevented_sowing_loading = data.preventedSowingLoading ? 'True' : 'False';
        this.current_year = data.currentYear;
      }
      this.loading = false
    }).catch(err => {
      this.loading = false;
    })

  }


  onPerceptionValueChange(uwPerceptionData: any, subSection: any) {
    this.validPerceptionData(uwPerceptionData, subSection);
  }

  onStateLoadingChange(subData: any) {
    if (!subData.finalLoadingPercentage) {
      subData.finalLoadingPercentage = 0;
    }
  }

  onCardSave(data, indx) {
    if (!this.validateCard()) {
      return;
    }
    for (
      let index = 0;
      index < this.configData.ldWithSubSections.length;
      index++
    ) {
      const ldWithSubSections = this.configData.ldWithSubSections[index];
      ldWithSubSections.isCollapsed = true;
      if (indx == index) {
        ldWithSubSections.isDataSaved = true;
      }
    }

    if (indx < this.configData.ldWithSubSections.length - 1) {
      this.configData.ldWithSubSections[indx + 1].isCollapsed = false;
      this.openCard(
        this.configData.ldWithSubSections[indx + 1].loadingDiscountID
      );
    } else {
      this.closeCard(this.configData.ldWithSubSections[indx].loadingDiscountID);
    }
  }

  onCardToggle(loadingDiscountID) {
    if (!this.validateCard()) {
      return;
    }
    this.toogleCard(loadingDiscountID);
  }

  onCardEdit(loadingDiscountID) {
    if (!this.validateCard()) {
      return;
    }
    this.openCard(loadingDiscountID);
  }

  onDefaultChange(perceptionData, pindx) {
    for (let index = 0; index < perceptionData.length; index++) {
      if (index != pindx) {
        const element = perceptionData[index];
        element.defaultUWPerception = false;
      }
    }
  }

  toogleCard(loadingDiscountID) {
    $(`#${loadingDiscountID}`).collapse("toggle");
  }
  openCard(loadingDiscountID) {
    $(`#${loadingDiscountID}`).collapse("show");
  }
  closeCard(loadingDiscountID) {
    $(`#${loadingDiscountID}`).collapse("hide");
  }

  validateAllData() {
    const ldWithSubSections = this.configData.ldWithSubSections;
    const ldNoSubSections = this.configData.ldNoSubSections;
    if (Array.isArray(ldWithSubSections) && Array.isArray(ldNoSubSections)) {
      for (let index = 0; index < ldWithSubSections.length; index++) {
        const element = ldWithSubSections[index];
        for (
          let subSecIndx = 0;
          subSecIndx < element.subSections.length;
          subSecIndx++
        ) {
          const subSection = element.subSections[subSecIndx];
          this.validPerceptionData(subSection.uwPerceptionData, subSection);
        }
      }
      for (let index = 0; index < ldNoSubSections.length; index++) {
        const element = ldNoSubSections[index];
        // if (
        //   [null, undefined, ""].includes(element.totalFinalLoadingPercentage)
        // ) {
        //   errorMessage.msg = `${element.loadingDiscountName} value should not be empty`;
        //   break;
        // }
      }
    }
  }

  validPerceptionData(uwPerceptionData: any, subSection: any) {
    const errorMessage = {};
    if (Array.isArray(uwPerceptionData)) {
      for (
        let perpIndex = 0;
        perpIndex < uwPerceptionData.length;
        perpIndex++
      ) {
        const perception = uwPerceptionData[perpIndex];
        if (perception.options) {
          if (
            [null, undefined, ""].includes(perception.finalLoadingPercentageMin)
          ) {
            // errorMessage.eleId = `${subSection.key}_${perception.text}_min`;
            errorMessage[`${subSection.key}_${perception.text}_min`] =
              "Min value is requried";
          }
          if (
            [null, undefined, ""].includes(perception.finalLoadingPercentageMax)
          ) {
            // errorMessage.eleId = `${subSection.key}_${perception.text}_max`;
            errorMessage[`${subSection.key}_${perception.text}_max`] =
              "Max value is requried";
          }
          if (
            [null, undefined, ""].includes(perception.finalLoadingPercentage)
          ) {
            // errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
            errorMessage[`${subSection.key}_${perception.text}_percentage`] =
              "Percentage value is requried";
          }
          if (
            +perception.finalLoadingPercentageMin >
            +perception.finalLoadingPercentageMax
          ) {
            // errorMessage.eleId = `${subSection.key}_${perception.text}_min`;
            errorMessage[`${subSection.key}_${perception.text}_min`] =
              "Min value should be less than Max value";
          }
          if (
            !perception.options.find(
              (opt) => +opt.value == +perception.finalLoadingPercentageMin
            )
          ) {
            // errorMessage.eleId = `${subSection.key}_${perception.text}_min`;
            errorMessage[`${subSection.key}_${perception.text}_min`] =
              "Min value should be selected from given options only";
          }
          if (
            !perception.options.find(
              (opt) => +opt.value == +perception.finalLoadingPercentageMax
            )
          ) {
            // errorMessage.eleId = `${subSection.key}_${perception.text}_max`;
            errorMessage[`${subSection.key}_${perception.text}_max`] =
              "Max value should be selected from given options only";
          }
          if (
            ![null, undefined, ""].includes(
              perception.finalLoadingPercentage
            ) &&
            ![null, undefined, ""].includes(
              perception.finalLoadingPercentageMin
            ) &&
            ![null, undefined, ""].includes(
              perception.finalLoadingPercentageMax
            ) &&
            (perception.finalLoadingPercentage <
              perception.finalLoadingPercentageMin ||
              perception.finalLoadingPercentage >
                perception.finalLoadingPercentageMax)
          ) {
            // errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
            errorMessage[`${subSection.key}_${perception.text}_percentage`] =
              "Final loading value should be between min and max value";
          } else if ((+perception.finalLoadingPercentage % 1) != 0) {
            errorMessage[`${subSection.key}_${perception.text}_percentage`] =
              "Final loading value should not have decimal number";
          }
        } else {
          if ([null, undefined, ""].includes(perception.value)) {
            // errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
            errorMessage[
              `${subSection.key}_${perception.text}_percentage`
            ] = `${subSection.title}'s ${perception.text}'s value is requried`;
          } else if (perception.value < -100 || perception.value > 200) {
            errorMessage[
              `${subSection.key}_${perception.text}_percentage`
            ] = `${subSection.title}'s ${perception.text} value should be between -100 to 200`;
          }
        }
        perception.errorMessage = errorMessage;
      }
    }
  }

  validateCard() {
    const collapseId = $(".collapse.show").prop("id");
    if (collapseId) {
      let errorMessage: any = { msg: null, eleId: null };
      this.configData;
      const ldWithSubSections = this.configData.ldWithSubSections;
      debugger
      if (Array.isArray(ldWithSubSections)) {
        for (let index = 0; index < ldWithSubSections.length; index++) {
          const element = ldWithSubSections[index];
          if (element.loadingDiscountID == collapseId) {
            let totalFinalLoadingWeightageApplied = 0;
            if (errorMessage.msg) {
              break;
            }
            for (
              let subSecIndx = 0;
              subSecIndx < element.subSections.length;
              subSecIndx++
            ) {
              const subSection = element.subSections[subSecIndx];
              if (
                [null, undefined, ""].includes(
                  subSection.finalLoadingWeightageApplied
                )
              ) {
                errorMessage.eleId = `${subSection.key}_weightage}`;
                errorMessage.msg = "weightage value should not be empty";
                break;
              }
              if (this.weightageFields.includes(element.loadingDiscountID)) {
                totalFinalLoadingWeightageApplied +=  subSection.finalLoadingWeightageApplied
              }
              if (subSection.uwPerceptionData) {
                for (
                  let perpIndex = 0;
                  perpIndex < subSection.uwPerceptionData.length;
                  perpIndex++
                ) {
                  if (errorMessage.msg) {
                    break;
                  }
                  const perception = subSection.uwPerceptionData[perpIndex];
                  if (perception.options) {
                    if (
                      [null, undefined, ""].includes(
                        perception.finalLoadingPercentageMin
                      )
                    ) {
                      errorMessage.eleId = `${subSection.key}_${perception.text}_min`;
                      errorMessage.msg = "Min Value should not be empty";
                      break;
                    }
                    if (
                      [null, undefined, ""].includes(
                        perception.finalLoadingPercentageMax
                      )
                    ) {
                      errorMessage.eleId = `${subSection.key}_${perception.text}_max`;
                      errorMessage.msg = "Max Value should not be empty";
                      break;
                    }
                    if (
                      [null, undefined, ""].includes(
                        perception.finalLoadingPercentage
                      )
                    ) {
                      errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
                      errorMessage.msg = "Percentage Value should not be empty";
                      break;
                    }
                    if (
                      +perception.finalLoadingPercentageMin >
                      +perception.finalLoadingPercentageMax
                    ) {
                      errorMessage.eleId = `${subSection.key}_${perception.text}_min`;
                      errorMessage.msg =
                        "Min value should be less than Max value";
                      break;
                    }
                    if (
                      !perception.options.find(
                        (opt) =>
                          +opt.value == +perception.finalLoadingPercentageMin
                      )
                    ) {
                      errorMessage.eleId = `${subSection.key}_${perception.text}_min`;
                      errorMessage.msg =
                        "Min value should be selected from given options only";
                      break;
                    }
                    if (
                      !perception.options.find(
                        (opt) =>
                          +opt.value == +perception.finalLoadingPercentageMax
                      )
                    ) {
                      errorMessage.eleId = `${subSection.key}_${perception.text}_max`;
                      errorMessage.msg =
                        "Max value should be selected from given options only";
                      break;
                    }
                    if (
                      perception.finalLoadingPercentage <
                        perception.finalLoadingPercentageMin ||
                      perception.finalLoadingPercentage >
                        perception.finalLoadingPercentageMax
                    ) {
                      errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
                      errorMessage.msg =
                        "Final loading value should be between min and max value";
                      break;
                    } else if ((+perception.finalLoadingPercentage % 1) != 0) {
                      errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
                      errorMessage.msg = 'Final loading value should not have decimal number';
                      break;
                    }
                  } else {
                    if ([null, undefined, ""].includes(perception.value)) {
                      errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
                      errorMessage.msg = `${subSection.title}'s ${perception.text}'s value is empty`;
                      break;
                    } else if (
                      perception.value < -100 || perception.value > 200
                  ) {
                    errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
                      errorMessage.msg = `${subSection.title}'s ${perception.text} value should be between -100 to 200`;
                    break;
                  }
                  }
                }
              } else {
                if (errorMessage.msg) {
                  break;
                }
                if (
                  [null, undefined, ""].includes(
                    subSection.finalLoadingPercentage
                  )
                ) {
                  errorMessage.eleId = `${subSection.key}`;
                  errorMessage.msg = `${subSection.title}'s value is empty`;
                  break;
                } else {
                  if (subSection.finalLoadingPercentage < -50 || subSection.finalLoadingPercentage > 200) {
                    errorMessage.eleId = `${subSection.key}`;
                    errorMessage.msg = `${subSection.title} value should be between -50 to 200`;
                    break
                  }
                }

              }
            }
            if (totalFinalLoadingWeightageApplied != 100  && !errorMessage.msg && this.weightageFields.includes(element.loadingDiscountID)) {
              errorMessage.msg = `Total weightages should be equal to 100`;
            }
          }
        }
      } else {
        errorMessage.msg = "Invalid Config Data";
      }

      if (errorMessage.msg) {
        const swal: any = Swal({
          title: "Required",
          text: errorMessage.msg,
          type: "warning",
          confirmButtonColor: "#175788",
        });
        swal.finally(() => {
          if (errorMessage.eleId) {
            const element: any = document.querySelector(
              "#" + errorMessage.eleId
            );
            if (element) {
              setTimeout(() => {
                element.scrollIntoView();
                element.focus();
              }, 500);
            }
          }
        });
        return false;
      }
    }
    return true;
  }

  validateData() {
    let errorMessage: any = { msg: null, eleId: null, collapseId: null };
    const ldWithSubSections = this.configData.ldWithSubSections;
    const ldNoSubSections = this.configData.ldNoSubSections;
    let totalSubSectionWeightage = 0;
    if (Array.isArray(ldWithSubSections) && Array.isArray(ldNoSubSections)) {
      for (let index = 0; index < ldWithSubSections.length; index++) {
        const element = ldWithSubSections[index];
        if (this.weightageFields.includes(element.loadingDiscountID)) {
          if (
            [null, undefined, ""].includes(
              element.sectionFinalLoadingWeightagePercentage
            )
          ) {
            errorMessage.eleId = `${element.key}_weightage}`;
            errorMessage.msg = "Weightage value should not be empty";
            break;
          }
          totalSubSectionWeightage += element.sectionFinalLoadingWeightagePercentage;
        }
        let totalFinalLoadingWeightageApplied = 0;
        if (errorMessage.msg) {
          break;
        }
        for (
          let subSecIndx = 0;
          subSecIndx < element.subSections.length;
          subSecIndx++
        ) {
          const subSection = element.subSections[subSecIndx];
          if (this.weightageFields.includes(element.loadingDiscountID)) {
            totalFinalLoadingWeightageApplied +=  subSection.finalLoadingWeightageApplied
          }
          if (
            [null, undefined, ""].includes(
              subSection.finalLoadingWeightageApplied
            )
          ) {
            errorMessage.eleId = `${subSection.key}_weightage}`;
            errorMessage.msg = "Weightage value should not be empty";
            break;
          }
          if (subSection.uwPerceptionData) {
            for (
              let perpIndex = 0;
              perpIndex < subSection.uwPerceptionData.length;
              perpIndex++
            ) {
              if (errorMessage.msg) {
                break;
              }
              const perception = subSection.uwPerceptionData[perpIndex];
              if (perception.options) {
                if (
                  [null, undefined, ""].includes(
                    perception.finalLoadingPercentageMin
                  )
                ) {
                  errorMessage.collapseId = element.loadingDiscountID;
                  errorMessage.eleId = `${subSection.key}_${perception.text}_min`;
                  errorMessage.msg = "Min Value should not be empty";
                  break;
                }
                if (
                  [null, undefined, ""].includes(
                    perception.finalLoadingPercentageMax
                  )
                ) {
                  errorMessage.collapseId = element.loadingDiscountID;
                  errorMessage.eleId = `${subSection.key}_${perception.text}_max`;
                  errorMessage.msg = "Max Value should not be empty";
                  break;
                }
                if (
                  [null, undefined, ""].includes(
                    perception.finalLoadingPercentage
                  )
                ) {
                  errorMessage.collapseId = element.loadingDiscountID;
                  errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
                  errorMessage.msg = "Percentage Value should not be empty";
                  break;
                }
                if (
                  +perception.finalLoadingPercentageMin >
                  +perception.finalLoadingPercentageMax
                ) {
                  errorMessage.collapseId = element.loadingDiscountID;
                  errorMessage.eleId = `${subSection.key}_${perception.text}_min`;
                  errorMessage.msg = "Min value should be less than Max value";
                  break;
                }
                if (
                  !perception.options.find(
                    (opt) => +opt.value == +perception.finalLoadingPercentageMin
                  )
                ) {
                  errorMessage.collapseId = element.loadingDiscountID;
                  errorMessage.eleId = `${subSection.key}_${perception.text}_min`;
                  errorMessage.msg =
                    "Min value should be selected from given options only";
                  break;
                }
                if (
                  !perception.options.find(
                    (opt) => +opt.value == +perception.finalLoadingPercentageMax
                  )
                ) {
                  errorMessage.collapseId = element.loadingDiscountID;
                  errorMessage.eleId = `${subSection.key}_${perception.text}_max`;
                  errorMessage.msg =
                    "Max value should be selected from given options only";
                  break;
                }
                if (
                  perception.finalLoadingPercentage <
                    perception.finalLoadingPercentageMin ||
                  perception.finalLoadingPercentage >
                    perception.finalLoadingPercentageMax
                ) {
                  errorMessage.collapseId = element.loadingDiscountID;
                  errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
                  errorMessage.msg =
                    "Final loading value should be between min and max value";
                  break;
                } else if ((+perception.finalLoadingPercentage % 1) != 0) {
                  errorMessage.collapseId = element.loadingDiscountID;
                  errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
                  errorMessage.msg = 'Final loading value should not have decimal number';
                  break;
                }
              } else {
                if ([null, undefined, ""].includes(perception.value)) {
                  errorMessage.collapseId = element.loadingDiscountID;
                  errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
                  errorMessage.msg = `${subSection.title}'s ${perception.text}'s value is empty`;
                  break;
                } else if (perception.value < -100 || perception.value > 200) {
                  errorMessage.collapseId = element.loadingDiscountID;
                  errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
                  errorMessage.msg = `${subSection.title}'s ${perception.text}'s value should be between -100 to 200`;
                  break;
                }
              }
            }
          } else {
            if (errorMessage.msg) {
              break;
            }
            if (
              [null, undefined, ""].includes(subSection.finalLoadingPercentage)
            ) {
              errorMessage.collapseId = element.loadingDiscountID;
              errorMessage.eleId = `${subSection.key}`;
              errorMessage.msg = `${subSection.title}'s value is empty`;
              break;
            } else {
              if (subSection.finalLoadingPercentage < -50 || subSection.finalLoadingPercentage > 200) {
                errorMessage.collapseId = element.loadingDiscountID;
                errorMessage.eleId = `${subSection.key}`;
                errorMessage.msg = `${subSection.title} value should be between -50 to 200`;
                break
              }
            }
          }
        }
        if (totalFinalLoadingWeightageApplied != 100  && !errorMessage.msg && this.weightageFields.includes(element.loadingDiscountID)) {
          errorMessage.msg = `${element.loadingDiscountName}' total weightage should equal to 100`;
        }
      }
      if (totalSubSectionWeightage != 100  && !errorMessage.msg) {
        errorMessage.msg = `Total weightages should be equal to 100`;
      }
      for (let index = 0; index < ldNoSubSections.length; index++) {
        const element = ldNoSubSections[index];
        if (errorMessage.msg) {
          break;
        }
        if (
          [null, undefined, ""].includes(element.totalFinalLoadingPercentage)
        ) {
          errorMessage.eleId = element.loadingDiscountID;
          errorMessage.msg = `${element.loadingDiscountName} value should not be empty`;
          break;
        } else {
          if (element.loadingDiscountID !== 'commercial_discount' && (element.totalFinalLoadingPercentage < -100 || element.totalFinalLoadingPercentage > 200)) {
            errorMessage.eleId = element.loadingDiscountID;
            errorMessage.msg = `${element.loadingDiscountName} value should be between -100 to 200`;
            break
          }
          if (element.loadingDiscountID === 'commercial_discount' && (element.totalFinalLoadingPercentage < -50 || element.totalFinalLoadingPercentage > 200)) {
            errorMessage.eleId = element.loadingDiscountID;
            errorMessage.msg = `${element.loadingDiscountName} value should be between -50 to 200`;
            break
          }
        }
      }
    } else {
      errorMessage.msg = "Invalid config data";
    }

      if (
        [null, undefined, ""].includes(this.detrending) && !errorMessage.msg
      ) {
        errorMessage.eleId = "select-detrending";
        errorMessage.msg = `De-trending value should not be empty`;
      }
      if (
        [null, undefined, ""].includes(this.gpDownscaling) && !errorMessage.msg
      ) {
        errorMessage.eleId = "select-gpDownscaling";
        errorMessage.msg = ` GP Downscaling value should not be empty`;
      }
      if (
        [null, undefined, ""].includes(this.catastropheLoading) && !errorMessage.msg
      ) {
        errorMessage.eleId = "select-catastropheLoading";
        errorMessage.msg = `Catastrophe Loading value should not be empty`;
      }
      if (
        [null, undefined, ""].includes(this.prevented_sowing_loading) && !errorMessage.msg
      ) {
        errorMessage.eleId = "select-prevented_sowing_loading";
        errorMessage.msg = `Prevented Sowing Loading value should not be empty`;
      }

    if (errorMessage.msg) {
      const swal: any = Swal({
        title: "Required",
        text: errorMessage.msg,
        type: "warning",
        confirmButtonColor: "#175788",
      });
      swal.finally(() => {
        if (errorMessage.collapseId) {
          this.openCard(errorMessage.collapseId);
        }
        if (errorMessage.eleId) {
          const element: any = document.querySelector("#" + errorMessage.eleId);
          if (element) {
            setTimeout(() => {
              element.scrollIntoView();
              element.focus();
            }, 500);
          }
        }
      });
      return false;
    }
    return true;
  }

  onSubmit(formData) {
    if (!this.validateData()) {
      return false;
    }
    const configData = JSON.parse(JSON.stringify(this.configData));
    const ldWithSubSections = [];
    const ldNoSubSections = [];
    if (configData) {
      const reqldWithSubSections = configData.ldWithSubSections;
      const reqldNoSubSections = configData.ldNoSubSections;
      if (
        Array.isArray(reqldWithSubSections) &&
        Array.isArray(ldNoSubSections)
      ) {
        for (let index = 0; index < reqldWithSubSections.length; index++) {
          const element = reqldWithSubSections[index];
          const idWithSubSecObj = {
            loadingDiscountID: element.loadingDiscountID,
            loadingDiscountName: element.loadingDiscountName,
            sectionFinalLoadingWeightagePercentage: element.sectionFinalLoadingWeightagePercentage,
            isMandatory: element.isMandatory,
            isActive: element.isActive,
            ldWeightage: element.ldWeightage,
            subSections: [],
            totalFinalLoadingPercentage: element.totalFinalLoadingPercentage,
            sectionTotalLoadingDiscount: element.sectionTotalLoadingDiscount,
            isEnable: element.isEnable,
            seqno: element.seqno,
          };
          for (
            let subSecIndx = 0;
            subSecIndx < element.subSections.length;
            subSecIndx++
          ) {
            const subSection = element.subSections[subSecIndx];
            if (subSection.uwPerceptionData) {
              for (
                let perpIndex = 0;
                perpIndex < subSection.uwPerceptionData.length;
                perpIndex++
              ) {
                const perception = subSection.uwPerceptionData[perpIndex];
                const reqSubSec = {
                  key: subSection.key,
                  weightage: subSection.weightage,
                  title: subSection.title,
                  seqno: subSection.seqno,
                  uwPerception: perception.text,
                  finalLoadingPercentage:
                    perception.finalLoadingPercentage || perception.value,
                  finalLoadingPercentageMin:
                    perception.finalLoadingPercentageMin,
                  finalLoadingPercentageMax:
                    perception.finalLoadingPercentageMax,
                  defaultUWPerception: perception.defaultUWPerception,
                  finalLoadingWeightageApplied:
                    subSection.finalLoadingWeightageApplied,
                };
                idWithSubSecObj.subSections.push(reqSubSec);
              }
            } else {
              const reqSubSec = {
                key: subSection.key,
                weightage: subSection.weightage,
                title: subSection.title,
                seqno: subSection.seqno,
                finalLoadingPercentage: subSection.finalLoadingPercentage,
                finalLoadingWeightageApplied:
                  subSection.finalLoadingWeightageApplied,
              };
              idWithSubSecObj.subSections.push(reqSubSec);
            }
          }
          ldWithSubSections.push(idWithSubSecObj);
        }
        for (let index = 0; index < reqldNoSubSections.length; index++) {
          const element = reqldNoSubSections[index];
          const idNoSubSecObj = {
            loadingDiscountID: element.loadingDiscountID,
            loadingDiscountName: element.loadingDiscountName,
            isMandatory: element.isMandatory,
            isActive: element.isActive,
            ldWeightage: element.ldWeightage,
            subSections: [],
            totalFinalLoadingPercentage: element.totalFinalLoadingPercentage,
            sectionTotalLoadingDiscount: element.sectionTotalLoadingDiscount,
            isEnable: element.isEnable,
            seqno: element.seqno,
          };
          ldNoSubSections.push(idNoSubSecObj);
        }
      }
    }
    let request = {
      tieupID: null,
      tenantId: null,
      updatedBy: null,
      id: null,
      ldWithSubSections,
      ldNoSubSections,
      detrending: this.detrending == "True" ? true : false,
      gpDownscaling: this.gpDownscaling == "True" ? true : false,
      catastropheLoading: this.catastropheLoading == "True" ? true : false,
      preventedSowingLoading: this.prevented_sowing_loading == "True" ? true : false,
      currentYear: this.current_year
    };
    request = this.apiData.getParam(request, "global");
    this.loading = true;
    this.apiData
      .configureDefaultLoadingDiscount(request)
      .then((res: any) => {
        if (res && res.status == 1) {
          this.validate.popUpAlert(res.message, "Success", "success");
          this.fetchConfigData();
        } else {
          this.validate.popUpAlert(res.message, "Error", "error");
        }
        this.loading = false;
      })
      .catch((err) => {
        this.validate.popUpAlert(
          "Unable to update global discount",
          "Error",
          "error"
        );
        this.loading = false;
      });
  }

  avoidDecimal(event: any): any | void {
    if (event.key == '.') {
      event.stopPropagation();
      return false;
    }
  }
}

import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
declare var $: any;
import Swal from 'sweetalert2';
import { RouterModule, Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "../services/apiservices.service";
import { MandatoryfieldService } from "../common/Validators/mandatoryfield.service";
import {
  FormBuilder,
  FormArray,
  Validators,
  FormControl,
  FormGroup
} from "@angular/forms";
import { AlertService } from "../services/alert.service";
import { IfStmt } from "@angular/compiler";
import { CommonfunctionsService } from '../services/commonfunctions.service';
@Component({
  selector: "app-user-creation",
  templateUrl: "./user-creation.component.html",
  styleUrls: ["./user-creation.component.css"]
})
export class UserCreationComponent implements OnInit {
  /****Set Focus the element****/
  @ViewChild('userName') userName: ElementRef;
  @ViewChild('userEmailID') userEmailID: ElementRef;
  @ViewChild('userID') userID: ElementRef;
  @ViewChild('tieupID') tieupID: ElementRef;
  @ViewChild('userType') userType: ElementRef;
  //@ViewChild('status') status: ElementRef;


  loading: boolean = false;

  UserDetail: any;
  tieuplist: any = [];
  statusList: any = [];
  userlist: any;

  resArray: any = {};
  tableData: any = {};
  showModal: boolean = false;
  disablefield: boolean = true;
  showtable: boolean = false;
  isValid: boolean;
  CreateOrEdit: any;
  idVal: any;
  tenantID: any;
  errorText: any;
  type: any

  dropdownList: any;

  constructor(
    private alertService: AlertService,
    private router: Router,
    private formBuilder: FormBuilder,
    private apiData: ApiService,
    private validate: MandatoryfieldService,
    private route: ActivatedRoute,
    private commonfunctions: CommonfunctionsService
  ) { }

  ngOnInit() {
    this.getUserlist();
    this.UserDetail = this.loadForm();
    this.type = this.route.snapshot.paramMap.get("term")
    if (this.type == "create") {
      this.CreateOrEdit = "Create";
      this.apiData.formRequest.EditOrCreate = "create";
      this.loadDropDown();
      this.UserDetail.patchValue(this.apiData.userEdit.popData());
      this.UserDetail.controls.userEmailID.enable(true);
      this.UserDetail.controls.tieupID.disable(true);
      this.showModal = true;
    }

    var obj = this;
    var rowindex = {};
    $("#usertable").on("click", ".editUser", function (e) {
      e.stopImmediatePropagation();
      e.preventDefault();
      obj.CreateOrEdit = "Edit";
      obj.loadDropDown();
      obj.UserDetail.patchValue(obj.apiData.userEdit.popData());
      obj.UserDetail.controls.tieupID.disable(true);
      obj.showModal = true;
    });

    $("#usertable").on("click-row.bs.table", function (e, row, $element) {
      rowindex = {};
      rowindex = row;
      obj.idVal = row.id;
      obj.apiData.userEdit.pushdata(rowindex);
    });
    $("#usertable").on("refresh.bs.table", function (e, params) {
      obj.getUserlist();
    });
  }

  getUserlist() {
    this.loading = true;
    this.apiData.userAPI.getUserList(res => {
      if (!this.validate.isUndefined(res)) {
        let result = res;
        if (result.status == 1) {
          this.loading = false;
          this.prepareJson(result);
          $("#usertable").bootstrapTable("destroy");
          this.tableData = $("#usertable").bootstrapTable({
            data: this.resArray,
            columnDefs: [
              {
                className: "table-data"
              }
            ],
            select: true
          });
          this.showtable = true;
        }
      }
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }
  prepareJson(resData) {
    this.resArray = [];
    for (var i = 0; i < resData.data.length; i++) {
      let resArray1 = {};
      resArray1["action"] = "";
      resArray1["id"] = resData.data[i]["id"];
      resArray1["tenantId"] = resData.data[i]["tenantId"];
      resArray1["sno"] = i + 1;
      resArray1["userName"] = resData.data[i]["userName"];
      resArray1["tieupID"] = resData.data[i]["tieupID"];
      resArray1["tieupName"] = resData.data[i]["tieupName"];
      resArray1["userID"] = resData.data[i]["userID"];
      resArray1["userType"] = resData.data[i]["userType"];
      resArray1["userEmailID"] = resData.data[i]["userEmailID"];
      resArray1["userMobileNumber"] = resData.data[i]["userMobileNumber"];
      var status_txt=resData.data[i]["status"].toLowerCase();
      resArray1["status"] = status_txt!='active' ?  'Inactive':'Active';
      resArray1["action"] =
        '<div class="editUser" style="cursor:pointer" title="Edit"><img src="/assets/images/edit.png"></div>';
      this.resArray.push(resArray1);
    }
  }
  loadForm() {
    return this.formBuilder.group({
      tieupID: ["", Validators.required],
      tieupName: ["", Validators.required],
      userName: ["", Validators.required],
      userEmailID: ["", Validators.email],
      userID: ["", Validators.required],
      userType: ["", Validators.required],
      status: ["", Validators.required]
    });
  }
  loadDropDown() {
    this.statusList = this.apiData.dropdownList.userstatus;
    this.loading = true;
    this.apiData.getdropdownList.getUserType(res => {
      let result = res;
      this.loading = false;
      if (result.status == 1) {
        this.userlist = result.data;
      } else {
        let errorText = this.alertService.ErrorText.checkError(result.data);
        this.validate.popUpAlert(errorText, "", "error");
      }
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    });
    this.loading = true;
    this.apiData.getdropdownList.getTieUplist(res => {
      if (!this.validate.isUndefined(res)) {
        let result = res;
        this.loading = false;
        if (result.status == 1) {
          this.tieuplist = result.data;
        } else {
          let errorText = this.alertService.ErrorText.checkError(result.data);
          this.validate.popUpAlert(errorText, "", "error");
        }
      }
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }

  /**  Create /Edit User  **/

  createUser() {
    this.apiData.userEdit.removData();
    this.CreateOrEdit = "Create";
    this.UserDetail.reset();
    this.UserDetail.controls["status"].setValue("Active");
    this.UserDetail.controls.tieupID.enable(true);
    this.UserDetail.controls.userEmailID.enable(true);
    this.loadDropDown();
    this.showModal = true;
  }

  select(value: any) {
    var result = this.tieuplist.filter(obj => {
      return obj.value == value;
    });
    this.UserDetail.controls["tieupName"].setValue(result[0].text);
    this.UserDetail.controls["tieupID"].setValue(value);
  }
  closemodel() {
    this.showModal = false;
    if (this.type == "create") {
      this.router.navigate(["/tie-up"]);
    }
  }
  resetForm() {
    this.UserDetail.reset();
    if (this.apiData.formRequest.EditOrCreate == "edit" || this.type == "create") {
      this.UserDetail.patchValue(this.apiData.userEdit.popData());
    } else {
      this.UserDetail.controls.status.setValue('Active');
    }
  }
  submitClick() {
    debugger;
    this.UserDetail.controls.userEmailID.enable(true);
    this.UserDetail.controls.tieupID.enable(true);
    this.isValid = this.validate.submitDetails(this.UserDetail);
    this.loading = true;
    if (this.isValid) {
      this.apiData.formRequest.getRequest(
        this.idVal,
        this.UserDetail.value,
        "user"
      );
      this.apiData.userAPI.CreateOrEditUser(res => {
        if (!this.validate.isUndefined(res)) {
          this.loading = false;
          let result = res;
          if (result.status == 1) {
            if (this.apiData.formRequest.EditOrCreate == "edit") {
              this.validate.popUpAlert("User Updated Successfully", "", "success");
            } else {
              this.validate.popUpAlert("User Created Successfully", "", "success");
            }
            this.showModal = false;
            //$("#usertable").bootstrapTable("destroy");
            this.getUserlist();
          } else if (result.status == 0) {
            this.errorText = this.alertService.ErrorText.checkError(result.data);
            this.validate.popUpAlert(this.errorText, "", "error");
          }
        }
      }, err => {
        this.loading = false;
        this.commonfunctions.serviceErrorHandling(err);
      });
    } else {
      this.loading = false;
      let valid = true;
      Swal({
        title: "Required",
        text: "Please Fill Required Fields!",
        type: "warning",
        confirmButtonColor: "#175788",
      }).then((result) => {
        if (result) {
          Object.keys(this.UserDetail.controls).forEach(field => {
            const control = this.UserDetail.get(field);
            if (control.status == "INVALID" && valid) {
              if (field == "tieupID" || field == "status" || field == "userType") {
                setTimeout(() => this[field].optionsOpen(), 0);
              } else {
                setTimeout(() => this[field].nativeElement.focus(), 500);
              }
              valid = false;
            }
          });
        }
      });
    }
  }
}

import { Injectable,ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormArray, Validators, FormControl, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Injectable()
export class MandatoryfieldService {
  //passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  passwordPattern = "^(?=[a-zA-Z0-9$*-+_=!{}]{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[$*-+_=!{}]).*";
  trigger:boolean = false;
  @ViewChild('name') name: ElementRef;
  @ViewChild('tieupID') tieupID: ElementRef;
  @ViewChild('phoneNumber') phoneNumber: ElementRef;
  @ViewChild('emailID') emailID: ElementRef;
  @ViewChild('country') country: ElementRef;
  constructor(private router: Router) { }

  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    let config = {
      'required': 'Required',
      'invalidEmailAddress': 'Invalid email address',
      'email': 'Kindly provide the valid Email ID',
      'minlength': `Minimum length ${validatorValue.requiredLength}`,
    };

    return config[validatorName];
  }


  static emailValidator(control) {
    if (control.value.match(/^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i)) {
      return null;
    } else {
      return { 'invalidEmailAddress': true };
    }
  }

  static patternMatch(control) {
    if (control.pattern.actualValue.match(control.pattern.requiredPattern)) {
      return null;
    } else
      return 'Alphanumeric with special character';
  }


  submitDetails(currentForm) {
    this.validateAllFormFields(currentForm);
    if (currentForm.status == "VALID") {
      return true;
    } else {
      return false;
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.newPass.value;
    let confirmPass = group.controls.newPassRe.value;
    return pass === confirmPass ? true : false;
  }

  popUpAlert(msg, title, type) {
    Swal({
      title: title,
      text: msg,
      type: type,
      confirmButtonColor: "#175788",
      timer: parseFloat('60000')
    });
	//callback();
  }

  isUndefined(value) {
    if (value == undefined || value == "" || value == null || value == NaN) {
      return true;
    } else {
      return false;
    }
  }
  makeCode(model) {
    let finalcode = "";
    if (!this.isUndefined(model["codeone"]) && !this.isUndefined(model["codetwo"]) &&
      !this.isUndefined(model["codethree"]) && !this.isUndefined(model["codefour"]) &&
      !this.isUndefined(model["codefive"]) && !this.isUndefined(model["codesix"])) {
      finalcode = model["codeone"] + model["codetwo"] + model["codethree"];
      finalcode += model["codefour"] + model["codefive"] + model["codesix"];
      return finalcode;
    }
    return finalcode;
  }

  convertDate(utcDate: any)
  {
    if(utcDate!=null && utcDate!='')
    {
      var strDate=utcDate.toString();

      //with TZ
      //present date
      if(strDate.indexOf('+')!=-1)
      {
        var cDateTime=strDate.split('T')[1];
        var spHrs=cDateTime.split(':')[0];
        var spMins=cDateTime.split(':')[1];
        var spSec=cDateTime.split(':')[2];
        //day start (2019-05-10T00:00:00+05:30)
        //no conversion
        if(parseInt(spHrs)==0 && parseInt(spMins)==0 && parseInt(spSec)==0)
        {
          var cDate=strDate.split('T')[0];
          var cDateTime=(strDate.split('T')[1]).split('+')[0];
          return cDate+' '+cDateTime;
        }
      }
      else if(strDate.indexOf('+')==-1) //future date, present date with time
      {
        var cDateTime=strDate.split('T')[1];
        var spHrs=cDateTime.split(':')[0];
        var spMins=cDateTime.split(':')[1];
        var spSec=cDateTime.split(':')[2];
        //day start (2019-05-10T00:00:00)
        //no conversion
        if(parseInt(spHrs)==0 && parseInt(spMins)==0 && parseInt(spSec)==0)
        {
          var cDate=strDate.split('T')[0];
          var cDateTime=strDate.split('T')[1];
          return cDate+' '+cDateTime;
        }
        else{
          //(2019-05-10T17:21:08.1062663)
          utcDate=utcDate+'Z';
        }
      }
      
      var localDate = new Date(utcDate);      
      var monthsort=(1+localDate.getMonth()).toString().length==1 ? ('0'+(1+localDate.getMonth())) : (1+localDate.getMonth());
      var daysort=localDate.getDate().toString().length==1 ? ('0' + localDate.getDate()) : localDate.getDate();

      var HRsort=localDate.getHours().toString().length==1 ? ('0' + localDate.getHours()) : localDate.getHours();
      var Minssort=localDate.getMinutes().toString().length==1 ? ('0' + localDate.getMinutes()) : localDate.getMinutes();
      var Secondssort=localDate.getSeconds().toString().length==1 ? ('0' + localDate.getSeconds()) : localDate.getSeconds();

      var convertedDat=(localDate.getFullYear()+"-"+monthsort+"-" + daysort +" " + HRsort +":"+ Minssort +":"+Secondssort);
      return convertedDat;      
    }
    else{
        return utcDate;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { IMultiSelectOption } from 'angular-2-dropdown-multiselect';
import { MandatoryfieldService } from '../../common/Validators/mandatoryfield.service';
import { ApiService } from '../../services/apiservices.service';

@Component({
  selector: 'app-choosecombination',
  templateUrl: './choosecombination.component.html',
  styleUrls: ['./choosecombination.component.css']
})
export class ChoosecombinationComponent implements OnInit {
  droppedoutputs = [];
  dragEnabled = true;
  outputcombination: boolean = false;
  loading:boolean = false;
  outputs = [];
  selectedtoright = [];
  selectedtoleft = [];
  constructor(public alertService: AlertService, private validate: MandatoryfieldService, private apiData: ApiService) { }

  ngOnInit() {
    if (this.validate.isUndefined(this.alertService.outputList.length) && this.validate.isUndefined(this.apiData.outputCombo.data.length)) {
      this.validate.popUpAlert("Output combinations are not available", "", "error");
      this.alertService.navigateTo("/choose-transaction");
    } else {
      this.outputcombination = true;
      this.outputs.push(this.alertService.outputList);
      this.alertService.changeStepper(2);
      let fetchedOutput = this.apiData.outputCombo.popData();
      if (!this.validate.isUndefined(fetchedOutput)) {
        for (let i = 0; i < fetchedOutput.length; i++) {
          this.droppedoutputs.push(fetchedOutput[i]);
        }
      }
    }
  }
  select(item: any, event) {
    item.checked = !item.checked;
    if (event == "toright") {
      if (item.checked)
        this.selectedtoright.push(item);
      else {
        let index = this.selectedtoright.indexOf(this.selectedtoright.find(c => c.text == item.text));
        this.selectedtoright.splice(index, 1);
      }
    }
    else if (event == "toleft") {
      if (!item.checked)
        this.selectedtoleft.push(item);
      else {
        let index = this.selectedtoleft.indexOf(this.selectedtoleft.find(c => c.text == item.text));
        this.selectedtoleft.splice(index, 1);
      }
    }
  }
  swapItem(event) {
    if (event == "swaptoright") {
      for (let i = 0; i < this.selectedtoright.length; i++) {
        if (this.selectedtoright[i].checked) {
          this.droppedoutputs.push(this.selectedtoright[i]);
          let index = this.alertService.outputList.indexOf(this.alertService.outputList.find(c => c.text == this.selectedtoright[i].text));
          this.alertService.outputList.splice(index, 1);
        }
      }
      this.selectedtoright = [];
    } else if (event == "swaptoleft") {
      for (let i = 0; i < this.selectedtoleft.length; i++) {
        if (!this.selectedtoleft[i].checked) {
          this.alertService.outputList.push(this.selectedtoleft[i]);
          let index = this.droppedoutputs.indexOf(this.droppedoutputs.find(c => c.text == this.selectedtoleft[i].text));
          this.droppedoutputs.splice(index, 1);
        }
      }
      this.selectedtoleft = [];
    } else if (event == "torightall") {
      for (let i = 0, j = 0; i < this.alertService.outputList.length; i++ , j++) {
        this.alertService.outputList[i]["checked"] = true;
        this.droppedoutputs.push(this.alertService.outputList[i]);
        this.alertService.outputList.splice(i, 1);
        i--;
      }
      this.selectedtoright = [];
    } else if (event == "toleftall") {
      for (let i = 0; i < this.droppedoutputs.length; i++) {
        this.droppedoutputs[i]["checked"] = false;
        this.alertService.outputList.push(this.droppedoutputs[i]);
        this.droppedoutputs.splice(i, 1);
        i--;
      }
      this.selectedtoleft = [];
    }
  }

  hasValueDeep(json, firstValue, secondValue) {
    var result = json.filter(obj => {
      return obj.value == firstValue || obj.value == secondValue;
    });
    return result.length;
  }
  createRequest(output_array) {
    let outputs = [];
    output_array.forEach(function (value) {
      outputs.push(value.value);
    });
    return {
      "output": outputs
    }
  }
  goto(process) {
    if (process == "back") {
      this.apiData.outputCombo.removData();
      this.alertService.navigateTo("/choose-transaction");
    } else {
      if (this.droppedoutputs.length > 0) {
        const hasValue = this.hasValueDeep(this.droppedoutputs, "Rate", "Sum Insured");
        if (hasValue == 2) {
          this.apiData.outputCombo.pushdata(this.droppedoutputs);
          this.apiData.formRequest.getRequest("", this.createRequest(this.droppedoutputs), "outputcombination");
          this.loading = true;
          this.apiData.rateGenerationApi.saveOutput(res => {
            this.loading = false;
            if (res.status == 1) {
              this.alertService.navigateTo("/file-upload");
            } else if (res.status == 0) {
              let errorText = this.alertService.ErrorText.checkError(res.data);
              this.validate.popUpAlert(errorText, "", "error");
            }
          }, err => {
            this.loading = false;
            let errorText = this.alertService.ErrorText.checkError(err);
            this.validate.popUpAlert(errorText, "", "error");
            this.apiData.errorhanlingApi.generateRequestData(errorText, window.location.pathname);
            this.apiData.errorhanlingApi.sendError(res => {
            }, err => {
            });
          });
        } else {
          this.validate.popUpAlert("Select Rate & Sum Insured", "Required", "warning");
        }
      } else {
        this.validate.popUpAlert("Please Select Output Combinations!", "Required", "warning");
      }
    }
  }
}

import { Component, OnInit } from "@angular/core";
import { AlertService } from "../services/alert.service";
import { ApiService } from "../services/apiservices.service";
import { CommonfunctionsService } from "../services/commonfunctions.service";
import Swal from "sweetalert2";
import { MandatoryfieldService } from "../common/Validators/mandatoryfield.service";
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-manage-state-district',
  templateUrl: './manage-state-district.component.html',
  styleUrls: ['./manage-state-district.component.css']
})
export class ManageStateDistrictComponent implements OnInit {
  loading = 0;
  allStatesDistrict: any[] = [];
  allStates: any[] = [];
  allUploadedStates: any[] = [];
  rowData: any[] = [];
  allTieups: any[] = [];
  selectedFormState: any
  selectedUPloadState: any
  isSelectAll: boolean = false;
  activeTab = 1;
  fileName: any = '';
  inProgress = false;
  file: any;
  enableSubmit: boolean = false;

  constructor(private apiData: ApiService, public commonfunctions: CommonfunctionsService, private alert: AlertService,private validate: MandatoryfieldService) {}

  ngOnInit() {
    this.getAllStateDistrictData();
  }

  getAllStateDistrictData(updated?: any) {
    this.loading++
    this.apiData.parameterSettingAllStatesDistricts().then((res: any) => {
      if (res && res.status == 1) {
        this.allStatesDistrict = res.data;
        this.allStates = Array.from(new Set(res.data.map(d => d.state))).map(d => ({state: d}))
        this.allStates.sort((a: any, b: any) => a.state.localeCompare(b.state));
        this.allUploadedStates = JSON.parse(JSON.stringify(this.allStates));
        this.allUploadedStates.unshift({state: 'All'});
        this.loading--;
        if (updated) {
          this.onStateChange(this.selectedFormState);
        }
      }
    }).catch(err => {
      this.loading--
      this.commonfunctions.serviceErrorHandling(err);
    });
  }

  onStateChange(event: any) {
    this.rowData = [];
    this.enableSubmit = false;
    if (event) {
      const rowData: any = [];
      for (let sind = 0; sind < this.allStatesDistrict.length; sind++) {
        const state = this.allStatesDistrict[sind];
        if (state.state === event) {
          rowData.push({id: state.id, state: state.state, cluster: state.cluster, district: state.district, season: state.season})
        }
      }
      this.rowData = rowData
    }
  }

  onUploadStateChange(event: any) {}

  updateAll() {
    this.allStates.forEach(state => {
      state.clusters.forEach(cluster => {
        cluster.checked = cluster.districts.every(district => district.checked)
      })
      state.checked = state.clusters.every(cluster => cluster.checked);
    })
    this.isSelectAll = this.allStates.every(state => state.checked);
  }

  onTabChange(tabId) {
    this.activeTab = tabId;
  }

  async dropped(event: any) {
    this.file = null;
    let getFile = event.target.files;
    if (getFile.length == 1) {
      this.file = getFile[0];
      const fileData = await this.getExcelData(getFile[0]);
      if (!fileData) {
        return;
      }
      this.fileName = this.file.name;
    }
  }

  async onFileChange (event) {
    this.file = null;
    let getFile = event.target.files;
    if (getFile.length == 1) {
      const fileData = await this.getExcelData(getFile[0]);
      if (!fileData) {
        return;
      }
      this.file = getFile[0];
      this.fileName = this.file.name;
    }
  }

  async getExcelData(file) {
    return await new Promise((res,rej) => {
      try {
        const reader = new FileReader();
      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* sheet data */
        const tableData = (XLSX.utils.sheet_to_json(ws, { header: 1 }));
        let header: any = [];
        const jsonData: any = [];
        const firstRow: any = tableData[0];
        if (!firstRow || !firstRow.length) {
          this.validate.popUpAlert('Invalid CSV file', "Warning", "warning");
          res(null);
          return null;
        } else {
          const headerDataTemplate = `state,district,season,cluster`
          const headerData = firstRow.map(d => d.trim().toLowerCase()).join();
          if (headerData != headerDataTemplate) {
            this.validate.popUpAlert('Invalid CSV file', "Warning", "warning");
            res(null);
            return null;
          }
        }
        for (let i = 0; i < tableData.length; i++) {
          const row: any = tableData[i];
          if (i == 0) {
            row.forEach((d) => header.push(d.trim().toLowerCase()));
          } else {
            const data: any = {};
            for (let j = 0; j < header.length; j++) {
              const cell = ['',null,undefined].includes(row[j]) ? '' : row[j];
              if (cell && typeof cell == 'string') {
                data[header[j]] = cell.trim();
              } else if (typeof cell == 'number') {
                data[header[j]] = cell;
              } else {
                data[header[j]] = cell || '';
              }
            }
            jsonData.push(data);
          }
        }
        res(jsonData);
      }
      reader.readAsBinaryString(file);


    } catch(err) {
      rej(err);
    }
  });
    }


  onFileUpload () {
    if (!this.selectedUPloadState) {
      this.validate.popUpAlert('Please select state first', "Warning", "warning");
    }
    if (!this.file || !this.fileName) {
      this.validate.popUpAlert('Please select file first', "Warning", "warning");
      return
    }
    const fromData = new FormData();
    fromData.append('file', this.file, this.fileName)
    fromData.append('state', this.selectedUPloadState)
    this.loading++
    this.apiData.MasterUploadClusterMapUpload(fromData).then((response: any) => {
      this.loading--;
      if (response.status === 1) {
        this.validate.popUpAlert(response.message, "Success", "success");
        this.file = null;
        this.fileName = null;
        this.getAllStateDistrictData();
      } else {
        this.validate.popUpAlert(response.message, "Error", "error");
      }
    }).catch(err => {
      this.loading--;
      console.error(err);
      this.validate.popUpAlert('Unable to upload file', "Error", "error");
    })
  }

  downloadSample() {
    this.loading++;
      this.apiData.MasterUploaddownloadSampleClusterMapping().then(res => {
        this.loading--;
        this.commonfunctions.csvDownloadFromServer(res);
      }, err => {
        this.loading--;
        this.commonfunctions.serviceErrorHandling(err);
      })
  }

  onDisctictUpdate(data) {
    data.isUpdated = true;
    this.enableSubmit = true;
  }

  onSubmit() {
    this.loading++
    const request = this.rowData.filter((data: any) => data.isUpdated).map(data => {
      return { id: data.id,  district: data.district, cluster: data.cluster  }
    });
    this.apiData.MasterUploadUpdateClusterMapping(request).then((response: any) => {
      this.loading--;
      if (response.status === 1) {
        this.validate.popUpAlert(response.message, "Success", "success");
        this.getAllStateDistrictData(true);
      } else {
        this.validate.popUpAlert(response.message, "Error", "error");
      }
    }).catch(err => {
      this.loading--;
      console.error(err);
      this.validate.popUpAlert('Unable to update data', "Error", "error");
    })
  }

}

import { Directive,HostListener,ElementRef } from '@angular/core';

@Directive({
  selector: '[appPosNumberonly]'
})
export class PosNumberonlyDirective {

  private regex: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Delete','Arrows'];
 
  constructor(private el: ElementRef) {
  }
 
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    let pos = this.el.nativeElement.selectionEnd;
    let next: string = current;
    // Do not use event.keycode this is deprecated.
    // We need this because the current value on the DOM element
    // is not yet updated with the value from this event
    if ((pos == 0 && event.key == "0")) {
      event.preventDefault();
    } else {
      next = current.concat(event.key);
      if (next && !String(next).match(this.regex)) {
        event.preventDefault();
      }
    }
  }

}

import { Component, OnInit } from "@angular/core";
import { AlertService } from "../services/alert.service";
import { ApiService } from "../services/apiservices.service";
import { CommonfunctionsService } from "../services/commonfunctions.service";
import Swal from "sweetalert2";
import { MandatoryfieldService } from "../common/Validators/mandatoryfield.service";

@Component({
  selector: "app-tie-up-mapping",
  templateUrl: "./tie-up-mapping.component.html",
  styleUrls: ["./tie-up-mapping.component.css"],
})
export class TieUpMappingComponent implements OnInit {
  loading = 0;
  allStatesDistrict: any[] = [];
  allStates: any[] = [];
  allTieups: any[] = [];
  selectedTieup: any
  isSelectAll: boolean = false;

  constructor(private apiData: ApiService, public commonfunctions: CommonfunctionsService, private alert: AlertService,private validate: MandatoryfieldService) {}

  ngOnInit() {
    this.getAllTieupList()
    this.getAllStateDistrictData();
  }

  getAllStateDistrictData() {
    this.loading++
    this.apiData.parameterSettingAllStatesDistricts().then((res: any) => {
      if (res && res.status == 1) {
        this.allStatesDistrict = res.data;
        this.generateLoationData();
        this.loading--
      }
    }).catch(err => {
      this.loading--
      this.commonfunctions.serviceErrorHandling(err);
    });
  }

  getAllTieupList() {
    this.loading++
    this.apiData.portalRevaApi.getTieUpList(res => {
      if (res && res.status == 1) {
        this.allTieups = res.data.sort((a:any, b: any) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      }
      this.loading--
    }, err => {
      this.loading--;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }

  generateLoationData() {
    const states = Array.from(
      new Set(this.allStatesDistrict.map((data) => data.state))
    ).map((state) => {
      const stateResult: any = { state, state_id: state.replaceAll(' ', '') };
      stateResult.clusters = Array.from(
        new Set(
          this.allStatesDistrict
            .filter((data) => data.state == state)
            .map((data) => data.cluster)
        )
      ).map((cluster) => {
        const clusterResult: any = { cluster, clusterId: state.replaceAll(' ', '') + '-' + cluster.replaceAll(' ', '') };
        clusterResult.districts = Array.from(
          new Set(
            this.allStatesDistrict
              .filter((data) => data.cluster == cluster && data.state == state)
              .map((data) => data.district)
          )
        ).map(district => {
          return {district, districtId: `${state}-${cluster}-${district}`}
        });
        clusterResult.districts.sort((a: any,b: any) => a.district.toLowerCase().localeCompare(b.district.toLowerCase()))
        return clusterResult;
      });
      stateResult.clusters.sort((a:any, b:any) => +a.cluster - +b.cluster)
      return stateResult;
    });
    this.allStates = states.sort((a: any,b: any) => a.state.toLowerCase().localeCompare(b.state.toLowerCase()));
  }

  onTieUpChange(event: any) {
    this.apiData.parameterSettingAllowedStatesDistricts(event).then((res: any) => {
      if (res && res.status == 1) {
        const checkedData = res.data || [];
        const checkedDistricts = [];
        checkedData.forEach(state => {
          state.clusters.forEach(cluster => {
            cluster.districts.forEach(district => {

              checkedDistricts.push(`${state.state}-${cluster.cluster}-${district}`);
            })
          })
        });
        this.allStates.forEach(state => {
          state.clusters.forEach(cluster => {
            cluster.districts.forEach(district => {
              district.checked = checkedDistricts.includes(district.districtId)
            })
          })
        });
        this.updateAll();
      }
    }).catch(err => {
      this.loading--
      this.commonfunctions.serviceErrorHandling(err);
    })
  }

  onSelectAll(event) {
    const allStates: any = this.allStates;
    allStates.flatMap(data => data.clusters).flatMap(data => data.districts).forEach(data => data.checked = event)
    this.updateAll();

  }

  onStateChange(state: any, event: any) {
    const stateData = this.allStates.find(data => data.state == state);
    if (stateData) {
      stateData.clusters.forEach((data: any) => {
        data.checked = event;
        this.onClusterChange(data.clusterId, data.checked, true);
      })
    }
    this.updateAll();
  }

  onClusterChange(clusterId: any, event: any, ignore?: boolean) {
    const allStates: any = this.allStates;
    const clusterData = allStates.flatMap(data => data.clusters).find(data => data.clusterId == clusterId)
    if (clusterData) {
      clusterData.districts.forEach(data => {
        data.checked = event;
        this.onDistrictChange(data.district, data.checked, true);
      })
    }
    if (!ignore) {
      this.updateAll();
    }
  }

  onDistrictChange(district: any, event: any, ignore?: boolean) {
    if (!ignore) {
      this.updateAll();
    }
  }

  updateAll() {
    this.allStates.forEach(state => {
      state.clusters.forEach(cluster => {
        cluster.checked = cluster.districts.every(district => district.checked)
      })
      state.checked = state.clusters.every(cluster => cluster.checked);
    })
    this.isSelectAll = this.allStates.every(state => state.checked);
  }

  onSubmit() {
    if (!this.selectedTieup) {
      Swal({
        title: "Required",
        text: "Please select tieup Id",
        type: "warning",
        confirmButtonColor: "#175788",
      })
      return;
    }
    const allStates = JSON.parse(JSON.stringify(this.allStates))
    const states = allStates.map(state => {
      const result: any = {state: state.state}
      result.clusters  = state.clusters.map(cluster => {
        const clusterResult:any = {cluster: cluster.cluster}
        clusterResult.districts = cluster.districts.filter(district => district.checked).map(district => district.district);
        return clusterResult;
      }).filter(cluster => cluster.districts.length);
      return result;
    }).filter(state => state.clusters.length);

    const request = {
      tieupId: this.selectedTieup,
      states,
      tenantId: this.alert.getUserDetails("getTenant")
    }

    this.loading++

    this.apiData.parameterSettingassignStatesDistricts(request).then((res: any) => {
      if (res && res.status == 1) {
        this.validate.popUpAlert(res.message, "Success", "success");
        this.onTieUpChange(this.selectedTieup)
      } else {
        this.validate.popUpAlert(res.message, "Error", "success");
      }
      this.loading--
    }).catch(err => {
      this.loading--
      this.commonfunctions.serviceErrorHandling(err);
    })

  }
}

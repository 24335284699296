import { Component, OnInit, Inject } from "@angular/core";
import { AlertService } from "../../services/alert.service";
import { MandatoryfieldService } from "../../common/Validators/mandatoryfield.service";
import { ApiService } from "../../services/apiservices.service";
import Swal from "sweetalert2";
import {
  FormBuilder,
  FormArray,
  Validators,
  FormControl,
  FormGroup,
} from "@angular/forms";
import { CommonfunctionsService } from "../../services/commonfunctions.service";
import { ActivatedRoute } from "@angular/router";
declare var $: any;
@Component({
  selector: "app-rategenerate",
  templateUrl: "./rategenerate.component.html",
  styleUrls: ["./rategenerate.component.css"],
})
export class RategenerateComponent implements OnInit {
  result: any = {};
  paramForm: any;
  paramFormWithSubSections = new FormGroup({});
  paramFormNoSubSections = new FormGroup({});
  FinalLoadingWF = new Array();
  clusterDistrictOptions: any = {};
  sectionControlDisable = new Array();
  sectiontotalFinalloadingpercent = new Array();
  totalLoadingDiscount = 0;
  parameter: any = {
    name: [],
  };
  comments: any = "";
  loading: boolean = false;
  emptyComment: boolean = false;
  loadingDiscountData: any;
  ldNoSubSections: any;
  wf_ensoelninoimpact: [{ text: "0"; value: "0" }];
  wf_iod_forecast: [{ text: "0"; value: "0" }];
  wf_monsoonforecast: [{ text: "0"; value: "0" }];
  isPreFinalRate: Boolean;
  finalRate: Boolean = false;
  loadingPermission: any[] = [];
  totalSumInsured: any;
  yearData: any[] = [];
  paramData: any[] = [];
  showClusterLabel = false;
  timer: any;
  sum_insured_errorMessage = '';

  constructor(
    public alertService: AlertService,
    private validate: MandatoryfieldService,
    private apiData: ApiService,
    private formBuilder: FormBuilder,
    private commonfunctions: CommonfunctionsService,
    private activeRoute: ActivatedRoute
  ) {
    this.paramFormNoSubSections = this.createparamFormNoSubSections();
  }

  ngOnInit() {
    this.getParam();
  }

  getParam() {
    this.activeRoute.params.subscribe((data) => {
      if (
        !(data && data.param)
      ) {
        this.alertService.navigateTo("/choose-transaction");
      } else {
        this.paramData = JSON.parse(atob(data.param));
        if (this.paramData[1] && this.paramData[1] == "cluster") {
          this.showClusterLabel = true;
        }
        this.alertService.changeStepper(3);
        this.isPreFinalRate =
          this.alertService.isFinalRate.get() == 1 ? true : false;
        this.getFieldList(data.param);
      }
    });
  }
  async getFieldList(param: any) {
    this.loading = true;
    await this.getSumInsuredDetails(param);
    this.apiData.getParameterAPI.getLoadongDiscount(
      (res) => {
        if (!this.validate.isUndefined(res)) {
          let result = res;
          this.result = result;
          if (result.status == 1) {
            this.loadingDiscountData = result.data.ldWithSubSections;
            this.ldNoSubSections = result.data.ldNoSubSections;
            for (let ldi = 0; ldi < result.data.ldWithSubSections.length; ldi++) {
              const subSection = result.data.ldWithSubSections[ldi];
              if (subSection.loadingDistricts && subSection.loadingDistricts.length) {
                subSection.loadingDistricts.sort((a: any,b: any) => {
                  if (this.paramData[1] === 'cluster'){
                    if (a.cluster != b.cluster) {
                      return +a.cluster - +b.cluster
                    } else {
                      return a.district.localeCompare(b.district);
                    }
                  } else {
                    return a.district.localeCompare(b.district);
                  }
                })
              }
            }
            this.loadingPermission = result.data.loadingDiscountPermissions;
            this.setFinalLoadingArray(this.loadingDiscountData);
            this.paramFormNoSubSections = this.createparamFormNoSubSections();
            for (
              let withIndex = 0;
              withIndex < this.loadingDiscountData.length;
              withIndex++
            ) {
              const section = this.loadingDiscountData[withIndex];
              for (
                let loadingIndex = 0;
                loadingIndex < this.loadingPermission.length;
                loadingIndex++
              ) {
                const permission = this.loadingPermission[loadingIndex];
                if (permission.loadingDiscountId == section.loadingDiscountID) {
                  section.view = permission.view;
                  section.edit = permission.edit;
                }
              }
              if (section.subSections && section.subSections.length) {
                for (
                  let subInex = 0;
                  subInex < section.subSections.length;
                  subInex++
                ) {
                  const subSection = section.subSections[subInex];
                  const defaultVal = subSection.uwPerceptionData.find(
                    (d) => d.defaultUWPerception
                  );
                  if (defaultVal) {
                    subSection.uwPerception = defaultVal.text;
                    this.getFinalLoading(
                      subSection.uwPerceptionData,
                      subSection.uwPerception,
                      withIndex,
                      subInex
                    );
                    subSection.finalLoadingPercentage = defaultVal.options
                      ? Math.round(defaultVal.finalLoadingPercentage)
                      : defaultVal.value;
                    const FinalLoadingWF =
                      this.FinalLoadingWF[withIndex][subInex] || [];
                    subSection.isReadonly = !FinalLoadingWF.map(
                      (d) => d.value
                    ).includes(subSection.finalLoadingPercentage);
                    $("#fl_percentip_" + withIndex + "" + subInex).val(
                      subSection.finalLoadingPercentage
                    );
                  }
                  const finalLoadingWithWeightageInDecimal =
                    (parseFloat(subSection.finalLoadingPercentage) / 100) *
                    (parseFloat(subSection.weightage) / 100);
                }

                let totalFinalLoadinginDecimal = 0;
                for (let i = 0; i < section.subSections.length; i++) {
                  const subsection = section.subSections[i];
                  totalFinalLoadinginDecimal +=
                    (subsection.finalLoadingPercentage / 100) *
                    (subsection.finalLoadingWeightageApplied / 100) *
                    100;
                }
                section.avgFinalLoadingPercentage =
                  (section.sectionFinalLoadingWeightagePercentage / 100) *
                  totalFinalLoadinginDecimal;
                section.totalFinalLoadingPercentage =
                  +totalFinalLoadinginDecimal.toFixed(1);
                this.sectiontotalFinalloadingpercent[withIndex] =
                  +totalFinalLoadinginDecimal.toFixed(1);
                this.totalLoadingDiscount =
                  +this.totalLoadingDiscount.toFixed(2);
                console.log("002");
              }
              if (section.loadingDistricts && section.loadingDistricts.length) {
                let totalDistrictFinalLoading = 0;
                let totalLoadedDistrict = 0;
                for (
                  let dIndx = 0;
                  dIndx < section.loadingDistricts.length;
                  dIndx++
                ) {
                  const district = section.loadingDistricts[dIndx];
                  district.totalYeildData = 0;
                  for (let yindx = 0; yindx < this.yearData.length; yindx++) {
                    const yieldData = this.yearData[yindx];
                    if (
                      yieldData.admin2 &&
                      district.district &&
                      yieldData.admin2.toLowerCase() ==
                        district.district.toLowerCase()
                    ) {
                      district.totalYeildData += yieldData.sum_insured || 0;
                    }
                  }
                  district.yeildDataPercentage =
                    this.totalSumInsured == 0
                      ? 0
                      : district.totalYeildData / this.totalSumInsured;
                  district.sum_insured_text = district.totalYeildData
                    ? +district.totalYeildData.toFixed(2)
                    : 0;
                  district.district_weightage_text =
                    district.yeildDataPercentage
                      ? +(district.yeildDataPercentage * 100).toFixed(2)
                      : 0;
                  if (district.subSections && district.subSections.length) {
                    for (
                      let subInex = 0;
                      subInex < district.subSections.length;
                      subInex++
                    ) {
                      const subSection = district.subSections[subInex];
                      const defaultVal = subSection.uwPerceptionData.find(
                        (d) => d.defaultUWPerception
                      );
                      if (defaultVal) {
                        subSection.uwPerception = defaultVal.text;
                        this.setClusterDistrictFinalLoadingOPtion(
                          withIndex,
                          dIndx,
                          subInex,
                          subSection.uwPerceptionData,
                          subSection.uwPerception
                        );
                        subSection.finalLoadingPercentage = defaultVal.options
                          ? Math.round(defaultVal.finalLoadingPercentage)
                          : defaultVal.value;
                        const clusterPercentageOption =
                          this.clusterDistrictOptions[
                            `${withIndex}${dIndx}${subInex}`
                          ] || [];
                        subSection.isReadonly = !clusterPercentageOption
                          .map((d) => d.value)
                          .includes(subSection.finalLoadingPercentage);
                        $("#fl_percentip_" + withIndex + "" + subInex).val(
                          subSection.finalLoadingPercentage
                        );
                      }
                    }

                    let totalFinalLoadinginDecimala = 0;
                    for (let i = 0; i < district.subSections.length; i++) {
                      const subsection = district.subSections[i];
                      totalFinalLoadinginDecimala +=
                        (subsection.finalLoadingPercentage / 100) *
                        (subsection.finalLoadingWeightageApplied / 100) *
                        100;
                    }
                    totalFinalLoadinginDecimala =
                      totalFinalLoadinginDecimala *
                      district.yeildDataPercentage;
                    district.avgFinalLoadingPercentage =
                      (district.sectionFinalLoadingWeightagePercentage / 100) *
                      totalFinalLoadinginDecimala;
                    district.totalFinalLoadingPercentage =
                      +totalFinalLoadinginDecimala;
                    totalDistrictFinalLoading +=
                      district.totalFinalLoadingPercentage;
                    totalLoadedDistrict++;
                  }
                }
                this.sectiontotalFinalloadingpercent[withIndex] =
                  +totalDistrictFinalLoading.toFixed(2);
                section.totalFinalLoadingPercentage = totalDistrictFinalLoading;
                section.avgFinalLoadingPercentage =
                  (section.sectionFinalLoadingWeightagePercentage / 100) *
                  totalDistrictFinalLoading;
                console.log("003");
              }
              section.updatestatus = "done";
            }
            for (
              let withoutIndx = 0;
              withoutIndx < this.ldNoSubSections.length;
              withoutIndx++
            ) {
              const section = this.ldNoSubSections[withoutIndx];
              for (
                let loadingIndex = 0;
                loadingIndex < this.loadingPermission.length;
                loadingIndex++
              ) {
                const permission = this.loadingPermission[loadingIndex];
                if (permission.loadingDiscountId == section.loadingDiscountID) {
                  section.view = permission.view;
                  section.edit = permission.edit;
                }
              }
            }
            this.updateTotalLoadingDiscountVal();
            this.loading = false;
          }
        }
        console.log(this.loadingDiscountData);
      },
      (err) => {
        this.loading = false;
        this.commonfunctions.serviceErrorHandling(err);
      },
      param
    );
  }

  async getSumInsuredDetails(param: any) {
    const paramData = JSON.parse(atob(param));
    const state = paramData[0];
    const year = paramData[5];
    await this.apiData
      .rateGenerationGetYieldData(state, year)
      .then((res: any) => {
        if (res && res.status == 1) {
          this.yearData = (res.data || []).filter((d) => {
            if (
              ((this.paramData[3] || "").toLowerCase() == "all" ||
                (d.season || "").toLowerCase() ==
                  (this.paramData[3] || "").toLowerCase()) &&
              ((this.paramData[4] || "").toLowerCase() == "all" ||
                (this.paramData[4] || "").toLowerCase() == "all_individual" ||
                (d.crop || "").toLowerCase() ==
                  (this.paramData[4] || "").toLowerCase())
            ) {
              if ((this.paramData[2] || "").toLowerCase() == "all") {
                return true;
              } else if (this.paramData[1] == "cluster") {
                return d.cluster_num == this.paramData[2];
              } else {
                return d.admin2 == this.paramData[2];
              }
            } else {
              return false;
            }
          });
          this.totalSumInsured = this.yearData
            .map((d) => d.sum_insured)
            .reduce((a, b) => a + b, 0);
          this.totalSumInsured = +this.totalSumInsured.toFixed(2);
        } else {
          console.error("No Yield Data");
        }
      })
      .catch((err) => {
        console.error("No Yield Data");
      });
  }

  goto(process) {
    if (process == "next") {
      let isvalid = this.validateLoadinDiscount();
      if (isvalid) {
        if (this.sum_insured_errorMessage) {
          Swal({
            title: 'Sum insured value of some of the districts is zero. Do you want to continue?',
            showConfirmButton: true,
            showCancelButton: true,
          }).then((result: any) => {
            if (result && result.value) {
              this.MRgetstatusApi();
              this.sum_insured_errorMessage = '';
            }
          });
        } else {
          this.MRgetstatusApi();
        }
      }
    }
  }

  saveRateGeneration() {
    var loadingDiscountDataSave = JSON.parse(
      JSON.stringify(this.loadingDiscountData)
    );
    var noloadingDiscountDataSave = JSON.parse(
      JSON.stringify(this.ldNoSubSections)
    );
    for (var i = 0; i < loadingDiscountDataSave.length; i++) {
      if (
        loadingDiscountDataSave[i].loadingDistricts &&
        loadingDiscountDataSave[i].loadingDistricts.length
      ) {
        loadingDiscountDataSave[i].sectionTotalLoadingDiscount = loadingDiscountDataSave[i].avgFinalLoadingPercentage;
      } else {
        loadingDiscountDataSave[i].sectionTotalLoadingDiscount =
          loadingDiscountDataSave[i].avgFinalLoadingPercentage;
      }
      for (var j = 0; j < loadingDiscountDataSave[i].subSections.length; j++) {
        loadingDiscountDataSave[i].subSections[j].uwPerceptionData =
          new Array();
      }
    }

    for (let i = 0; i < noloadingDiscountDataSave.length; i++) {
      const element = noloadingDiscountDataSave[i];
      element.sectionTotalLoadingDiscount = element.totalFinalLoadingPercentage;
    }

    var data = {
      ldWithSubSections: loadingDiscountDataSave,
      ldNoSubSections: noloadingDiscountDataSave,
      isFinalRate: this.finalRate,
    };


    this.apiData.formRequest.getRequest("", data, "rateloadingdisc");
    this.loading = true;
    this.apiData.rateGenerationApi.saveLoadingDisc(
      (res) => {
        this.loading = false;
        if (!this.validate.isUndefined(res)) {
          if (res.status == 1) {
            this.comments = "";
            this.alertService.createLocal("loading", "true");
            this.apiData.rateGenerationApi.markTransactionRateGenerated(res => {
              if (!res || !res.data || res.data.status != 'RateGenerated') {
                this.validate.popUpAlert('Unable to update status', "", "error");
              }else {
                this.alertService.navigateTo("/rate-download");
              }
            }, err => {
              this.loading = false;
              this.commonfunctions.serviceErrorHandling(err);
              this.validate.popUpAlert('Unable to update status', "", "error");
            })
          } else if (res.status == 0) {
            let errorText = this.alertService.ErrorText.checkError(res.data);
            this.validate.popUpAlert(errorText, "", "error");
          }
        }
      },
      (err) => {
        this.loading = false;
        this.commonfunctions.serviceErrorHandling(err);
      }
    );
  }

  setFinalLoadingArray(loadingDiscounts: any) {
    this.sectionControlDisable = new Array(loadingDiscounts.length);
    this.sectiontotalFinalloadingpercent = new Array(loadingDiscounts.length);
    this.FinalLoadingWF = new Array(loadingDiscounts.length);
    for (var i = 0; i < loadingDiscounts.length; i++) {
      this.sectionControlDisable[i] = false;
      this.sectiontotalFinalloadingpercent[i] = 0;
      this.FinalLoadingWF[i] = new Array(
        loadingDiscounts[i].subSections.length
      );
    }
  }

  createparamFormNoSubSections() {
    if (this.validate.isUndefined(this.ldNoSubSections)) {
      var group = this.formBuilder.group({});
      group.addControl(
        "totalFinalLoadingPercentage",
        this.formBuilder.control({ value: "00.00", disabled: true })
      );
      group.addControl(
        "ldComments",
        this.formBuilder.control({ value: "", disabled: true })
      );

      var groupArray = this.formBuilder.array([]);
      groupArray.push(group);

      var groupall = this.formBuilder.group({
        ldSections: groupArray,
      });

      return groupall;
    } else {
      var groupArray = this.formBuilder.array([]);

      for (let i = 0; i < this.ldNoSubSections.length; i++) {
        var group = this.formBuilder.group({});
        if (this.ldNoSubSections[i]["isActive"]) {
          if (!this.ldNoSubSections[i]["isMandatory"]) {
            group.addControl(
              "totalFinalLoadingPercentage",
              this.formBuilder.control("")
            );
            group.addControl("ldComments", this.formBuilder.control(""));
          } else {
            group.addControl(
              "totalFinalLoadingPercentage",
              this.formBuilder.control("", Validators.required)
            );
            group.addControl(
              "ldComments",
              this.formBuilder.control("", Validators.required)
            );
          }
        } else {
          group.addControl(
            "totalFinalLoadingPercentage",
            this.formBuilder.control({ value: "00.00", disabled: true })
          );
          group.addControl(
            "ldComments",
            this.formBuilder.control({ value: "", disabled: true })
          );
        }

        groupArray.push(group);
      }

      var groupall = this.formBuilder.group({
        ldSections: groupArray,
      });

      return groupall;
    }
  }

  //done
  getFinalLoading(uwPerceptionData, cur_event, sectionIndex, subSectionsIndex) {
    if (this.loadingDiscountData[sectionIndex].updatestatus == "add") {
      this.loadingDiscountData[sectionIndex].updatestatus = "edit";
    }

    $("#fl_uwperceptionval_" + sectionIndex + "" + subSectionsIndex).hide();
    $("#fl_uwperceptionval_" + sectionIndex + "" + subSectionsIndex).text("");
    var curUWPerceptionval =
      this.loadingDiscountData[sectionIndex].subSections[subSectionsIndex]
        .uwPerception;

    for (var i = 0; i < uwPerceptionData.length; i++) {
      if (uwPerceptionData[i].text == cur_event) {
        if (
          this.loadingDiscountData[sectionIndex].subSections[subSectionsIndex]
            .key == "wf_ensoelninoimpact"
        ) {
          this.FinalLoadingWF[sectionIndex][subSectionsIndex] =
            uwPerceptionData[i].options;
        } else if (
          this.loadingDiscountData[sectionIndex].subSections[subSectionsIndex]
            .key == "wf_iod_forecast"
        ) {
          this.FinalLoadingWF[sectionIndex][subSectionsIndex] =
            uwPerceptionData[i].options;
        } else if (
          this.loadingDiscountData[sectionIndex].subSections[subSectionsIndex]
            .key == "wf_monsoonforecast"
        ) {
          this.FinalLoadingWF[sectionIndex][subSectionsIndex] =
            uwPerceptionData[i].options;
        } else {
          this.loadingDiscountData[sectionIndex].subSections[
            subSectionsIndex
          ].finalLoadingPercentage = uwPerceptionData[i].value;
        }
        break;
      }
    }

    this.loadingDiscountData[sectionIndex].subSections[
      subSectionsIndex
    ].uwPerception = cur_event;

    if (
      this.loadingDiscountData[sectionIndex].loadingDiscountID !=
      "weather_forecast"
    ) {
      var totalFinalLoadinginDecimal = 0;
      for (
        var i = 0;
        i < this.loadingDiscountData[sectionIndex].subSections.length;
        i++
      ) {
        const subsection =
          this.loadingDiscountData[sectionIndex].subSections[i];
        totalFinalLoadinginDecimal +=
          (subsection.finalLoadingPercentage / 100) *
          (subsection.finalLoadingWeightageApplied / 100) *
          100;
      }

      this.loadingDiscountData[sectionIndex].avgFinalLoadingPercentage =
        (this.loadingDiscountData[sectionIndex]
          .sectionFinalLoadingWeightagePercentage /
          100) *
        totalFinalLoadinginDecimal;
      this.loadingDiscountData[sectionIndex].totalFinalLoadingPercentage =
        +totalFinalLoadinginDecimal.toFixed(1);
      this.sectiontotalFinalloadingpercent[sectionIndex] =
        +totalFinalLoadinginDecimal.toFixed(1);
      console.log("004");
      var sectionTotalLoadingDiscountDecimal =
        totalFinalLoadinginDecimal *
        (parseFloat(this.loadingDiscountData[sectionIndex].ldWeightage) / 100) *
        100;
      this.loadingDiscountData[sectionIndex].sectionTotalLoadingDiscount =
        sectionTotalLoadingDiscountDecimal;
    } else if (
      this.loadingDiscountData[sectionIndex].loadingDiscountID ==
      "weather_forecast"
    ) {
      var cur_finaloadingval = $(
        "#fl_percentip_" + sectionIndex + "" + subSectionsIndex
      ).val();
      var NotsameFinalloading = true;
      for (var iFl = 0; iFl < uwPerceptionData[i].options.length; iFl++) {
        if (cur_finaloadingval == uwPerceptionData[i].options[iFl].value) {
          NotsameFinalloading = false;
          break;
        }
      }

      //not same UW perception and not same finalloading value
      if (curUWPerceptionval != cur_event && NotsameFinalloading) {
        $("#fl_percentip_" + sectionIndex + "" + subSectionsIndex).val("");
        this.loadingDiscountData[sectionIndex].subSections[
          subSectionsIndex
        ].finalLoadingPercentage = 0;

        var totalFinalLoadinginDecimal = 0;
        for (
          var i = 0;
          i < this.loadingDiscountData[sectionIndex].subSections.length;
          i++
        ) {
          const subsection =
            this.loadingDiscountData[sectionIndex].subSections[i];
          totalFinalLoadinginDecimal +=
            (subsection.finalLoadingPercentage / 100) *
            (subsection.finalLoadingWeightageApplied / 100) *
            100;
        }
        this.loadingDiscountData[sectionIndex].avgFinalLoadingPercentage =
          (this.loadingDiscountData[sectionIndex]
            .sectionFinalLoadingWeightagePercentage /
            100) *
          totalFinalLoadinginDecimal;
        this.loadingDiscountData[sectionIndex].totalFinalLoadingPercentage =
          +totalFinalLoadinginDecimal.toFixed(1);
        this.sectiontotalFinalloadingpercent[sectionIndex] =
          +totalFinalLoadinginDecimal.toFixed(1);
        console.log("005");
        var sectionTotalLoadingDiscountDecimal =
          totalFinalLoadinginDecimal *
          (parseFloat(this.loadingDiscountData[sectionIndex].ldWeightage) /
            100) *
          100;
        this.loadingDiscountData[sectionIndex].sectionTotalLoadingDiscount =
          sectionTotalLoadingDiscountDecimal;
      }
    }

    if (this.loadingDiscountData[sectionIndex].edit) {
      this.loadingDiscountData[sectionIndex].updatestatus = "edit";
    }
  }

  //done
  getFinalLoadingWF(cur_event, sectionIndex, subSectionsIndex) {
    if (this.loadingDiscountData[sectionIndex].updatestatus == "add") {
      this.loadingDiscountData[sectionIndex].updatestatus = "edit";
    }

    $("#fl_percentval_" + sectionIndex + "" + subSectionsIndex).hide();
    $("#fl_percentval_" + sectionIndex + "" + subSectionsIndex).text("");

    $("#fl_percentip_" + sectionIndex + "" + subSectionsIndex).val(cur_event);

    this.loadingDiscountData[sectionIndex].subSections[
      subSectionsIndex
    ].finalLoadingPercentage = cur_event;

    var totalFinalLoadinginDecimal = 0;
    for (
      var i = 0;
      i < this.loadingDiscountData[sectionIndex].subSections.length;
      i++
    ) {
      const subsection = this.loadingDiscountData[sectionIndex].subSections[i];
      totalFinalLoadinginDecimal +=
        (subsection.finalLoadingPercentage / 100) *
        (subsection.finalLoadingWeightageApplied / 100) *
        100;
    }
    this.loadingDiscountData[sectionIndex].avgFinalLoadingPercentage =
      (this.loadingDiscountData[sectionIndex]
        .sectionFinalLoadingWeightagePercentage /
        100) *
      totalFinalLoadinginDecimal;
    this.loadingDiscountData[sectionIndex].totalFinalLoadingPercentage =
      +totalFinalLoadinginDecimal.toFixed(1);
    this.sectiontotalFinalloadingpercent[sectionIndex] =
      +totalFinalLoadinginDecimal.toFixed(1);
    console.log("006");
    var sectionTotalLoadingDiscountDecimal =
      totalFinalLoadinginDecimal *
      (parseFloat(this.loadingDiscountData[sectionIndex].ldWeightage) / 100) *
      100;
    this.loadingDiscountData[sectionIndex].sectionTotalLoadingDiscount =
      sectionTotalLoadingDiscountDecimal;

    if (this.loadingDiscountData[sectionIndex].edit) {
      this.loadingDiscountData[sectionIndex].updatestatus = "edit";
    }
  }

  getDistrictFinalLoading(
    uwPerceptionData,
    event,
    subSection,
    index,
    districts,
    dIndx,
    subIndx
  ) {
    const perception = uwPerceptionData.find((data) => data.text == event);
    subSection.finalLoadingPercentage = null;
    if (perception) {
      subSection.finalLoadingPercentage =
        perception.finalLoadingPercentage || perception.value;
      if (perception.options) {
        this.setClusterDistrictFinalLoadingOPtion(
          index,
          dIndx,
          subIndx,
          subSection.uwPerceptionData,
          subSection.uwPerception
        );
      }
    }

    const finalLoadingWithWeightageInDecimal =
      (parseFloat(subSection.finalLoadingPercentage || 0) / 100) *
      (parseFloat(subSection.weightage || 0) / 100);
    this.updateClusterDistrictFinalLoadingPercentage(index, districts);

    if (this.loadingDiscountData[index].edit) {
      this.loadingDiscountData[index].updatestatus = "edit";
    }
  }

  getDistrictFinalLoadingDW(
    uwPerceptionData,
    event,
    subSection,
    index,
    districts
  ) {
    const finalLoadingWithWeightageInDecimal =
      (parseFloat(subSection.finalLoadingPercentage || 0) / 100) *
      (parseFloat(subSection.weightage || 0) / 100);
    this.updateFinalLoading()
    if (this.loadingDiscountData[index].edit) {
      this.loadingDiscountData[index].updatestatus = "edit";
    }
  }

  updateClusterDistrictFinalLoadingPercentage(index, districts) {
    if (districts && districts.length) {
      let totalDistrictFinalLoading = 0;
      let totalLoadedDistrict = 0;
      for (let dIndx = 0; dIndx < districts.length; dIndx++) {
        const district = districts[dIndx];
        let totalFinalLoadinginDecimala = 0;
        if (district.subSections && district.subSections.length) {
          for (
            let subInex = 0;
            subInex < district.subSections.length;
            subInex++
          ) {
            const subsection = district.subSections[subInex];
            totalFinalLoadinginDecimala +=
              (subsection.finalLoadingPercentage / 100) *
              (subsection.finalLoadingWeightageApplied / 100) *
              100;
          }

          totalFinalLoadinginDecimala =
            totalFinalLoadinginDecimala * district.yeildDataPercentage;
          district.avgFinalLoadingPercentage =
            (district.sectionFinalLoadingWeightagePercentage / 100) *
            totalFinalLoadinginDecimala;
          district.totalFinalLoadingPercentage =
            +totalFinalLoadinginDecimala.toFixed(1);
          totalDistrictFinalLoading += district.totalFinalLoadingPercentage;
          totalLoadedDistrict++;
        }
      }
      this.sectiontotalFinalloadingpercent[index] =
        +totalDistrictFinalLoading.toFixed(2);
      console.log("001");
    }
  }

  setClusterDistrictFinalLoadingOPtion(
    index,
    districtIndex,
    subInex,
    perceptionData,
    event
  ) {
    this.clusterDistrictOptions[`${index}${districtIndex}${subInex}`] = [];
    const perception = perceptionData.find((data) => data.text == event);
    if (perception) {
      this.clusterDistrictOptions[`${index}${districtIndex}${subInex}`] =
        perception.options;
    }
  }

  //done
  setComments(sectionIndex) {
    if (this.loadingDiscountData[sectionIndex].edit) {
      this.loadingDiscountData[sectionIndex].updatestatus = "edit";
    }

    var cmts_value = $("#cmts_" + sectionIndex).val();
    $("#cmtsval_" + sectionIndex).text("");
    $("#cmtsval_" + sectionIndex).hide();
    if (
      cmts_value.length == 0 &&
      this.loadingDiscountData[sectionIndex].isMandatory == true
    ) {
      $("#cmtsval_" + sectionIndex).show();
      $("#cmtsval_" + sectionIndex).text("Required");
    } else if (
      (cmts_value.length < 25 || cmts_value.length > 250) &&
      this.loadingDiscountData[sectionIndex].isMandatory == true
    ) {
      $("#cmtsval_" + sectionIndex).show();
      $("#cmtsval_" + sectionIndex).text(
        "Comments should be minimum 25 to maximum 250 characters"
      );
    }

    this.loadingDiscountData[sectionIndex].ldComments = cmts_value;
  }

  //done
  setTotFlPercentValLdOthers(sectionIndex, loadingID) {
    var ctrlId = "totFlPercentLdOthers_" + loadingID;
    if (loadingID == 'state_loading' && !this.ldNoSubSections[sectionIndex].totalFinalLoadingPercentage) {
      this.ldNoSubSections[sectionIndex].totalFinalLoadingPercentage = '0';
      $("#" + ctrlId).val(this.ldNoSubSections[sectionIndex].totalFinalLoadingPercentage);
    }
    var totalFinaloadingPercentage_value = $("#" + ctrlId).val();
    $("#totFlPercentLdOthersval_" + loadingID).hide();
    $("#totFlPercentLdOthersval_" + loadingID).text("");
    if (totalFinaloadingPercentage_value.length == 0) {
      this.ldNoSubSections[sectionIndex].totalFinalLoadingPercentage = 0;
      this.ldNoSubSections[sectionIndex].sectionTotalLoadingDiscount = 0;
    } else {
      this.ldNoSubSections[sectionIndex].totalFinalLoadingPercentage =
        totalFinaloadingPercentage_value;
      this.ldNoSubSections[sectionIndex].sectionTotalLoadingDiscount =
        totalFinaloadingPercentage_value;
    }

    if (
      $("#" + ctrlId).val().length == 0 &&
      this.ldNoSubSections[sectionIndex].isActive == true
    ) {
      $("#totFlPercentLdOthersval_" + loadingID).show();
      $("#totFlPercentLdOthersval_" + loadingID).text("Required");
    } else {
      const value = +$("#" + ctrlId).val()
      if (!['commercial_discount', 'state_loading'].includes(loadingID) && (value < -100 ||  value > 200)) {
        $("#totFlPercentLdOthersval_" + loadingID).show();
      $("#totFlPercentLdOthersval_" + loadingID).text("Value should be between -100 to 200");
      }
      if (['commercial_discount', 'state_loading'].includes(loadingID) && (value < -50 ||  value > 200)) {
        $("#totFlPercentLdOthersval_" + loadingID).show();
      $("#totFlPercentLdOthersval_" + loadingID).text("Value should be between -50 to 200");
      }
    }
  }

  //done
  setCommentsLdOthers(sectionIndex) {
    var cmts_value = $("#cmtsLdOthers_" + sectionIndex).val();
    $("#cmtsLdOthersval_" + sectionIndex).hide();
    $("#cmtsLdOthersval_" + sectionIndex).text("");
    if (
      cmts_value.length == 0 &&
      this.ldNoSubSections[sectionIndex].isMandatory == true
    ) {
      $("#cmtsLdOthersval_" + sectionIndex).show();
      $("#cmtsLdOthersval_" + sectionIndex).text("Required");
    } else if (
      (cmts_value.length < 25 || cmts_value.length > 250) &&
      this.ldNoSubSections[sectionIndex].isMandatory == true
    ) {
      $("#cmtsLdOthersval_" + sectionIndex).show();
      $("#cmtsLdOthersval_" + sectionIndex).text(
        "Comments should be minimum 25 to maximum 250 characters"
      );
    }

    this.ldNoSubSections[sectionIndex].ldComments = cmts_value;
  }

  checkDecimalNoSubSections(ctrltocheck) {
    ctrltocheck.setValue(ctrltocheck.value.replace(/[^\d.-]/g, "")); // numbers and decimals only
    ctrltocheck.setValue(ctrltocheck.value.replace("-.", "-")); //not decimal point after minus
    ctrltocheck.setValue(
      ctrltocheck.value.replace(/(^[-]+[\d]{2})[\d-]/g, "$1")
    ); // not more than 2 digitsafter the minus
    ctrltocheck.setValue(ctrltocheck.value.replace(/(^[\d]{2})[\d-]/g, "$1")); // not more than 2 digits at the beginning
    ctrltocheck.setValue(ctrltocheck.value.replace(/(\..*)\./g, "$1")); // decimal can't exist more than once
    ctrltocheck.setValue(ctrltocheck.value.replace(/(\.[\d]{3})./g, "$1")); // not more than 3 digits after decimal
  }

  validateLoadinDiscount() {
    this.sum_insured_errorMessage = '';
    $(".invalid-feedback").hide();
    $(".invalid-feedback").text("");

    var isvalid = true;
    let errorMessage = '';
    for (var i = 0; i < this.loadingDiscountData.length; i++) {
      if (
        this.loadingDiscountData[i].isActive == true &&
        this.loadingDiscountData[i].view == true
      ) {
        if (this.loadingDiscountData[i].updatestatus == "edit") {
          this.validate.popUpAlert(
            "Save " +
              this.loadingDiscountData[i].loadingDiscountName +
              " details and continue",
            "",
            "error"
          );
          isvalid = false;
          return false;
        }

        if (this.loadingDiscountData[i].isMandatory == true) {
          if (this.loadingDiscountData[i].updatestatus != "done") {
            this.validate.popUpAlert(
              "Provide " +
                this.loadingDiscountData[i].loadingDiscountName +
                " details and continue",
              "",
              "error"
            );
            isvalid = false;
            return false;
          }

          for (
            var j = 0;
            j < this.loadingDiscountData[i].subSections.length;
            j++
          ) {
            if (
              this.validate.isUndefined(
                this.loadingDiscountData[i].subSections[j].uwPerception
              )
            ) {
              $("#fl_uwperceptionval_" + i + "" + j).show();
              $("#fl_uwperceptionval_" + i + "" + j).text("Required");
              isvalid = false;
            }

            if (
              this.loadingDiscountData[i].loadingDiscountID ==
              "weather_forecast"
            ) {
              var flpercentVal = $("#fl_percentip_" + i + "" + j).val();
              if (
                this.loadingDiscountData[i].subSections[j]
                  .finalLoadingPercentage.length == 0
              ) {
                $("#fl_percentval_" + i + "" + j).show();
                $("#fl_percentval_" + i + "" + j).text("Required");
                isvalid = false;
              }
            }
          }

          if (
            this.loadingDiscountData[i].loadingDistricts &&
            this.loadingDiscountData[i].loadingDistricts.length
          ) {
            let total_district_weightage = 0;
            for (
              var dIndx = 0;
              dIndx < this.loadingDiscountData[i].loadingDistricts.length;
              dIndx++
            ) {
              const district =
                this.loadingDiscountData[i].loadingDistricts[dIndx];
              if (!district.sum_insured_text && district.sum_insured_text != '0') {
                isvalid = false
                errorMessage = `${district.district}'s sum insured is missing`;
              }
              if (district.sum_insured_text == 0) {
                this.sum_insured_errorMessage = `Sum insured value of some of the districts is empty`;
              }
              if (district.subSections && district.subSections.length) {
                for (
                  let subIndex = 0;
                  subIndex < district.subSections.length;
                  subIndex++
                ) {
                  const subSections = district.subSections[subIndex];
                  if (this.validate.isUndefined(subSections.uwPerception)) {
                    isvalid = false;
                  }
                  if (
                    [undefined, null].includes(
                      subSections.finalLoadingPercentage
                    )
                  ) {
                    isvalid = false;
                  }
                }
              }
            }
          }

          if (this.loadingDiscountData[i].edit) {
            if (
              this.validate.isUndefined(this.loadingDiscountData[i].ldComments)
            ) {
              isvalid = false;
              errorMessage = `${this.loadingDiscountData[i].loadingDiscountName}'s comment is missing.`
            } else if (
              this.loadingDiscountData[i].ldComments.length < 25 ||
              this.loadingDiscountData[i].ldComments.length > 250
            ) {
              isvalid = false;
              errorMessage = `${this.loadingDiscountData[i].loadingDiscountName}'s comment is missing.`
            }
          }
        } else {
          if (this.loadingDiscountData[i].ldComments.length == 0) {
            this.loadingDiscountData[i].ldComments = "";
          }
        }
      }
    }

    for (var i = 0; i < this.ldNoSubSections.length; i++) {
      if (
        this.ldNoSubSections[i].isActive == true &&
        this.ldNoSubSections[i].view == true
      ) {
        var ctrlId =
          "totFlPercentLdOthers_" + this.ldNoSubSections[i].loadingDiscountID;
        if (this.ldNoSubSections[i].isMandatory == true) {

          if (
            this.validate.isUndefined(
              this.ldNoSubSections[i].totalFinalLoadingPercentage
            ) &&
            $("#" + ctrlId).val().length != 0 &&
            parseFloat(this.ldNoSubSections[i].totalFinalLoadingPercentage) !=
              parseFloat($("#" + ctrlId).val())
          ) {
            var totalFinaloadingPercentage_value = $("#" + ctrlId).val();
            if (totalFinaloadingPercentage_value.length == 0) {
              this.ldNoSubSections[i].totalFinalLoadingPercentage = 0;
              this.ldNoSubSections[i].sectionTotalLoadingDiscount = 0;
            } else {
              this.ldNoSubSections[i].totalFinalLoadingPercentage =
                totalFinaloadingPercentage_value;
              this.ldNoSubSections[i].sectionTotalLoadingDiscount =
                totalFinaloadingPercentage_value;
            }
          }

          if (
            $("#" + ctrlId).val().length == 0 ||
            (this.validate.isUndefined(
              this.ldNoSubSections[i].totalFinalLoadingPercentage
            ) &&
              this.ldNoSubSections[i].totalFinalLoadingPercentage != 0)
          ) {
            $(
              "#totFlPercentLdOthersval_" +
                this.ldNoSubSections[i].loadingDiscountID
            ).show();
            $(
              "#totFlPercentLdOthersval_" +
                this.ldNoSubSections[i].loadingDiscountID
            ).text("Required");
            isvalid = false;
            errorMessage =  `${this.ldNoSubSections[i].loadingDiscountName}'s value is missing.`
          } else {
            const val = $("#" + ctrlId).val();
            if ((!['commercial_discount', 'state_loading'].includes(this.ldNoSubSections[i].loadingDiscountID)) && val < -100 || val > 200) {
              $(
                "#totFlPercentLdOthersval_" +
                  this.ldNoSubSections[i].loadingDiscountID
              ).show();
              $(
                "#totFlPercentLdOthersval_" +
                  this.ldNoSubSections[i].loadingDiscountID
              ).text("value should be between -100 to 200");
              isvalid = false;
              errorMessage =  `${this.ldNoSubSections[i].loadingDiscountName} value should be between -100 to 200.`
            }
            if (['commercial_discount', 'state_loading'].includes(this.ldNoSubSections[i].loadingDiscountID) && (val < -50 || val > 200)) {
              $(
                "#totFlPercentLdOthersval_" +
                  this.ldNoSubSections[i].loadingDiscountID
              ).show();
              $(
                "#totFlPercentLdOthersval_" +
                  this.ldNoSubSections[i].loadingDiscountID
              ).text("value should be between -50 to 200");
              isvalid = false;
              errorMessage =  `${this.ldNoSubSections[i].loadingDiscountName} value should be between -50 to 200.`
            }
          }

          if (this.ldNoSubSections[i].edit) {
            if (
              this.validate.isUndefined(this.ldNoSubSections[i].ldComments) ||
              $("#cmtsLdOthers_" + i).val().length == 0
            ) {
              $("#cmtsLdOthersval_" + i).show();
              $("#cmtsLdOthersval_" + i).text("Required");
              isvalid = false;
              errorMessage = `${this.ldNoSubSections[i].loadingDiscountName}'s comment is missing.`
            } else if (
              this.ldNoSubSections[i].ldComments.length < 25 ||
              this.ldNoSubSections[i].ldComments.length > 250
            ) {
              $("#cmtsLdOthersval_" + i).show();
              $("#cmtsLdOthersval_" + i).text(
                "Comments should be minimum 25 to maximum 250 characters"
              );
              isvalid = false;
              errorMessage = `${this.ldNoSubSections[i].loadingDiscountName}'s comment must be more than 25 and less than 250 characters.`
            }
          }
        } else {
          const val = $("#" + ctrlId).val();
          if ((!['commercial_discount', 'state_loading'].includes(this.ldNoSubSections[i].loadingDiscountID)) && val < -100 || val > 200) {
            $(
              "#totFlPercentLdOthersval_" +
                this.ldNoSubSections[i].loadingDiscountID
            ).show();
            $(
              "#totFlPercentLdOthersval_" +
                this.ldNoSubSections[i].loadingDiscountID
            ).text("value should be between -100 to 200");
            isvalid = false;
            errorMessage =  `${this.ldNoSubSections[i].loadingDiscountName} value should be between -100 to 200.`
          }
          if (['commercial_discount', 'state_loading'].includes(this.ldNoSubSections[i].loadingDiscountID) && (val < -50 || val > 200)) {
            $(
              "#totFlPercentLdOthersval_" +
                this.ldNoSubSections[i].loadingDiscountID
            ).show();
            $(
              "#totFlPercentLdOthersval_" +
                this.ldNoSubSections[i].loadingDiscountID
            ).text("value should be between -50 to 200");
            isvalid = false;
            errorMessage =  `${this.ldNoSubSections[i].loadingDiscountName} value should be between -50 to 200.`
          }
          var ctrlId =
            "totFlPercentLdOthers_" + this.ldNoSubSections[i].loadingDiscountID;
          if ($("#" + ctrlId).val().length == 0) {
            this.ldNoSubSections[i].totalFinalLoadingPercentage = 0;
            this.ldNoSubSections[i].sectionTotalLoadingDiscount = 0;
          }

          if (this.ldNoSubSections[i].edit) {
            if ($("#cmtsLdOthers_" + i).val().length == 0) {
              this.ldNoSubSections[i].ldComments = "";
            }
          }
        }
      }
    }

    if (!isvalid) {
      this.validate.popUpAlert(errorMessage || "Provide required fields", "", "error");
      return false;
    } else {
      return true;
    }
  }

  onDistrictWeightageChange(event: any, district: any, loadingDiscounts: any) {
    const errorEle = $(`.district_weightage_text[data-district="${district.district}"]`)
    errorEle.text('');
    district.yeildDataPercentage = 0;
    district.district_weightage_text = event.target.value;
    if (event.target.value) {
      district.yeildDataPercentage = +event.target.value / 100;
      event.target.value = (+event.target.value).toFixed(2)
      district.district_weightage_text = +event.target.value;
    } else {
        errorEle.text('Requried.');
    }
    this.updateFinalLoading()
    this.updateTotalLoadingDiscountVal();
  }

  onsumInsuredChange(event: any, district: any, loadingDiscounts: any) {
    const errorEle = $(`.district_weightage_text[data-district="${district.district}"]`)
    errorEle.text('');
    district.totalYeildData = 0;
    district.sum_insured_text = event.target.value;
    if (event.target.value) {
      district.totalYeildData = +event.target.value;
      event.target.value = (+event.target.value).toFixed(2)
      district.sum_insured_text = event.target.value;
    } else {
        errorEle.text('Requried.');
    }

    const district_yield_data_map = new Map();
    const district_sum_insured_map = new Map();
    const district_weightage_map = new Map();
    const district_weightage_text_map = new Map();

    this.totalSumInsured = loadingDiscounts.loadingDistricts.map(d => d.totalYeildData).reduce((a,b) => a+b)

      if (loadingDiscounts.loadingDistricts && loadingDiscounts.loadingDistricts.length) {
        for (let di = 0; di < loadingDiscounts.loadingDistricts.length; di++) {
          const district_data = loadingDiscounts.loadingDistricts[di];
          district_data.yeildDataPercentage =    this.totalSumInsured == 0   ? 0 : district_data.totalYeildData / this.totalSumInsured;
          if (district_data.district == district.district) {
            district_data.sum_insured_text = event.target.value;
          } else {
            district_data.sum_insured_text = district_data.totalYeildData ? (district_data.totalYeildData).toFixed(2) : '0';
          }
          district_data.district_weightage_text =  district_data.yeildDataPercentage ? +(district_data.yeildDataPercentage * 100).toFixed(2) : '0';

          district_yield_data_map.set(district_data.district, district_data.totalYeildData);
          district_sum_insured_map.set(district_data.district, district_data.sum_insured_text);
          district_weightage_map.set(district_data.district, district_data.yeildDataPercentage);
          district_weightage_text_map.set(district_data.district, district_data.district_weightage_text);

        }
      }
    for (let i = 0; i < this.loadingDiscountData.length; i++) {
      const loadingDiscountData = this.loadingDiscountData[i];
      if (loadingDiscountData.loadingDistricts && loadingDiscountData.loadingDistricts.length) {
        for (let di = 0; di < loadingDiscountData.loadingDistricts.length; di++) {
          const district_data = loadingDiscountData.loadingDistricts[di];
          district_data.totalYeildData = district_yield_data_map.get(district_data.district)
          district_data.sum_insured_text = district_sum_insured_map.get(district_data.district)
          district_data.yeildDataPercentage = district_weightage_map.get(district_data.district);
          district_data.district_weightage_text = district_weightage_text_map.get(district_data.district);
        }
      }
    }
    this.updateFinalLoading()
    this.updateTotalLoadingDiscountVal();
  }

  updateFinalLoading() {
    for (
      let withIndex = 0;
      withIndex < this.loadingDiscountData.length;
      withIndex++
    ) {
      const section = this.loadingDiscountData[withIndex];
      if (section.subSections && section.subSections.length) {
        let totalFinalLoadinginDecimal = 0;
        for (let i = 0; i < section.subSections.length; i++) {
          const subsection = section.subSections[i];
          totalFinalLoadinginDecimal +=
            (subsection.finalLoadingPercentage / 100) *
            (subsection.finalLoadingWeightageApplied / 100) *
            100;
        }
        section.avgFinalLoadingPercentage =
          (section.sectionFinalLoadingWeightagePercentage / 100) *
          totalFinalLoadinginDecimal;
        section.totalFinalLoadingPercentage =
          +totalFinalLoadinginDecimal.toFixed(1);
        this.sectiontotalFinalloadingpercent[withIndex] =
          +totalFinalLoadinginDecimal.toFixed(1);
        this.totalLoadingDiscount = +this.totalLoadingDiscount.toFixed(2);
      }
      if (section.loadingDistricts && section.loadingDistricts.length) {
        let totalDistrictFinalLoading = 0;
        let totalLoadedDistrict = 0;
        for (let dIndx = 0; dIndx < section.loadingDistricts.length; dIndx++) {
          const district = section.loadingDistricts[dIndx];
          if (district.subSections && district.subSections.length) {
            let totalFinalLoadinginDecimala = 0;
            for (let i = 0; i < district.subSections.length; i++) {
              const subsection = district.subSections[i];
              totalFinalLoadinginDecimala +=
                (subsection.finalLoadingPercentage / 100) *
                (subsection.finalLoadingWeightageApplied / 100) *
                100;
            }
            totalFinalLoadinginDecimala =
              totalFinalLoadinginDecimala * district.yeildDataPercentage;
            district.avgFinalLoadingPercentage =
              (district.sectionFinalLoadingWeightagePercentage / 100) *
              totalFinalLoadinginDecimala;
            district.totalFinalLoadingPercentage = +totalFinalLoadinginDecimala;
            totalDistrictFinalLoading += district.totalFinalLoadingPercentage;
            totalLoadedDistrict++;
          }
        }
        this.sectiontotalFinalloadingpercent[withIndex] =
          +totalDistrictFinalLoading.toFixed(2);
        section.totalFinalLoadingPercentage = totalDistrictFinalLoading;
        section.avgFinalLoadingPercentage =
          (section.sectionFinalLoadingWeightagePercentage / 100) *
          totalDistrictFinalLoading;
      }
    }
  }

  fnToggleSection(sectionIndex) {
    if (this.loadingDiscountData[sectionIndex].isActive) {
      var unSavedEntry = false;
      for (var i = 0; i < this.loadingDiscountData.length; i++) {
        if (this.loadingDiscountData[i].updatestatus == "edit") {
          unSavedEntry = true;
          this.validate.popUpAlert(
            "Save " +
              this.loadingDiscountData[i].loadingDiscountName +
              " details and continue",
            "",
            "warning"
          );
          break;
        }
      }

      if (unSavedEntry) {
        return false;
      }

      $("#" + sectionIndex).collapse("toggle");
    }
  }

  accordionToggle(collapseId: any) {
    $("#" + collapseId).collapse("toggle");
  }

  fnEditSection(sectionIndex) {
    //not show add status - done
    //1.change status - done
    //2.validate previous un saved section here - done
    //3.enable all controls here - done
    if (
      this.loadingDiscountData[sectionIndex].isActive &&
      this.loadingDiscountData[sectionIndex].updatestatus == "done"
    ) {
      var unSavedEntry = false;
      for (var i = 0; i < this.loadingDiscountData.length; i++) {
        if (
          i != sectionIndex &&
          this.loadingDiscountData[i].updatestatus == "edit"
        ) {
          unSavedEntry = true;
          this.validate.popUpAlert(
            "Save " +
              this.loadingDiscountData[i].loadingDiscountName +
              " details and continue.",
            "",
            "warning"
          );
          break;
        }
      }

      if (unSavedEntry) {
        return false;
      }

      this.loadingDiscountData[sectionIndex].updatestatus = "edit";
      this.sectionControlDisable[sectionIndex] = false;
      $("#" + sectionIndex).collapse("show");
    }
  }

  fnSaveSection(sectionIndex) {
    //disable once saved
    //1.change status - done
    //2.validate section here - done
    //3.disable all controls here - done
    //4.calculate total loading/discount here - done
    if (this.validateLoadingDiscountBySections(sectionIndex)) {
      this.loadingDiscountData[sectionIndex].updatestatus = "done";
      this.sectionControlDisable[sectionIndex] = true;

      var totalLoadingDiscounttemp = 0;
      for (var i = 0; i < this.loadingDiscountData.length; i++) {
        totalLoadingDiscounttemp =
          totalLoadingDiscounttemp +
          this.loadingDiscountData[i].sectionTotalLoadingDiscount;
      }
      this.totalLoadingDiscount = this.loadingDiscountData
        .map((d: any, i) => {
          if (d.loadingDistricts && d.loadingDistricts.length) {
            return (
              this.sectiontotalFinalloadingpercent[i] *
              (d.sectionFinalLoadingWeightagePercentage / 100)
            );
          }
          return (
            this.sectiontotalFinalloadingpercent[i] *
            (d.sectionFinalLoadingWeightagePercentage / 100)
          );
        })
        .reduce((a: any, b: any) => a + b, 0);
      this.totalLoadingDiscount = +this.totalLoadingDiscount.toFixed(2);

      $("#" + sectionIndex).collapse("toggle");
    }
  }

  updateTotalLoadingDiscountVal() {
    this.totalLoadingDiscount = this.loadingDiscountData
      .map((d: any, i) => {
        if (d.loadingDistricts && d.loadingDistricts.length) {
          return (
            this.sectiontotalFinalloadingpercent[i] *
            (d.sectionFinalLoadingWeightagePercentage / 100)
          );
        }
        return (
          this.sectiontotalFinalloadingpercent[i] *
          (d.sectionFinalLoadingWeightagePercentage / 100)
        );
      })
      .reduce((a: any, b: any) => a + b, 0);
    this.totalLoadingDiscount = +this.totalLoadingDiscount.toFixed(2);
    console.log("007");
  }

  validateLoadingDiscountBySections(i) {
    var isvalid = true;
    let errorMessage = '';
    if (this.loadingDiscountData[i].isActive == true) {
      if (this.loadingDiscountData[i].isMandatory == true) {
        for (
          var j = 0;
          j < this.loadingDiscountData[i].subSections.length;
          j++
        ) {
          $("#fl_uwperceptionval_" + i + "" + j).hide();
          $("#fl_uwperceptionval_" + i + "" + j).text("");
          $("#fl_percentval_" + i + "" + j).hide();
          $("#fl_percentval_" + i + "" + j).text("");

          if (
            this.validate.isUndefined(
              this.loadingDiscountData[i].subSections[j].uwPerception
            )
          ) {
            $("#fl_uwperceptionval_" + i + "" + j).show();
            $("#fl_uwperceptionval_" + i + "" + j).text("Required");
            isvalid = false;
          }
          if (
            this.loadingDiscountData[i].loadingDiscountID == "weather_forecast"
          ) {
            var flpercentVal = $("#fl_percentip_" + i + "" + j).val();
            if (
              this.loadingDiscountData[i].subSections[j].finalLoadingPercentage
                .length == 0 ||
              this.validate.isUndefined(flpercentVal)
            ) {
              $("#fl_percentval_" + i + "" + j).show();
              $("#fl_percentval_" + i + "" + j).text("Required");
              isvalid = false;
            }
          }
        }

        if (
          this.loadingDiscountData[i].loadingDistricts &&
          this.loadingDiscountData[i].loadingDistricts.length
        ) {
          for (
            var dIndx = 0;
            dIndx < this.loadingDiscountData[i].loadingDistricts.length;
            dIndx++
          ) {
            const district =
              this.loadingDiscountData[i].loadingDistricts[dIndx];
              if (!district.sum_insured_text && district.sum_insured_text !== 0) {
                $(`.district_weightage_text[data-district="${district.district}"]`).text("Requried.")
                isvalid = false;
                errorMessage = this.loadingDiscountData[i].loadingDistricts[dIndx].district + ' value is empty'
              }
            if (district.subSections && district.subSections.length) {
              for (
                let subIndex = 0;
                subIndex < district.subSections.length;
                subIndex++
              ) {
                const subSections = district.subSections[subIndex];
                if (this.validate.isUndefined(subSections.uwPerception)) {
                  isvalid = false;
                }
                if (
                  [undefined, null].includes(subSections.finalLoadingPercentage)
                ) {
                  isvalid = false;
                }
              }
            }
          }
        }

        if (this.loadingDiscountData[i].edit) {
          $("#cmtsval_" + i).hide();
          $("#cmtsval_" + i).text("");

          if (
            this.validate.isUndefined(this.loadingDiscountData[i].ldComments)
          ) {
            $("#cmtsval_" + i).show();
            $("#cmtsval_" + i).text("Required");
            isvalid = false;
            errorMessage ="Comments should not be empty"
          } else if (
            this.loadingDiscountData[i].ldComments.length < 25 ||
            this.loadingDiscountData[i].ldComments.length > 250
          ) {
            $("#cmtsval_" + i).show();
            $("#cmtsval_" + i).text(
              "Comments should be minimum 25 to maximum 250 characters"
            );
            isvalid = false;
            errorMessage = "Comments should be minimum 25 to maximum 250 characters";
          }
        }
      } else {
        if ($("#cmts_" + i).val().length == 0) {
          this.loadingDiscountData[i].ldComments = "";
        }
      }
    }

    if (!isvalid) {
      this.validate.popUpAlert(errorMessage || "Provide required fields", "", "error");
      return false;
    }  else {
      return true;
    }
  }

  localDenominateSign(numString) {
    if (numString) {
      let numeric = parseInt(numString);
      let absNumeric = Math.abs(numeric);
      let absStringLength = absNumeric.toString().length;
      let parsedDenomination;
      switch (true) {
        case absStringLength >= 8:
          parsedDenomination = `${(absNumeric / 10000000).toFixed(1)} Cr`;
          if (numeric >= 0) return parsedDenomination;
          else return `-${parsedDenomination}`;
        case absStringLength == 6 || absStringLength == 7:
          parsedDenomination = `${(absNumeric / 100000).toFixed(1)} L`;
          if (numeric >= 0) return parsedDenomination;
          else return `-${parsedDenomination}`;
        case absStringLength == 5:
          parsedDenomination = `${absNumeric
            .toString()
            .slice(0, 2)},${absNumeric.toString().slice(2, 5)}`;
          if (numeric >= 0) return parsedDenomination;
          else return `-${parsedDenomination}`;
        case absStringLength == 4:
          parsedDenomination = `${absNumeric
            .toString()
            .slice(0, 1)},${absNumeric.toString().slice(1, 4)}`;
          if (numeric >= 0) return parsedDenomination;
          else return `-${parsedDenomination}`;
        default:
          return numeric;
      }
    } else return 0;
  }

  FnToggle() {
    $(".collapse").collapse("toggle");
  }

  FnShow() {
    $(".collapse").collapse("show");
  }

  FnHide() {
    $(".collapse").collapse("hide");
  }
  test() {
    this.loadingDiscountData;
  }

  MRgetstatusApi() {
    this.loading = true
		this.apiData.rateGenerationApi.MRgetstatusApi(res => {
			if (!this.validate.isUndefined(res)) {
				if (res.status == 1) {
						if (res.data.toLowerCase() == "completed") {
							this.loading = false;
							this.saveRateGeneration()
						}
						else if (res.data.toLowerCase() == "terminated") {
							this.loading = false;
							this.validate.popUpAlert("Upload the file again", "", "error");
              return
						}
				}
				else if (res.status == 0 && res.message=='INVALID_REQUEST') {
					this.loading = false;
					this.validate.popUpAlert(res.data, "", "error");
				}
				else if (res.status == 0) {
					this.loading = false;
					let errorText = this.alertService.ErrorText.checkError(res.data);
					this.validate.popUpAlert(errorText, "", "error");
				}
			}
		}, err => {
			this.serviceError(err);
		})
	}

  getTransactonByMRId() {
    this.loading = true
    this.apiData.rateGenerationApi.getTransactonByMRId(res => {
      if (res && res.status == 1 && res.data) {
        if (res.data.status === 'Completed') {
          this.loading = false
          this.MRgetstatusApi();
        } else if (res.data.status === 'InProgress') {
          this.timer = setTimeout(() => this.getTransactonByMRId(), 1e4)
        } else {
          this.validate.popUpAlert(res.message, "", "error");
          this.loading = false
        }
      }
    }, err => {
      this.validate.popUpAlert("Unable to get Transaction Id", "", "error");
      this.serviceError(err)
    })
  }

  serviceError(err) {
		this.loading = false;
		this.commonfunctions.serviceErrorHandling(err);
	}

  clearTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }
}

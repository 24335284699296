import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/apiservices.service';
import Swal from 'sweetalert2';
import { MandatoryfieldService } from "../common/Validators/mandatoryfield.service";
import { ActivatedRoute, Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-create-tieup',
  templateUrl: './create-tieup.component.html',
  styleUrls: ['./create-tieup.component.css']
})
export class CreateTieupComponent implements OnInit {

  loading = 0;
  configData: any;
  countryData: any[] = [];
  statusVal: any[] = [];
  permissionSaved = false;
  selectedCountry: any;
  tieUpname: any;
  tieUpId: any;
  emailId: any;
  status: any;
  loadingPermission: any[] = [];
  mode: any;
  currentTieUpId: any;
  currentTieUpData: any;
  isDeleteEnable = false;


  constructor(private apiData: ApiService, private validate: MandatoryfieldService, private router: Router, private activatedroute: ActivatedRoute) { }

  ngOnInit() {
    this.initLoading();
  }

  initLoading() {
    this.loadPermission();
    this.getParams()
    this.statusVal = this.apiData.dropdownList.status;
    this.getCountryList();
  }

  loadPermission() {
    this.loadingPermission = [
      {
        loadingDiscountName: 'Weather Forecast',
        loadingDiscountId: 'weather_forecast',
        view: false,
        edit: false,
      },
      {
        loadingDiscountName: 'Farm Management',
        loadingDiscountId: 'farm_management_practices',
        view: false,
        edit: false,
      },
      {
        loadingDiscountName: 'Ground Level Expertise',
        loadingDiscountId: 'ground_level_expertise',
        view: false,
        edit: false,
      },
      {
        loadingDiscountName: 'Admin Loading',
        loadingDiscountId: 'admin_loading',
        view: false,
        edit: false,
      },
      {
        loadingDiscountName: 'Cost of Capital Loading',
        loadingDiscountId: 'cost_capital_loading',
        view: false,
        edit: false,
      },
      {
        loadingDiscountName: 'Commercial Discount',
        loadingDiscountId: 'commercial_discount',
        view: false,
        edit: false,
      },
      {
        loadingDiscountName: 'State Loading',
        loadingDiscountId: 'state_loading',
        view: false,
        edit: false,
      },
      {
        loadingDiscountName: 'De-trending',
        loadingDiscountId: 'detrending',
        view: false,
        edit: false,
      },
      {
        loadingDiscountName: 'GP Downscaling',
        loadingDiscountId: 'gpDownscaling',
        view: false,
        edit: false,
      },
      {
        loadingDiscountName: 'Catastrophe Loading',
        loadingDiscountId: 'catastropheLoading',
        view: false,
        edit: false,
      },
      {
        loadingDiscountName: 'Client Rating',
        loadingDiscountId: 'client_rating',
        view: false,
        edit: false,
      },
      {
        loadingDiscountName: 'Prevented Sowing Loading',
        loadingDiscountId: 'prevented_sowing_loading',
        view: false,
        edit: false,
      },
    ]
  }

  getParams() {
    this.activatedroute.data.subscribe(data => {
      if (data && data.mode) {
        this.mode = data.mode;
        if (this.mode == 'create') {
          this.fetchConfigData();
        } else if (this.mode == 'edit') {
          this.activatedroute.params.subscribe(param => {
            this.currentTieUpId = param.id;
            this.fetchUserData();
          })
        }
      }
  })
  }

  fetchConfigData() {
    this.loading++
    this.apiData.loadingApi.getloadingDiscount((res: any) => {
      if (res && res.status == 1) {
        this.configData = res.data;
        this.validateAllData()
      }
      this.loading--;
    }, err => {
      this.loading--;

    }, this.mode == 'create' ? 'allclient': this.tieUpId)
  }

  fetchUserData() {
    this.isDeleteEnable = false;
    this.loading++;
    this.apiData.fetchTieUpsId(this.currentTieUpId).then((res: any) => {
      if (res && res.status == 1) {
        this.currentTieUpData = res.data;
        this.selectedCountry = this.currentTieUpData.country;
        this.isDeleteEnable = this.currentTieUpData.status != 'Active' ? true : false;
        this.tieUpname = this.currentTieUpData.name;
        this.tieUpId = this.currentTieUpData.tieupID;
        this.emailId = this.currentTieUpData.emailID;
        this.emailId = this.currentTieUpData.emailID;
        this.status = this.currentTieUpData.status;
        const loadingData = this.currentTieUpData.loadingDiscountPermissions;
        if (Array.isArray(loadingData)) {
          for (let index = 0; index < loadingData.length; index++) {
            const load = loadingData[index];
            const permissionData = this.loadingPermission.find(data => data.loadingDiscountId == load.loadingDiscountId);
            if (permissionData) {
              permissionData.edit = load.edit;
              permissionData.view = load.view;
            }

          }
        }
        this.fetchConfigData();
      } else {
        this.validate.popUpAlert("Invalid Tieup Id", "Error", "error");
        this.router.navigate(['/tie-up'])
      }
      this.loading--;
    }).catch(err => {
      this.validate.popUpAlert("Invalid Tieup Id", "Error", "error");
      this.router.navigate(['/tie-up'])
      this.loading--;
    })
  }

  getCountryList() {
    this.loading++;
    this.apiData.getdropdownList.getCountry((res: any) => {
      if (res && res.status == 1) {
        this.countryData = res.data;
      }
      this.loading--;
    }, err => {
      this.loading--;

    });
  }

  onPerceptionValueChange(uwPerceptionData: any, subSection: any) {
    this.validPerceptionData(uwPerceptionData, subSection);
  }

  onStateLoadingChange(subData: any) {
    if (!subData.finalLoadingPercentage) {
      subData.finalLoadingPercentage = 0;
    }
  }

  onCardSave(data, indx) {
    if (indx == -1) {
      this.permissionSaved = true;
    }
    if (!this.validateCard()) {
      return;
    }
    for (let index = 0; index < this.configData.ldWithSubSections.length; index++) {
      const ldWithSubSections = this.configData.ldWithSubSections[index];
      ldWithSubSections.isCollapsed = true;
      if (indx == index) {
        ldWithSubSections.isDataSaved = true
      }
    }

    if (indx < (this.configData.ldWithSubSections.length -1)) {
      this.configData.ldWithSubSections[indx + 1].isCollapsed = false;
      this.openCard(this.configData.ldWithSubSections[indx + 1].loadingDiscountID)
    } else {
      this.closeCard(this.configData.ldWithSubSections[indx].loadingDiscountID)
    }
  }

  onCardToggle(loadingDiscountID) {
    if (!this.validateCard()) {
      return;
    }
    this.toogleCard(loadingDiscountID)
  }

  onCardEdit(loadingDiscountID) {
    if (!this.validateCard()) {
      return;
    }
    this.openCard(loadingDiscountID)
  }

  onDefaultChange(perceptionData, pindx) {
    for (let index = 0; index < perceptionData.length; index++) {
      if (index != pindx) {
        const element = perceptionData[index];
        element.defaultUWPerception = false
      }
    }
  }

  toogleCard(loadingDiscountID) {
    $(`#${loadingDiscountID}`).collapse("toggle");
  }
  openCard(loadingDiscountID) {
    $(`#${loadingDiscountID}`).collapse("show");
  }
  closeCard(loadingDiscountID) {
    $(`#${loadingDiscountID}`).collapse("hide");
  }

  validateAllData() {
    const ldWithSubSections = this.configData.ldWithSubSections;
    const ldNoSubSections = this.configData.ldNoSubSections;
    if (Array.isArray(ldWithSubSections) && Array.isArray(ldNoSubSections)) {
      for (let index = 0; index < ldWithSubSections.length; index++) {
        const element = ldWithSubSections[index];
        for (
          let subSecIndx = 0;
          subSecIndx < element.subSections.length;
          subSecIndx++
        ) {
          const subSection = element.subSections[subSecIndx];
          this.validPerceptionData(subSection.uwPerceptionData, subSection)
        }
      }
      for (let index = 0; index < ldNoSubSections.length; index++) {
        const element = ldNoSubSections[index];
        // if (
        //   [null, undefined, ""].includes(element.totalFinalLoadingPercentage)
        // ) {
        //   errorMessage.msg = `${element.loadingDiscountName} value should not be empty`;
        //   break;
        // }
      }
    }
  }

  validPerceptionData(uwPerceptionData: any, subSection: any) {
    const errorMessage = {};
    if (Array.isArray(uwPerceptionData)) {
      for (
        let perpIndex = 0;
        perpIndex < uwPerceptionData.length;
        perpIndex++
      ) {
        const perception = uwPerceptionData[perpIndex];
        if (perception.options) {
          if (
            [null, undefined, ""].includes(
              perception.finalLoadingPercentageMin
            )
          ) {
            // errorMessage.eleId = `${subSection.key}_${perception.text}_min`;
            errorMessage[`${subSection.key}_${perception.text}_min`] = "Min value is requried";
          }
          if (
            [null, undefined, ""].includes(
              perception.finalLoadingPercentageMax
            )
          ) {
            // errorMessage.eleId = `${subSection.key}_${perception.text}_max`;
            errorMessage[`${subSection.key}_${perception.text}_max`] = "Max value is requried";
          }
          if (
            [null, undefined, ""].includes(
              perception.finalLoadingPercentage
            )
          ) {
            // errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
            errorMessage[`${subSection.key}_${perception.text}_percentage`] = "Percentage value is requried";
          }
          if (
            +perception.finalLoadingPercentageMin >
            +perception.finalLoadingPercentageMax
          ) {
            // errorMessage.eleId = `${subSection.key}_${perception.text}_min`;
            errorMessage[`${subSection.key}_${perception.text}_min`] = "Min value should be less than Max value";
          }
          if (
            !perception.options.find(
              (opt) =>
                +opt.value == +perception.finalLoadingPercentageMin
            )
          ) {
            // errorMessage.eleId = `${subSection.key}_${perception.text}_min`;
            errorMessage[`${subSection.key}_${perception.text}_min`] =
              "Min value should be selected from given options only";
          }
          if (
            !perception.options.find(
              (opt) =>
                +opt.value == +perception.finalLoadingPercentageMax
            )
          ) {
            // errorMessage.eleId = `${subSection.key}_${perception.text}_max`;
            errorMessage[ `${subSection.key}_${perception.text}_max`] =
              "Max value should be selected from given options only";
          }
          if ( (![null, undefined, ""].includes(perception.finalLoadingPercentage)) &&
           (![null, undefined, ""].includes(perception.finalLoadingPercentageMin)) &&
            (![null, undefined, ""].includes(perception.finalLoadingPercentageMax)) &&
            (perception.finalLoadingPercentage <
              perception.finalLoadingPercentageMin ||
            perception.finalLoadingPercentage >
              perception.finalLoadingPercentageMax)
          ) {
            // errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
            errorMessage[`${subSection.key}_${perception.text}_percentage`] =
              "Final loading value should be between min and max value";
          }
        } else {
          if ([null, undefined, ""].includes(perception.value)) {
            errorMessage[`${subSection.key}_${perception.text}_percentage`] = `${subSection.title}'s ${perception.text} value is requried`;
          } else if (perception.value < -100 || perception.value > 200) {
            errorMessage[`${subSection.key}_${perception.text}_percentage`] = `${subSection.title}'s ${perception.text} value should be between -100 to 200`;
          }
        }
        perception.errorMessage = errorMessage;
      }
    }
  }

  validateCard() {
    const collapseId = $(".collapse.show").prop("id");
    if (collapseId) {
      let errorMessage: any = {msg: null, eleId: null};
      this.configData;
      const ldWithSubSections = this.configData.ldWithSubSections;
      if (Array.isArray(ldWithSubSections)) {
        for (let index = 0; index < ldWithSubSections.length; index++) {
          const element = ldWithSubSections[index];
          if (element.loadingDiscountID == collapseId) {
            if (errorMessage.msg) {
              break;
            }
            for (
              let subSecIndx = 0;
              subSecIndx < element.subSections.length;
              subSecIndx++
            ) {
              const subSection = element.subSections[subSecIndx];
              if (subSection.uwPerceptionData && subSection.uwPerceptionData.length) {
                for (
                  let perpIndex = 0;
                  perpIndex < subSection.uwPerceptionData.length;
                  perpIndex++
                ) {
                  if (errorMessage.msg) {
                    break;
                  }
                  const perception = subSection.uwPerceptionData[perpIndex];
                  if (perception.options) {
                    if (
                      [null, undefined, ""].includes(
                        perception.finalLoadingPercentageMin
                      )
                    ) {
                      errorMessage.eleId = `${subSection.key}_${perception.text}_min`;
                      errorMessage.msg = "Min Value should not be empty";
                      break;
                    }
                    if (
                      [null, undefined, ""].includes(
                        perception.finalLoadingPercentageMax
                      )
                    ) {
                      errorMessage.eleId = `${subSection.key}_${perception.text}_max`;
                      errorMessage.msg = "Max Value should not be empty";
                      break;
                    }
                    if (
                      [null, undefined, ""].includes(
                        perception.finalLoadingPercentage
                      )
                    ) {
                      errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
                      errorMessage.msg = "Percentage Value should not be empty";
                      break;
                    } else {
                      console.log(perception.text);
                    }
                    if (
                      +perception.finalLoadingPercentageMin >
                      +perception.finalLoadingPercentageMax
                    ) {
                      errorMessage.eleId = `${subSection.key}_${perception.text}_min`;
                      errorMessage.msg = "Min value should be less than Max value";
                      break;
                    }
                    if (
                      !perception.options.find(
                        (opt) =>
                          +opt.value == +perception.finalLoadingPercentageMin
                      )
                    ) {
                      errorMessage.eleId = `${subSection.key}_${perception.text}_min`;
                      errorMessage.msg =
                        "Min value should be selected from given options only";
                      break;
                    }
                    if (
                      !perception.options.find(
                        (opt) =>
                          +opt.value == +perception.finalLoadingPercentageMax
                      )
                    ) {
                      errorMessage.eleId = `${subSection.key}_${perception.text}_max`;
                      errorMessage.msg =
                        "Max value should be selected from given options only";
                      break;
                    }
                    if (
                      perception.finalLoadingPercentage <
                        perception.finalLoadingPercentageMin ||
                      perception.finalLoadingPercentage >
                        perception.finalLoadingPercentageMax
                    ) {
                      errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
                      errorMessage.msg =
                        "Final loading value should be between min and max value";
                      break;
                    }
                  } else {
                    if ([null, undefined, ""].includes(perception.value)) {
                      errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
                      errorMessage.msg = `${subSection.title}'s ${perception.text} value is empty`;
                      break;
                    } else if (perception.value < -50 || perception.value > 200) {
                      errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
                      errorMessage.msg = `${subSection.title}'s ${perception.text} value should be between -50 to 200`;
                      break;
                    }
                  }
                }
              } else {
                if (errorMessage.msg) {
                  break;
                }
                if (
                  [null, undefined, ""].includes(subSection.finalLoadingPercentage)
                ) {
                  errorMessage.collapseId = element.loadingDiscountID;
                  errorMessage.eleId = `${subSection.key}`;
                  errorMessage.msg = `${subSection.title} value is empty`;
                  break;
                } else {
                  if (subSection.finalLoadingPercentage < -50 || subSection.finalLoadingPercentage > 200) {
                    errorMessage.collapseId = element.loadingDiscountID;
                    errorMessage.eleId = `${subSection.key}`;
                    errorMessage.msg = `${subSection.title} value should be between -50 to 200`;
                  }
                }
              }
            }
          }
        }
      } else {
        errorMessage.msg = "Invalid Config Data";
      }

      if (errorMessage.msg) {
        const swal: any = Swal({
          title: "Required",
          text: errorMessage.msg,
          type: "warning",
          confirmButtonColor: "#175788",
        })
        swal.finally(() => {
          if (errorMessage.eleId) {
            const element: any = document.querySelector('#'+errorMessage.eleId);
            if (element) {
              setTimeout(() => {
                element.scrollIntoView();
                element.focus();
              }, 500)
            }
          }
        });
        return false;
      }
    }
    return true;
  }

  validateData() {
    let errorMessage: any = { msg: null, eleId: null, collapseId: null };
    const ldWithSubSections = this.configData.ldWithSubSections;
    const ldNoSubSections = this.configData.ldNoSubSections;
//     this.selectedCountry
// this.tieUpname
// this.tieUpId
// this.emailId
// this.status
    if (!this.selectedCountry) {
      errorMessage.msg = "Country is requried";
    } else if (!this.tieUpname) {
      errorMessage.msg = "Tie-up Name is requried";
    } else if (!this.tieUpId) {
      errorMessage.msg = "Tie-up ID is requried";
    } else if (!this.emailId) {
      errorMessage.msg = "Email ID is requried";
    } else if (!this.status) {
      errorMessage.msg = "Status is requried";
    }

    if (!errorMessage.msg) {
      if (Array.isArray(ldWithSubSections) && Array.isArray(ldNoSubSections)) {
        for (let index = 0; index < ldWithSubSections.length; index++) {
          const element = ldWithSubSections[index];
          if (errorMessage.msg) {
            break;
          }
          for (
            let subSecIndx = 0;
            subSecIndx < element.subSections.length;
            subSecIndx++
          ) {
            const subSection = element.subSections[subSecIndx];
            if (subSection.uwPerceptionData) {
              for (
                let perpIndex = 0;
                perpIndex < subSection.uwPerceptionData.length;
                perpIndex++
              ) {
                if (errorMessage.msg) {
                  break;
                }
                const perception = subSection.uwPerceptionData[perpIndex];
                if (perception.options) {
                  if (
                    [null, undefined, ""].includes(
                      perception.finalLoadingPercentageMin
                    )
                  ) {
                    errorMessage.collapseId = element.loadingDiscountID;
                    errorMessage.eleId = `${subSection.key}_${perception.text}_min`;
                    errorMessage.msg = "Min Value should not be empty";
                    break;
                  }
                  if (
                    [null, undefined, ""].includes(
                      perception.finalLoadingPercentageMax
                    )
                  ) {
                    errorMessage.collapseId = element.loadingDiscountID;
                    errorMessage.eleId = `${subSection.key}_${perception.text}_max`;
                    errorMessage.msg = "Max Value should not be empty";
                    break;
                  }
                  if (
                    [null, undefined, ""].includes(
                      perception.finalLoadingPercentage
                    )
                  ) {
                    errorMessage.collapseId = element.loadingDiscountID;
                    errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
                    errorMessage.msg = "Percentage Value should not be empty";
                    break;
                  }
                  if (
                    +perception.finalLoadingPercentageMin >
                    +perception.finalLoadingPercentageMax
                  ) {
                    errorMessage.collapseId = element.loadingDiscountID;
                    errorMessage.eleId = `${subSection.key}_${perception.text}_min`;
                    errorMessage.msg = "Min value should be less than Max value";
                    break;
                  }
                  if (
                    !perception.options.find(
                      (opt) => +opt.value == +perception.finalLoadingPercentageMin
                    )
                  ) {
                    errorMessage.collapseId = element.loadingDiscountID;
                    errorMessage.eleId = `${subSection.key}_${perception.text}_min`;
                    errorMessage.msg =
                      "Min value should be selected from given options only";
                    break;
                  }
                  if (
                    !perception.options.find(
                      (opt) => +opt.value == +perception.finalLoadingPercentageMax
                    )
                  ) {
                    errorMessage.collapseId = element.loadingDiscountID;
                    errorMessage.eleId = `${subSection.key}_${perception.text}_max`;
                    errorMessage.msg =
                      "Max value should be selected from given options only";
                    break;
                  }
                  if (
                    perception.finalLoadingPercentage <
                      perception.finalLoadingPercentageMin ||
                    perception.finalLoadingPercentage >
                      perception.finalLoadingPercentageMax
                  ) {
                    errorMessage.collapseId = element.loadingDiscountID;
                    errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
                    errorMessage.msg =
                      "Final loading value should be between min and max value";
                    break;
                  }
                } else {
                  if ([null, undefined, ""].includes(perception.value)) {
                    errorMessage.collapseId = element.loadingDiscountID;
                    errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
                    errorMessage.msg = `${subSection.title}'s ${perception.text} value is empty`;
                    break;
                  } else if (perception.value < -100 || perception.value > 200) {
                    errorMessage.collapseId = element.loadingDiscountID;
                    errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
                    errorMessage.msg = `${subSection.title}'s ${perception.text} value should be between -100 to 200`;
                    break;
                  }
                }
              }
            } else {
              if (errorMessage.msg) {
                break;
              }
              if (
                [null, undefined, ""].includes(subSection.finalLoadingPercentage)
              ) {
                errorMessage.collapseId = element.loadingDiscountID;
                errorMessage.eleId = `${subSection.key}`;
                errorMessage.msg = `${subSection.title} value is empty`;
                break;
              } else {
                if (subSection.finalLoadingPercentage < -50 || subSection.finalLoadingPercentage > 200) {
                  errorMessage.collapseId = element.loadingDiscountID;
                  errorMessage.eleId = `${subSection.key}`;
                  errorMessage.msg = `${subSection.title} value should  be between -50 to 200`;
                }
              }
            }
          }
        }
        for (let index = 0; index < ldNoSubSections.length; index++) {
          const element = ldNoSubSections[index];
          if (errorMessage.msg) {
            break;
          }
          if (
            [null, undefined, ""].includes(element.totalFinalLoadingPercentage)
          ) {
            errorMessage.eleId = element.loadingDiscountID;
            errorMessage.msg = `${element.loadingDiscountName} value should not be empty`;
            break;
          } else {
            if (element.loadingDiscountID !== 'commercial_discount' && (element.totalFinalLoadingPercentage < -100 || element.totalFinalLoadingPercentage > 200)) {
              errorMessage.eleId = element.loadingDiscountID;
              errorMessage.msg = `${element.loadingDiscountName} value should  be between -100 to 200`;
              break;
            }
            if (element.loadingDiscountID === 'commercial_discount' && (element.totalFinalLoadingPercentage < -50 || element.totalFinalLoadingPercentage > 200)) {
              errorMessage.eleId = element.loadingDiscountID;
              errorMessage.msg = `${element.loadingDiscountName} value should  be between -50 to 200`;
              break;
            }
          }
        }
      } else {
        errorMessage.msg = "Invalid Config Data";
      }
    }

    if (errorMessage.msg) {
      const swal: any = Swal({
        title: "Required",
        text: errorMessage.msg,
        type: "warning",
        confirmButtonColor: "#175788",
      });
      swal.finally(() => {
        if (errorMessage.collapseId) {
          this.openCard(errorMessage.collapseId);
        }
        if (errorMessage.eleId) {
          const element: any = document.querySelector("#" + errorMessage.eleId);
          if (element) {
            setTimeout(() => {
              element.scrollIntoView();
              element.focus();
            }, 500);
          }
        }
      });
      return false;
    }
    return true;
  }

  onSubmit(fromData) {
    if (!this.validateData()) {
      return false;
    }


    const loadingDiscountPermissions = JSON.parse(JSON.stringify(this.loadingPermission));
    for (let index = 0; index < loadingDiscountPermissions.length; index++) {
      const element = loadingDiscountPermissions[index];
      delete element.loadingDiscountName
    }

    const tieUpDetails = {id: undefined, country: this.selectedCountry, name: this.tieUpname, tieupID: this.tieUpId, emailID: this.emailId, loadingDiscountPermissions, status: this.status}
    if (this.currentTieUpId) {
      tieUpDetails.id = this.currentTieUpId;
    }
    const request = this.apiData.getParam(tieUpDetails, this.mode == 'create' ? 'create-tieup' : 'edit-tieup');
    const promese = this.mode == 'create' ? this.apiData.tieups(request) : this.apiData.updatetieups(this.currentTieUpId, request);
    this.loading++
    promese.then((res: any) => {
      if (res && res.status == 1) {
        this.validate.popUpAlert(res.message, "Success", "success");
        this.updateClientDiscount(res.tieUpID)
      } else {
        this.validate.popUpAlert(res.message, "Error", "error");
      }
      this.loading--;
    }).catch(err => {
      console.error(err);
      this.validate.popUpAlert("Unable to Create Tie Up", "Error", "error");
      this.loading--;

    })

  }

  updateClientDiscount(tieUpID) {
    const configData = JSON.parse(JSON.stringify(this.configData));
    const ldWithSubSections = [];
    const ldNoSubSections = [];
    if (configData) {
      const reqldWithSubSections = configData.ldWithSubSections;
      const reqldNoSubSections = configData.ldNoSubSections;
      if (
        Array.isArray(reqldWithSubSections) &&
        Array.isArray(ldNoSubSections)
      ) {
        for (let index = 0; index < reqldWithSubSections.length; index++) {
          const element = reqldWithSubSections[index];
          const idWithSubSecObj = {
            loadingDiscountID: element.loadingDiscountID,
            loadingDiscountName: element.loadingDiscountName,
            isMandatory: element.isMandatory,
            isActive: element.isActive,
            ldWeightage: element.ldWeightage,
            subSections: [],
            totalFinalLoadingPercentage: element.totalFinalLoadingPercentage,
            sectionTotalLoadingDiscount: element.sectionTotalLoadingDiscount,
            isEnable: element.isEnable,
            seqno: element.seqno,
          };
          for (
            let subSecIndx = 0;
            subSecIndx < element.subSections.length;
            subSecIndx++
          ) {
            const subSection = element.subSections[subSecIndx];
            if (subSection.uwPerceptionData) {
              for (
                let perpIndex = 0;
                perpIndex < subSection.uwPerceptionData.length;
                perpIndex++
              ) {
                const perception = subSection.uwPerceptionData[perpIndex];
                const reqSubSec = {
                  key: subSection.key,
                  weightage: subSection.weightage,
                  title: subSection.title,
                  seqno: subSection.seqno,
                  uwPerception: perception.text,
                  finalLoadingPercentage:
                    perception.finalLoadingPercentage || perception.value,
                  finalLoadingPercentageMin:
                    perception.finalLoadingPercentageMin,
                  finalLoadingPercentageMax:
                    perception.finalLoadingPercentageMax,
                  defaultUWPerception: perception.defaultUWPerception,
                  finalLoadingWeightageApplied:
                    subSection.finalLoadingWeightageApplied,
                };
                idWithSubSecObj.subSections.push(reqSubSec);
              }
            } else {
              const reqSubSec = {
                key: subSection.key,
                weightage: subSection.weightage,
                title: subSection.title,
                seqno: subSection.seqno,
                finalLoadingPercentage: subSection.finalLoadingPercentage,
                finalLoadingWeightageApplied:
                  subSection.finalLoadingWeightageApplied,
              };
              idWithSubSecObj.subSections.push(reqSubSec);
            }
          }
          ldWithSubSections.push(idWithSubSecObj);
        }
        for (let index = 0; index < reqldNoSubSections.length; index++) {
          const element = reqldNoSubSections[index];
          const idNoSubSecObj = {
            loadingDiscountID: element.loadingDiscountID,
            loadingDiscountName: element.loadingDiscountName,
            isMandatory: element.isMandatory,
            isActive: element.isActive,
            ldWeightage: element.ldWeightage,
            subSections: [],
            totalFinalLoadingPercentage: element.totalFinalLoadingPercentage,
            sectionTotalLoadingDiscount: element.sectionTotalLoadingDiscount,
            isEnable: element.isEnable,
            seqno: element.seqno,
          };
          ldNoSubSections.push(idNoSubSecObj);
        }
      }
    }
    let request = {
      tieupID: this.tieUpId,
      tenantId: null,
      updatedBy: null,
      id: null,
      ldWithSubSections,
      ldNoSubSections,
    }
    request = this.apiData.getParam(request, 'create-tieup');
    this.loading++
    this.apiData.configureDefaultLoadingDiscount(request).then((res: any) => {
      if (res && res.status == 1) {
        this.validate.popUpAlert(res.message, "Success", "success");
        if (this.currentTieUpId) {
          Swal({
            title: "Success",
            type: "success",
            confirmButtonText: "Continue",
            cancelButtonText: "Back",
            showConfirmButton: true,
            showCancelButton: true,
          }).then((result: any) => {
            if (result && result.value) {
              if (this.router.url.includes('/update-tie-up')) {
                this.initLoading();
              } else {
                this.router.navigate(['/update-tie-up',this.tieUpId])
              }
            } else {
              this.router.navigate(['/tie-up'])
            }
          });
        } else {
          this.router.navigate(['/tie-up'])
        }

      } else {
        this.validate.popUpAlert(res.message, "Error", "error");
      }
      this.loading--;
    }).catch(err => {
      this.validate.popUpAlert("Unable to Update Global Discount", "Error", "error");
      this.loading--;

    })
  }

  onPermissionChange(event: any, loadingInfo: any, type: any) {
    if (type === 'edit' && event) {
      loadingInfo.view = true;
      return;
    }
    if (type === 'view' && !event) {
      loadingInfo.edit = false;
      return
    }
  }

  onDeleteClick() {
    Swal({
      title: "Do you want to delete Tie-up?",
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result: any) => {
      if (result && result.value) {
        this.loading++;
    this.apiData.deleteTieup(this.currentTieUpId).then((res: any) => {
      if (res && res.status == 1) {
        this.router.navigate(['/tie-up'])
        this.validate.popUpAlert("Tie-up deleted successfully", "", "success");
      } else {
        this.validate.popUpAlert("Unable to delete Tie-up", "Error", "error");
      }
      this.loading--;
    }).catch(err => {
      this.validate.popUpAlert("Unable to delete Tie-up", "Error", "error");
      this.loading--;
    })
      }
    });
  }

}

import { Injectable } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { Observable, Subject } from "rxjs";

@Injectable()
export class AlertService {
  private subject = new Subject<any>();
  private keepAfterNavigationChange = false;
  first: boolean = false;
  /**** user cration popup *****/
  public tieupuserCreate(page) {
    this.router.navigate([page, "create"]);
  }
  /***** user creation popup end****/

  /******Rate Generation stepper**/
  loadingOutput: boolean = false;
  outputList: any = {};
  stepper: any = [{
    id: 1,
    current: false,
    complete: false
  }, {
    id: 2,
    current: false,
    complete: false
  }, {
    id: 3,
    current: false,
    complete: false
  }];
  /**** Rate generation End ***/
  public ErrorText: any = {
    servererror: 'Server Not Found',
    incorrectfile: 'Incorrect File Type',
    unauthorized: "Unauthorized Access Denied",
    uncaptured: 'Something Went Wrong',
    data: [
      {
        getmessage: "requestratelarge_rpt",
        returnmessage: "Background service running, please try few minutes later"
      },
      {
        getmessage: "requestratelarge",
        returnmessage: "Request not completed, please try again"
      },
      {
        getmessage: "ExistTieupID",
        returnmessage: "TieupId already exists"
      },
      {
        getmessage: "validEmailID",
        returnmessage: "Invalid Email Id"
      },
      {
        getmessage: "NOT_FOUND",
        returnmessage: "Unknown user name or bad password"
      },
      {
        getmessage: "InvalidLogin",
        returnmessage: "Unknown user name or bad password"
      },
      {
        getmessage: "UserDeactivated",
        returnmessage: "User De-activated"
      },
      {
        getmessage: "ExistUser",
        returnmessage: "User already exists"
      },
      {
        getmessage: "UserLimitExceed",
        returnmessage: "User Limit Exceeds for the Tie Up"
      },
      {
        getmessage: "validId",
        returnmessage: "Invalid ID"
      }, {
        getmessage: "validActivityType",
        returnmessage: "Invalid Activity Type"
      },
      {
        getmessage: "ExistEmailID",
        returnmessage: "Tie-Up for the Email ID already exists"
      }, {
        getmessage: "ProvidePassword",
        returnmessage: "Invalid Password"
      }, {
        getmessage: "ProvideRateGenerationID",
        returnmessage: "Invalid Rategeneration ID"
      }, {
        getmessage: "ProvideUserType",
        returnmessage: "Invalid user type"
      }, {
        getmessage: "ProvideYear",
        returnmessage: "Year is empty"
      }, {
        getmessage: "selectAdminOption",
        returnmessage: "Admin option is empty"
      }, {
        getmessage: "ProvideVerifyCode",
        returnmessage: "Invalid verification code"
      }, {
        getmessage: "ProvideReportID",
        returnmessage: "Invalid Report ID"
      }, {
        getmessage: "ProvideReportName",
        returnmessage: "Invalid Report Name"
      }, {
        getmessage: "ProvideState",
        returnmessage: "Invalid State"
      }, {
        getmessage: "selectState",
        returnmessage: "Invalid State"
      }, {
        getmessage: "invalidId",
        returnmessage: "Invalid Report ID"
      }, {
        getmessage: "selectTieupID",
        returnmessage: "Invalid Tie-Up ID"
      }, {
        getmessage: "selectUserType",
        returnmessage: "Invalid User Type"
      }, {
        getmessage: "selectLoginUser",
        returnmessage: "Invalid login user"
      }, {
        getmessage: "selectName",
        returnmessage: "Invalid Name"
      }, {
        getmessage: "selectParameterID",
        returnmessage: "Invalid Parameter ID"
      }, {
        getmessage: "selectCountry",
        returnmessage: "Invalid Country"
      }, {
        getmessage: "selectEmailID",
        returnmessage: "Invalid Email-ID"
      }, {
        getmessage: "selectParamName",
        returnmessage: "Invalid Parameter Name"
      }, {
        getmessage: "selectEffectiveDate",
        returnmessage: "Invalid Effective Date"
      }, {
        getmessage: "ProvideParamValue",
        returnmessage: "Invalid Input Value"
      }, {
        getmessage: "InvalidEffectiveDate",
        returnmessage: "Invalid Effective Date"
      }, {
        getmessage: "EXIST",
        returnmessage: "Already Exists"
      }, {
        getmessage: "ProvideCountry",
        returnmessage: "Invalid Country"
      }, {
        getmessage: "ProvideTieupID",
        returnmessage: "Invalid Tie-Up ID"
      }, {
        getmessage: "ProvideLoadingDiscountID",
        returnmessage: "Invalid Loading/Discounting ID"
      }, {
        getmessage: "ProvideLoadingDiscountName",
        returnmessage: "Invalid Loading/Discounting name"
      }, {
        getmessage: "ProvideEffectiveDate",
        returnmessage: "Invalid Effective date"
      }, {
        getmessage: "ProvideIsActive",
        returnmessage: "Invalid active status"
      }, {
        getmessage: "ProvideIsMandatory",
        returnmessage: "Invalid mandatory status"
      }, {
        getmessage: "InvalidId",
        returnmessage: "Invalid ID"
      }, {
        getmessage: "EmptyInputCombination",
        returnmessage: "Invalid Input Combinations"
      }, {
        getmessage: "EmptyOutputCombination",
        returnmessage: "Invalid Output Combinations"
      }, {
        getmessage: "EmptyLoadingDiscounts",
        returnmessage: "Invalid Loading/Discounting Percentage"
      }, {
        getmessage: "emptycsv",
        returnmessage: "Please upload a non-empty csv file"
      }, {
        getmessage: "deactivate_subusers",
        returnmessage: "In order to reduce the user count, please de-activate tie-up sub user(s) before reducing the user count"
      }, {
        getmessage: "deactivate_adminusers",
        returnmessage: "In order to reduce the user count, please de-activate tie-up admin user(s) before reducing the user count"
      }, {
        getmessage: "enterEmailID",
        returnmessage: "Email-Id is empty"
      }, {
        getmessage: "enterUserName",
        returnmessage: "Username is empty"
      }, {
        getmessage: "ExistParameter",
        returnmessage: "Parameter already exists"
      }, {
        getmessage: "ProvideCreatedBy",
        returnmessage: "Invalid CreatedBy-ID"
      }, {
        getmessage: "ProvideLDComment",
        returnmessage: "Invalid Loading/Discount Comment"
      }, {
        getmessage: "ProvideEmail",
        returnmessage: "Invalid Email-ID"
      }, {
        getmessage: "provideId",
        returnmessage: "Invalid ID"
      },{
        getmessage: "provideFromToDate",
        returnmessage: "Provide both From Date and To Date"
      }, {
        getmessage: "futuredate_notallowed",
        returnmessage: "No future dates allowed"
      }, {
        getmessage: "InvalidToDate",
        returnmessage: "To Date should not less than From Date"
      }, {
        getmessage: "NO_DATA_FOUND",
        returnmessage: "Data Not Found"
      }, {
        getmessage: "Errorcode:504",
        returnmessage: "Request timed out. Please try again later."
      },
      {
        getmessage: "weightagenot100",
        returnmessage: "Loading/Discount factors weightage sum should add up to 100%"
      },
      {
        getmessage: "selectldkey",
        returnmessage: "Provide Loading/Discount"
      },
      {
        getmessage: "selectsubsectionkey",
        returnmessage: "Provide Loading/Discount Sub Section"
      },
      {
        getmessage: "invalidfile",
        returnmessage: "Invalid file data, please upload valid data"
      }
    ],
    checkError: msg => {
      var message = this.ErrorText.data.filter(obj => {
        return obj.getmessage == msg;
      });
      if (message.length == 0)
        return this.ErrorText.uncaptured;
      else
        return message[0].returnmessage;
    }
  };
  patchDate: any = {
    convertedDate: {},
    changeToLocal: (data) => {
      let date = new Date(data);
      this.patchDate.convertedDate["year"] = date.getFullYear();
      this.patchDate.convertedDate["month"] = date.getMonth() + 1;
      this.patchDate.convertedDate["day"] = date.getDate();
    },
    getPatchedDate: () => {
      return this.patchDate.convertedDate;
    }
  };

  firstTimeLogin(resultData, page) {
    if (page == "login") {
      localStorage.setItem(
        "userFunction",
        JSON.stringify(resultData)
      );

      if (resultData.lastLoginDate == null && resultData.changepassword_id != "") {
        this.first = true;
        return true;
      }
      return false;
    }
  }

  /***Add and remove @ local storage***/
  public changePasswordId: any = {
    get: () => {
      return localStorage.getItem('ChangePasswordID');
    },
    set: (data) => {
      localStorage.setItem(
        "ChangePasswordID", data
      );
    }
  };
  public enteredMailtoForgetScreen: any = {
    get: () => {
      return localStorage.getItem('enteredMail');
    },
    set: (data) => {
      localStorage.setItem(
        "enteredMail",
        JSON.stringify(data)
      );
    },
  };
  public rateGenerationID: any = {
    get: () => {
      return localStorage.getItem('rateGenerationID');
    },
    set: (data) => {
      localStorage.setItem(
        "rateGenerationID", data
      );
    },
    remove: () => {
      localStorage.setItem("rateGenerationID", "")
    }
  }
  public mrCode: any = {
    get: () => {
      return localStorage.getItem('mrcode');
    },
    set: (data) => {
      localStorage.setItem(
        "mrcode", data
      );
    },
    remove: () => {
      localStorage.setItem("mrcode", "")
    }
  }
  public combinationId: any = {
    get: () => {
      return localStorage.getItem('combinationId');
    },
    set: (data) => {
      localStorage.setItem(
        "combinationId", data
      );
    },
    remove: () => {
      localStorage.setItem("combinationId", "")
    }
  }
  public tokenId: any = {
    get: () => {
      return localStorage.getItem('userToken');
    },
    set: (data) => {
      localStorage.setItem(
        "userToken", data
      );
    }, remove: () => {
      localStorage.setItem("userToken", "")
    }
  }
  public userId: any = {
    get: () => {
      return localStorage.getItem('userId');
    },
    set: (data) => {
      localStorage.setItem(
        "userId", data
      );
    }
  }
  public downloadInUsertracking: any = {
    get: (data) => {
      if (data == "id")
        return localStorage.getItem('trackingDescriptionid');
      else
        return localStorage.getItem('trackingActivityType');
    },
    set: (descriptionid, activityType) => {
      localStorage.setItem(
        "trackingDescriptionid", descriptionid.toString()
      );
      localStorage.setItem(
        "trackingActivityType", activityType.toString()
      );
    }, remove: () => {
      localStorage.removeItem("trackingDescriptionid");
      localStorage.removeItem("trackingActivityType");
    }
  }
  public isFinalRate: any = {
    get: () => {
      return localStorage.getItem('isFinalRate');
    },
    set: (data) => {
      localStorage.setItem(
        "isFinalRate", data ? '1' : '0'
      );
    },
    remove: () => {
      localStorage.setItem("isFinalRate", "")
    }
  }
  getUserDetails(value) {
    let user = {};
    let postValue = "";
    if (localStorage.getItem('userFunction')) {
      user = JSON.parse(localStorage.getItem('userFunction'));
      switch (value) {
        case "getTenant": {
          postValue = user['tenantId'];
          break;
        }
        case "getUsertype": {
          postValue = user['userType'];
          break;
        }
        case "getchangepasswordID": {
          postValue = user['changepassword_id'];
          break;
        }
        case "getuserEmailID": {
          postValue = user['userEmailID'];
          break;
        }
        case "getid": {
          postValue = user['id'];
          break;
        }
        case "getUserName": {
          postValue = user['firstName'];
          break;
        }
        case "getCountry": {
          postValue = user['country'];
          break;
        }
        case "getTieup": {
          postValue = user['tieupID'];
          break;
        }
        case "getSessiontime": {
          postValue = user['expires_in'];
          break;
        }
        case "permissions": {
          postValue = user['loadingPermissions'];
          break;
        }
      }
      return postValue;
    } else
      return;
  }
  public clearLocalstorage() {
    localStorage.clear();
    localStorage.removeItem('userToken');
    localStorage.removeItem('Timer');
  }
  public createLocal(key, data) {
    localStorage.setItem(key, data.toString());
  }
  public deleteLocal(key) {
    localStorage.removeItem(key);
  }
  public getLocal(key) {
    let a =localStorage.getItem(key);
    return localStorage.getItem(key);
  }
  /***Rate Generation****/
  changeStepper(id) {
    for (let i = 0; i < this.stepper.length; i++) {
      this.stepper[i].current = false;
      this.stepper[i].complete = false;
      if (this.stepper[i].id == id) {
        this.stepper[i].current = true;
      }
      if (this.stepper[i].id < id) {
        this.stepper[i].complete = true;
      }
    }
  }
  /*********Page Navigation*************/
  constructor(private router: Router) { }
  navigateTo(page, param?) {
    if (param) {
      this.router.navigate([page,param]);
      return
    }
    this.router.navigate([page]);
  }
}

import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpClient
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ApiService } from './services/apiservices.service';
import { MandatoryfieldService } from './common/Validators/mandatoryfield.service';
import { Http } from '@angular/http';
import { Router } from '@angular/router';

export class HttpErrorInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errMsg = '';
          // if(error instanceof HttpErrorResponse){
          //   alert("serverside");
          // }
          if (!this.isUndefined(error.error)) {
            if (!this.isUndefined(error.error.message))
              errMsg = `${error.error.message}`;
            else
              errMsg = `Error Code: ${error.status},  Message: ${error.message}`;
          }
          else {
            if (error.status == 504) {
              errMsg = `Errorcode:504`;
            } else {
              errMsg = `Error Code: ${error.status},  Message: ${error.message}`;
            }
          }
          return throwError(errMsg);
        })
      )
  }
  isUndefined(value) {
    if (value == undefined || value == "" || value == null || value == NaN) {
      return true;
    } else {
      return false;
    }
  }
}

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  host: string = "https://mr-reva-fe-app-dev.azurewebsites.net/"; //Local
  path: string = "api/";
  url: string = this.host + this.path;
  tenentId: string;
  createdBy: string;
  constructor(private http: HttpClient, private injector: Injector) { }
  handleError(error) {
    //let router = this.injector.get(Router);
    //console.log(error + "location:::" + window.location.pathname);    
    console.log(error);
    if(error.message!='Collapse is transitioning' && error.message.indexOf('ExpressionChangedAfterItHasBeenCheckedError')==-1){
    let user = JSON.parse(localStorage.getItem('userFunction'));
    this.tenentId = (this.isUndefined(user)) ? "root" : user['tenantId'];
    this.createdBy = (this.isUndefined(user)) ? "" : user['getid'];
    this.errorhanlingApi.generateRequestData(error.message, window.location.pathname);
    this.errorhanlingApi.sendError(res => {
    }, err => {
    });
  }
    return true;
  }
  /******************************
   * Global Error handling
   *****************************/
  public errorhanlingApi: any = {
    url: this.url,
    data: {},
    generateRequestData: (error, page) => {
      this.errorhanlingApi.data = {
        "logApplication": "Client",
        "logModule": page, //controller name or screen name
        "logDesc": error, // exception error message
        "tenantId": this.tenentId,//by default root or tenantId
        "createdBy": this.createdBy
      }
    },
    sendError: (success, err) => {
      this.http.post(this.errorhanlingApi.url + "ErrorLog", this.errorhanlingApi.data).subscribe(
        response => {
          if (typeof success === "function") {
            success(response);
          }
        },
        error => {
          if (typeof err === "function") {
            err(error);
          }
        }
      );
    }
  }
  isUndefined(value) {
    if (value == undefined || value == "" || value == null || value == NaN) {
      return true;
    } else {
      return false;
    }
  }
}

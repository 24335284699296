import { Directive, ElementRef, HostListener, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[appAlphaAndSpaceOnly]'
})
export class AlphaAndSpaceOnlyDirective {

  //Added By Aravind: Allow Alphabets and space and ' only
  private regex: RegExp = new RegExp(/^[a-zA-Z. ']*$/);
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Delete', 'Arrows','space'];

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    let pos = this.el.nativeElement.selectionEnd;
    let next: string = current;
    // restrict start with space and allow single space only between the words
    if ((pos == 0 && event.key == " ") || (current[pos - 1] == " " && current[pos - 1] == event.key)) {
      event.preventDefault();
    } else {
      next = current.concat(event.key);
      if (next && !String(next).match(this.regex)) {
        event.preventDefault();
      }
    }

  }
}
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FormBuilder, FormArray, Validators, FormControl, FormGroup } from '@angular/forms';
import { takeUntil, take, map, first } from 'rxjs/operators';
import { Router } from "@angular/router";
import * as CryptoJS from 'crypto-js';
import { AlertService } from "./alert.service";
import { ApiService } from './apiservices.service';
import { MandatoryfieldService } from '../common/Validators/mandatoryfield.service';
import { CommonfunctionsService } from './commonfunctions.service';
import { environment } from '../../environments/environment';
@Injectable()
export class AuthenticationService {
  host: string;
  path: string;
  url: string;

  reqHeader: any;
  public timeOut: any = null;
  userID: any;
  forget_password: boolean = false;
  new_password: boolean = false;
  showModal: boolean = false;
  changePwdReq: boolean = false;
  loginForm: FormGroup;


  constructor(private http: HttpClient, private router: Router, private alertservice: AlertService, private apiData: ApiService, private validate: MandatoryfieldService,private commonfunctions:CommonfunctionsService) {
    // this.host = "https://mr-reva-fe-app-dev.azurewebsites.net/";
    this.host =  environment.host; //  "https://mr-reva-fe-app-development.azurewebsites.net/";
    // this.host = "https://mr-reva-fe-app-tst.azurewebsites.net/";
    this.path = "api/";
    this.url = this.host + this.path;
  }

  enCryptText(text) {
    return CryptoJS.SHA512(text).toString();
    //return CryptoJS.HmacSHA1(text, "c320c51b5b9414efb7fbeb95107e73951269b2ed").toString(CryptoJS.enc.Hex);
  }
  authenticationUser() {
    var authKey="Bearer " + this.alertservice.tokenId.get();
    if(this.validate.isUndefined(this.alertservice.tokenId.get())){
      authKey="";
    }
    this.reqHeader = new HttpHeaders({
      ContentType: "application/x-www-urlencoded",
      Authorization: authKey
    });
  }

  login(reqData) {
    return this.http
      .post<any>(this.url + "auth/token", reqData, { headers: this.reqHeader })
      .pipe(
        map(result => {
          if (result) {
            if (result.status == 1) {
              console.log('login start');
              if (!this.validate.isUndefined(result.access_token)) {
                this.alertservice.tokenId.set(result.access_token);
                console.log('access token set');
              } else if (!this.validate.isUndefined(result.refresh_token)) {
                this.alertservice.tokenId.set(result.refresh_token);
                console.log('refresh token set');
              }
              this.timeOut = setTimeout(() => {
                this.authenticationUser();
                let req = {
                  username: this.alertservice.userId.get(),
                  grant_type: "refresh_token"
                }
                console.log('refresh token start');
                if(!this.validate.isUndefined(this.alertservice.userId.get()))
                {
                  console.log('refresh token with userid');
                  this.login(req)
                  .pipe(first())
                  .subscribe(res => {
                  }, err => {
                    this.logoutService();
                    //this.validate.popUpAlert(this.alertservice.ErrorText.unauthorized, "", "error");
                  });
                }
              }, result.expires_in * 2 * 1000); // 30 minutes if  result.expires_in = 900ms

              console.log('login end');
            }
          }
          return result;
        })
      );
  }

  loginFormsave(formGroup: FormGroup) {
    this.loginForm = formGroup;
  }
  forgetOrChangePassword(page) {
    if (page == "loggedoff") {
      this.showModal = true;
    } else if (page == "loggedin") {
      this.changePwdReq = true;
      let requestData = {
        "EmailId": this.alertservice.getUserDetails("getuserEmailID"),
        "TenantId": this.alertservice.getUserDetails("getTenant"),
        "activityType": "Change Password"
      };
      this.changePasswordApi("changepasswordreq", requestData).pipe(first())
        .subscribe(
          data => {
            if (data.status == 1) {
              this.showModal = true;
              this.new_password = true;
              this.forget_password = false;
              this.alertservice.changePasswordId.set(data.data);
            }
          },
          error => { }
        );
    }
  }

  firstTimeLogin() {
    this.forget_password = false;
    this.new_password = true;
  }

  changePasswordApi(type, reqdata: any) {
    let target = "auth/requestChangePassword";
    if (type == "verifycode") {
      target = "auth/verifyCode";
    } else if (type == "setpassword") {
      target = "auth/changePassword";
    }
    return this.http.post<any>(this.url + target, reqdata).pipe(
      map(result => {
        return result;
      })
    );
  }
  logoutService() {
    this.apiData.inputCombo.removData();
    this.apiData.outputCombo.removData();
    const sweetAlertConfirm = document.querySelector('.swal2-confirm') as HTMLElement;
    if (sweetAlertConfirm) {
      sweetAlertConfirm.click(); //if cancel doesn't exist , confirm is the equivalent for Ok button
    }

    let id = this.alertservice.getUserDetails("getid");
    if(!this.validate.isUndefined(id))
    {
      this.logout()
      .pipe(first())
      .subscribe(
        data => {
          this.reqHeader = "";
          this.alertservice.tokenId.remove();
          localStorage.clear();
          clearTimeout(this.timeOut);
          this.changePwdReq = false;
          this.alertservice.navigateTo("/login");
        },
        error => {
          this.commonfunctions.serviceErrorHandling(error);
        });
    }
    else {
      this.reqHeader = "";
      this.alertservice.tokenId.remove();
      localStorage.clear();
      clearTimeout(this.timeOut);
      this.changePwdReq = false;
      this.alertservice.navigateTo("/login");
    }
  }

  logout() {
    // remove user from local storage to log user out
    let id = this.alertservice.getUserDetails("getid");
    return this.http
      .post<any>(this.url + "auth/logout?Id=" + id,"")
      .pipe(
        map(result => {
          // if (result) {
            this.reqHeader = "";
            this.alertservice.tokenId.remove();
            localStorage.clear();
            clearTimeout(this.timeOut);
            this.changePwdReq = false;
          // }
          return result;
        })
      );
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AlertService } from '../services/alert.service';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(private alert:AlertService , private router: Router,private auth:AuthenticationService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      const expectedRole = next.data.expectedRole;
      const expectedRole2 = next.data.expectedRole2;
      let user = this.alert.getUserDetails("getUsertype");
      if (user !== expectedRole && user !== expectedRole2) {
        this.auth.logoutService();
        return false;
      }
    
    return true;
  }
}

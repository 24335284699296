import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { ApiService } from '../../services/apiservices.service';
import { MandatoryfieldService } from '../../common/Validators/mandatoryfield.service';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CommonfunctionsService } from '../../services/commonfunctions.service';
import Swal from 'sweetalert2';
declare var $: any;
declare function escape(s: string): string;

@Component({
  selector: 'app-rate-list',
  templateUrl: './rate-list.component.html',
  styleUrls: ['./rate-list.component.css']
})
export class RateListComponent implements OnInit {
  loading: boolean = false;
  showtable: boolean = false;
  fields: any = [];
  tableData: any;
  resArray: any = [];
  title: any = [];
  rateGenerateId: any;
  constructor(private alertService: AlertService, private apiData: ApiService, private validate: MandatoryfieldService, private commonfunctions: CommonfunctionsService) { }

  ngOnInit() {
    if (this.validate.isUndefined(this.alertService.combinationId.get()) || this.validate.isUndefined(this.alertService.getLocal("loading"))) {
      this.alertService.navigateTo("/choose-transaction");
    } else {
      this.getRateList();
      this.alertService.deleteLocal("upload");
      var obj = this;
      $("#rateListTable").on("refresh.bs.table", function (e, params) {
        obj.getRateList();
      });
    }
  }
  goto(page) {
    this.alertService.rateGenerationID.remove();
    this.alertService.combinationId.remove();
    this.alertService.isFinalRate.remove();
    this.alertService.mrCode.remove();
    this.apiData.inputCombo.removData();
    this.apiData.outputCombo.removData();
    this.alertService.deleteLocal("loading");
    this.alertService.navigateTo(page);
  }
  getRateList() {
    this.loading = true;
    this.apiData.rateGenerationApi.viewRateData(res => {
      if (!this.validate.isUndefined(res)) {
        let result = res;
        this.loading = false;
        if (result.status == 1) {
          this.rateGenerateId = result.data[0].rategenerationid;
          this.validate.popUpAlert("Rate Generated Successfully", "Transaction ID: " + result.data[0].rategenerationid, "success");
          $("#rateListTable").bootstrapTable("destroy");
          this.prepareHeader(result);
          this.prepareJson(result);
          this.tableData = $("#rateListTable").bootstrapTable({
            data: this.resArray,
            columnDefs: [
              {
                className: "table-data"
              }
            ],
            select: true
          });
          this.showtable = true;
        }
        else {
          let errorText = this.alertService.ErrorText.checkError(res.data);
          this.validate.popUpAlert(errorText, "", "error");
        }
      }
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    })
  }
  prepareHeader(resData) {
    this.fields = [];
    this.title = [];
    for (var i = 0; i < resData.fieldname.length; i++) {
      if (!resData.fieldname[i].hide) {
        if (resData.fieldname[i].title === 'Sum Insured') {
          resData.fieldname[i].title += ' (Cr)'
        }
        let resArray1 = {};
        this.fields.push(resData.fieldname[i]["title"]);
        this.title.push({ header: resData.fieldname[i]["title"], field: resData.fieldname[i]["name"] });
        var tr = $('<th class="table-head" data-sortable="true" data-field="' + resData.fieldname[i]["title"] + '" >' + resData.fieldname[i]["title"] + '</th>');
        $("#rateListTable thead tr").append(tr);
      }
    }
  }
  prepareJson(resData) {
    this.resArray = [];
    for (var i = 0; i < resData.data.length; i++) {
      if (resData.data[i].area_sown) {
        resData.data[i].area_sown = +(+resData.data[i].area_sown.toFixed(0));
      }
      resData.data[i].actual_sum_insured = resData.data[i].sum_insured;
      resData.data[i].sum_insured = +(resData.data[i].sum_insured / 1e7).toFixed(2)
      let resArray1 = {actual_sum_insured: resData.data[i].actual_sum_insured};
      for (var j = 0; j < this.title.length; j++) {
        resArray1[this.title[j].header] = resData.data[i][this.title[j].field];
        if (typeof +resArray1[this.title[j].header] == 'number' && !isNaN(+resArray1[this.title[j].header])) {
          resArray1[this.title[j].header] = +(+resArray1[this.title[j].header]).toFixed(2)
        }
      }
      this.resArray.push(resArray1);
    }
  }
  exportAsXLSX(): void {
    const fields = JSON.parse(JSON.stringify(this.fields));
    const data = JSON.parse(JSON.stringify(this.resArray));
    for (let i = 0; i < data.length; i++) {
      const d = data[i];
      d["Sum Insured"] = d.actual_sum_insured;
      delete d["Sum Insured (Cr)"];
      delete d.actual_sum_insured;
    }
    const sum_insured_index = fields.indexOf("Sum Insured (Cr)");
    if (sum_insured_index >= 0 ) {
      fields[sum_insured_index] = "Sum Insured";
    }
    this.commonfunctions.exportAsExcelFile(data, fields, "RateData");
  }
  checkColumns(columname) {
    if (columname == "weatherforecast") {
      return false;
    } else if (columname == "agroclimatezone") {
      return false;
    } else if (columname == "specificrisks") {
      return false;
    } else if (columname == "irrigationfacilities") {
      return false;
    } else if (columname == "farmmgmtpractices") {
      return false;
    } else if (columname == "groundlevelexpertise") {
      return false;
    } else if (columname == "commercialdiscount") {
      return false;
    } else if (columname == "adminld") {
      return false;
    } else if (columname == "year") {
      return false;
    } else if (columname == "costcapitalld") {
      return false;
    } else {
      return true;
    }
  }

  convertToPdf() {
    var pdf = new jsPDF('portrait', 'pt', 'a4');
    pdf.setFontSize(6);
    var col = [];
    var rowdata = [];
    var len = this.resArray.length;
    var colStyle = [];
    var colCount = 0;
    for (var i = 0; i < this.title.length; i++) {
      if (this.checkColumns(this.title[i].field)) {
        col.push({ header: this.title[i].header });
        colStyle.push({ [colCount++]: { cellWidth: 30, halign: 'right' } })
      }
    }
    for (var k = 0; k < this.resArray.length; k++) {
      let fileddata = [];
      for (var i = 0; i < col.length; i++) {
        for (var j = 0; j < this.title.length; j++) {
          if (this.title[j].header == col[i].header)
            fileddata.push(this.resArray[k][this.title[j].header]);
        }
      }
      rowdata.push(fileddata);
    }

    pdf.autoTable(col, rowdata, {
      startY: 40,
      margin: {
        right: 20,
        left: 20
      },
      headStyles: {
        fontSize: 8,
        fontStyle: 'normal',
        textColor: "#fff",
        fillColor: "#175788",
        halign: 'center'
      },
      styles: {
        cellWidth: 30
      },
      tableLineWidth: 0,
      theme: "striped",
      bodyStyles: {
        fontSize: 7,
        halign: 'center',
        valign: 'middle'
      },
    }
    );
    pdf.save("RateData" + '_' + this.commonfunctions.getDateTimeExt() + ".pdf");
  }
  ratedataDownloadapi() {
    this.loading = true;
    this.apiData.rateGenerationApi.downloadRateData(res => {
      this.loading = false;
    }, err => {
      this.loading = false;
      this.commonfunctions.serviceErrorHandling(err);
    })
  }

  confirmMessage() {
    Swal({
      title: "Do you want to replace this as final pricing?",
      text: "",
      type: "question",
      confirmButtonColor: "#175788",
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result: any) => {
      if (result && result.value) {
        this.apiData.rateGenerationLocakPrice(this.rateGenerateId).then((res: any) => {
         if (res && res.status == 1) {
            this.validate.popUpAlert(res.message, "Success", "success");
          } else {
            this.validate.popUpAlert(res.message, "Error", "error");
          }
          this.loading = false;
        })
        .catch((err) => {
          this.validate.popUpAlert(
            "Unable to Update Final Loading",
            "Error",
            "error"
          );
          this.loading = false;
        });
      }
    });
  }

}



import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
/*Directivies*/

import { AlphaAndSpaceOnlyDirective } from './Directives/alpha-and-space-only.directive';
import { NumberOnlyDirective } from './Directives/number-only.directive';
import { MandatoryfieldService } from './Validators/mandatoryfield.service';
import { AlphaNumericOnlyDirective } from './Directives/alpha-numeric-only.directive';
import { ErrorMessageComponent } from './Validators/error-message/error-message.component';
import { DisableControlDirective } from './Directives/disable-control.directive';
import { ShowHideDirective } from './Directives/show-hide.directive';
import { TooltipDirective } from './Directives/tooltip.directive';
import { CutCopyPasteBlockDirective } from './Directives/cut-copy-paste-block.directive';
import { PosNumberonlyDirective } from './Directives/pos-numberonly.directive';
import { UseridpatternDirective } from './Directives/useridpattern.directive';
import { PercnumberonlyDirective } from './Directives/percnumberonly.directive';

@NgModule({
  declarations: [AlphaAndSpaceOnlyDirective, NumberOnlyDirective, AlphaNumericOnlyDirective, ErrorMessageComponent,
    DisableControlDirective, ShowHideDirective, TooltipDirective, CutCopyPasteBlockDirective, PosNumberonlyDirective,
    UseridpatternDirective, PercnumberonlyDirective],
  imports: [
    BrowserModule,
    FormsModule,
  ],
  exports: [AlphaAndSpaceOnlyDirective, NumberOnlyDirective, AlphaNumericOnlyDirective, ErrorMessageComponent,
    DisableControlDirective, ShowHideDirective, TooltipDirective, CutCopyPasteBlockDirective, PosNumberonlyDirective, UseridpatternDirective,
    PercnumberonlyDirective],
  providers: [
    MandatoryfieldService
  ],

})
export class CommonModules { }


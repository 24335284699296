import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../../services/apiservices.service';
import { CommonfunctionsService } from '../../services/commonfunctions.service';
import { MandatoryfieldService } from '../../common/Validators/mandatoryfield.service';
import { AlertService } from '../../services/alert.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-choose-transaction',
  templateUrl: './choose-transaction.component.html',
  styleUrls: ['./choose-transaction.component.css']
})
export class ChooseTransactionComponent implements OnInit, OnDestroy {

  loading = 0;
  fileProcess = 0;
  transactionData: any[] = [];
  selectedTransaction: any[] = [];
  timer: any = null;
  currentYear: any;
  hasFailedTransaction: any = false;

  constructor(private apiData: ApiService,private commonfunctions: CommonfunctionsService, private validate: MandatoryfieldService, public alertservice: AlertService) { }

  ngOnInit() {
    this.getTransactonByUserId();
  }

  getTransactonByUserId() {
    this.hasFailedTransaction = false;
    this.loading++;
    this.apiData.rateGenerationApi.getTransactonByUserId(res => {
      if (res && res.status == 1 && res.data) {
        this.hasFailedTransaction = res.data.some(d => d.status.toLowerCase() == 'failed')
        let transactionData: any[] = [];
        res.data.sort((a,b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()).forEach((ele: any, i) => {
          if (i < 5) {
            // const actualData = this.formatDate(new Date(new Date(ele.createdAt).getTime() + (5*60*60*1000) + (30*60*1000)))
            const actualData = this.formatDate(new Date(ele.createdAt));
            ele.label = `${ele.rateGenerationID} (${ele.stateName}) ${actualData}`;
            transactionData.push(ele)
          }
        });
        this.transactionData = transactionData;
        this.loading--;
      }
    },err => {
      this.loading--;
      this.commonfunctions.serviceErrorHandling(err);
    })
  }

  onClearTransactionClick() {
    Swal({
      title: "Do you want to clear failed transactions?",
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result: any) => {
      if (result && result.value) {
        this.loading++
    this.apiData.rateGenerationApi.deleteTransactionByUserId(res => {
      if (res && res.status == 1) {
        this.validate.popUpAlert("Failed transactions are cleared", "", "success");
        this.getTransactonByUserId()
      }
      this.loading--;
    }, err => {
      this.commonfunctions.serviceErrorHandling(err);
      this.loading--;
    })
      }
    })

  }

  onViewTransactionClick() {
    const transaction = this.transactionData.find(d => d.id == this.selectedTransaction);
    if (transaction) {
      this.alertservice.mrCode.set(this.selectedTransaction);
      this.getTransactonByMRId();
    }
  }

  getTransactonByMRId() {
    this.loading++
    this.fileProcess++;
    this.apiData.rateGenerationApi.getTransactonByMRId(res => {
      if (res && res.status == 1 && res.data) {
        if (res.data.status === 'Completed') {
          this.loading--;
          this.fileProcess--;
          this.alertservice.rateGenerationID.set(res.data.rateGenerationID);
          this.getRateGenerationData();
        } else if (res.data.status === 'InProgress') {
          this.timer = setTimeout(() => {
            this.loading--;
            this.fileProcess--;
            this.getTransactonByMRId()
          }, 1e4)
        } else {
          this.validate.popUpAlert('Processing failed due to large data set.', "", "error");
          this.loading--;
          this.fileProcess--;
        }
      }
    }, err => {
      this.validate.popUpAlert("Unable to get Transaction Id", "", "error");
      this.serviceError(err)
      this.fileProcess--;
    })
  }

  async  getRateGenerationData() {
    this.loading++
    this.apiData.rateGenerationApi.getRateGenerationData(async (res) => {
      if (res && res.status == 1) {
        const input = res.rateGeneration.input;
        if (input && input.length > 0) {
          const state = input.find(d => d.key == 'state');
          const level = input.find(d => d.key == 'level');
          const admin = input.find(d => d.key == 'admin');
          let season = input.find(d => d.key == 'season');
          let crop = input.find(d => d.key == 'crop');
          season = season.value ? season.value : null;
          crop = crop.value ? crop.value : null;
          this.alertservice.combinationId.set(res.rateGeneration.id);
          if (!this.currentYear) {
            await this.getDefault()
          }
          const param = btoa(JSON.stringify([state ? state.value : null, level ? level.value : null, admin ? admin.value : null, season, crop, this.currentYear]))
          this.alertservice.navigateTo("/rate-generate", param);
        }
      }
      this.loading--;

    }, err => {
      this.loading--;
      this.commonfunctions.serviceErrorHandling(err);
    })
  }

  async getDefault() {
    this.loading++;
   const result = await  this.apiData.rateGenerationDefaultLoadingDiscount().then((res: any) => {
     this.loading--;
    if (res && res.status == 1) {
      this.currentYear = res.data.currentYear;
      return res.data;
    }
   }).catch(err => {
    this.commonfunctions.serviceErrorHandling(err);
     this.loading--;
   })
   return result || {}
  }

  serviceError(err) {
		this.loading--;
		this.commonfunctions.serviceErrorHandling(err);
	}

  formatDate(date: any) {
    return (date.getDate() + '').padStart(2,'0') + '-' + (date.getMonth()+1+'').padStart(2,'0') + '-'+ date.getFullYear() + ' ' + (date.getHours() + '').padStart(2,'0') + ':' + (date.getMinutes() + '').padStart(2,'0') + ':' +(date.getSeconds() + '').padStart(2,'0')
  }

  ngOnDestroy(): void {
    clearTimeout(this.timer)
  }

}

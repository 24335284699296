import { Component, OnDestroy, OnInit } from '@angular/core';
import { UploadEvent, UploadFile, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { MandatoryfieldService } from '../common/Validators/mandatoryfield.service';
import { AlertService } from '../services/alert.service';
import { ApiService } from '../services/apiservices.service';
import { CommonfunctionsService } from '../services/commonfunctions.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { a, D } from '@angular/core/src/render3';

@Component({
  selector: 'app-admin-upload',
  templateUrl: './admin-upload.component.html',
  styleUrls: ['./admin-upload.component.css']
})
export class AdminUploadComponent implements OnInit, OnDestroy {

  fileList: any;
  fileName: any;
  loading = 0;
  selectedState: any;
  stateDistrictData: any[] = []
  stateData: any[] = []
  showUpload: boolean = true;
  showclientWarn: boolean = false;
  showDbWarn: boolean = false;
  excelJsonData: any[] = [];
  years: any[] = [];
  mismatchedYieldDatas: any[] = [];
  warnings: any[] = [];
  validatedFileName: any;
  yearErrorData: any[] = [];
  mismatchedCurrentYearYieldDatas: any[] = [];
  transactionId: any;
  inProgress = false;
  completePercentage: any = 0;
  progressInterval: any = null;
  lastYieldData: any;
  zeroWarningYield = new FormData();
  blankWarningYield = new FormData();
  outliersWarningYield = new FormData();
  warningYield: any;

  constructor(private alert: AlertService,private apiData: ApiService, private commonfunctions: CommonfunctionsService, private validate: MandatoryfieldService, public alertService: AlertService,) {
    const tenantId = this.alert.getUserDetails("getTenant")
    this.zeroWarningYield.append("tenantId", tenantId+'');
    this.zeroWarningYield.append("year", '2022');
    this.zeroWarningYield.append("state", "");

    this.blankWarningYield.append("tenantId", tenantId+'');
    this.blankWarningYield.append("year", '2022');
    this.blankWarningYield.append("state", "");

    this.outliersWarningYield.append("tenantId", tenantId+'');
    this.outliersWarningYield.append("year", '2022');
    this.outliersWarningYield.append("state", "");


   }


  ngOnInit() {
    this.getStateDistrictData();
  }

  getStateDistrictData() {
    this.loading++;
    this.apiData
      .getStateDistrictWiseData()
      .then((res: any) => {
        if (res && res.status == 1) {
          this.stateDistrictData = res.data || [];
        }
        if (this.stateDistrictData && this.stateDistrictData.length) {
          this.stateData = Array.from(
            new Set(this.stateDistrictData.map((data: any) => data.state))
          ).map((data) => ({ state: data }));
        }
        this.loading--;
      })
      .catch((err) => {
        this.loading--;
      });
  }

  onStateChange(event: any) {
    this.zeroWarningYield.delete("state")
    this.zeroWarningYield.append("state", event);
    this.zeroWarningYield.delete("zeroWarningYieldRecords")
    this.zeroWarningYield.delete("noOfRecords")
    this.zeroWarningYield.delete("previousYears")


    this.blankWarningYield.delete("state")
    this.blankWarningYield.append("state", event);
    this.blankWarningYield.delete("blankWarningYieldRecords");
    this.blankWarningYield.delete("noOfRecords")
    this.blankWarningYield.delete("previousYears")

    this.outliersWarningYield.delete("state")
    this.outliersWarningYield.append("state", event);
    this.outliersWarningYield.delete("outlierWarningYieldRecords");
    this.outliersWarningYield.delete("noOfRecords")
    this.outliersWarningYield.delete("previousYears")

    this.years = [];
    this.yearErrorData = [];
    this.excelJsonData = [];
    this.warningYield = null;

      this.clearProgressInterval();
      this.lastYieldData = null;
      if (event) {
        this.getStateProgress(event);
        this.getLastYieldData(event);
      }

  }

  clearProgressInterval() {
    if (this.progressInterval) {
      clearInterval(this.progressInterval);
      this.progressInterval = null;
    }
  }

  getStateProgress(event) {
    this.clearProgressInterval();
    this.apiData.masterUploadGetLastYieldDataUploadStatus(event).then((response: any) => {
      if(response && response.status == 'InProgress') {
        this.inProgress = true;
        this.completePercentage = Math.floor((response.totalUploadedRecords*100) / response.totalRecords)
        this.progressInterval = setInterval(() => {this.getStateProgress(event)}, 15*1e3)
      } else {
        this.inProgress = false;
        this.completePercentage = 0;
        this.getLastYieldWarning(event)
      }
    }).catch(() => {})
  }
  getLastYieldData(event) {
    this.apiData.getLastYieldData(event).then((response: any) => {
      if (response && response.status == 1) {
        this.lastYieldData = response.data;
      }
    }).catch(() => {})
  }

  getLastYieldWarning(event: any) {
    this.loading++;
    this.apiData.masterUploadgetAgreeDatasetWarnings(2022,event).then((response: any) => {
      if (response.status == 1) {
        let years = [];
        let yearErrorData = [];
        let excelBlankJsonData = [];
        let excelZeroJsonData = [];
          if (!response.data.reva_BlankWarningYields && !response.data.reva_OutliersWarningYields && !response.data.reva_ZeroWarningYields){
          } else {
            this.warningYield = response.data;
          }
        // let {reva_BlankWarningYields,reva_ZeroWarningYields,reva_OutliersWarningYields} = response.data;
        // reva_BlankWarningYields = reva_BlankWarningYields ? reva_BlankWarningYields[0].blankWarningYieldRecords || [] : [];
        // reva_ZeroWarningYields = reva_ZeroWarningYields ? reva_ZeroWarningYields[0].zeroWarningYieldRecords || [] : [];
        // reva_OutliersWarningYields =reva_OutliersWarningYields ?  reva_OutliersWarningYields[0].outlierWarningYieldRecords || [] : [];
        // if (reva_BlankWarningYields||reva_ZeroWarningYields||reva_OutliersWarningYields) {
            // const avaliableData = reva_BlankWarningYields.find(() => true) || reva_ZeroWarningYields.find(() => true) || reva_OutliersWarningYields.find(() => true)
            // if (avaliableData.prevYearsYieldData) {
            //   years = avaliableData.prevYearsYieldData.map((d: any) => d.previousYear).sort((a,b) => a-b);
            // }
            // if (reva_BlankWarningYields) {
            //   excelBlankJsonData = reva_BlankWarningYields.map(d => {
            //    for(let i=0; i< d.prevYearsYieldData.length; i++) {
            //       const yearData = d.prevYearsYieldData[i]
            //       d[yearData.previousYear] = yearData.previousYearYield;
            //    }
            //    d['blankError'] = true;
            //    d['lineNo'] = d.csvRowNumber;
            //    return d;
            //   })
            // }
            // if (reva_ZeroWarningYields) {
            //   excelZeroJsonData = reva_ZeroWarningYields.map(d => {
            //    for(let i=0; i< d.prevYearsYieldData.length; i++) {
            //     const yearData = d.prevYearsYieldData[i]
            //     d[yearData.previousYear] = yearData.previousYearYield;
            //    }
            //    d['zeroError'] = true;
            //    d['lineNo'] = d.csvRowNumber;
            //    return d;
            //   })
            // }
            // if (reva_OutliersWarningYields) {
            //   yearErrorData = reva_OutliersWarningYields.map(d => {
            //    for(let i=0; i< d.prevYearsYieldData.length; i++) {
            //     const yearData = d.prevYearsYieldData[i]
            //     d[yearData.previousYear] = yearData.previousYearYield;
            //    }
            //    d['max_50'] = d.plusFifty;
            //    d['min_50'] = d.minusFifty;
            //    d['average'] = d['avaerage'];
            //    return d;
            //   })
            // }
            // this.years = years;
            // this.yearErrorData = yearErrorData;
            // this.excelJsonData = [...excelBlankJsonData, ...excelZeroJsonData]
        // }
      }
      this.loading--;
    }).catch(err => {
      this.loading--;
      console.log(err)
    })
  }

  public dropped(event: UploadEvent) {
    this.years = [];
    this.yearErrorData = [];
    this.excelJsonData = [];
    this.warningYield = null;
    this.fileName = '';
    let getFile = event.files;
		let array = getFile[0].relativePath.split('.');
		if (array[array.length - 1] == "csv") {
			for (const droppedFile of event.files) {
				if (droppedFile.fileEntry.isFile) {
					const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
					fileEntry.file((file: File) => {
						console.log(droppedFile.relativePath, file);
						this.fileList = file;
						this.fileName = droppedFile.relativePath;
            const reader: FileReader = new FileReader();
            reader.onload = this.generateDataFromExcel;
            reader.readAsBinaryString(this.fileList);
					});
				}
			}
		} else {
			this.validate.popUpAlert(this.alertService.ErrorText.incorrectfile, "", "error");
		}
  }

  onFileChange(event): void {
    this.years = [];
    this.yearErrorData = [];
    this.excelJsonData = [];
    this.warningYield = null;
    this.fileName = '';
    let getFile = event.target.files;
		let array = getFile[0].name.split('.');
		if (array[array.length - 1] == "csv") {
			this.fileList = getFile[0];
			this.fileName = this.fileList.name;
      const reader: FileReader = new FileReader();
      reader.onload = this.generateDataFromExcel;
      reader.readAsBinaryString(this.fileList);
		} else {
			this.validate.popUpAlert(this.alertService.ErrorText.incorrectfile, "", "error");
		}
  }

  downloadSample() {
		this.loading++;
		this.apiData.rateGenerationApi.downloadSampleCsv(res => {
			this.loading--;
			this.commonfunctions.csvDownloadFromServer(res);
		}, err => {
			this.loading--;
			this.commonfunctions.serviceErrorHandling(err);
		})
	}

  downloadLastYieldFile() {
    if (this.lastYieldData && this.lastYieldData.azureFilePath) {
      const anchor = document.createElement('a');
      anchor.target = "download";
      anchor.href = this.lastYieldData.azureFilePath;

      anchor.click();
      anchor.remove();
      // this.apiData.downloadLastYieldFile(this.lastYieldData.azureFilePath)
      // .then(response => {
      //   this.commonfunctions.csvDownloadFromServer(response);
      // })
      // .catch(err => {
      //   this.commonfunctions.serviceErrorHandling(err);
      // })
    }
  }

  formFileRequest() {
		const formData = new FormData();
		formData.append("tenantId", this.alertService.getUserDetails('getTenant'));
		formData.append("state", this.selectedState);
		formData.append("updatedBy", this.alertService.getUserDetails('getid'));
		formData.append("userFileName",this.fileName);
		formData.append('file', this.fileList, this.fileName);
		return formData
	}

  varifyYiedData() {
    const request = this.formFileRequest();
      this.loading++
    this.apiData.masterUploadYielVarifyYiedData(request).then((res: any) => {
      if (res && res.status == 1 && res.result) {
        const result = res.result
        this.validatedFileName = result.validatedFileName;
        this.warnings = result.warnings;
        this.mismatchedYieldDatas = result.mismatchedYieldDatas;
        this.mismatchedCurrentYearYieldDatas = result.mismatchedCurrentYearYieldDatas;
        this.transactionId = result.transactionId
      } else {
        this.validate.popUpAlert(res.message, "Error", "error");
      }
      this.loading--
      this.uploadWarnings();

    }).catch(err => {
      this.loading--
      this.validate.popUpAlert("Something went wrong", "", "error");
    })
  }

  goto(process) {
    if (process == "next") {
      const request = {fileName: this.validatedFileName, state: this.selectedState, transactionId: this.transactionId}
      this.loading++
      this.apiData.masterUploadValidatedYieldDataUpload(request).then((res: any) => {
        if (res && res.status == 1) {
          this.fileList = null;
          this.fileName = null;
          this.showUpload = true;
          this.showDbWarn = false;
          this.showclientWarn = false;
          this.excelJsonData =  [];
          this.yearErrorData =  [];
          this.years = [];
          this.mismatchedCurrentYearYieldDatas = [];
          this.mismatchedYieldDatas = [];
          this.warnings = [];
          this.onStateChange(this.selectedState);
          this.validate.popUpAlert(res.message, "Success", "success");
        } else {
          this.validate.popUpAlert(res.message, "Error", "success");
        }
        this.loading--
      }).catch(err => {
        this.loading--
        this.validate.popUpAlert("Something went wrong", "", "error");
      })
    } else if(process == "client_warning") {
      this.varifyYiedData();
      this.showUpload = false;
      this.showDbWarn = false;
      this.showclientWarn = true;
    } else if(process == "client-yes") {
      this.showUpload = false;
      this.showDbWarn = true;
      this.showclientWarn = false;
    } else if(process == "client-no") {
      this.showUpload = true;
      this.showDbWarn = false;
      this.showclientWarn = false;
    }
  }

  uploadWarnings() {
      this.loading++
      const yieldWarningPromise = Promise.all([this.apiData.masterUploadaddBlankWarningYieldFile(this.blankWarningYield),
                                                this.apiData.masterUploadaddZeroWarningYieldFile(this.zeroWarningYield),
                                                this.apiData.masterUploadaddOutliersWarningYieldFile(this.outliersWarningYield)]);
      yieldWarningPromise.then((responses: any) => {
        if (responses.every(d => d.status == 1)) {
          this.goto('next');
        } else {
          const error = responses.find(d => d.status != 1);
          const message = error.data || error.message;
          this.validate.popUpAlert(message, "Error", "error");
        }
        this.loading--
      }).catch(err => {
        this.validate.popUpAlert("Something went wrong", "Error", "error");
        this.loading--
      })
      return false;
  }

  showWarnings(res) {
		var span = document.createElement("span");
		for (var i = 0; i < res.warnings.length; i++) {
			span.innerHTML += "<div style='text-align:left'>" + (i + 1) + ". " + res.warnings[i].warning + ". " + "</div>";
		}
		Swal({
			title: "Warnings",
			html: span,
			type: "warning",
			confirmButtonColor: "#175788",
			timer: parseFloat('120000')
		})
	}

  generateDataFromExcel = (e) =>  {
    this.loading++;
    setTimeout(() => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* sheet data */
      const tableData = (XLSX.utils.sheet_to_json(ws, { header: 1 }));

      if (tableData && tableData.length > 1) {
        const header: any = tableData[0];
        const headerContains = [
          "year",
          "season",
          "crop",
          "management",
          "admin1",
          "admin2",
          "admin3",
          "admin4",
          "admin5",
          "calamity1",
          "calamity2",
          "cluster_num",
          "cov_level",
          "area_sown",
          "value_per_crop_ha",
          "sum_insured",
          "admin2_shp",
          "admin3_shp",
          // "2008",
          // "2009"
      ]


        if (!headerContains.every((head, index) => head && head.trim().toLowerCase() == header[index].trim().toLowerCase())) {
          for (let i = 0; i < headerContains.length; i++) {
            const head = headerContains[i];
            if (head.trim().toLowerCase() != header[i].trim().toLowerCase()) {
              debugger
              console.log(head.trim().toLowerCase() , header[i])
            }
          }
          this.validate.popUpAlert("Invalid CSV File", "", "error");
          return;
        }
        this.years = header.filter((d,i) => i > 17)
        const jsonHeader = [
          "year",
          "season",
          "crop",
          "management",
          "admin1",
          "admin2",
          "admin3",
          "admin4",
          "admin5",
          "calamity1",
          "calamity2",
          "cluster_num",
          "cov_level",
          "area_sown",
          "value_per_crop_ha",
          "sum_insured",
          "admin2_shp",
          "admin3_shp",
          ...this.years
      ]
        const jsonData = [];
        const keys = [];
        const groupData = []
        const yearErrorData = [];
        for (let index = 1; index < tableData.length; index++) {
          const rowData: any = tableData[index];
          const json: any = {lineNo: index+1, blankError: 0, zeroError: 0}
          for (let rowIndex = 0; rowIndex < jsonHeader.length; rowIndex++) {
            let data = rowData[rowIndex];
            if (this.years.includes(jsonHeader[rowIndex]) && (!data || data == 0)) {
              json['blankError'] += ['',undefined,null].includes(data) ? 1 : 0;
              json['zeroError'] += data === 0 ? 1 : 0;
            }
            json[jsonHeader[rowIndex]] = data;
          }

          const key = `${json.year}-${json.season}-${json.crop}-${json.admin1}-${json.cluster_num}-${json.admin2}`;
          let group = {lineNo: index+1, key, repeat: 1}
          if (keys.includes(key)) {
            group = groupData.find(data => data.key == key);
            group.repeat += 1;
            for (let yindx = 0; yindx < this.years.length; yindx++) {
              const k = this.years[yindx];
              group[k] += json[k] || 0;
            }
          } else {
            for (let hindex = 0; hindex < jsonHeader.length; hindex++) {
              const k = jsonHeader[hindex];
              if (this.years.includes(k) && !json[k]) {
                group[k] = 0;
              } else {
                group[k] = json[k];
              }
            }
            keys.push(key)
            groupData.push(group)
          }
          jsonData.push(json)
        }
        this.excelJsonData = jsonData;
        if (this.excelJsonData && this.excelJsonData.length) {
          const zeroWarningYieldRecords: any[] = [];
          const blankWarningYieldRecords: any[] = [];
          for (let index = 0; index < this.excelJsonData.length; index++) {
            const json = this.excelJsonData[index];
            if (json.zeroError || json.blankError) {
              const yearData = {};
              this.years.forEach((d: any) => {
                yearData[`${d} `] = json[d];
              })
              const warningData = {
                "year": json.year,
                "season": json.season,
                "crop": json.crop,
                "management": json.management,
                "admin1": json.admin1,
                "admin2": json.admin2,
                "admin3": json.admin3,
                "admin4": json.admin4,
                "admin5": json.admin5,
                "cluster_num": json.cluster_num,
                "cov_level": json.cov_level,
                "area_sown": json.area_sown,
                "value_per_crop_ha": json.value_per_crop_ha,
                "sum_insured": json.sum_insured,
                "calamity1": json.calamity1,
                "calamity2": json.calamity2,
                "csvRowNumber": json.lineNo,
                "isGiveWarning": true,
                ...yearData
              }

              if (json.zeroError) {
                zeroWarningYieldRecords.push(warningData)
              } else {
                blankWarningYieldRecords.push(warningData)
              }
            }
          }
          this.zeroWarningYield.append("noOfRecords", zeroWarningYieldRecords.length.toString())
          this.blankWarningYield.append("noOfRecords", blankWarningYieldRecords.length.toString())

          this.blankWarningYield.append("previousYears", this.years.join())
          this.zeroWarningYield.append("previousYears", this.years.join())

          this.zeroWarningYield.append("zeroWarningYieldRecords", this.jsonToBlob(zeroWarningYieldRecords))
          this.blankWarningYield.append("blankWarningYieldRecords", this.jsonToBlob(blankWarningYieldRecords))
        }
        for (let index = 0; index < groupData.length; index++) {
          const json = JSON.parse(JSON.stringify(groupData[index]));
          for (let yr = 0; yr < this.years.length; yr++) {
            const year = this.years[yr];
            json[year] = json[year] / json.repeat;
          }
          const average = Math.round(this.years.map(d => +(json[d] || 0)).reduce((a: any, b: any) => a+b, 0) / this.years.length);
          const max_50 = average * 1.5;
          const min_50 = average * 0.5;
          let isOutlier = false;
          let count = 0;
          // const lastYear = +(json[this.years[this.years.length - 1]]);
          // const lastsndYear = +(json[this.years[this.years.length -2]])
          // const diff = ((lastsndYear - lastYear) / lastsndYear) *100;
          for (let yr = 0; yr < this.years.length; yr++) {
            const year = this.years[yr];
            json[year] = json[year] > min_50 && json[year] < max_50 ? ''  : Math.round(json[year]);
            if (!isOutlier && json[year]) {
              isOutlier = true;
            }
            if(json[year] || json[year] === 0) {
              count++;
            }
          }
          json['average'] = average;
          json['max_50'] = max_50;
          json['min_50'] = min_50;
          json['count'] = count;
          // if ( diff > 50 || diff < -50) {
          //   json['yearValueError'] = true;
          //   json['previousValue'] = +((lastsndYear || 0).toFixed(2));
          //   json['currentValue'] = +((lastYear || 0).toFixed(2));
          //   json['difference'] = lastsndYear == 0 ? 0 : (diff).toFixed(0) + '%';
          // }
          if(isOutlier) {
            yearErrorData.push(json);
          }
        }
        this.yearErrorData = yearErrorData.sort((a,b) => a.sum_insured - b.sum_insured);
        const outlierWarningYieldRecords: any[] = []
        for (let index = 0; index < this.yearErrorData.length; index++) {
          const json = this.yearErrorData[index];
          const yearData = {};
              this.years.forEach((d: any) => {
                yearData[`${d} `] = json[d];
              })
          const warningData = {
            "year": json.year || null,
            "season": json.season || null,
            "crop": json.crop || null,
            "management": json.management || null,
            "admin1": json.admin1 || null,
            "admin2": json.admin2 || null,
            "admin3": json.admin3 || null,
            "admin4": json.admin4 || null,
            "admin5": json.admin5 || null,
            "average": json.average || null,
            "plusFifty": json.max_50 || null,
            "minusFifty": json.min_50 || null,
            "count": json.count,
            "csvRowNumber": json.lineNo,
            "isGiveWarning": true,
            ...yearData
          }
          // "prevYearsYieldData": this.years.map(d => ({previousYear: d, previousYearYield: json[d] || 0, isBlank: false})),
          outlierWarningYieldRecords.push(warningData)
        }
        this.outliersWarningYield.append('noOfRecords', outlierWarningYieldRecords.length.toString())
        this.outliersWarningYield.append("previousYears", this.years.join())
        this.outliersWarningYield.append('outlierWarningYieldRecords', this.jsonToBlob(outlierWarningYieldRecords))
      }
      this.loading--;
    }, 1000)
}

onClientAction(process) {
  this.goto(process)
}

jsonToBlob(obj: any): any {
  obj = obj || []; // obj is the array of objects with non-english characters
    const length = obj.length;
    if (length) {
      // const str = JSON.stringify(obj);
      const data = this.jsonToCSV( obj );

      const blob = new Blob( [ data ], {
        type: "text/csv"
     });
return blob
}
 return null
}

encode (s) {
  const out: any[] = [];
  for ( let i = 0; i < s.length; i++ ) {
    out[i] = s.charCodeAt(i);
  }
  return new Uint8Array(out);
}

jsonToCSV(obj) {
  if (obj && obj.length) {
    const headers = Object.keys(obj[0]);
    return headers.join(',') + '\n' + obj.map((d: any) => headers.map((k: string) => {
      let value = d[k] === undefined ? '' : d[k];
      if (value && typeof value === 'string') {
        if (value.includes('\n')) {
          value = value.split('\n').join(' ')
        }
        if (value.includes('\t')) {
          value = value.split('\t').join(' ')
        }
      }
      return value
    }).join(',')).join('\n')
  }
  return "";
}



onCancel() {
  this.fileList = null;
  this.fileName = null;
  this.showUpload = true;
  this.showDbWarn = false;
  this.showclientWarn = false;
  this.excelJsonData =  [];
  this.yearErrorData =  [];
  this.years = [];
  this.mismatchedCurrentYearYieldDatas = [];
  this.mismatchedYieldDatas = [];
  this.warnings = [];
  this.zeroWarningYield = new FormData();
  this.blankWarningYield = new FormData();
  this.outliersWarningYield = new FormData();
  this.clearProgressInterval()
  this.selectedState = '';
}

ngOnDestroy(): void {
  this.clearProgressInterval();
}

}

import { Directive, ElementRef, HostListener, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[appPercnumberonly]'
})
export class PercnumberonlyDirective {
// Allow decimal numbers and negative values
private regex: RegExp = new RegExp(/^([0-9.-]+)$/g);
// Allow key codes for special events. Reflect :
// Backspace, tab, end, home
private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Delete','Arrows'];

constructor(private el: ElementRef) {
}

@HostListener('keydown', ['$event'])
onKeyDown(event: KeyboardEvent) {
  if (this.specialKeys.indexOf(event.key) !== -1) {
    return;
  }
  
  let current: string = this.el.nativeElement.value;
  let pos = this.el.nativeElement.selectionEnd;
  let next: string = current.concat(event.key);
  if ((pos != 0 && event.key == "-")) {
    event.preventDefault();
  }
  if(pos == 0 && event.key == "."){
    event.preventDefault();
  }
  if (next && !String(next).match(this.regex)) {
    event.preventDefault();
  }
}

}

import { AfterViewInit, Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { MandatoryfieldService } from '../common/Validators/mandatoryfield.service';
import { ApiService } from '../services/apiservices.service';
import { AlertService } from '../services/alert.service';
import { FormBuilder, Validators } from '@angular/forms';
import { CommonfunctionsService } from '../services/commonfunctions.service';

import * as jsPDF from 'jspdf';
import 'jspdf-autotable';

declare var $: any;

@Component({
  selector: 'app-previousyear',
  templateUrl: './previousyear.component.html',
  styleUrls: ['./previousyear.component.css']
})
export class PreviousyearComponent implements OnInit, AfterViewInit {
  loading = 0;
  fieldsNamas: any[] = [];
  tableData: any;
  showtable = false;
  resArray: any[] = [];
  fields: any[] = [];
  title: any[] = [];
  tieupId: any
  constructor(private formBuilder: FormBuilder, private validate: MandatoryfieldService, private apiData: ApiService, private alertService: AlertService, private commonfunctions: CommonfunctionsService) { }

  ngOnInit() {
    this.tieupId = this.alertService.getUserDetails("getTieup");
  }

  ngAfterViewInit(): void {
    this.getPreviousYearPricingResultsRpt();
  }

  getPreviousYearPricingResultsRpt() {
    this.loading++;
    this.apiData.finalReportsApi.previousYearPricingResultsRpt(this.tieupId, res => {
      $("#rateListTable").bootstrapTable("destroy");
      if (res && res.status == 1) {
        if (res.fieldname && res.fieldname.length) {
          res.fieldname = res.fieldname.filter((d: any) => !d.hide).sort((a: any, b: any) => a.seqno - b.seqno);
        }
        if (res.data && res.data.length) {
          res.data = Array.from(new Set(res.data.map(d => d.admin2 + d.crop))).map(d => res.data.find(e => (e.admin2 + e.crop) === d)).sort((a,b) => a.sno - b.sno)
        }
        this.prepareHeader(res);
        this.prepareJson(res);
        setTimeout(() => {
          this.tableData = $("#rateListTable").bootstrapTable({
            data: this.resArray,
            columnDefs: [
              {
                className: "table-data"
              }
            ],
            select: true
          });
        })
        this.showtable = true;
      }
      this.loading--
    }, err => {
      console.error(err);
      this.loading--;
    });
  }

  prepareHeader(resData) {
    this.fields = [];
    this.title = [];
    for (var i = 0; i < resData.fieldname.length; i++) {
      if (!resData.fieldname[i].hide) {
        if (resData.fieldname[i].title === 'Sum Insured') {
          resData.fieldname[i].title += ' (Cr)'
        }
        let resArray1 = {};
        this.fields.push(resData.fieldname[i]["title"]);
        this.title.push({ header: resData.fieldname[i]["title"], field: resData.fieldname[i]["name"] });
        var tr = $('<th class="table-head" data-sortable="true" data-field="' + resData.fieldname[i]["title"] + '" >' + resData.fieldname[i]["title"] + '</th>');
        $("#rateListTable thead tr").append(tr);
      }
    }
  }
  prepareJson(resData) {
    this.resArray = [];
    for (var i = 0; i < resData.data.length; i++) {
      if (resData.data[i].area_sown) {
        resData.data[i].area_sown = +(+resData.data[i].area_sown.toFixed(0));
      }
      resData.data[i].actual_sum_insured = resData.data[i].sum_insured;
      resData.data[i].sum_insured = +(resData.data[i].sum_insured / 1e7).toFixed(2)
      let resArray1 = {actual_sum_insured: resData.data[i].actual_sum_insured};
      for (var j = 0; j < this.title.length; j++) {
        resArray1[this.title[j].header] = resData.data[i][this.title[j].field];
        if (![null, undefined].includes(resData.data[i][this.title[j].field]) && typeof +resArray1[this.title[j].header] == 'number' && !isNaN(+resArray1[this.title[j].header])) {
          resArray1[this.title[j].header] = +(+resArray1[this.title[j].header]).toFixed(2)
        }
      }
      this.resArray.push(resArray1);
    }
  }

  convertToPdf() {
    var pdf = new jsPDF('portrait', 'pt', 'a4');
    pdf.setFontSize(6);
    var col = [];
    var rowdata = [];
    var len = this.resArray.length;
    var colStyle = [];
    var colCount = 0;
    for (var i = 0; i < this.title.length; i++) {
        col.push({ header: this.title[i].header });
        colStyle.push({ [colCount++]: { cellWidth: 30, halign: 'right' } })
    }
    for (var k = 0; k < this.resArray.length; k++) {
      let fileddata = [];
      for (var i = 0; i < col.length; i++) {
        for (var j = 0; j < this.title.length; j++) {
          if (this.title[j].header == col[i].header)
            fileddata.push(this.resArray[k][this.title[j].header]);
        }
      }
      rowdata.push(fileddata);
    }

    pdf.autoTable(col, rowdata, {
      startY: 40,
      margin: {
        right: 20,
        left: 20
      },
      headStyles: {
        fontSize: 8,
        fontStyle: 'normal',
        textColor: "#fff",
        fillColor: "#175788",
        halign: 'center'
      },
      styles: {
      },
      tableLineWidth: 0,
      theme: "striped",
      bodyStyles: {
        fontSize: 7,
        halign: 'center',
        valign: 'middle'
      },
    }
    );
    pdf.save("RateData" + '_' + this.commonfunctions.getDateTimeExt() + ".pdf");
  }

  exportAsXLSX(): void {
    const fields = JSON.parse(JSON.stringify(this.fields));
    const data = JSON.parse(JSON.stringify(this.resArray));
    for (let i = 0; i < data.length; i++) {
      const d = data[i];
      d["Sum Insured"] = d.actual_sum_insured;
      delete d["Sum Insured (Cr)"];
      delete d.actual_sum_insured;
    }
    const sum_insured_index = fields.indexOf("Sum Insured (Cr)");
    if (sum_insured_index >= 0 ) {
      fields[sum_insured_index] = "Sum Insured";
    }
    this.commonfunctions.exportAsExcelFile(data, fields, "RateData");
  }





}

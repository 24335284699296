import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ApiService } from '../../services/apiservices.service';
import { MandatoryfieldService } from '../../common/Validators/mandatoryfield.service';
import Swal from 'sweetalert2';
import { FormBuilder, FormArray, Validators, FormControl, FormGroup } from '@angular/forms';
import { AlertService } from '../../services/alert.service';
import { CommonfunctionsService } from '../../services/commonfunctions.service';
@Component({
  selector: 'app-choosedetails',
  templateUrl: './choosedetails.component.html',
  styleUrls: ['./choosedetails.component.css']
})
export class ChoosedetailsComponent implements OnInit, OnDestroy {
  tieupForm: any;
  paramForm: any;
  dropdown: any = [];
  tieuplist: any;
  loading: any = 0;
  fileProcess: any = 0;
  showInputs: boolean = false;
  searchTieup: boolean = false;
  loadForm: boolean = false;
  parameter: any = {
    name: [],
    id: []
  }
  allList: any = {
    values: []
  }
  result: any = {};
  adminDataExist:boolean=false;
  permissionComponent = {
    'detrending': {view: false, edit: false},
    'gp_downscaling': {view: false, edit: false},
    'catloading': {view: false, edit: false},
    'prevented_sowing_loading': {view: false, edit: false},
  }
  combinationInputData: any;
  GetStatusHitCount: number;
  currentYear: any;
  timer: any = null;
  transactionData: any[] = [];
  selectedTransaction: any;
  constructor(private formBuilder: FormBuilder, private apiData: ApiService, private validate: MandatoryfieldService, public alertservice: AlertService, private commonfunctions: CommonfunctionsService) { }

  ngOnInit() {
    this.alertservice.mrCode.remove();
    this.alertservice.deleteLocal("upload");
    this.alertservice.deleteLocal("loading");
    this.paramForm = new FormGroup({});
    this.alertservice.changeStepper(1);
    if (!this.validate.isUndefined(this.alertservice.getUserDetails("getTieUp"))) {
      this.searchTieup = true;
      this.showInputs = false;
      this.loadDropDown();
    } else {
      this.getFieldList();
      this.showInputs = true;
    }
    this.getPermissions()
    this.getTransactonByUserId();
  }

  getTransactonByUserId() {
    this.loading++;
    this.apiData.rateGenerationApi.getTransactonByUserId(res => {
      if (res && res.status == 1 && res.data) {
        let transactionData: any[] = [];
        res.data.sort((a,b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()).forEach((ele: any, i) => {
          if (i < 5) {
            ele.label = `${ele.rateGenerationID} (${ele.stateName})`;
            transactionData.push(ele)
          }
        });
        this.transactionData = transactionData;
        this.loading--;
      }
    },err => {
      this.loading--;
      this.commonfunctions.serviceErrorHandling(err);
    })
  }

 async  getRateGenerationData() {
    this.loading++
    this.apiData.rateGenerationApi.getRateGenerationData(async (res) => {
      if (res && res.status == 1) {
        const input = res.rateGeneration.input;
        if (input && input.length > 0) {
          const state = input.find(d => d.key == 'state');
          const level = input.find(d => d.key == 'level');
          const admin = input.find(d => d.key == 'admin');
          let season = input.find(d => d.key == 'season');
          let crop = input.find(d => d.key == 'crop');
          season = season.value ? season.value : null;
          crop = crop.value ? crop.value : null;
          this.alertservice.combinationId.set(res.rateGeneration.id);
          if (!this.currentYear) {
            await this.getDefault()
          }
          const param = btoa(JSON.stringify([state ? state.value : null, level ? level.value : null, admin ? admin.value : null, season, crop, this.currentYear]))
          this.alertservice.navigateTo("/rate-generate", param);
        }
      }
      this.loading--;

    }, err => {
      this.loading--;
      this.commonfunctions.serviceErrorHandling(err);
    })
  }

  onViewTransactionClick() {
    const transaction = this.transactionData.find(d => d.id == this.selectedTransaction);
    if (transaction) {
      this.alertservice.mrCode.set(this.selectedTransaction);
      this.getTransactonByMRId();
    }
  }

  onClearTransactionClick() {
    this.loading++
    this.apiData.rateGenerationApi.deleteTransactionByUserId(res => {
      if (res && res.status == 1) {
        this.validate.popUpAlert("Failed transaction are cleared", "", "success");
        this.getTransactonByUserId()
      }
      this.loading--;
    }, err => {
      this.commonfunctions.serviceErrorHandling(err);
      this.loading--;
    })
  }

  getPermissions() {
    const permissions = this.alertservice.getUserDetails("permissions");
    const permissionList = permissions ? JSON.parse(JSON.stringify(permissions)) : []
    if (permissionList && permissionList.length) {
      const detrending = permissionList.find(data => data.loadingDiscountId == 'detrending');
      const gp_downscaling = permissionList.find(data => data.loadingDiscountId == 'gpDownscaling');
      const catloading = permissionList.find(data => data.loadingDiscountId == 'catastropheLoading');
      const preventedSowingLoading = permissionList.find(data => data.loadingDiscountId == 'prevented_sowing_loading');
      if (detrending) {
        this.permissionComponent.detrending.view = detrending.view;
        this.permissionComponent.detrending.edit = detrending.edit;
      }
      if (gp_downscaling) {
        this.permissionComponent.gp_downscaling.view = gp_downscaling.view;
        this.permissionComponent.gp_downscaling.edit = gp_downscaling.edit;
      }
      if (catloading) {
        this.permissionComponent.catloading.view = catloading.view;
        this.permissionComponent.catloading.edit = catloading.edit;
      }
      if (preventedSowingLoading) {
        this.permissionComponent.prevented_sowing_loading.view = preventedSowingLoading.view;
        this.permissionComponent.prevented_sowing_loading.edit = preventedSowingLoading.edit;
      }
    }
  }
  loadDropDown() {
    this.loading++;
    this.apiData.getdropdownList.getTieUplist(res => {
      this.loading--;
      if (!this.validate.isUndefined(res)) {
        let result = res;
        if (result.status == 1) {
          this.tieuplist = result.data;
        } else {
          let errorText = this.alertservice.ErrorText.checkError(result.data);
          this.validate.popUpAlert(errorText, "", "error");
        }
      }
    }, err => {
      this.loading--;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }
  async getFieldList() {
    this.loading++;
    this.apiData.getParameterAPI.getlist(async (res) => {
      if (!this.validate.isUndefined(res)) {
        let result = res;
        this.result = result;
        if (result.status == 1) {
          if (result.inputCombination.length > 0) {
            this.generateFields(result.inputCombination);
            this.paramForm =  await this.createform();
            this.loadForm = true
            this.paramForm.patchValue(this.apiData.inputCombo.popData());
            if (!this.validate.isUndefined(this.apiData.inputCombo.data["level"])) {
              this.select("level");
              this.paramForm.controls["admin"].setValue(this.apiData.inputCombo.data["admin"]);
            }
          } else {
            this.validate.popUpAlert("Inputs are not available", "", "error");
          }
        }
        this.loading--;
      }
    }, err => {
      this.loading--;
      this.commonfunctions.serviceErrorHandling(err);
    });
  }
  generateFields(inputs) {
    this.parameter.name = [];
    for (let i = 0; i < inputs.length; i++) {
      this.parameter.name.push(inputs[i].paramName);
      this.parameter.id.push(inputs[i].parameterID.toLowerCase());
      this.dropdown = [];
      if (!this.validate.isUndefined(inputs[i].data)) {
        for (let j = 0; j < inputs[i].data.length; j++) {
          this.dropdown.push({ text: inputs[i].data[j].text, value: inputs[i].data[j].value });
        }
      }
      this.allList.values.push(this.dropdown);
    }
  }
  async createform() {
    const defultValues = await this.getDefault()
    const group = this.formBuilder.group({});
    for (let i = 0; i < this.parameter.id.length; i++) {
      if (this.parameter.id[i] == "admin") {
        group.addControl(this.parameter.id[i], this.formBuilder.control('', Validators.required));
      } else {
        if (this.parameter.id[i] == 'detrending') {
          group.addControl(this.parameter.id[i], this.formBuilder.control(defultValues.detrending ? 'True' : 'False', Validators.required));
        } else if (this.parameter.id[i] == 'gp_downscaling') {
          group.addControl(this.parameter.id[i], this.formBuilder.control(defultValues.gpDownscaling ? 'True' : 'False', Validators.required));
        } else if (this.parameter.id[i] == 'catloading') {
          group.addControl(this.parameter.id[i], this.formBuilder.control(defultValues.catastropheLoading ? 'True' : 'False', Validators.required));
        } else if (this.parameter.id[i] == 'prevented_sowing_loading') {
          group.addControl(this.parameter.id[i], this.formBuilder.control(defultValues.preventedSowingLoading ? 'True' : 'False', Validators.required));
        } else {
          group.addControl(this.parameter.id[i], this.formBuilder.control('', Validators.required));
        }
      }
    }
    return group;
  }
  select(parameter) {
    if (parameter == "state" || parameter == "level") {
      this.paramForm.controls["admin"].setValue('');
      if (!this.validate.isUndefined(this.paramForm.controls["state"]) && !this.validate.isUndefined(this.paramForm.controls["state"].value) && !this.validate.isUndefined(this.paramForm.controls["level"].value)) {
        this.getLevel();
      }
    } else if (parameter == "admin") {
      this.alertservice.rateGenerationID.remove();
      this.alertservice.combinationId.remove();
      this.alertservice.isFinalRate.remove();
    }
  }
  clickField(clickedField, parameterList,allListindex) {
    for (var i = 0; i < this.parameter.id.length; i++) {
      if (clickedField == this.parameter.id[i] && i != 0) {
        let previousName = this.parameter.name[i - 1];
        let previousId = this.parameter.id[i - 1];
        if (this.validate.isUndefined(this.paramForm.controls[previousId].value)) {
          this.validate.popUpAlert("Select " + previousName.replace('Input Parameter ', '') + " Value", "Required", "warning");
        }
        else if(clickedField=="admin")
        {
          var level_val=this.paramForm.controls["level"].value;
          var levelddl=this.allList.values[allListindex-1];
          var level_name=level_val;
          for(var iadmin=0;iadmin<levelddl.length;iadmin++)
          {
            if(levelddl[iadmin].value==level_val)
            {
              level_name=levelddl[iadmin].text;
              break;
            }
          }

          if(this.adminDataExist!=true)
          {
            this.validate.popUpAlert(level_name + " details not configured", "", "warning");
          }
        }
      }
    }
  }
  getLevel() {
    this.loading++;
    this.adminDataExist=false;
    let request = {
      "tieupId": this.alertservice.getUserDetails("getTieup"),
      "state": this.paramForm.controls["state"].value,
      "adminOption": this.paramForm.controls["level"].value
    }
    this.apiData.saveData.pushdata(request);
    this.apiData.rateGenerationApi.getAdminLevel(res => {
      this.loading--;
      if (!this.validate.isUndefined(res)) {
        if (res.status == 1) {
          this.allList.values = [];
          this.generateFields(this.result.inputCombination)
          let result = res.inputCombination;
          let list = [];
          if (result.length>0 && !this.validate.isUndefined(result[0].data)) {
            if (request.adminOption === 'cluster') {
              result[0].data.sort((a:any,b:any) => {
                if (isNaN(a.text)) {
                  return -1
                } else {
                  return +a.text - +b.text;
                }
              });
            } else {
              result[0].data.sort((a:any,b:any) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()))
            }

            if(result[0].data.length>0)
            {
              this.adminDataExist=true;
            }

            for (let j = 0; j < result[0].data.length; j++) {
              list.push(result[0].data[j].text);
            }
            for (var i = 0; i < this.allList.values.length; i++) {
              if (this.allList.values[i].length == 0) {
                this.allList.values[i] = list;
              }
            }
          }
        } else {
          let errorText = this.alertservice.ErrorText.checkError(res.data);
          this.validate.popUpAlert(errorText, "", "error");
        }
      }
    }, err => {
      let errorText = this.alertservice.ErrorText.checkError(err);
      this.commonfunctions.serviceErrorHandling(err);
    });
  }
  createRequest(input_array) {
    let objValues = Object.keys(input_array);
    let inputs = [];
    for (let i = 0; i < objValues.length; i++) {
      inputs.push({ "key": objValues[i], "value": input_array[objValues[i]] })
    }
    return {
      "input": inputs
    }
  }
  gotNext() {
    let isValid = this.validate.submitDetails(this.paramForm);
    if (isValid) {
      this.apiData.inputCombo.pushdata(this.paramForm.value);
      this.apiData.formRequest.getRequest("", this.createRequest(this.paramForm.value), "inputcombination");
      this.loading++;
      this.apiData.rateGenerationApi.saveInputs(res => {
        this.loading--;
        if (res.status == 1) {
          if (this.validate.isUndefined(this.alertservice.rateGenerationID.get()))
            this.alertservice.rateGenerationID.set(res.data.rateGenerationID);
          this.alertservice.combinationId.set(res.data.id);
          this.alertservice.isFinalRate.set(res.data.isFinalRate)
          const combinationInputData = res.data.input;
          this.combinationInputData = combinationInputData;
          const state = combinationInputData.find(d => d.key == 'state');
          const level = combinationInputData.find(d => d.key == 'level');
          const admin = combinationInputData.find(d => d.key == 'admin');
          const param = btoa(JSON.stringify([state ? state.value : null, level ? level.value : null, admin ? admin.value : null]))
          const request = {rateGenerationId: res.data.id, updatedBy: res.data.createdBy};
          this.loading++;
          this.apiData.rateGenerationProcessYieldData(request).then((res: any) => {
            if (res && res.status == 1) {
              this.MRexecuteApi();
            } else {
              this.validate.popUpAlert(res.data, "", "error");
            }
            this.loading--;
          }).catch(err => {
            this.loading--;
          })
        } else if (res.status == 0) {
          let errorText = this.alertservice.ErrorText.checkError(res.data);
          this.validate.popUpAlert(errorText, "", "error");
        }
      }, err => {
        this.loading--;
        this.commonfunctions.serviceErrorHandling(err);
      });
    } else {
      let valid = true;
      Swal({
        title: "Required",
        text: "Please Select Required Fields!",
        type: "warning",
        confirmButtonColor: "#175788",
      }).then((result) => {
      });
    }
  }

  async getDefault() {
    this.loading++;
   const result = await  this.apiData.rateGenerationDefaultLoadingDiscount().then((res: any) => {
     this.loading--;
    if (res && res.status == 1) {
      this.currentYear = res.data.currentYear;
      return res.data;
    }
   }).catch(err => {
     this.loading--;
   })
   return result || {}
  }

  MRexecuteApi() {

    const combinationInputData = this.combinationInputData;
    const state = combinationInputData.find(d => d.key == 'state');
    const level = combinationInputData.find(d => d.key == 'level');
    const admin = combinationInputData.find(d => d.key == 'admin');
    let season = combinationInputData.find(d => d.key == 'season');
    let crop = combinationInputData.find(d => d.key == 'crop');
    this.loading++;
    this.fileProcess++;
		this.apiData.rateGenerationApi.MRexecuteApiNew(res => {
			if (!this.validate.isUndefined(res)) {
				if (res.status == 1) {
					if (this.validate.isUndefined(res.mrcode)) {
						this.loading--;
            this.fileProcess--;
						this.validate.popUpAlert("Upload the file again", "", "error");
            return;
					} else {
						this.GetStatusHitCount=0;
            this.alertservice.mrCode.set(res.mrcode)
            this.getTransactonByMRId()
					}
				}
				else if (res.status == 0 && res.message=='INVALID_REQUEST') {
					this.loading--;
          this.fileProcess--;
					this.validate.popUpAlert(res.data, "", "error");
				}
				else if (res.status == 0) {
					this.loading--;
          this.fileProcess--;
					let errorText = this.alertservice.ErrorText.checkError(res.data);
					this.validate.popUpAlert(errorText, "", "error");
				}
			}
		}, err => {
			this.serviceError(err);
		})
	}

  MRgetstatusApi() {
    this.loading++;
    const combinationInputData = this.combinationInputData;
		this.apiData.rateGenerationApi.MRgetstatusApi(res => {
			if (!this.validate.isUndefined(res)) {
				if (res.status == 1) {
						if (res.data.toLowerCase() == "completed") {
							this.loading--;
              this.getRateGenerationData()
						}
						else if (res.data.toLowerCase() == "terminated") {
							this.loading = 0;
							this.validate.popUpAlert("Upload the file again", "", "error");
              return
						}
						else {
							//hit count increased from start
							if(this.GetStatusHitCount<5)
							{
								setTimeout(any=>{this.MRgetstatusApi();}, 20000);
							}
							else{
								this.loading--;
								this.validate.popUpAlert("Upload the file again", "", "error");
                return
							}
						}
				}
				else if (res.status == 0 && res.message=='INVALID_REQUEST') {
					this.loading--;
					this.validate.popUpAlert(res.data, "", "error");
				}
				else if (res.status == 0) {
					this.loading--;
					let errorText = this.alertservice.ErrorText.checkError(res.data);
					this.validate.popUpAlert(errorText, "", "error");
				}
			}
		}, err => {
			this.serviceError(err);
		})
	}

  getTransactonByMRId() {
    this.loading++
    this.fileProcess++;
    this.apiData.rateGenerationApi.getTransactonByMRId(res => {
      if (res && res.status == 1 && res.data) {
        if (res.data.status === 'Completed') {
          this.loading--;
          this.fileProcess--;
          this.alertservice.rateGenerationID.set(res.data.rateGenerationID);
          this.getRateGenerationData();
        } else if (res.data.status === 'InProgress') {
          this.timer = setTimeout(() => {
            this.loading--;
            this.fileProcess--;
            this.getTransactonByMRId()
          }, 1e4)
        } else {
          this.validate.popUpAlert('Processing failed due to large data set.', "", "error");
          this.loading--;
          this.fileProcess--;
        }
      }
    }, err => {
      this.validate.popUpAlert("Unable to get Transaction Id", "", "error");
      this.serviceError(err)
      this.fileProcess--;
    })
  }

  serviceError(err) {
		this.loading--;
		this.commonfunctions.serviceErrorHandling(err);
	}

  ngOnDestroy(): void {
    clearTimeout(this.timer)
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonfunctionsService } from '../services/commonfunctions.service';


declare var $: any;

@Component({
  selector: 'app-file-upload-warnings',
  templateUrl: './file-upload-warnings.component.html',
  styleUrls: ['./file-upload-warnings.component.css']
})
export class FileUploadWarningsComponent implements OnInit {

  @Input() excelJsonData: any[] = [];
  @Input() years: any[] = [];
  @Output() action: EventEmitter<any> = new EventEmitter();
  blankData: any[] = [];
  blankFields: any[] = [];
  @Input() yearErrorData: any[] = [];
  yearErrorFields: any[] = [];
  zeroData: any[] = [];
  totalOutlierCount = 0;
  @Input() hideTitle = false

  @Input() warningYield: any;

  blankWarningYields: any = null;
  zeroWarningYields: any = null;
  outliersWarningYields: any = null;

  noOfBlankRecords: any = null;
  noOfZerosRecords: any = null;
  noOfoutliersRecords: any = null;

  isLoadingBlank = false
  isLoadingZero = false
  isLoadingOutlier = false



  constructor(private commonfunctions: CommonfunctionsService) { }

  ngOnInit() {
    if (this.warningYield) {
      this.getWarningYieldData()
    } else {
      this.getTableData(true)
    }
  }

  async getWarningYieldData() {
    this.blankWarningYields = null;
    this.zeroWarningYields = null;
    this.outliersWarningYields = null;
    let {reva_BlankWarningYields,reva_ZeroWarningYields,reva_OutliersWarningYields} = this.warningYield;
        reva_BlankWarningYields = reva_BlankWarningYields ? reva_BlankWarningYields[0] || {} : {};
        reva_ZeroWarningYields = reva_ZeroWarningYields ? reva_ZeroWarningYields[0] || {} : {};
        reva_OutliersWarningYields =reva_OutliersWarningYields ?  reva_OutliersWarningYields[0] || {} : {};
        if (reva_BlankWarningYields||reva_ZeroWarningYields||reva_OutliersWarningYields) {
          this.blankWarningYields = reva_BlankWarningYields;
          this.zeroWarningYields = reva_ZeroWarningYields;
          this.outliersWarningYields = reva_OutliersWarningYields;

          this.noOfBlankRecords = this.blankWarningYields.noOfRecords
          this.noOfZerosRecords = this.zeroWarningYields.noOfRecords
          this.noOfoutliersRecords = this.outliersWarningYields.noOfRecords
          let years = reva_BlankWarningYields.previousYears||reva_ZeroWarningYields.previousYears||reva_OutliersWarningYields.previousYears;
          this.years = years ? years.split(',') : []
          const blankData = [] // await this.getJsonFromCsvUrl(reva_BlankWarningYields.azureFilePath, 'blank');
          const zeroData = [] //  await this.getJsonFromCsvUrl(reva_ZeroWarningYields.azureFilePath, 'zero');
          const outliersData =  [] // await this.getJsonFromCsvUrl(reva_OutliersWarningYields.azureFilePath, 'outliers');
          this.excelJsonData = [...blankData, ...zeroData];
          this.yearErrorData = outliersData;
        }
        this.getTableData();
  }

  getTableData(showNoOfRecord?: any) {
    const blankData = [];
    const zeroData = [];
    const yearErrorData = [];
    const stateCount = {};
    const clusterCount = {};
    const districtCount = {};
    const tehsilCount = {};
    const blockCount = {};
    this.totalOutlierCount = this.yearErrorData.map((d: any) => +d.count || 0).reduce((a: any,b: any) => a+b, 0);
    for (let index = 0; index < this.excelJsonData.length; index++) {
      const data = this.excelJsonData[index];
      if (data.zeroError || data.blankError) {
        stateCount[data['admin1']] = stateCount[data['admin1']] ? stateCount[data['admin1']] + 1 : 1;
        clusterCount[data['cluster_num']] = clusterCount[data['cluster_num']] ? clusterCount[data['cluster_num']] + 1 : 1;
        districtCount[`${data['admin1']}-${data['admin2']}`] = districtCount[`${data['admin1']}-${data['admin2']}`] ? districtCount[`${data['admin1']}-${data['admin2']}`] + 1 : 1;
        tehsilCount[`${data['admin1']}-${data['admin2']}-${data['admin3']}`] = tehsilCount[`${data['admin1']}-${data['admin2']}-${data['admin3']}`] ? tehsilCount[`${data['admin1']}-${data['admin2']}-${data['admin3']}`] + 1 : 1;
        blockCount[`${data['admin1']}-${data['admin2']}-${data['admin3']}-${data['admin4']}`] = blockCount[`${data['admin1']}-${data['admin2']}-${data['admin3']}-${data['admin4']}`] ? blockCount[`${data['admin1']}-${data['admin2']}-${data['admin3']}-${data['admin4']}`] + 1 : 1;
        if (data.zeroError) {
          for (let i = 0; i < data.zeroError; i++) {
            zeroData.push(data);
          }
        }

        if (data.blankError) {
          for (let i = 0; i < data.blankError; i++) {
            blankData.push(data);
          }
        }
      }
      if (data.yearValueError) {
        yearErrorData.push(data);
      }
    }

    for (let index = 0; index < blankData.length; index++) {
      const data = blankData[index];
      data.stateCount = stateCount[data['admin1']];
      data.clusterCount = stateCount[data['cluster_num']];
      data.districtCount = districtCount[`${data['admin1']}-${data['admin2']}`];
      data.tehsilCount = tehsilCount[`${data['admin1']}-${data['admin2']}-${data['admin3']}`];
      data.blockCount = blockCount[`${data['admin1']}-${data['admin2']}-${data['admin3']}-${data['admin4']}`];
    }
    blankData.sort((a: any, b: any) => {
      if (a.sum_insured == b.sum_insured) {
        if (a.stateCount == b.stateCount) {
          if (a.clusterCount == b.clusterCount) {
            if (a.districtCount == b.districtCount) {
              if (a.tehsilCount == b.tehsilCount) {
                return b.blockCount - a.blockCount;
              } else {
                return b.tehsilCount - a.tehsilCount;
              }
            } else {
              return b.districtCount - a.districtCount;
            }
          } else {
            return b.clusterCount - a.clusterCount;
          }
        } else {
          return b.stateCount - a.stateCount
        }
      } else {
        return a.sum_insured - b.sum_insured;
      }
    })

    for (let index = 0; index < zeroData.length; index++) {
      const data = zeroData[index];
      data.stateCount = stateCount[data['admin1']];
      data.clusterCount = stateCount[data['cluster_num']];
      data.districtCount = districtCount[`${data['admin1']}-${data['admin2']}`];
      data.tehsilCount = tehsilCount[`${data['admin1']}-${data['admin2']}-${data['admin3']}`];
      data.blockCount = blockCount[`${data['admin1']}-${data['admin2']}-${data['admin3']}-${data['admin4']}`];
    }

    zeroData.sort((a: any, b: any) => {
      if (a.sum_insured == b.sum_insured) {
        if (a.stateCount == b.stateCount) {
          if (a.clusterCount == b.clusterCount) {
            if (a.districtCount == b.districtCount) {
              if (a.tehsilCount == b.tehsilCount) {
                return b.blockCount - a.blockCount;
              } else {
                return b.tehsilCount - a.tehsilCount;
              }
            } else {
              return b.districtCount - a.districtCount;
            }
          } else {
            return b.clusterCount - a.clusterCount;
          }
        } else {
          return b.stateCount - a.stateCount
        }
      } else {
        return a.sum_insured - b.sum_insured;
      }
    })
    this.blankData = blankData;
    this.zeroData = zeroData
    this.blankFields = [
      {
        "field": "lineNo",
        "header": "Line No"
      },
      {
        "field": "crop",
        "header": "Crop"
      },
      {
        "field": "year",
        "header": "Previous Year"
      },
      {
        "field": "season",
        "header": "Season"
      },
      {
        "field": "cluster_num",
        "header": "Cluster"
      },
      {
        "field": "admin2",
        "header": "District"
      },
      ...this.years.map(d => ({
        "field": d+'',
        "header": d+''
      })),
  ]

  this.yearErrorFields = [
    {
        "field": "year",
        "header": "Year"
    },
    {
        "field": "season",
        "header": "Season"
    },
    {
        "field": "crop",
        "header": "Crop"
    },
    {
        "field": "admin1",
        "header": "State"
    },
    {
        "field": "admin2",
        "header": "District"
    },
    {
        "field": "average",
        "header": "Average"
    },
    {
        "field": "max_50",
        "header": "+50%"
    },
    {
        "field": "min_50",
        "header": "-50%"
    },
    ...this.years.map(d => ({
      "field": d+'',
      "header": d+''
    })),
    {
        "field": "count",
        "header": "Count"
    }
]

if (showNoOfRecord) {
  this.noOfBlankRecords = this.blankData.length
  this.noOfZerosRecords = this.zeroData.length
  this.noOfoutliersRecords = this.yearErrorData.length
}

  this.refreshBlankTable();
  this.refreshYearErrorTable();
  }


  refreshBlankTable() {
    $("#blankReport").bootstrapTable("destroy");
  setTimeout(() => {
    $("#blankReport").bootstrapTable({
      data: this.blankData,
      columnDefs: [
        {
          className: "table-data"
        }
      ],
      select: true
    });
  })
    $("#zeroReport").bootstrapTable("destroy");
  setTimeout(() => {
    $("#zeroReport").bootstrapTable({
      data: this.zeroData,
      columnDefs: [
        {
          className: "table-data"
        }
      ],
      select: true
    });
  })
  }
  refreshYearErrorTable() {
    $("#yearErrorReport").bootstrapTable("destroy");
  setTimeout(() => {
    $("#yearErrorReport").bootstrapTable({
      data: this.yearErrorData,
      columnDefs: [
        {
          className: "table-data"
        }
      ],
      select: true
    });
  })
  }

  goto(process) {
    this.action.emit(process);
  }

  exportAsXLSX(data, fields, name): void {
    this.commonfunctions.downloadTableToExcel(data, fields, name);
  }

  async getJsonFromCsvUrl(url: any, type) {
    const jsonData: any[] = [];
    const csvText = await fetch(url).then(res => res.text())
    if (csvText) {
      const records = csvText.split('\n');
      if (records.length > 1) {
        const header = records[0].split(',').map(d => d.trim());
        for (let i = 1; i < records.length; i++) {
          const data = records[i].split(',');
          const result: any = {};
          header.forEach((k: any, i: any) => result[k] = data[i])
          result['lineNo'] = result.csvRowNumber;
          if (type == 'blank') {
            result['blankError'] = true;
          } else if (type == 'zero') {
            result['zeroError'] = true;
          } else if (type == 'outliers') {
            result['max_50'] = result.plusFifty;
            result['min_50'] = result.minusFifty;
            // result['average'] = result['avaerage'];
          }
          jsonData.push(result)
        }
      }
    }
    return jsonData;
  }

  downloadFile(data, field, name, warningYield) {
    if (warningYield && warningYield.azureFilePath) {
      const element = document.createElement('a');
      element.href = warningYield.azureFilePath;
      element.target = 'download';
      element.click();
      element.remove();
    } else {
      this.exportAsXLSX(data, field, name);
    }
  }

 async displayBlankData(warningYields) {
  this.isLoadingBlank = true;
    const blankData = await this.getJsonFromCsvUrl(warningYields.azureFilePath, 'blank');
    blankData.sort((a: any, b: any) => {
      if (a.sum_insured == b.sum_insured) {
        if (a.stateCount == b.stateCount) {
          if (a.clusterCount == b.clusterCount) {
            if (a.districtCount == b.districtCount) {
              if (a.tehsilCount == b.tehsilCount) {
                return b.blockCount - a.blockCount;
              } else {
                return b.tehsilCount - a.tehsilCount;
              }
            } else {
              return b.districtCount - a.districtCount;
            }
          } else {
            return b.clusterCount - a.clusterCount;
          }
        } else {
          return b.stateCount - a.stateCount
        }
      } else {
        return a.sum_insured - b.sum_insured;
      }
    })
    this.blankData = blankData
    this.noOfBlankRecords = this.blankData.length
    this.refreshBlankTable();
    this.isLoadingBlank = false;
  }
 async displayZeroData(warningYields) {
  this.isLoadingZero = true;
    const zeroData = await this.getJsonFromCsvUrl(warningYields.azureFilePath, 'blank');
    zeroData.sort((a: any, b: any) => {
      if (a.sum_insured == b.sum_insured) {
        if (a.stateCount == b.stateCount) {
          if (a.clusterCount == b.clusterCount) {
            if (a.districtCount == b.districtCount) {
              if (a.tehsilCount == b.tehsilCount) {
                return b.blockCount - a.blockCount;
              } else {
                return b.tehsilCount - a.tehsilCount;
              }
            } else {
              return b.districtCount - a.districtCount;
            }
          } else {
            return b.clusterCount - a.clusterCount;
          }
        } else {
          return b.stateCount - a.stateCount
        }
      } else {
        return a.sum_insured - b.sum_insured;
      }
    })
    this.zeroData = zeroData
    this.noOfZerosRecords = this.zeroData.length;
    this.refreshBlankTable();
    this.isLoadingZero = false;
  }
async displayOutliersData(warningYields) {
  this.isLoadingOutlier = true
  const outliersData =  await this.getJsonFromCsvUrl(warningYields.azureFilePath, 'outliers');
  this.yearErrorData = outliersData;
  this.noOfoutliersRecords = this.yearErrorData.length;
  this.refreshYearErrorTable();
  this.isLoadingOutlier = false;
}

}

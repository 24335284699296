import { Injectable } from '@angular/core';
import { ApiService } from './apiservices.service';
import { AlertService } from './alert.service';
import { MandatoryfieldService } from '../common/Validators/mandatoryfield.service';
declare function escape(s: string): string;
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as moment from "moment";
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xls';
@Injectable()
export class CommonfunctionsService {
  constructor(private apiData: ApiService, private alertService: AlertService, private validate: MandatoryfieldService) { }

  public csvDownloadFromServer(res) {
    let parsedResponse = res.body;
    let filename = res.headers.get("content-disposition").toString().split(";")[1].split('=')[1].trim();
    let blob = new Blob([parsedResponse], { type: 'text/csv' });
    let url = window.URL.createObjectURL(blob);
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, filename);
    } else {
      let a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }
  public JSONToXLSConvertor(JSONData, file, ShowLabel) {
    var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    var CSV = '';
    if (ShowLabel) {
      var row = "";
      for (var index in arrData[0]) {
        row += index + ',';
      }
      row = row.slice(0, -1);
      CSV += row + '\r\n';
    }
    for (var i = 0; i < arrData.length; i++) {
      var row = "";
      for (var index in arrData[i]) {
        var arrValue = arrData[i][index] == null ? "" : '"' + arrData[i][index] + '"';
        row += arrValue + ',';
      }
      row = row.slice(0, row.length - 1);
      CSV += row + '\r\n';
    }
    if (CSV == '') {
      return;
    }
    return CSV;
  }
  public xlsFileDownload(data, fileName) {
    let blob = new Blob([data], { type: 'application/xls' });
    let url = window.URL.createObjectURL(blob);
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, fileName + ".xls");
    } else {
      let a = document.createElement('a');
      a.href = url;
      a.download = fileName + ".xls";;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }
  public exportAsExcelFile(json: any[], headers: any[], excelFileName: string): void {
    if(json.length==0)
    {
      json=new Array();
    }
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, { header: headers });
    console.log('worksheet', worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xls', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  public exportAsMultiExcelFile(json: any[], headers: any[], excelFileName: string): void {
    var objValues = Object.keys(headers);
    var SheetNames = [];
    var sheets = {};
    var data = [];
    for (var i = 0; i < objValues.length; i++) {
      let object = objValues[i];
      SheetNames.push(object);
      if(json[object].length==0)
      {
        json[object]=new Array();
      }
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json[object], { header: headers[object] });
      data.push({ [object]: worksheet });
      var keys = Object.keys(data[i]);
      keys.map(obj => {
        sheets[obj] = data[i][obj];
      });
    }
    const workbook: XLSX.WorkBook = { Sheets: sheets, SheetNames: SheetNames };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xls', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  public exportComprehensiveExcel(json: any[], headers: any[], excelFileName: string): void {
    var objValues = Object.keys(headers);
    var SheetNames = [];
    var sheets = {};
    var data = [];
    var validRptCt=0;
    for (var i = 0; i < objValues.length; i++) {
      let object = objValues[i];
      console.log(object)
      console.log(json[object].length)
      if(json[object].length>0)
      {
        SheetNames.push(object);
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json[object], { header: headers[object] });
        data.push({ [object]: worksheet });
        var keys = Object.keys(data[validRptCt]);
        keys.map(obj => {
          sheets[obj] = data[validRptCt][obj];
        });
        validRptCt++;
      }
    }
    const workbook: XLSX.WorkBook = { Sheets: sheets, SheetNames: SheetNames };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xls', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });

    FileSaver.saveAs(data, fileName + '_' + this.getDateTimeExt() + EXCEL_EXTENSION);
  }
  /**
   * getDateTimeExt
   */
  public getDateTimeExt() {
    var today = new Date();
    var current_date = today.getDate();
    var current_month = today.getMonth() + 1; //Month starts from 0
    var current_year = today.getFullYear();
    var postname = current_date.toString() + current_month.toString() + current_year.toString();
    return postname + today.getTime();
  }
  public serviceErrorHandling(error) {
    let errorText = this.alertService.ErrorText.checkError(error);
    this.validate.popUpAlert(errorText, "", "error");
    this.apiData.errorhanlingApi.generateRequestData(error, window.location.pathname);
    this.apiData.errorhanlingApi.sendError(res => {
    }, err => {
    });
  }

  downloadTableToExcel(data, fields, name) {
    const excelData = [];
    const header = [];
    const headerFields = [];
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      header.push(field.header.toLowerCase().trim().replaceAll(' ', '_'))
      headerFields.push(field.field)
    }
    excelData.push(header);
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      const row = [];
      for (let kIndx = 0; kIndx < fields.length; kIndx++) {
        const key = fields[kIndx].field;
        row.push(element[key]);
      }
      excelData.push(row);
    }
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(excelData); //  json_to_sheet(excelData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const sheetName = name;
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, `${moment(new Date()).format('yyyyMMDD')}_${name}.xlsx`);
  }
}

import { Component, OnInit } from "@angular/core";
import { ApiService } from "../services/apiservices.service";
import Swal from "sweetalert2";
import { MandatoryfieldService } from "../common/Validators/mandatoryfield.service";
import { UploadEvent, UploadFile, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { AlertService } from "../services/alert.service";
import { CommonfunctionsService } from "../services/commonfunctions.service";
import * as XLSX from 'xlsx';

declare var $: any;

@Component({
  selector: "app-district-wise-form",
  templateUrl: "./district-wise-form.component.html",
  styleUrls: ["./district-wise-form.component.css"],
})
export class DistrictWiseFormComponent implements OnInit {
  fileList: any;
  loading = 0;
  configData: any;
  stateDistrictData: any[] = [];
  stateData: any[] = [];
  fileStateData: any[] = [];
  districtData: any[] = [];
  selectedState: any;
  selectedFileState: any;
  selectedDistrict: any;
  loadingPermission: any[] = [];
  fileName: any = '';
  districtActionStatus: any;
  actionData: any[] = [];
  activeTab = 1;
  inProgress = false;
  currentFilePath: any = '';
  readonly districtDefultHeader = `Scheme,State,District,Season,Politically Active,Constituency of Agriculture/ Chief/ Cabinet Minister,Name of Political leader,Govt Support,Farmer Awareness,% Loanee,Major insured crop 1 (Name),Major insured crop 1 (% Share),Major insured crop 2 (Name),Major insured crop 2 (% Share),Irrigation %,Type of Irrigation,Major Loss Years-1,Reason of loss,Major Loss Years-2,Reason of loss 2,Major Loss Years-3,Reason of loss 3,Major Loss Years-4,Reason of loss 4,Major Loss Years-5,Reason of loss 5,Comments/ Historical Issues,Politically Active Loading,Govt Support Loading,Farmer Awareness Loading,ENSO,IOD,Monsoon forecast,ENSO Loading,IOD Loading,Monsoon forecast Loading`;
  distictDefaultValues = ``;

  constructor(
    private apiData: ApiService,
    private validate: MandatoryfieldService,
    public alertService: AlertService,
    private commonfunctions: CommonfunctionsService,
  ) {
    this.actionData = [{text: 'Upload', value: 'upload'}, {text: 'Update', value: 'update'}]
  }

  ngOnInit() {
    // "loadingDiscountPermissions": [
    //   {
    //     "loadingDiscountId": "string",
    //     "view": true,
    //     "edit": true
    //   }
    // ]
    this.getStateDistrictData();
  }

  fetchConfigData() {
    this.loading++;
    this.distictDefaultValues = '';
    this.apiData.loadingApi.getloadingDiscount(
      (res: any) => {
        if (res && res.status == 1) {
          this.configData = res.data;
          this.addCsvData(this.configData)
          this.validateAllData()
        }
        this.loading--;
      },
      (err) => {
        this.loading--;
      },
      "district",
      this.selectedState,
      this.selectedDistrict
    );
  }

  addCsvData(resData: any) {
    const weather_forecast = resData.ldWithSubSections.find((d:any) => d.loadingDiscountID == 'weather_forecast');
    const farm_management_practices = resData.ldWithSubSections.find((d:any) => d.loadingDiscountID == 'farm_management_practices');
    let esno_data: any = {};
    let iod_data: any = {};
    let monsoon_data: any = {};
    let farmer_awareness_data: any = {};
    let govt_support_data: any = {};
    let political_scenario_data: any = {};

    if (weather_forecast) {
      const esno_sub_section = weather_forecast.subSections.find(d => d.key == 'wf_ensoelninoimpact');
      if (esno_sub_section && esno_sub_section.uwPerceptionData && esno_sub_section.uwPerceptionData.length) {
        esno_data = esno_sub_section.uwPerceptionData.find((d: any) => d.defaultUWPerception) || {};
      }

      const iod_sub_section = weather_forecast.subSections.find(d => d.key == 'wf_iod_forecast');
      if (iod_sub_section && iod_sub_section.uwPerceptionData && iod_sub_section.uwPerceptionData.length) {
        iod_data = iod_sub_section.uwPerceptionData.find((d: any) => d.defaultUWPerception) || {};
      }

      const monsoon_sub_section = weather_forecast.subSections.find(d => d.key == 'wf_monsoonforecast');
      if (monsoon_sub_section && monsoon_sub_section.uwPerceptionData && monsoon_sub_section.uwPerceptionData.length) {
        monsoon_data = monsoon_sub_section.uwPerceptionData.find((d: any) => d.defaultUWPerception) || {};
      }
    }

    if (farm_management_practices) {
      const farmer_awareness_sub_section = farm_management_practices.subSections.find(d => d.key == 'fm_farmerawareness');
      if (farmer_awareness_sub_section && farmer_awareness_sub_section.uwPerceptionData && farmer_awareness_sub_section.uwPerceptionData.length) {
        farmer_awareness_data = farmer_awareness_sub_section.uwPerceptionData.find((d: any) => d.defaultUWPerception) || {};
      }

      const govt_support_sub_section = farm_management_practices.subSections.find(d => d.key == 'fm_govtsupport');
      if (govt_support_sub_section && govt_support_sub_section.uwPerceptionData && govt_support_sub_section.uwPerceptionData.length) {
        govt_support_data = govt_support_sub_section.uwPerceptionData.find((d: any) => d.defaultUWPerception) || {};
      }

      const political_scenario_sub_section = farm_management_practices.subSections.find(d => d.key == 'fm_politicalscenario');
      if (political_scenario_sub_section && political_scenario_sub_section.uwPerceptionData && political_scenario_sub_section.uwPerceptionData.length) {
        political_scenario_data = political_scenario_sub_section.uwPerceptionData.find((d: any) => d.defaultUWPerception) || {};
      }
    }

    this.distictDefaultValues = `"","${this.selectedState}","${this.selectedDistrict}","","${political_scenario_data.text || ''}","","${govt_support_data.text || ''}","${farmer_awareness_data.text || ''}","","","","","","","","","","","","","","","","","","","","${political_scenario_data.value || 0}","${govt_support_data.value || 0}","${farmer_awareness_data.value || 0}","${esno_data.text || ''}","${iod_data.text || ''}","${monsoon_data.text || ''}","${esno_data.finalLoadingPercentage || 0}","${iod_data.finalLoadingPercentage || 0}","${monsoon_data.finalLoadingPercentage || 0}"`;

  }

  getStateDistrictData() {
    this.loading++;
    this.apiData
      .getStateDistrictWiseData()
      .then((res: any) => {
        if (res && res.status == 1) {
          this.stateDistrictData = res.data || [];
        }
        if (this.stateDistrictData && this.stateDistrictData.length) {
          this.stateData = Array.from(
            new Set(this.stateDistrictData.filter((data:any) => data.state !== 'All').map((data: any) => data.state))
          ).map((data) => ({ state: data }));
          this.fileStateData = Array.from(
            new Set(this.stateDistrictData.map((data: any) => data.state))
          ).map((data) => ({ state: data }));
        }
        this.loading--;
      })
      .catch((err) => {
        this.loading--;
      });
  }

  onStateChange(event) {
    this.configData = null;
    this.selectedDistrict = null;
    this.districtData = [];
    if (event) {
      this.districtData = this.stateDistrictData.filter(
        (location) => location.state == event
      );
    }
  }

  onFileStateChange(event) {
    this.districtActionStatus = null;
    this.fileList = null;
    this.fileName = null;
    this.getDistrictUploadTransaction();
  }

  getDistrictUploadTransaction() {
    this.currentFilePath = '';
    this.loading++;
    this.apiData.masterUploadGetDistrictUploadTransaction().then((res: any) => {
      if (res && res.status == 1) {
        const stateFilesData = res.data.filter((d: any) => d.state == this.selectedFileState);
        if (stateFilesData) {
          const recentDate = stateFilesData.map((d: any) => new Date(d.createdAt).getTime())
          const fileData = stateFilesData.find(d => new Date(d.createdAt).getTime() == recentDate);
          if (fileData) {
            this.currentFilePath = fileData.azureFilePath;
          }
        }
      }
      this.loading--;
    }).catch(err => {
      console.error(err)
      this.loading--;
    })
  }

  onDistrictChange() {
    this.configData = null;
    this.districtActionStatus = 'showButtons'
    this.fetchConfigData();
  }

  onActionChange(event: any) {
   if (event == 'update' && this.selectedState && this.selectedDistrict) {
    this.fetchConfigData();
   }
  }
  onPerceptionValueChange(uwPerceptionData: any, subSection: any) {
    this.validPerceptionData(uwPerceptionData, subSection);
  }

  onCardSave(data, indx) {
    if (!this.validateCard()) {
      return;
    }
    for (
      let index = 0;
      index < this.configData.ldWithSubSections.length;
      index++
    ) {
      const ldWithSubSections = this.configData.ldWithSubSections[index];
      ldWithSubSections.isCollapsed = true;
      if (indx == index) {
        ldWithSubSections.isDataSaved = true;
      }
    }

    if (indx < this.configData.ldWithSubSections.length - 1) {
      this.configData.ldWithSubSections[indx + 1].isCollapsed = false;
      this.openCard(
        this.configData.ldWithSubSections[indx + 1].loadingDiscountID
      );
    } else {
      this.closeCard(this.configData.ldWithSubSections[indx].loadingDiscountID);
    }
  }

  onCardToggle(loadingDiscountID) {
    if (!this.validateCard()) {
      return;
    }
    this.toogleCard(loadingDiscountID);
  }

  onCardEdit(loadingDiscountID) {
    if (!this.validateCard()) {
      return;
    }
    this.openCard(loadingDiscountID);
  }

  onDefaultChange(perceptionData, pindx) {
    for (let index = 0; index < perceptionData.length; index++) {
      if (index != pindx) {
        const element = perceptionData[index];
        element.defaultUWPerception = false;
      }
    }
  }

  toogleCard(loadingDiscountID) {
    $(`#${loadingDiscountID}`).collapse("toggle");
  }
  openCard(loadingDiscountID) {
    $(`#${loadingDiscountID}`).collapse("show");
  }
  closeCard(loadingDiscountID) {
    $(`#${loadingDiscountID}`).collapse("hide");
  }

  validateAllData() {
    const ldWithSubSections = this.configData.ldWithSubSections;
    const ldNoSubSections = this.configData.ldNoSubSections;
    if (Array.isArray(ldWithSubSections) && Array.isArray(ldNoSubSections)) {
      for (let index = 0; index < ldWithSubSections.length; index++) {
        const element = ldWithSubSections[index];
        for (
          let subSecIndx = 0;
          subSecIndx < element.subSections.length;
          subSecIndx++
        ) {
          const subSection = element.subSections[subSecIndx];
          this.validPerceptionData(subSection.uwPerceptionData, subSection)
        }
      }
      for (let index = 0; index < ldNoSubSections.length; index++) {
        const element = ldNoSubSections[index];
        // if (
        //   [null, undefined, ""].includes(element.totalFinalLoadingPercentage)
        // ) {
        //   errorMessage.msg = `${element.loadingDiscountName} value should not be empty`;
        //   break;
        // }
      }
    }
  }

  validPerceptionData(uwPerceptionData: any, subSection: any) {
    const errorMessage = {};
    if (Array.isArray(uwPerceptionData)) {
      for (
        let perpIndex = 0;
        perpIndex < uwPerceptionData.length;
        perpIndex++
      ) {
        const perception = uwPerceptionData[perpIndex];
        if (perception.options) {
          if (
            [null, undefined, ""].includes(
              perception.finalLoadingPercentageMin
            )
          ) {
            // errorMessage.eleId = `${subSection.key}_${perception.text}_min`;
            errorMessage[`${subSection.key}_${perception.text}_min`] = "Min value is requried";
          }
          if (
            [null, undefined, ""].includes(
              perception.finalLoadingPercentageMax
            )
          ) {
            // errorMessage.eleId = `${subSection.key}_${perception.text}_max`;
            errorMessage[`${subSection.key}_${perception.text}_max`] = "Max value is requried";
          }
          if (
            [null, undefined, ""].includes(
              perception.finalLoadingPercentage
            )
          ) {
            // errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
            errorMessage[`${subSection.key}_${perception.text}_percentage`] = "Percentage value is requried";
          }
          if (
            +perception.finalLoadingPercentageMin >
            +perception.finalLoadingPercentageMax
          ) {
            // errorMessage.eleId = `${subSection.key}_${perception.text}_min`;
            errorMessage[`${subSection.key}_${perception.text}_min`] = "Min value should be less than Max value";
          }
          if (
            !perception.options.find(
              (opt) =>
                +opt.value == +perception.finalLoadingPercentageMin
            )
          ) {
            // errorMessage.eleId = `${subSection.key}_${perception.text}_min`;
            errorMessage[`${subSection.key}_${perception.text}_min`] =
              "Min value should be selected from given options only";
          }
          if (
            !perception.options.find(
              (opt) =>
                +opt.value == +perception.finalLoadingPercentageMax
            )
          ) {
            // errorMessage.eleId = `${subSection.key}_${perception.text}_max`;
            errorMessage[ `${subSection.key}_${perception.text}_max`] =
              "Max value should be selected from given options only";
          }
          if ( (![null, undefined, ""].includes(perception.finalLoadingPercentage)) &&
           (![null, undefined, ""].includes(perception.finalLoadingPercentageMin)) &&
            (![null, undefined, ""].includes(perception.finalLoadingPercentageMax)) &&
            (perception.finalLoadingPercentage <
              perception.finalLoadingPercentageMin ||
            perception.finalLoadingPercentage >
              perception.finalLoadingPercentageMax)
          ) {
            // errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
            errorMessage[`${subSection.key}_${perception.text}_percentage`] =
              "Final loading value should be between min and max value";
          }
          else if ((+perception.finalLoadingPercentage % 1) != 0) {
            errorMessage[`${subSection.key}_${perception.text}_percentage`] =
              "Final loading value should not have decimal number";
          }
        } else {
          if ([null, undefined, ""].includes(perception.value)) {
            // errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
            errorMessage[`${subSection.key}_${perception.text}_percentage`] = `${subSection.title}'s ${perception.text} value is requried`;
          } else if (perception.value < -100 || perception.value > 200) {
            errorMessage[`${subSection.key}_${perception.text}_percentage`] = `${subSection.title}'s ${perception.text} value should be between -100 to 200`;
          }
        }
        perception.errorMessage = errorMessage;
      }
    }
  }

  validateCard() {
    const collapseId = $(".collapse.show").prop("id");
    if (collapseId) {
      let errorMessage: any = {msg: null, eleId: null};
      this.configData;
      const ldWithSubSections = this.configData.ldWithSubSections;
      if (Array.isArray(ldWithSubSections)) {
        for (let index = 0; index < ldWithSubSections.length; index++) {
          const element = ldWithSubSections[index];
          if (element.loadingDiscountID == collapseId) {
            if (errorMessage.msg) {
              break;
            }
            for (
              let subSecIndx = 0;
              subSecIndx < element.subSections.length;
              subSecIndx++
            ) {
              const subSection = element.subSections[subSecIndx];
              for (
                let perpIndex = 0;
                perpIndex < subSection.uwPerceptionData.length;
                perpIndex++
              ) {
                if (errorMessage.msg) {
                  break;
                }
                const perception = subSection.uwPerceptionData[perpIndex];
                if (perception.options) {
                  if (
                    [null, undefined, ""].includes(
                      perception.finalLoadingPercentageMin
                    )
                  ) {
                    errorMessage.eleId = `${subSection.key}_${perception.text}_min`;
                    errorMessage.msg = "Min Value should not be empty";
                    break;
                  }
                  if (
                    [null, undefined, ""].includes(
                      perception.finalLoadingPercentageMax
                    )
                  ) {
                    errorMessage.eleId = `${subSection.key}_${perception.text}_max`;
                    errorMessage.msg = "Max Value should not be empty";
                    break;
                  }
                  if (
                    [null, undefined, ""].includes(
                      perception.finalLoadingPercentage
                    )
                  ) {
                    errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
                    errorMessage.msg = "Percentage Value should not be empty";
                    break;
                  }
                  if (
                    +perception.finalLoadingPercentageMin >
                    +perception.finalLoadingPercentageMax
                  ) {
                    errorMessage.eleId = `${subSection.key}_${perception.text}_min`;
                    errorMessage.msg = "Min value should be less than Max value";
                    break;
                  }
                  if (
                    !perception.options.find(
                      (opt) =>
                        +opt.value == +perception.finalLoadingPercentageMin
                    )
                  ) {
                    errorMessage.eleId = `${subSection.key}_${perception.text}_min`;
                    errorMessage.msg =
                      "Min value should be selected from given options only";
                    break;
                  }
                  if (
                    !perception.options.find(
                      (opt) =>
                        +opt.value == +perception.finalLoadingPercentageMax
                    )
                  ) {
                    errorMessage.eleId = `${subSection.key}_${perception.text}_max`;
                    errorMessage.msg =
                      "Max value should be selected from given options only";
                    break;
                  }
                  if (
                    perception.finalLoadingPercentage <
                      perception.finalLoadingPercentageMin ||
                    perception.finalLoadingPercentage >
                      perception.finalLoadingPercentageMax
                  ) {
                    errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
                    errorMessage.msg =
                      "Final loading value should be between min and max value";
                    break;
                  }  else if ((+perception.finalLoadingPercentage % 1) != 0) {
                    errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
                    errorMessage.msg = 'Final loading value should not have decimal number';
                    break;
                  }
                } else {
                  if ([null, undefined, ""].includes(perception.value)) {
                    errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
                    errorMessage.msg = `${subSection.title}'s ${perception.text} value is empty`;
                    break;
                  } else if (perception.value < -100 || perception.value > 200) {
                    errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
                    errorMessage.msg = `${subSection.title}'s ${perception.text} value should be between -100 to 200`;
                    break;
                  }
                }
              }
            }
          }
        }
      } else {
        errorMessage.msg = "Invalid Config Data";
      }

      if (errorMessage.msg) {
        const swal: any = Swal({
          title: "Required",
          text: errorMessage.msg,
          type: "warning",
          confirmButtonColor: "#175788",
        })
        swal.finally(() => {
          if (errorMessage.eleId) {
            const element: any = document.querySelector('#'+errorMessage.eleId);
            if (element) {
              setTimeout(() => {
                element.scrollIntoView();
                element.focus();
              }, 500)
            }
          }
        });
        return false;
      }
    }
    return true;
  }

  validateData() {
    let errorMessage: any = {msg: null, eleId: null, collapseId: null};
    if (!this.selectedState) {
      errorMessage.eleId = 'state';
      errorMessage = "State should not be empty";
    }
    if (!this.selectedDistrict && !errorMessage) {
      errorMessage.eleId = 'district';
      errorMessage = "District should not be empty";
    }
    const ldWithSubSections = this.configData.ldWithSubSections;
    const ldNoSubSections = this.configData.ldNoSubSections;
    if (Array.isArray(ldWithSubSections) && Array.isArray(ldNoSubSections)) {
      for (let index = 0; index < ldWithSubSections.length; index++) {
        const element = ldWithSubSections[index];
        if (errorMessage.msg) {
          break;
        }
        for (
          let subSecIndx = 0;
          subSecIndx < element.subSections.length;
          subSecIndx++
        ) {
          const subSection = element.subSections[subSecIndx];
          for (
            let perpIndex = 0;
            perpIndex < subSection.uwPerceptionData.length;
            perpIndex++
          ) {
            if (errorMessage.msg) {
              break;
            }
            const perception = subSection.uwPerceptionData[perpIndex];
            if (perception.options) {
              if (
                [null, undefined, ""].includes(
                  perception.finalLoadingPercentageMin
                )
              ) {
                errorMessage.collapseId = element.loadingDiscountID;
                errorMessage.eleId = `${subSection.key}_${perception.text}_min`;
                errorMessage.msg = "Min Value should not be empty";
                break;
              }
              if (
                [null, undefined, ""].includes(
                  perception.finalLoadingPercentageMax
                )
              ) {
                errorMessage.collapseId = element.loadingDiscountID;
                errorMessage.eleId = `${subSection.key}_${perception.text}_max`;
                errorMessage.msg = "Max Value should not be empty";
                break;
              }
              if (
                [null, undefined, ""].includes(
                  perception.finalLoadingPercentage
                )
              ) {
                errorMessage.collapseId = element.loadingDiscountID;
                errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
                errorMessage.msg = "Percentage Value should not be empty";
                break;
              }
              if (
                +perception.finalLoadingPercentageMin >
                +perception.finalLoadingPercentageMax
              ) {
                errorMessage.collapseId = element.loadingDiscountID;
                errorMessage.eleId = `${subSection.key}_${perception.text}_min`;
                errorMessage.msg = "Min value should be less than Max value";
                break;
              }
              if (
                !perception.options.find(
                  (opt) => +opt.value == +perception.finalLoadingPercentageMin
                )
              ) {
                errorMessage.collapseId = element.loadingDiscountID;
                errorMessage.eleId = `${subSection.key}_${perception.text}_min`;
                errorMessage.msg =
                  "Min value should be selected from given options only";
                break;
              }
              if (
                !perception.options.find(
                  (opt) => +opt.value == +perception.finalLoadingPercentageMax
                )
              ) {
                errorMessage.collapseId = element.loadingDiscountID;
                errorMessage.eleId = `${subSection.key}_${perception.text}_max`;
                errorMessage.msg =
                  "Max value should be selected from given options only";
                break;
              }
              if (
                perception.finalLoadingPercentage <
                  perception.finalLoadingPercentageMin ||
                perception.finalLoadingPercentage >
                  perception.finalLoadingPercentageMax
              ) {
                errorMessage.collapseId = element.loadingDiscountID;
                errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
                errorMessage.msg =
                  "Final loading value should be between min and max value";
                break;
              } else if ((+perception.finalLoadingPercentage % 1) != 0) {
                errorMessage.collapseId = element.loadingDiscountID;
                errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
                errorMessage.msg = 'Final loading value should not have decimal number';
                break;
              }
            }
            else {
              if ([null, undefined, ""].includes(perception.value)) {
                errorMessage.collapseId = element.loadingDiscountID;
                errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
                errorMessage.msg = `${subSection.title}'s ${perception.text} value is empty`;
                break;
              } else if (perception.value < -100 || perception.value > 200) {
                errorMessage.collapseId = element.loadingDiscountID;
                errorMessage.eleId = `${subSection.key}_${perception.text}_percentage`;
                errorMessage.msg = `${subSection.title}'s ${perception.text} value should be between -100 to 200`;
                break;
              }
            }
          }
        }
      }
      for (let index = 0; index < ldNoSubSections.length; index++) {
        const element = ldNoSubSections[index];
        if (errorMessage.msg) {
          break;
        }
        if (
          [null, undefined, ""].includes(element.totalFinalLoadingPercentage)
        ) {
          errorMessage.msg = `${element.loadingDiscountName} value should not be empty`;
          break;
        }
      }
    } else {
      errorMessage.msg = "Invalid Config Data";
    }

    if (errorMessage.msg) {
     const swal: any =  Swal({
        title: "Required",
        text: errorMessage.msg,
        type: "warning",
        confirmButtonColor: "#175788",
      })
      swal.finally(() => {
        if (errorMessage.collapseId) {
          this.openCard(errorMessage.collapseId);
        }
        if (errorMessage.eleId) {
          const element: any = document.querySelector('#'+errorMessage.eleId);
          if (element) {
            setTimeout(() => {
              element.scrollIntoView();
              element.focus();
            }, 500)
          }
        }
      });
      return false;
    }
    return true;
  }

  onSubmit(formData) {
    if (!this.validateData()) {
      return false;
    }
    const configData = JSON.parse(JSON.stringify(this.configData));
    const ldWithSubSections = [];
    const ldNoSubSections = [];
    if (configData) {
      const reqldWithSubSections = configData.ldWithSubSections;
      const reqldNoSubSections = configData.ldNoSubSections;
      if (
        Array.isArray(reqldWithSubSections) &&
        Array.isArray(ldNoSubSections)
      ) {
        for (let index = 0; index < reqldWithSubSections.length; index++) {
          const element = reqldWithSubSections[index];
          const idWithSubSecObj = {
            loadingDiscountID: element.loadingDiscountID,
            loadingDiscountName: element.loadingDiscountName,
            isMandatory: element.isMandatory,
            isActive: element.isActive,
            ldWeightage: element.ldWeightage,
            subSections: [],
            totalFinalLoadingPercentage: element.totalFinalLoadingPercentage,
            sectionTotalLoadingDiscount: element.sectionTotalLoadingDiscount,
            isEnable: element.isEnable,
            seqno: element.seqno,
          };
          for (
            let subSecIndx = 0;
            subSecIndx < element.subSections.length;
            subSecIndx++
          ) {
            const subSection = element.subSections[subSecIndx];
            for (
              let perpIndex = 0;
              perpIndex < subSection.uwPerceptionData.length;
              perpIndex++
            ) {
              const perception = subSection.uwPerceptionData[perpIndex];
              const reqSubSec = {
                key: subSection.key,
                weightage: subSection.weightage,
                title: subSection.title,
                seqno: subSection.seqno,
                uwPerception: perception.text,
                finalLoadingPercentage:
                  perception.finalLoadingPercentage || perception.value,
                finalLoadingPercentageMin: perception.finalLoadingPercentageMin,
                finalLoadingPercentageMax: perception.finalLoadingPercentageMax,
                defaultUWPerception: perception.defaultUWPerception,
                finalLoadingWeightageApplied:
                  subSection.finalLoadingWeightageApplied,
              };
              idWithSubSecObj.subSections.push(reqSubSec);
            }
          }
          ldWithSubSections.push(idWithSubSecObj);
        }
        for (let index = 0; index < reqldNoSubSections.length; index++) {
          const element = reqldNoSubSections[index];
          const idNoSubSecObj = {
            loadingDiscountID: element.loadingDiscountID,
            loadingDiscountName: element.loadingDiscountName,
            isMandatory: element.isMandatory,
            isActive: element.isActive,
            ldWeightage: element.ldWeightage,
            subSections: [],
            totalFinalLoadingPercentage: element.totalFinalLoadingPercentage,
            sectionTotalLoadingDiscount: element.sectionTotalLoadingDiscount,
            isEnable: element.isEnable,
            seqno: element.seqno,
          };
          ldNoSubSections.push(idNoSubSecObj);
        }
      }
    }
    let request = {
      updatedBy: null,
      id: null,
      state: this.selectedState,
      district: this.selectedDistrict,
      ldWithSubSections,
      ldNoSubSections,
    };
    request = this.apiData.getParam(request, "district-wise");
    this.loading++;
    this.apiData
      .configureDefaultLoadingDiscountByDistrict(request)
      .then((res: any) => {
        if (res && res.status == 1) {
          this.validate.popUpAlert(res.message, "Success", "success");
          this.fetchConfigData();
        } else {
          this.validate.popUpAlert(res.message, "Error", "success");
        }
        this.loading--;
      })
      .catch((err) => {
        this.validate.popUpAlert(
          "Unable to Update District Discount",
          "Error",
          "success"
        );
        this.loading--;
      });
    console.log(request);
  }

  public async dropped(event: UploadEvent) {
    let getFile = event.files;
		let array = getFile[0].relativePath.split('.');
		if (array[array.length - 1] == "csv") {
			for (const droppedFile of event.files) {
				if (droppedFile.fileEntry.isFile) {
					const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
					fileEntry.file(async (file: File) => {
						console.log(droppedFile.relativePath, file);
            const fileData: any = await this.getExcelData(getFile[0]);
            if (this.validateFileData(fileData)) {
              this.fileList = file;
              this.fileName = droppedFile.relativePath;
            }
					});
				}
			}
		} else {
			this.validate.popUpAlert(this.alertService.ErrorText.incorrectfile, "", "error");
		}
  }

  async onFileChange (event) {
    let getFile = event.target.files;
		let array = getFile[0].name.split('.');
		if (array[array.length - 1] == "csv") {
      const fileData: any = await this.getExcelData(getFile[0]);
      if (this.validateFileData(fileData)) {
        this.fileList = getFile[0];
        this.fileName = this.fileList.name;
      }
		} else {
			this.validate.popUpAlert(this.alertService.ErrorText.incorrectfile, "", "error");
		}
  }


  validateFileData(fileData) {
    if (!fileData || !fileData.length) {
      this.validate.popUpAlert('File has no data', "Invalid file data", "warning");
      return false;
    }

    const headers = ["scheme","state","district","season","politically active","constituency of agriculture/ chief/ cabinet minister","name of political leader","govt support","farmer awareness","% loanee","major insured crop 1 (name)","major insured crop 1 (% share)","major insured crop 2 (name)","major insured crop 2 (% share)","irrigation %","type of irrigation","major loss years-1","reason of loss","major loss years-2","reason of loss 2","major loss years-3","reason of loss 3","major loss years-4","reason of loss 4","major loss years-5","reason of loss 5","comments/ historical issues","politically active loading","govt support loading","farmer awareness loading","enso","iod","monsoon forecast","enso loading","iod loading","monsoon forecast loading"];
    const avaliable_headers = Object.keys(fileData[0]);

    if (!headers.every(d => avaliable_headers.includes(d))) {
      this.validate.popUpAlert('Invalid file headers', "Invalid file data", "warning");
      return false;
    }
    let errorMsg = '';
    const isNumber =(val: any) => {
      if (val || val === 0) {
        if (typeof val == 'string') {
          return !isNaN(+val.replace('%', '').trim())
        } else if (typeof val == 'number') {
          return !isNaN(val);
        }
      }
      return false;
    }
    for (let i = 0; i < fileData.length; i++) {

      const data = fileData[i];
      if ((!data['state'] || this.selectedFileState.toLowerCase() != data['state'].toLowerCase()) && this.selectedFileState.toLowerCase() != 'all') {
        errorMsg = `Invalid state name on row no. ${i+2}`
        break;
      }

      if (!data['enso'] || !['Positive', 'Neutral', 'Negative'].includes(data['enso'].trim())) {
        errorMsg = `Invalid ENSO on row no. ${i+2}`
        break;
      }
      if (!isNumber(data['enso loading'])) {
        errorMsg = `Invalid ENSO Loading on row no. ${i+2}`
        break;
      }

      if (!data['iod'] || !['Positive', 'Neutral', 'Negative'].includes(data['iod'].trim())) {
        errorMsg = `Invalid IOD on row no. ${i+2}`
        break;
      }
      if (!isNumber(data['iod loading'])) {
        errorMsg = `Invalid IOD Loading on row no. ${i+2}`
        break;
      }

      if (!data['monsoon forecast'] || !['Excess', 'Normal', 'Deficit'].includes(data['monsoon forecast'].trim())) {
        errorMsg = `Invalid Monsoon forecast on row no. ${i+2}`
        break;
      }
      if (!isNumber(data['monsoon forecast loading'])) {
        errorMsg = `Invalid Monsoon forecast Loading on row no. ${i+2}`
        break;
      }


      if (!data['farmer awareness'] || !['Low', 'Medium', 'High'].includes(data['farmer awareness'].trim())) {
        errorMsg = `Invalid Farmer Awareness on row no. ${i+2}`
        break;
      }
      if (!isNumber(data['farmer awareness loading'])) {
        errorMsg = `Invalid farmer awareness loading on row no. ${i+2}`
        break;
      }

      if (!data['govt support'] || !['Low', 'Medium', 'High'].includes(data['govt support'].trim())) {
        errorMsg = `Invalid Govt Support on row no. ${i+1}`
        break;
      }
      if (!isNumber(data['govt support loading'])) {
        errorMsg = `Invalid Govt Support Loading on row no. ${i+1}`
        break;
      }

      if (!data['politically active'] || !['Positive', 'Neutral', 'Negative'].includes(data['politically active'].trim())) {
        errorMsg = `Invalid Politically Active on row no. ${i+1}`
        break;
      }
      if (!isNumber(data['politically active loading'])) {
        errorMsg = `Invalid Politically Active Loading on row no. ${i+1}`
        break;
      }
    }

    if (errorMsg) {
      this.validate.popUpAlert(errorMsg, "Invalid file data", "warning");
      return false
    }
    return true;
  }

  async getExcelData(file) {
    return await new Promise((res,rej) => {
      try {
        const reader = new FileReader();
      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* sheet data */
        const tableData = (XLSX.utils.sheet_to_json(ws, { header: 1 }));
        let header: any = [];
        const jsonData: any = [];
        for (let i = 0; i < tableData.length; i++) {
          const row: any = tableData[i];
          if (i == 0) {
            row.forEach((d) => header.push(d.trim().toLowerCase()));
          } else {
            const data: any = {};
            for (let j = 0; j < header.length; j++) {
              const cell = ['',null,undefined].includes(row[j]) ? '' : row[j];
              if (cell && typeof cell == 'string') {
                data[header[j]] = cell.trim();
              } else if (typeof cell == 'number') {
                data[header[j]] = cell;
              } else {
                data[header[j]] = cell || '';
              }
            }
            jsonData.push(data);
          }
        }
        res(jsonData);
      }
      reader.readAsBinaryString(file);


    } catch(err) {
      rej(err);
    }
  });
    }


  formFileRequest() {
		const formData = new FormData();
		formData.append("tenantId", this.alertService.getUserDetails('getTenant'));
		// formData.append("state", this.selectedState);
		formData.append("updatedBy", this.alertService.getUserDetails('getid'));
		formData.append("userFileName",this.fileName);
		formData.append('file', this.fileList, this.fileName);
		formData.append('state', this.selectedFileState);
		return formData
	}

  goto(process) {
    if (process == "next") {
      if (!this.selectedFileState) {
        this.validate.popUpAlert("Please select state first", "Warning", "warning");
        return
      }
      if (!this.fileName || !this.fileList) {
        this.validate.popUpAlert("Please select csv file", "Warning", "warning");
        return;
      }
      const request = this.formFileRequest();
      this.loading++
    this.apiData.masterUploadVerifyDistrictsLevelLoadingData(request).then((res: any) => {
      if (res && res.status == 1) {
        // if(res.result && res.result.warnings && res.result.warnings.length) {
        //   this.showWarnings(res);
        // } else {
          this.validatedDistrictLoadingDataUpload(res.result.districtUploadTransactionId)
        // }
        // this.validate.popUpAlert(res.message, "Success", "success");
      } else {
        this.validate.popUpAlert(res.message, "Error", "error");
      }
      this.loading--
    }).catch(err => {
      this.loading--
      this.validate.popUpAlert("Something went wrong", "", "error");
    })
    }
  }

  showWarnings(res) {
		var span = document.createElement("span");
		for (var i = 0; i < res.result.warnings.length; i++) {
			span.innerHTML += "<div style='text-align:left'>" + (i + 1) + ". " + res.result.warnings[i].warning + ". " + "</div>";
		}
		Swal({
			title: "Warnings",
			html: span,
			type: "warning",
			confirmButtonColor: "#175788",
      showCancelButton: true,
			timer: parseFloat('120000')
		}).then((result) => {
      if (result && result.value) {
        this.validatedDistrictLoadingDataUpload(res.result.districtUploadTransactionId)
      }
		})
	}

  validatedDistrictLoadingDataUpload(transactionID) {
    const request = {transactionId: transactionID}
      this.loading++
    this.apiData.masterUploadValidatedDistrictLoadingDataUpload(request).then((res: any) => {
      if (res && res.status == 1) {
        this.districtActionStatus = null;
        this.fileList = null;
        this.fileName = null;
        this.validate.popUpAlert(res.message, "Success", "success");
      } else {
        this.validate.popUpAlert(res.message, "Error", "error");
      }
      this.loading--
    }).catch(err => {
      this.loading--
      this.validate.popUpAlert("Something went wrong", "", "error");
    })
    }


    downloadSample() {
      this.loading++;
      this.apiData.masterUploadDownloadSampleDistrictLoadingUpload().then(res => {
        this.loading--;
        this.commonfunctions.csvDownloadFromServer(res);
      }, err => {
        this.loading--;
        this.commonfunctions.serviceErrorHandling(err);
      })
    }

    downloadCurentFile() {
      if (this.currentFilePath) {
       const link = document.createElement('a');
       link.href = this.currentFilePath;
       link.download = this.selectedFileState;
       link.click();
       link.remove()
      }
    }

    avoidDecimal(event: any): any | void {
      if (event.key == '.') {
        event.stopPropagation();
        return false;
      }
    }

    downloadCSVFileFromText() {
      const csvData = this.districtDefultHeader +'\n'+ this.distictDefaultValues;
      const textFile = new Blob([csvData], { type: 'text/plain' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(textFile);
      link.download = 'district_defult.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    onTabChange(tabId) {
      this.activeTab = tabId;
      if (this.activeTab == 2) {
        // this.inProgress = true
      }
    }


  }


